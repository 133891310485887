import { Box, Dialog, IconButton, Typography } from "@mui/material";
import ExoTable from "../exo/ExoTable";
import StartIcon from "@mui/icons-material/Start";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { ExoTableToolbar, ExoTableToolbarItem } from "../exo/ExoTableToolbar";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import NewProduct from "../new/NewProduct";
import DBSearch from "../special/DBSearch";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../label/MoneyLabel";

const SelectProduct = ({ onSelect, onClose = () => {} }) => {
  const [products, setProducts] = useState({});
  const [productsLoading, setProductsLoading] = useState(true);
  const { t } = useTranslation();
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "products",
      method: API_GET,
      onResponse: setProducts,
      onLoading: setProductsLoading,
    });
  }, [config]);

  function handleSelect(data) {
    onSelect(data);
  }

  // table
  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100px", // width of column
      cell: ({ row }) => <Box className="monospace">{row.nr}</Box>, // custom xml for cells in the column. you get the row as object
      hiddenMobile: true, // hides the column on mobile
    },
    {
      header: t("Name"), // header of column
      key: "name", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "auto", // width of column
    },
    {
      header: t("Price"), // header of column
      key: "price", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      cell: ({ row }) => <MoneyLabel money={row.price} />, // custom xml for cells in the column. you get the row as object
      sortable: true, // enable sort function for column
      width: "auto", // width of column
    },
    {
      header: "", // header of column
      key: "price", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "100px", // width of column
      cell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleSelect(row);
            onClose();
          }}
          color="success"
        >
          <StartIcon />
        </IconButton>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];

  // new product
  const [open, setOpen] = useState(false);
  function handleNewProductClick() {
    setOpen(!open);
  }

  function handleNewProductCreation(product) {
    var newProducts = { ...products };
    newProducts.data.unshift(product);
    setProducts({ ...newProducts });
  }

  return (
    <Box className="p-2 h-full w-full flex flex-col">
      <Typography variant="h2" align="center">
        {t("Products")}
      </Typography>
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Product")}
          icon={<PlaylistAddIcon />}
          onClick={handleNewProductClick}
        />
      </ExoTableToolbar>
      <Box className="h-full flex flex-col">
        <ExoTable
          data={products}
          isLoading={productsLoading}
          columns={Columns}
        />
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <NewProduct
          onClose={handleNewProductClick}
          onCreate={handleNewProductCreation}
        />
      </Dialog>
    </Box>
  );
};

export default SelectProduct;
