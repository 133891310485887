import { Box, Typography, useTheme } from "@mui/material";
import SavingsIcon from "@mui/icons-material/Savings";
import { tokens } from "../../../global/theme/tokens";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import { ChartsReferenceLine, LineChart } from "@mui/x-charts";
import MoneyLabel from "../../label/MoneyLabel";
import { useEffect, useRef, useState } from "react";
import Trans from "../../special/Trans";
import { numberToString } from "../../special/numberConverter";
import HourLabel from "../../label/HourLabel";
import { useConfig } from "../../../api/api";
import NumberIcon from "./NumberIcon";

const NumberIconChart = ({
  label = "DEMO",
  number = 9999,
  Icon = SavingsIcon,
  trend,
  chartData,
  color,
  isLoading,
  trendLabel = Trans("Since last quarter"),
  type = "money",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const typeMap = {
    money: {
      element: MoneyLabel,
      props: { money: number },
    },
    hour: {
      element: HourLabel,
      props: { minutes: number },
    },
  };

  const NumberDisplayElement = typeMap[type].element;

  return (
    <Box className="w-full flex flex-col justify-center items-start gap-1 h-full">
      <NumberIcon
        label={label}
        number={number}
        Icon={Icon}
        color={color}
        type={type}
      />
      {!isLoading && trend !== undefined && (
        <>
          <Box
            className="flex flex-row gap-3 items-center transition-opacity px-5"
            sx={{ opacity: Boolean(trend) ? 1 : 0 }}
          >
            <Typography
              className="flex gap-2 justify-start items-center"
              sx={{
                color:
                  trend < 0 ? colors.redAccent[600] : colors.greenAccent[600],
              }}
            >
              {trend < 0 ? <SouthEastIcon /> : <NorthEastIcon />}
              <b>{trend} %</b>
            </Typography>
            <Typography sx={{ fontSize: "9px", opacity: 0.6 }}>
              {trendLabel}
            </Typography>
          </Box>

          <MyChart dataset={chartData} isLoading={isLoading} />
        </>
      )}
    </Box>
  );
};

// chart
const MyChart = ({ dataset, color, isLoading }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const boxRef = useRef(null);
  const [boxDimensions, setboxDimensions] = useState({ width: 0, height: 0 });

  const handleResize = () => {
    // Update containerWidth when the container is resized
    if (!boxRef.current) return;
    const width = boxRef.current.offsetWidth;
    const height = boxRef.current.offsetHeight;
    setboxDimensions({ width, height });
  };

  useEffect(() => {
    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial setup
    handleResize();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [boxRef]);

  const gradientIdPositive = "positiveAreaGradient";

  // highlight lines
  const [highlights, setHighlights] = useState(null);

  function calcHighlights() {
    var min = null;
    var max = null;

    dataset.forEach((datapoint) => {
      if (datapoint.y < min) min = datapoint.y;
      if (datapoint.y > max) max = datapoint.y;
    });

    return [
      {
        label: `${numberToString(max)} ${
          config !== null ? config.currency["currencySymbol"] : ""
        }`,
        value: max,
        color: colors.greenAccent[400],
      },
      {
        label: `${numberToString(min)} ${
          config !== null ? config.currency["currencySymbol"] : ""
        }`,
        value: min,
        color: colors.redAccent[400],
      },
    ];
  }
  useEffect(() => {
    if (dataset && config) setHighlights(calcHighlights());
  }, [dataset, config]);

  const spaceX = 20;

  return (
    <Box
      className=" h-full flex justify-center"
      ref={boxRef}
      sx={{ width: `calc(100% + ${2 * spaceX}px)`, marginLeft: `-${spaceX}px` }}
    >
      <LineChart
        loading={isLoading}
        colors={[color || colors.blueAccent[500]]}
        dataset={dataset}
        series={[{ type: "line", dataKey: "y", area: true, showMark: false }]}
        xAxis={[
          {
            scaleType: "point",
            dataKey: "x",
            disableTicks: true,
          },
        ]}
        height={boxDimensions.height}
        width={boxDimensions.width}
        sx={{
          "& .MuiAreaElement-root": {
            fill: `url(#${gradientIdPositive})`,
          },
          "& .MuiChartsAxis-left": {
            display: "none",
          },
          "& .MuiChartsAxis-bottom": {
            "& text": {
              opacity: 0.5,
            },
            "& line": {
              display: "none",
            },
          },
        }}
      >
        <ChartsReferenceLine
          y={0}
          lineStyle={{ opacity: 0.3, strokeDasharray: 10 }}
        />

        <defs>
          {/* Gradient for the positive area */}
          <linearGradient
            id={gradientIdPositive}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor={`${color || colors.blueAccent[400]}ff`}
            />
            <stop
              offset="100%"
              stopColor={`${color || colors.blueAccent[400]}00`}
            />
          </linearGradient>
        </defs>
      </LineChart>
    </Box>
  );
};

export default NumberIconChart;
