import { Box } from "@mui/material";
import MediaGridItemSkeleton from "./MediaGridItemSkeleton";
import MediaGridItem from "./MediaGridItem";

const MediaGrid = ({ media, isLoading, onSelect, selectedItems }) => {
  return (
    <Box className="w-full h-full overflow-y-auto">
      <Box className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-2 w-full ">
        {isLoading ? (
          <>
            {Array(20).map((i) => (
              <MediaGridItemSkeleton key={i} />
            ))}
          </>
        ) : (
          <>
            {media.map((item) => (
              <MediaGridItem
                key={item.id}
                item={item}
                src={item.thumbnailUrl || item.temporaryUrl}
                onClick={onSelect}
                selected={Boolean(
                  selectedItems.findIndex(
                    (selected) => selected.id === item.id
                  ) != -1
                )}
              />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};
export default MediaGrid;
