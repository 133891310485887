import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { tokens } from "../../global/theme/tokens";

export const AiProcessing = ({ active, className, label, style }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  if (!active) return;

  const width = 0.8;
  const blurStrength = 6;

  return (
    <Box
      className={"flex justify-center items-center " + className}
      sx={{
        pointerEvents: "none",
      }}
    >
      <Box
        className="px-2 py-1 backdrop-blur-lg"
        sx={{ bgcolor: colors.glass }}
      >
        <Typography
          variant="h3"
          className="rainbow"
          sx={{ backgroundClip: "text", color: "transparent" }}
          fontWeight={600}
        >
          {label || t("AI is thinking")}.
        </Typography>
      </Box>
      <div
        className="w-full h-full absolute top-0 left-0"
        style={{
          pointerEvents: "none",
          filter: `blur(${blurStrength}px)`,
        }}
      >
        <div
          className={"w-full h-full rainbow"}
          style={{
            pointerEvents: "none",
            clipPath: `polygon(0% 0%, 0% 100%, ${width}% 100%, ${width}% ${width}%, ${
              100 - width
            }% ${width}%, ${100 - width}% ${100 - width}%, ${width}% ${
              100 - width
            }%, ${width}% 100%, 100% 100%, 100% 0%)`,
          }}
        />
      </div>
    </Box>
  );
};
