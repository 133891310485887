import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import CardGrid from "../../../components/cards/layout/CardGrid";
import { API_DELETE, DBRequest, useConfig } from "../../../api/api";
import FilePreviewCard from "../../../components/cards/FilePreviewCard";
import SpeedDialMenu from "../../../components/menu/SpeedDialMenu";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

export const ExpandPayroll = ({ row, onCreate, onDelete, onEdit }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  function handleDelete() {
    DBRequest({
      config,
      path: `payrolls/${row.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(row),
    });
  }

  const actions = [
    {
      icon: <EditIcon />,
      name: t("Edit"),
      onClick: () => onEdit(row),
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  return (
    <Box className="flex flex-col gap-4 relative overflow-y-auto">
      <CardGrid>
        <SpeedDialMenu actions={actions} />
        <FilePreviewCard file={row.file} colSpan={2} />
      </CardGrid>
    </Box>
  );
};
