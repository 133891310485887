import { Box, Button } from "@mui/material";
import { API_DELETE, API_PATCH, DBRequest, useConfig } from "../../../api/api";
import Feedback from "../../../components/special/Feedback";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TypeCustomSelect from "../../../components/form/form-fields/TypeCustomSelect";

const ActionsTab = ({ project, onDelete, onStatusChange }) => {
  const { t } = useTranslation();
  const config = useConfig();
  function handleDelete() {
    DBRequest({
      config,
      path: "projects/" + project.id,
      method: API_DELETE,
      onFeedback: handleFeedback,
    });
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
    type === "success" && onDelete(project);
  }

  const options = [
    {
      label: t("Running"),
      value: 0,
    },
    {
      label: t("Finished"),
      value: 1,
    },
    {
      label: t("Cancled"),
      value: 2,
    },
  ];

  function handleChange(key, value) {
    DBRequest({
      config,
      path: "projects/" + project.id,
      method: API_PATCH({ status: value }),
      onFeedback: (text, type) => {
        handleFeedbackStatus(text, type, value);
      },
    });
  }
  function handleFeedbackStatus(text, type, value) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
    type === "success" && onStatusChange(value);
  }

  return (
    <Box className="flex flex-col gap-4 pt-4">
      <TypeCustomSelect
        updateValidationOnChange={handleChange}
        startDataCollection={project}
        dataCollection={project}
        field={{ label: t("Status"), key: "status", options }}
        errors={{}}
      />
      <Button variant="contained" color="error" onClick={handleDelete}>
        {t("Delete Project")}
      </Button>
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

export default ActionsTab;
