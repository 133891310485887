import React, { useEffect, useRef } from "react";

const HtmlRenderer = ({ htmlString, sx }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const doc = iframe.contentDocument || iframe.contentWindow.document;

    // Write the HTML content into the iframe
    doc.open();
    doc.write(htmlString);
    doc.close();
  }, [htmlString]);

  return (
    <iframe
      ref={iframeRef}
      style={
        sx || {
          width: "100%",
          height: "100%",
          border: "none",
        }
      }
      title="html-renderer"
    />
  );
};

export default HtmlRenderer;
