import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMethodColor } from "./time-functions/useMethodColors";
import CircleIcon from "@mui/icons-material/Circle";

export const Legend = () => {
  const { t } = useTranslation();

  const colorMap = useMethodColor("", true);

  const legendItems = [
    {
      color: colorMap["default"].secondary,
      label: t("Existing"),
    },
    {
      color: colorMap["add"].secondary,
      label: t("New"),
    },
    {
      color: colorMap["update"].secondary,
      label: t("Updated"),
    },
    {
      color: colorMap["delete"].secondary,
      label: t("Deleted"),
    },
  ];

  return (
    <Box className="flex gap-6 flex-wrap py-2">
      {legendItems.map((item) => (
        <Typography
          variant="h6"
          className="flex items-center gap-1"
          sx={{ fontSize: "10px" }}
        >
          <CircleIcon sx={{ color: item.color, fontSize: "12px" }} />
          {item.label}
        </Typography>
      ))}
    </Box>
  );
};
