import { Box, Dialog, useTheme } from "@mui/material";
import Header from "../../components/special/Header";
import { tokens } from "../../global/theme/tokens";
import ExoTable from "../../components/exo/ExoTable";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { useEffect, useState } from "react";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ExpandableInfo from "../../components/special/ExpandableInfo";
import NewService from "../../components/new/NewService";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useTranslation } from "react-i18next";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { NavLink } from "react-router-dom";
import { Categories } from "../../components/special/Categories";
import ExtendService from "./ExtendService";
import { updateOrCreate } from "../../components/special/updateOrCreate";
import ExoDialog from "../../components/exo/ExoDialog";

const Services = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [services, setServices] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "services",
      method: API_GET,
      onResponse: setServices,
      onLoading: setIsLoading,
    });
  }, [config]);

  // table
  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Name"), // header of column
      key: "name", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100%", // width of column
      cell: ({ row }) => (
        <ExpandableInfo
          name={row.name}
          description={row.description}
          icon={<SupervisorAccountIcon />}
        />
      ),
    },
    {
      header: t("Categories"), // header of column
      key: "productCategories", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "200px", // width of column
      hiddenMobile: true, // hides the column on mobile
      cell: ({ row }) => <Categories data={row.serviceCategories} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Unit"), // header of column
      key: "unit", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: true, // hides the column on mobile
      width: "60px", // width of column
    },
    {
      header: t("Tax Rate"), // header of column
      key: "taxRate", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: true, // hides the column on mobile
      cell: ({ row }) => <Box className="monospace">{row.taxRate} %</Box>, // custom xml for cells in the column. you get the row as object
      width: "100px", // width of column
    },
    {
      header: t("Price"), // header of column
      key: "price", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "130px", // width of column
      hiddenMobile: true, // hides the column on mobile
      cell: ({ row }) => <Box className="monospace">{row.price} €</Box>, // custom xml for cells in the column. you get the row as object
    },
  ];

  // new service dialog
  const [open, setOpen] = useState(false);
  function handleClose() {
    setOpen(false);
  }

  function handleCreateService(newService) {
    setServices(updateOrCreate(services, newService));
  }

  // actions
  const [serviceToEdit, setServiceToEdit] = useState(null);
  function handleClickEdit(data) {
    setServiceToEdit(data);
    setOpen(true);
  }

  return (
    <>
      <Header title={t("Services")} />
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Service")}
          icon={<PlaylistAddIcon />}
          onClick={() => setOpen(true)}
        />
        <NavLink to="/categories/service">
          <ExoTableToolbarItem
            text={t("Categories")}
            icon={<BookmarksIcon />}
          />
        </NavLink>
      </ExoTableToolbar>
      <ExoTable
        data={services}
        isLoading={isLoading}
        columns={Columns}
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendService
            row={row}
            onUpdate={onUpdate}
            onClickEdit={handleClickEdit}
            onDelete={onDelete}
          />
        )}
      />

      <ExoDialog open={open} limitWidth>
        <NewService
          onClose={handleClose}
          onCreate={handleCreateService}
          startData={serviceToEdit}
        />
      </ExoDialog>
    </>
  );
};

export default Services;
