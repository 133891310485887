import {
  Box,
  Dialog,
  IconButton,
  Link,
  useTheme,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { AccessRule } from "./AccessRule";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CreateIcon from "@mui/icons-material/Create";
import { tokens } from "../../../../global/theme/tokens";

export const AccessGroup = ({ group, activeRules, onChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const ruleKeys = Object.keys(group.rules);
  const runes = group.rules;

  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="p">
            <b>{t(group.label)}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ borderTopWidth: "2px", borderColor: colors.primary[400] }}
        >
          <table className="w-full">
            <tbody>
              <tr>
                <th className=" text-start">{t("Access")}</th>
                <TabelHeader Icon={VisibilityOffIcon} text={t("nothing")} />
                <TabelHeader Icon={VisibilityIcon} text={t("read")} />
                <TabelHeader Icon={CreateIcon} text={t("read/write")} />
              </tr>
              {ruleKeys.map((key) => (
                <AccessRule
                  onChange={onChange}
                  key={runes[key].id}
                  rule={runes[key]}
                  activeRules={activeRules}
                />
              ))}
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const TabelHeader = ({ Icon, text }) => {
  return (
    <th className=" w-24">
      <Box className="flex flex-col justify-center items-center">
        <Icon />
        <Typography>{text}</Typography>
      </Box>
    </th>
  );
};
