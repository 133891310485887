import { Box, Dialog, IconButton, Link, useTheme, Button } from "@mui/material";
import TypeSkeleton from "../../../components/form/form-fields/TypeSkeleton";
import { AccessRole } from "./AccessRole";
import { useTranslation } from "react-i18next";
import ExoForm from "../../../components/exo/ExoForm";
import useFetch from "../../../api/useFetch";
import { API_POST, DBRequest, useConfig } from "../../../api/api";
import { updateOrCreate } from "../../../components/special/updateOrCreate";

export const AccessRoleList = ({
  onSelect = () => {},
  selected = {},
  apiData,
  setApiData,
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const fields = [
    {
      label: t("Name"),
      key: "name",
      type: "text",
    },
  ];

  function handleSubmit(data) {
    DBRequest({
      config,
      path: "access-roles",
      method: API_POST(data),
      onResponse: handleResponse,
    });
  }

  function handleResponse(data) {
    setApiData(updateOrCreate(apiData, { ...data.data, accessRules: [] }));
  }

  return (
    <Box
      className="flex flex-col gap-2 lg:h-full overflow-y-auto"
      sx={{ minWidth: "300px" }}
    >
      <ExoForm
        fields={fields}
        onSubmit={handleSubmit}
        className="mt-2 w-full flex gap-0"
      />
      {apiData ? (
        apiData.data.map((role) => (
          <AccessRole
            key={role.id}
            role={role}
            isSelected={selected && selected.id === role.id}
            onSelect={onSelect}
          />
        ))
      ) : (
        <TypeSkeleton n={50} />
      )}
    </Box>
  );
};
