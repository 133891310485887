import { Box, IconButton, useTheme } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import { useState } from "react";
import ExoDialog from "../exo/ExoDialog";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Cookies from "universal-cookie";

export const LanguageSwitcher = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const cookies = new Cookies();
  const current = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1);

  const languages = [
    {
      label: t("German"),
      key: "de",
      flag: "/assets/languages/german.svg",
    },
    {
      label: t("English"),
      key: "en",
      flag: "/assets/languages/english.svg",
    },
  ];

  function handleChange(key) {
    setOpen(false);
    cookies.set("lng", key, { path: "/", expires: nextYear });
    i18n.changeLanguage(key);
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <TranslateIcon />
      </IconButton>
      <ExoDialog open={open}>
        <Box className="mx-auto flex flex-col gap-4 h-full w-full max-w-[250px] justify-center items-center">
          {languages.map((lng) => (
            <Language key={lng.key} lng={lng} onClick={handleChange} />
          ))}
        </Box>
      </ExoDialog>
    </>
  );
};

const Language = ({ lng, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { label, flag, key } = lng;

  return (
    <Box
      onClick={() => onClick(key)}
      sx={{
        bgcolor: colors.card,
        border: `1px solid ${colors.card}`,
        ":hover": {
          bgcolor: colors.info + "30",
          borderColor: colors.info + "80",
        },
      }}
      className="px-4 py-2 rounded-lg flex items-center gap-2 w-full cursor-pointer"
    >
      <img src={flag} className="w-8" />
      {label}
    </Box>
  );
};
