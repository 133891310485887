import { useEffect, useState, Fragment } from "react";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../global/theme/tokens";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Pagination from "../../special/Pagination";
import Loader from "../../special/Loader";
import FormHelperText from "../FormHelperText";
import NoDataAnimation from "../../animation/NoDataAnimation";
import { useTranslation } from "react-i18next";
import DateLabel from "../../label/DateLabel";
import ExoDialog from "../../exo/ExoDialog";
import TypeSkeleton from "./TypeSkeleton";
import Transaction from "../../transactions/Transaction";
import MoneyLabel from "../../label/MoneyLabel";

const TypeTransaction = ({
  filter,
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  //popup
  const [open, setOpen] = useState(false);

  // actions
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (!selected.id) setSelected(dataCollection[field.key]);
  }, [dataCollection[field.key]]);

  function handleSelect(transaction) {
    setOpen(false);
    setSelected(transaction);
    updateValidationOnChange(field.key, transaction);
  }

  function handleDeselect() {
    setSelected({});
    updateValidationOnChange(field.key, {});
  }

  useEffect(() => {
    if (!dataCollection[field.key]) setSelected({});
  }, [dataCollection[field.key]]);

  return (
    <Box
      sx={{
        gridColumn: field.colspan
          ? `span ${field.colspan} / span ${field.colspan}`
          : "unset",
      }}
    >
      {!selected.id ? (
        <Box
          onClick={() => setOpen(true)}
          className="flex flex-row pr-4 pl-1 cursor-pointer justify-between items-center"
          sx={{
            borderRadius: "10px",
            height: "56px",
            backgroundColor: colors.bgInput,
          }}
        >
          <Typography variant="p" className="px-2">
            {field.label}
          </Typography>
        </Box>
      ) : (
        <Box
          className="flex flex-row pr-4 pl-1 justify-between items-center py-1"
          sx={{
            borderRadius: "10px",
            borderWidth: "0px",
            minHeight: "53px",
            backgroundColor: colors.bgInput,
            borderColor: colors.primary[300],
            ":hover": {
              borderColor: colors.grey[900],
            },
          }}
        >
          <Box className="flex flex-row justify-start items-center gap-1">
            <IconButton onClick={handleDeselect}>
              <RemoveCircleOutlineIcon />
            </IconButton>
            <Box className="flex flex-col justify-center items-start">
              <Typography variant="p" className="flex gap-4">
                <DateLabel date={selected.bookingDate} />
                <b>{selected.transactionPartnerName}</b>
              </Typography>
              <Typography variant="p" sx={{ color: colors.grey[600] }}>
                {selected.intendedUse}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="p"
              className="min-w-fit block"
              textAlign="right"
              sx={
                parseFloat(selected.openAmount) +
                  parseFloat(selected.assignedAmount) >=
                0
                  ? { color: colors.success }
                  : { color: colors.error }
              }
            >
              {selected.openAmount !== selected.amount && t("available") + " "}
              <b>
                <MoneyLabel
                  money={
                    parseFloat(selected.openAmount) +
                    parseFloat(selected.assignedAmount)
                  }
                />
              </b>
            </Typography>
            {selected.openAmount !== selected.amount && (
              <Typography variant="h6" textAlign="right">
                {t("of") + " "}
                <MoneyLabel money={selected.amount} />
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
      <ExoDialog open={open} onClose={() => setOpen(false)}>
        <TransactionList onSelect={handleSelect} filter={filter} open={open} />
      </ExoDialog>
    </Box>
  );
};

const TransactionList = ({ onSelect, filter, open }) => {
  const config = useConfig();
  const { t } = useTranslation();

  // transactions
  const [transactions, setTransactions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  var dateLog = "";
  var newDate = true;

  useEffect(() => {
    if (!open || !config) return;
    DBRequest({
      config,
      path: `transactions?sortOrder=desc&sortBy=bookingDate&perPage=100${
        filter ||
        `&openAmount[ne]=0&bookingDate[gt]=${config.accounting.accountingStartDate}`
      }`,
      method: API_GET,
      onResponse: setTransactions,
      onLoading: setIsLoading,
    });
  }, [open, config]);
  return (
    <Box className="flex flex-col gap-2 h-full overflow-y-auto">
      {isLoading ? (
        <TypeSkeleton n={30} />
      ) : (
        <>
          {transactions.data.map((element, index) => {
            const elementDate = element.bookingDate;
            if (elementDate !== dateLog) {
              dateLog = elementDate;
              newDate = true;
            } else {
              newDate = false;
            }
            return (
              <Fragment key={index}>
                {elementDate === dateLog && newDate && (
                  <Box className="pt-2 px-2">
                    <Typography variant="p">
                      <DateLabel date={elementDate} />
                    </Typography>
                  </Box>
                )}

                <Box className="px-2">
                  <Transaction key={index} data={element} onClick={onSelect} />
                </Box>
              </Fragment>
            );
          })}
          {transactions.data.length < 1 && (
            <Box className="h-full flex justify-center items-center">
              <NoDataAnimation />
            </Box>
          )}
        </>
      )}

      <Pagination
        filter={{
          order: false,
          name: "bookingDate",
          filter:
            filter ||
            `openAmount[ne]=0&bookingDate[gt]=${
              config && config.accounting.accountingStartDate
            }`,
        }}
        data={transactions}
        setData={setTransactions}
        setLoading={setIsLoading}
      />
    </Box>
  );
};

export default TypeTransaction;
