import {
  Box,
  Dialog,
  IconButton,
  Link,
  Paper,
  useTheme,
  Typography,
} from "@mui/material";
import { tokens } from "../../../global/theme/tokens";

export const AccessRole = ({ role, onSelect, isSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Paper
      className="px-4 py-1 rounded-lg transition-colors cursor-pointer"
      sx={{
        bgcolor: isSelected ? colors.primary[400] : colors.paper,
        ":hover": {
          bgcolor: colors.primary[400],
        },
      }}
      onClick={() => onSelect(role)}
    >
      <Typography variant="h5">{role.name}</Typography>
    </Paper>
  );
};
