import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { memo, useEffect, useRef, useState } from "react";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiIcon from "@mui/icons-material/Wifi";
import { formatIBAN } from "../../components/special/formatIban";
import { useIntersection } from "../../components/special/useIntersection";
import { getColorBrightness } from "../../components/special/getColorBrightness";
import { useTranslation } from "react-i18next";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const BankCardSlider = memo(function (props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  var bank;
  if (props.single) {
    bank = props.data[0];
  } else {
    bank = props.data[props.dataIndex];
  }

  if (!bank) return;

  if (props.isCenterSlide) {
    props.onBankUpdate(bank);
  }

  return (
    <Box className="w-full flex justify-center items-center pt-2 pb-2">
      <Box
        sx={{
          maxWidth: "400px",
          width: "100%",
        }}
      >
        <BankCard bank={bank} />
      </Box>
    </Box>
  );
});

export const BankCard = ({ bank, onRemove }) => {
  const containerRef = useRef(null);

  const [dynStyles, setDynStyles] = useState(null);
  const isVisible = useIntersection(containerRef, "0px");

  useEffect(() => {
    if (!containerRef.current) return;

    // Attach event listener for window resize
    window.addEventListener("resize", calcStyles);

    // Initial setup
    calcStyles();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", calcStyles);
    };
  }, [containerRef.current, isVisible]);

  const brightness = getColorBrightness(bank.color);
  const textColor = brightness > 0.5 ? "#1c1c1c" : "#ededed";

  function calcStyles() {
    const newStyles = {
      ">div": {
        color: textColor,
      },
      "& .bank-name": {
        top: calcPxValue(1),
        left: calcPxValue(1),
        color: textColor,
        fontSize: calcPxValue(1.1),
      },
      "& .bank-iban": {
        bottom: calcPxValue(1),
        left: calcPxValue(1),
        fontSize: calcPxValue(1.3),
        color: textColor,
      },
      "& .bank-holder": {
        bottom: calcPxValue(4.5),
        left: calcPxValue(1),
        width: calcPxValue(20),
        fontSize: calcPxValue(1.3),
        color: textColor,
      },
      "& .bank-chip": {
        top: calcPxValue(4.5),
        left: calcPxValue(4.5),
        width: calcPxValue(3.5),
      },
      "& .bank-wireless": {
        filter: `invert(${brightness > 0.5 ? 1 : 0})`,
        top: calcPxValue(3.5),
        right: calcPxValue(3.6),
        width: calcPxValue(1.5),
      },
      "& .bank-wave": {
        height: calcPxValue(10),
      },
    };
    setDynStyles(newStyles);
  }

  function calcPxValue(weight) {
    const containerWidth = containerRef.current.offsetWidth;
    const maxContainerWidth = 400;

    const widthToCalc =
      containerWidth > maxContainerWidth ? maxContainerWidth : containerWidth;

    const scale = 0.04;
    return `${weight * widthToCalc * scale}px`;
  }

  return (
    <Box
      className="p-4 rounded-xl relative backdrop-blur-md w-full"
      ref={containerRef}
      sx={{
        aspectRatio: "86/54",
        backgroundColor: bank.color,
        ...dynStyles,
      }}
    >
      {dynStyles && (
        <>
          <img
            className="bank-wave bottom-0 right-0 absolute object-cover w-full object-top"
            src="/assets/svg/cardwave.svg"
            alt="wave"
          />
          <Box className="bank-name absolute">{bank.bankName}</Box>
          <Box className="bank-holder absolute">{bank.accountHolder}</Box>
          <Box className="bank-iban absolute">{formatIBAN(bank.iban)}</Box>
          <img
            className="bank-chip absolute"
            src="/assets/svg/ecChip.svg"
            alt="EC Chip"
          />
          <img
            className="bank-wireless absolute"
            src="/assets/svg/wireless.svg"
            alt="EC Chip"
          />
          {onRemove && (
            <IconButton
              onClick={() => onRemove(bank)}
              sx={{ position: "absolute" }}
              className="bottom-1 right-1"
            >
              <DeleteForeverIcon sx={{ color: textColor }} />
            </IconButton>
          )}
          <BankConnection connected={bank.connected} />
        </>
      )}
    </Box>
  );
};

const BankConnection = ({ connected }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={connected ? t("Connected") : t("Not Connected")}>
      <Box className="absolute -top-1 -right-1 bg-white rounded-full p-2 shadow-md">
        {connected ? (
          <WifiIcon color={connected ? "success" : "error"} />
        ) : (
          <WifiOffIcon color={connected ? "success" : "error"} />
        )}
      </Box>
    </Tooltip>
  );
};

export default BankCardSlider;
