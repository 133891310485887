import { useTheme, Box, Typography, Button } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";

const TemplateVariables = ({ variables = [], onClick, className = "" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className={"h-full w-full py-4 overflow-y-auto " + className}
      sx={{ backgroundColor: colors.primary[300] }}
    >
      <Typography variant="h2" textAlign="center">
        Template Variables
      </Typography>
      <VarList varsData={variables} onClick={onClick} />
    </Box>
  );
};

const VarList = ({ varsData, onClick }) => {
  return (
    <Box className="p-4 overflow-auto flex flex-col items-start">
      {varsData.map((element, index) => (
        <Var onClick={onClick} key={"var" + index} varData={element} />
      ))}
    </Box>
  );
};
const Var = ({ varData, onClick }) => {
  return (
    <Button onClick={() => onClick(varData.value)} className="rounded-lg p-1">
      {varData.name}
    </Button>
  );
};

export default TemplateVariables;
