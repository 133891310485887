import { Box } from "@mui/material";
import DataModule from "./../DataModules";
import { useTranslation } from "react-i18next";

const EmployeeContact = ({ employeeInfo }) => {
  const { t } = useTranslation();

  const dataModules = [
    {
      type: "mail",
      label: t("Business Mail"),
      value: employeeInfo.email,
    },
    {
      type: "mail",
      label: t("Private Mail"),
      value: employeeInfo.privateMail,
    },
    {
      type: "phone",
      label: t("Business Phone"),
      value: employeeInfo.phone,
    },
    {
      type: "phone",
      label: t("Private Phone"),
      value: employeeInfo.privatePhone,
    },
  ];

  return (
    <Box className="flex flex-col gap-3 items-start">
      {dataModules.map((display, index) => (
        <DataModule
          key={index}
          type={display.type}
          label={display.label}
          value={display.value}
        />
      ))}
    </Box>
  );
};

export default EmployeeContact;
