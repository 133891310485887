import {
  useTheme,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState, Fragment, useEffect } from "react";
import { tokens } from "../../global/theme/tokens";
import {
  API_GET,
  API_PATCH,
  DBRequest,
  DBNoSetData,
  useConfig,
} from "../../api/api";
import TemplateEditor from "../../components/editor/TemplateEditor";
import TemplatePreview from "../../components/templates/TemplatePreview";
import Loader from "../../components/special/Loader";

const Templates = ({ onFeedback }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "templates",
      method: API_GET,
      onResponse: handleSetData,
      onLoading: setIsLoading,
    });
  }, [config]);

  const [selected, setSelected] = useState({});
  function handleSelect(e) {
    const selectedTemplate = data.data.find(
      (template) => template.id === e.target.value
    );
    // Only update the state if the selected template is different from the current one
    if (selectedTemplate.id !== selected.id) {
      setSelected(selectedTemplate);
    }
  }

  function handleSetData(response) {
    setData(response);
    setSelected(response.data[0]);
  }

  const [openEditor, setOpenEditor] = useState(false);
  function handleCloseEditor() {
    setOpenEditor(false);
  }
  function handleSaveEditor(updatedTemplate) {
    const newTemplates = data.data.map((template) => {
      if (template.id === updatedTemplate.id) {
        return updatedTemplate;
      }
      return template;
    });
    setData({ ...data, data: newTemplates });
    setSelected(updatedTemplate);
  }

  return (
    <Box className="w-full h-full flex flex-col gap-2 overflow-hidden pt-2">
      {isLoading ? (
        <Loader active={true} message="Loading Templates ..." />
      ) : (
        <>
          <FormControl fullWidth>
            <InputLabel id="dselect-label">Template</InputLabel>
            <Select
              labelId="dselect-label"
              id="dselect"
              value={selected.id}
              label="Template"
              onChange={handleSelect}
            >
              {data.data.map((element, index) => (
                <MenuItem key={"template-" + element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selected && (
            <TemplatePreview
              sx={{
                height: "calc(100% - 62px)",
              }}
              onEdit={() => setOpenEditor(true)}
              data={selected}
              scaleable={true}
              paginate={true}
            />
          )}
        </>
      )}
      <TemplateEditor
        onFeedback={onFeedback}
        open={openEditor}
        templateData={selected}
        onClose={handleCloseEditor}
        onSave={handleSaveEditor}
      />
    </Box>
  );
};

export default Templates;
