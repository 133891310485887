import { Box, Typography } from "@mui/material";
import { leadingZero } from "../../../scenes/time-tracking/worktime";

const WorkDayHourLabel = ({ resHeight, hour, width }) => {
  return (
    <Box
      className=" flex justify-start items-center"
      sx={{
        height: `${resHeight}px`,
        minWidth: `${width}px!important`,
        maxWidth: `${width}px!important`,
        maxHeight: `${resHeight}px`,
        minHeight: `${resHeight}px`,
      }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: "500", fontSize: "10px" }}
      >{`${leadingZero(hour, 2)}:00`}</Typography>
    </Box>
  );
};

export default WorkDayHourLabel;
