import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import ExoAvatar from "../../components/exo/ExoAvatar";

const EmployeeCard = ({ employee, onSelect, selected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="flex items-center w-full p-2 rounded-lg flex-row gap-3 transition-transform backdrop-blur-lg"
      sx={{
        backgroundColor: selected ? colors.selected : colors.glass,
        cursor: onSelect ? "pointer" : "default",
        ":hover": {
          backgroundColor: onSelect ? colors.selected : colors.card,
        },
      }}
      onClick={onSelect ? () => onSelect(employee.id) : null}
    >
      <ExoAvatar
        picture={employee.picture ? employee.picture.temporaryUrl : ""}
        name={employee.firstName + " " + employee.lastName}
        icon={employee.icon}
      />
      <Box className="flex justify-start items-center">
        <Box>
          <Typography variant="h4">
            {employee.firstName + " " + employee.lastName}
          </Typography>
          <Typography variant="h5" sx={{ color: colors.primary[600] }}>
            {employee.position}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeCard;
