import { Box, Button, Typography } from "@mui/material";
import Div100vh from "react-div-100vh";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import ExoStepper from "../../components/exo/ExoStepper";
import * as Yup from "yup";
import StepCustomStartUp from "../../components/steps/StepCustomStartUp";
import { useEffect, useState } from "react";
import Loader from "../../components/special/Loader";
import { API_GET, API_PATCH, DBRequest, useConfig } from "../../api/api";
import { NavLink } from "react-router-dom";

const Install = () => {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const config = useConfig();
  const colorMode = cookies.get("colorMode");

  const [steps, setSteps] = useState(null);
  const [defaultData, setDefaultData] = useState({});

  useEffect(() => {
    DBRequest({
      config,
      path: `setup-form`,
      method: API_GET,
      onResponse: prepairSteps,
      onError: handleRedirect,
    });
  }, [config]);

  const validationMap = {
    address: Yup.object().shape({
      street: Yup.string().required("Access is required"),
      houseNumber: Yup.string().required("Access is required"),
      zipCode: Yup.string().required("Access is required"),
      city: Yup.string().required("Access is required"),
      country: Yup.string().required("Access is required"),
    }),
    image: Yup.object().shape({
      id: Yup.string().required("Access is required"),
    }),
    logo: Yup.object().shape({
      id: Yup.string().required("Access is required"),
    }),
  };

  function prepairSteps(apiData) {
    // generate steps
    const stepGroups = apiData.data;
    var newSteps = [];
    var startData = {};

    stepGroups.forEach((group) => {
      // fields & validation
      var preValidationSchema = {};
      var fields = [];

      group.items.forEach((element) => {
        if (element.type === "checkbox") startData[element.key] = "false";
        if (element.type === "rhythm") startData[element.key] = 1;

        if (element.required) {
          preValidationSchema[element.key] =
            validationMap[element.type] ||
            Yup.string().required("Access is required");
        }
        fields.push({
          key: element.key,
          label: `${t(element.label)} ${
            element.required ? "" : t("(optional)")
          }`,
          type: element.type,
        });
      });

      const validationSchema = Yup.object().shape(preValidationSchema);

      //add step
      newSteps.push({
        label: t(group.label),
        onNext: (data) => syncConfigData(data, group.api, fields),
        content: ({
          step,
          activeStep,
          setActiveStep,
          dataCollection,
          updateValidation,
          updateDataCollection,
        }) => (
          <StepCustomStartUp
            validationSchema={validationSchema}
            startData={startData}
            fields={fields}
            updateValidation={updateValidation}
            dataCollection={dataCollection}
            updateDataCollection={updateDataCollection}
          />
        ),
      });
    });
    setDefaultData(startData);
    setSteps(newSteps);
  }

  function handleRedirect() {
    window.location.href = "/";
  }

  function syncConfigData(data, path, fields) {
    var reqData = {};
    fields.forEach((field) => {
      reqData[field.key] = data[field.key];
    });

    DBRequest({ customPath: true, path, method: API_PATCH(reqData) });
  }

  const finalStep = {
    operation: ({ dataCollection, handleResponse, setIsLoading }) => {
      setIsLoading(false);
      handleResponse({});
      cookies.remove("initialSetup");
    }, // your operation with the data
    noReset: true,
    noClose: true,
    content: (response) => (
      <Box className="flex h-full w-full flex-col">
        <Typography variant="h2" textAlign="center">
          {t("Welcome to our ExoPaper Family")}
        </Typography>
        <Typography variant="h6" textAlign="center">
          {t("Your installation has been set up successfully.")}
        </Typography>
        <NavLink to="/" className="mx-auto mt-2">
          <Button variant="contained" color="success">
            {t("Lets Go")}
          </Button>
        </NavLink>
      </Box>
    ), // component with final operations and the feedback
  };

  return (
    <Div100vh>
      <Box className="w-full h-full p-4 flex flex-col gap-6 overflow-y-hidden">
        <Box className="flex flex-col justify-center items-center gap-2">
          <Typography variant="h2" textAlign="center">
            {t("Welcome to")}
          </Typography>
          <img
            className="h-24"
            src={
              colorMode === "dark"
                ? "/assets/svg/exopaper-text-darkmode.svg"
                : "/assets/svg/exopaper-text.svg"
            }
          />
        </Box>
        <Box className="h-full overflow-y-auto p-2">
          <Loader active={!Boolean(steps)} />
          {steps && (
            <ExoStepper
              startDataCollection={defaultData}
              data={steps}
              finalStep={finalStep}
            />
          )}
        </Box>
      </Box>
    </Div100vh>
  );
};

export default Install;
