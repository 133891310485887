import moment from "moment";

export const periodStepMap = {
  week: {
    param: "1 week",
    label: "Week",
    startDate: moment().subtract(20, "week").format("YYYY-MM-DD"),
  },
  month: {
    param: "1 month",
    label: "Month",
    startDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
  },
  quarter: {
    param: "1 quarter",
    label: "Quarter",
    startDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
  },
  year: {
    param: "1 year",
    label: "Year",
    startDate: moment().subtract(4, "year").format("YYYY-MM-DD"),
  },
};

export const periodTrendLabel = {
  week: "Compared to last week",
  month: "Compared to last month",
  quarter: "Compared to last quarter",
  year: "Compared to last year",
};
