import { useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import { useTranslation } from "react-i18next";

const Recruting = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  return (
    <>
      <Header title={t("Recruting")} />
    </>
  );
};

export default Recruting;
