import { Box, Button, Typography, useTheme } from "@mui/material";
import LabeledBox from "../../special/LabeledBox";
import { useTranslation } from "react-i18next";
import { Status } from "../../special/Status";
import ExoDialog from "../../exo/ExoDialog";
import ExoForm from "../../exo/ExoForm";
import { useState } from "react";
import { ProjectAddElementButton } from "../../exo/project/ProjectAddElementButton";
import FolderIcon from "@mui/icons-material/Folder";
import uuid from "react-uuid";
import { tokens } from "../../../global/theme/tokens";

export const TypeFolderStatus = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  function handleChange(value) {
    updateValidationOnChange(field.key, value);
  }

  function handleAdd(element) {
    var newElements = dataCollection[field.key] || [];

    newElements.push({ ...element, id: uuid() });
    updateValidationOnChange(field.key, newElements);
    setOpen(false);
  }

  function handleRemove(element) {
    var newElements = dataCollection[field.key].filter(
      (obj) => obj.id !== element.id
    );
    updateValidationOnChange(field.key, newElements);
  }

  const fields = [
    {
      label: t("Name"),
      key: "label",
      type: "text",
    },
    {
      label: t("Variant"),
      key: "variant",
      type: "customSelect",
      options: [
        {
          label: <Status variant="draft" text={t("Draft")} />,
          value: "draft",
        },
        {
          label: <Status variant="open" text={t("Open")} />,
          value: "open",
        },
        {
          label: <Status variant="accepted" text={t("Checked")} />,
          value: "accepted",
        },
        {
          label: <Status variant="rejected" text={t("Cancled")} />,
          value: "rejected",
        },
        {
          label: <Status variant="expired" text={t("Outdated")} />,
          value: "expired",
        },
        {
          label: <Status variant="finished" text={t("Finished")} />,
          value: "finished",
        },
      ],
    },
    {
      label: t("Folder Name"),
      key: "directory",
      type: "text",
    },
    {
      label: t("Final State"),
      key: "isFinalState",
      type: "checkbox",
    },
  ];

  return (
    <>
      <LabeledBox label={field.label} className=" flex gap-2">
        {dataCollection[field.key] &&
          dataCollection[field.key].map((element, index) => (
            <StatusElement
              key={index}
              element={element}
              onRemove={handleRemove}
            />
          ))}
        <ProjectAddElementButton onClick={() => setOpen(true)} />
      </LabeledBox>
      <ExoDialog open={open} limitWidth>
        <ExoForm
          fields={fields}
          header={t("New Status")}
          onCancle={() => setOpen(false)}
          onSubmit={handleAdd}
        />
      </ExoDialog>
    </>
  );
};

const StatusElement = ({ element, onRemove }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Button
      onClick={() => onRemove(element)}
      variant="contained"
      color="error"
      className="rounded-lg p-2 flex flex-col gap-2 flex-wrap backdrop-blur-lg"
      sx={{ backgroundColor: colors.glass }}
    >
      <Status variant={element.variant} text={element.label} />
      <Typography className="flex items-center gap-2 w-full" variant="h6">
        <FolderIcon />
        {element.directory}
      </Typography>
    </Button>
  );
};
