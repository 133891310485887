import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { API_GET, DBRequest, useConfig } from "../../api/api";

const ExoInfiniteScroll = ({
  children,
  apiData,
  setApiData,
  className,
  scrollContainerRef,
  triggerDist = 500,
}) => {
  useEffect(() => {
    // Ensure the scrollContainerRef is defined
    if (scrollContainerRef && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      var isLoadingNewEntries = false;
      var nextUrl = apiData.links.next;
      // Function to handle scroll event
      const handleScroll = () => {
        const remainingScroll =
          container.scrollHeight - container.clientHeight - container.scrollTop;
        if (triggerDist > remainingScroll && nextUrl) {
          loadMore(
            isLoadingNewEntries,
            handleLoadingState,
            handleHasNextState,
            nextUrl
          );
        }
        function handleLoadingState(state) {
          isLoadingNewEntries = state;
        }
        function handleHasNextState(state) {
          nextUrl = state;
        }
      };

      // Attach scroll event listener to the container
      container.addEventListener("scroll", handleScroll);

      // Cleanup function
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrollContainerRef, apiData]);

  function loadMore(loading, setLoadingState, setNextUrl, nextUrl) {
    if (loading || !nextUrl) return;
    DBRequest({
      customPath: true,
      path: nextUrl,
      onResponse: (res) => {
        setNextUrl(res.links.next);
        handleResponse(res);
      },
      onLoading: setLoadingState,
      method: API_GET,
    });
  }

  function handleResponse(response) {
    var newApiData = { ...response };
    const a1 = apiData.data;
    const a2 = response.data;
    newApiData.data = a1.concat(a2);
    setApiData(newApiData);
  }

  return <Box className={className}>{children}</Box>;
};

export default ExoInfiniteScroll;
