import { SelectPayroll } from "../../select/SelectPayroll";

export const NewPayrollRelation = ({ onSubmit }) => {
  function handleSubmit(payroll) {
    onSubmit({ payroll, type: "payroll" });
  }

  return (
    <SelectPayroll
      hasOpenAmount
      apiUrl={"payrolls/open"}
      onSelect={handleSubmit}
      className=" w-full"
    />
  );
};
