import { useState, useEffect } from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import ExoAvatar from "../../exo/ExoAvatar";
import TypeSkeleton from "./TypeSkeleton";
import FormHelperText from "../FormHelperText";
import { useTranslation } from "react-i18next";

const TypeCompanyId = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  value,
}) => {
  const { t } = useTranslation();

  const [companies, setCompanies] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: field.filter ? `companies?${field.filter}` : "companies",
      method: API_GET,
      onResponse: setCompanies,
      onLoading: setIsLoading,
    });
  }, [config]);
  function handleChangeSelect(e) {
    updateValidationOnChange(field.key, e.target.value);
  }

  return (
    <FormControl fullWidth>
      {isLoading ? (
        <TypeSkeleton />
      ) : (
        <>
          <InputLabel id={"companyId-select-" + field.key}>
            {field.label}
          </InputLabel>
          <Select
            labelId={"companyId-select-" + field.key}
            value={value || dataCollection[field.key] || ""}
            label={field.label}
            onChange={handleChangeSelect}
            error={Boolean(errors[field.key])}
            sx={{
              "& .MuiSelect-select": {
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "row",
                gap: "8px",
                padding: "0px 32px 0px 14px",
                height: "53px!important",
              },
            }}
          >
            <MenuItem
              value={""}
              className="flex flex-row gap-2 justify-start items-center"
            >
              {t("Unselect")}
            </MenuItem>
            {companies.data.map((element) => (
              <MenuItem
                key={"company-" + element.id}
                value={element.id}
                className="flex flex-row gap-2 justify-start items-center"
              >
                <ExoAvatar
                  picture={element.logo ? element.logo.thumbnailUrl : ""}
                  name={element.name}
                  size="30px"
                  type={element.logo ? "company" : "contact"}
                />
                {element.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            error={Boolean(errors[field.key])}
            helperText={errors[field.key]}
          />
        </>
      )}
    </FormControl>
  );
};

export default TypeCompanyId;
