import { Box, Button, FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RenderHTML from "../../components/preview/RenderHTML";
import StyledTextContainer from "../../components/preview/StyledTextContainer";

const AcceptTerms = ({ onAccept, onwCompanyData }) => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [status, setStatus] = useState([false, false]);

  function handleChange(e) {
    var newStatus = [...status];
    newStatus[activeStep] = e.target.checked;
    setStatus(newStatus);
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
    if (activeStep === 1) onAccept();
  }

  return (
    <Box className="h-full flex flex-col overflow-y-auto">
      {activeStep === 0 && (
        <TermsOfService
          onChange={handleChange}
          status={status[activeStep]}
          data={onwCompanyData.termsAndConditionsDigitalSignature}
        />
      )}
      {activeStep === 1 && (
        <Privacy
          onChange={handleChange}
          status={status[activeStep]}
          data={onwCompanyData.privacyPolicy}
        />
      )}
      <Box className="flex justify-center pt-2">
        <Button
          variant="contained"
          disabled={!status[activeStep]}
          onClick={handleNext}
        >
          {t("Next")}
        </Button>
      </Box>
    </Box>
  );
};

const TermsOfService = ({ onChange, status, data }) => {
  const { t } = useTranslation();
  return (
    <Box className="flex flex-col gap-2 w-full">
      <StyledTextContainer
        fullWidth
        backgroundColor={"transparent"}
        className="w-full"
      >
        <RenderHTML HTML={data} />
      </StyledTextContainer>
      <Box className="px-2">
        <FormControlLabel
          control={<Switch onChange={onChange} value={status} />}
          label={t(
            "I have read, understood and accept the general terms of service"
          )}
        />
      </Box>
    </Box>
  );
};

const Privacy = ({ onChange, status, data }) => {
  const { t } = useTranslation();
  return (
    <Box className="flex flex-col gap-2">
      <StyledTextContainer fullWidth backgroundColor={"transparent"}>
        <RenderHTML HTML={data} />
      </StyledTextContainer>
      <Box className="px-2">
        <FormControlLabel
          control={<Switch onChange={onChange} value={status} />}
          label={t("I have read, understood and accept the privacy statement")}
        />
      </Box>
    </Box>
  );
};

export default AcceptTerms;
