import { useTheme, Typography, Box } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useEffect, useState } from "react";

import { useConfig } from "../../api/api";
import { numberToString } from "../special/numberConverter";
import { useTranslation } from "react-i18next";
import RenderHTML from "./RenderHTML";
import StyledTextContainer from "./StyledTextContainer";
import MoneyLabel from "../label/MoneyLabel";
import { PositionName } from "../select/SelectPositions";

const PreviewPositions = ({
  positions,
  columns,
  showSum = true,
  sx = {},
  groupKey = "name",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const [sum, setSum] = useState({ netto: 0.0, tax: 0.0 });

  const config = useConfig();
  const defaultCols = [
    {
      label: t("Position"),
      key: "position",
      align: "left",
      hiddenMobile: true,
      className: "monospace",
      width: "80px",
    },
    {
      label: t("Description"),
      key: "name",
      align: "left",
      hiddenMobile: false,
      type: "custom",
      width: "auto",
      cell: (row) => <PositionName element={row} />,
    },
    {
      label: t("Quantity"),
      key: "amount",
      align: "right",
      hiddenMobile: false,
      type: "number",
      width: "70px",
    },
    {
      label: t("Price"),
      key: "price",
      align: "right",
      hiddenMobile: false,
      type: "number",
      width: "100px",
      unit: config && config.currency.currencySymbol,
    },
    {
      label: t("Total Netto"),
      key: "netto",
      align: "right",
      hiddenMobile: false,
      type: "netto",
      width: "120px",
      unit: config && config.currency.currencySymbol,
    },
  ];

  if (!columns) columns = defaultCols;

  useEffect(() => {
    var sum = { netto: 0.0, tax: 0.0 };
    positions.forEach((pos) => {
      if (pos.isOptional) return;
      sum.netto += pos.price * pos.amount;
      sum.tax += pos.price * pos.amount * (pos.taxRate / 100);
    });
    setSum(sum);
  }, [positions]);

  return (
    <Box
      className="overflow-auto"
      sx={{
        "& th:first-child": {
          borderTopLeftRadius: "8px",
        },
        "& th:last-child": {
          borderTopRightRadius: "8px",
        },
        "& tr:last-child>td:first-child": {
          borderBottomLeftRadius: "8px",
        },
        "& tr:last-child>td:last-child": {
          borderBottomRightRadius: "8px",
        },

        "& .position-group": {
          bgcolor: colors.selected + "50",
          "& td:first-child ": {
            borderColor: colors.selected,
            borderLeftWidth: "10px",
          },
          "& td:last-child ": {
            borderColor: colors.selected,
            borderRightWidth: "10px",
          },
        },
        ...sx,
      }}
    >
      <table className="w-full" cellPadding={"0px"}>
        <tbody>
          <TableHeader cols={columns} />
          {positions.map((posRow, index) => {
            return posRow.isGroup ? (
              <TableRowGroup
                key={"group-" + index}
                cols={columns}
                row={posRow}
                groupKey={groupKey}
              />
            ) : (
              <TableRow key={"pos-" + index} cols={columns} row={posRow} />
            );
          })}
          {showSum && <TableSum sum={sum} cols={columns} />}
        </tbody>
      </table>
    </Box>
  );
};

const TableHeader = ({ cols }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <tr style={{ borderBottom: `2px solid ${colors.grey[500]}` }}>
      {cols.map((col, index) => (
        <HeadElement key={"head-" + index} col={col} />
      ))}
    </tr>
  );
};
const HeadElement = ({ col }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  // configure style classes
  var headerClasses = "md:table-cell";
  headerClasses += " text-" + col.align;
  if (col.hiddenMobile) headerClasses += " hidden";

  return (
    <th
      style={{
        zIndex: "0",
        backdropFilter: "none",
        width: col.width || "auto",
        minWidth: col.width || "auto",
      }}
      className={headerClasses}
    >
      <Typography
        className="h-full w-full px-2 py-1"
        sx={{ textAlign: col.align, fontWeight: 600 }}
      >
        {col.label}
      </Typography>
    </th>
  );
};

const TableRow = ({ row, cols }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  // calc netto and tax
  const rowNetto = row.price * row.amount;
  const rowTax = row.price * row.amount * row.taxRate;

  return (
    <tr>
      {cols.map((col, index) => (
        <RowElement
          key={col.key + index}
          col={col}
          row={row}
          tax={rowTax}
          netto={rowNetto}
        />
      ))}
    </tr>
  );
};
const RowElement = ({ col, row, tax, netto }) => {
  const isOptional = Boolean(row.isOptional);
  const bracketKeys = ["price", "netto"];
  const showBrackets = isOptional && bracketKeys.includes(col.key);

  // configure style classes
  var rowClasses = "md:table-cell px-1 align-top ";
  rowClasses += " break-words whitespace-pre-wrap text-" + col.align;

  if (col.className) rowClasses += ` ${col.className}`;
  if (col.hiddenMobile) rowClasses += " hidden";
  if (col.cell) return <td>{col.cell(row)}</td>;
  switch (col.type) {
    default:
      return <td className={rowClasses}>{row[col.key]}</td>;
    case "number":
      return (
        <td className={rowClasses + " monospace"}>
          {showBrackets && "("}
          {numberToString(row[col.key], 2)} {col.unit}
          {showBrackets && ")"}
        </td>
      );
    case "tax":
      return (
        <td className={rowClasses + " monospace"}>
          {showBrackets && "("}
          {numberToString(tax, 0)} {col.unit}
          {showBrackets && ")"}
        </td>
      );
    case "netto":
      return (
        <td className={rowClasses + " monospace"}>
          {showBrackets && "("}
          {numberToString(netto, 2)} {col.unit}
          {showBrackets && ")"}
        </td>
      );
  }
};

const TableSum = ({ sum, cols }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const { t } = useTranslation();
  return (
    <>
      <tr
        style={{
          borderTop: "3px double " + colors.grey[500],
        }}
      >
        <EmptyCells cols={cols} />
        <td className="text-right px-1 pt-1">{t("Netto")}</td>
        <td className="text-right monospace px-1 pt-1">
          {`${numberToString(sum.netto)} ${
            config && config.currency.currencySymbol
          }`}
        </td>
      </tr>
      <tr>
        <EmptyCells cols={cols} />
        <td className="text-right px-1">{t("Tax")}</td>
        <td className="text-right monospace px-1">
          {`${numberToString(sum.tax)} ${
            config && config.currency.currencySymbol
          }`}
        </td>
      </tr>
      <tr>
        <EmptyCells cols={cols} />
        <td className="text-right px-1 py-2">
          <Typography variant="h5">{t("Brutto")}</Typography>
        </td>
        <td className="text-right px-1 py-2">
          <Typography variant="h5" className=" monospace">
            <b>
              <MoneyLabel money={sum.netto + sum.tax} />
            </b>
          </Typography>
        </td>
      </tr>
    </>
  );
};

const EmptyCells = ({ cols, space = 2 }) => {
  var count = 0;
  return (
    <>
      {cols.map((col, index) => {
        if (col.hiddenMobile === false && count != space) {
          count++;
          return;
        }
        return (
          <td
            key={"sum-col" + index}
            className={col.hiddenMobile ? "hidden md:table-cell" : ""}
          ></td>
        );
      })}
    </>
  );
};

const TableRowGroup = ({ row, cols, groupKey }) => {
  return (
    <tr className="position-group">
      {cols.map((col, i) => (
        <RowGroupElement
          key={`group-${i}`}
          col={col}
          row={row}
          groupKey={groupKey}
        />
      ))}
    </tr>
  );
};

const RowGroupElement = ({ row, col, groupKey }) => {
  if (col.key === groupKey || col.key === "position")
    return (
      <RowElement
        col={{
          ...col,
          className: `${col.className} font-bold`,
        }}
        row={row}
      />
    );
  return <td></td>;
};

export default PreviewPositions;
