import { useTheme, Box, Skeleton, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import DBSearch from "../special/DBSearch";
import AddIcon from "@mui/icons-material/Add";
import PersonRadio from "../radio/PersonRadio";
import NewContact from "../new/NewContact";
import { useTranslation } from "react-i18next";
import ExoProcessing from "../exo/ExoProcessing";

const SelectContacts = ({
  onSelect,
  filter = "",
  preSelected,
  name,
  dataCollection,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();
  // request
  const [searchResults, setSearchResults] = useState(null);

  // aktions
  const [selected, setSelected] = useState(preSelected || []);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (dataCollection) setSelected(dataCollection);
  }, [dataCollection]);

  function handleChange(newElement) {
    const selectedIndex = selected.findIndex(
      (selectedObj) => selectedObj.id === newElement.id
    );
    if (selectedIndex === -1) {
      // Object not in the selected array, add it

      handleSearchResultSelect(newElement);
    } else {
      // Object already in the selected array, remove it
      const updatedArray = [...selected];
      updatedArray.splice(selectedIndex, 1);
      setSelected(updatedArray);
      onSelect(updatedArray);
    }
  }

  function handleSearchResultSelect(element) {
    DBRequest({
      config,
      path: `contacts/${element.id}`,
      onResponse: (res) => {
        setSelected([...selected, res.data]);
        onSelect([...selected, res.data], res.data);
      },
      onLoading: setIsProcessing,
    });
  }

  function isSelected(id) {
    return selected.some((selectedObj) => selectedObj.id === id);
  }

  // new contact
  const [openNewContact, setOpenNewContact] = useState(false);
  function handleNewContact(data) {
    var newElements = searchResults;
    const newHit = {
      id: data.data.id,
      label: `${data.data.firstName} ${data.data.lastName}`,
      subLable: data.data.position,
      image: "",
    };
    newElements.unshift(newHit);
    setSearchResults(newElements);
  }

  return (
    <Box className=" flex flex-col gap-2 h-full w-full overflow-y-auto pr-2">
      <Box className="sticky top-0 z-10">
        <DBSearch param={"contacts"} onSearch={setSearchResults} />
      </Box>
      {!searchResults ? (
        <>
          {[...Array(8)].map((element, index) => (
            <Skeleton key={"skeleton-select-company-" + index} height={64} />
          ))}
        </>
      ) : (
        <>
          <PersonRadio
            person={{
              logo: {},
              icon: <AddIcon />,
              id: 0,
              firstName: t("New Contact"),
              lastName: "",
            }}
            radioKey="contactRadio"
            key={name + "select-contact-" + 0}
            onClick={() => {
              setOpenNewContact(true);
            }}
          />
          {searchResults.map((element, index) => (
            <PersonRadio
              person={{
                logo: {},
                id: element.id,
                firstName: element.label,
                lastName: "",
                position: element.subLable,
              }}
              key={name + "select-contact-" + element.id}
              onClick={(e) => handleChange(element)}
              checked={isSelected(element.id)}
            />
          ))}
          <Dialog
            onClose={() => setOpenNewContact(false)}
            open={openNewContact}
          >
            <NewContact
              onClose={() => setOpenNewContact(false)}
              onCreate={handleNewContact}
            />
          </Dialog>
        </>
      )}
      <ExoProcessing open={isProcessing} label={t("Loading Company")} />
    </Box>
  );
};

export default SelectContacts;
