import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

export const ExoShowLayout = () => {
  return (
    <Box className="flex flex-col h-full w-full overflow-x-hidden overflow-y-auto">
      <Outlet />
    </Box>
  );
};
