import { useState, useEffect } from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import ExoAvatar from "../../exo/ExoAvatar";
import TypeSkeleton from "./TypeSkeleton";
import FormHelperText from "../FormHelperText";

const TypeProjectId = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
}) => {
  const [projects, setProjects] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "projects/own",
      method: API_GET,
      onResponse: setProjects,
      onLoading: setIsLoading,
    });
  }, [config]);
  function handleChangeSelect(e) {
    updateValidationOnChange(field.key, e.target.value);
  }

  return (
    <FormControl fullWidth>
      {isLoading ? (
        <TypeSkeleton />
      ) : (
        <>
          <InputLabel id={"projectId-select-" + field.key}>
            {field.label}
          </InputLabel>
          <Select
            labelId={"projectId-select-" + field.key}
            value={dataCollection[field.key] ? dataCollection[field.key] : ""}
            label={field.label}
            onChange={handleChangeSelect}
            error={Boolean(errors[field.key])}
            sx={{
              "& .MuiSelect-select": {
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "row",
                gap: "8px",
                padding: "0px 32px 0px 14px",
                height: "53px!important",
              },
            }}
          >
            <MenuItem
              value={""}
              className="flex flex-row gap-2 justify-start items-center"
            >
              Unselect
            </MenuItem>
            {projects.data.map((element) => (
              <MenuItem
                key={"company-" + element.id}
                value={element.id}
                className="flex flex-row gap-2 justify-start items-center"
              >
                <ExoAvatar
                  picture={
                    element.client.logo ? element.client.logo.thumbnailUrl : ""
                  }
                  name={element.name}
                  size="40px"
                  type={
                    element.clientType === null ? "company" : element.clientType
                  }
                />
                {element.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            error={Boolean(errors[field.key])}
            helperText={errors[field.key]}
          />
        </>
      )}
    </FormControl>
  );
};

export default TypeProjectId;
