import { Box } from "@mui/material";
import { useState } from "react";
import ExoDialog from "../../../components/exo/ExoDialog";
import { PhaseWrapper } from "./PhaseWrapper";
import ProjectTimeCard from "../ProjectTimeCard";
import { useTimeTracking } from "../../../components/time-tracking/AppTimeProvider";

export const ProjectList = ({ projects }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const { clockIn, activeProject } = useTimeTracking();

  function handleProjectSelection(project) {
    var hasPackages = false;
    project.phases.forEach((phase) => {
      if (phase.workPackages.length > 0) hasPackages = true;
    });
    if (hasPackages) {
      setSelectedProject(project);
    } else {
      clockIn(project);
    }
  }
  return (
    <Box
      className="flex justify-around sm:justify-start flex-wrap gap-2 h-full"
      sx={{
        overflowY: activeProject ? "hidden" : "auto",
        height: activeProject ? "600px" : "auto",
      }}
    >
      {projects.map((project) => (
        <ProjectTimeCard
          key={project.id}
          project={project}
          onClick={handleProjectSelection}
        />
      ))}
      <ExoDialog
        open={Boolean(selectedProject)}
        onClose={() => setSelectedProject(null)}
      >
        {selectedProject && (
          <Box className="h-full flex flex-col overflow-y-auto">
            <Box className="flex flex-col gap-5">
              {selectedProject.phases.map(
                (phase) =>
                  phase.workPackages.length > 0 && (
                    <PhaseWrapper
                      key={phase.id}
                      phase={phase}
                      project={selectedProject}
                      onDeselect={() => setSelectedProject(null)}
                    />
                  )
              )}
            </Box>
          </Box>
        )}
      </ExoDialog>
    </Box>
  );
};
