import React, { useEffect, useState } from "react";
import { useTheme, Box, IconButton } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import ReactAce from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import "react-resizable/css/styles.css";
const Resizable = require("react-resizable").Resizable; // Use require to import Resizable

const CodeEditor = ({
  data,
  setData,
  editorRef = null,
  height = 800,
  className = "",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [code, setCode] = useState(data);
  const [isResizing, setIsResizing] = useState(false);
  const [editorHeight, setEditorHeight] = useState(height);

  useEffect(() => {
    setCode(data);
  }, [data]);

  const handleCodeChange = (newCode) => {
    setCode(newCode);
    setData(newCode);
  };

  const handleResizeStart = () => {
    setIsResizing(true);
  };

  const handleResizeStop = (event, { size }) => {
    setIsResizing(false);
    setEditorHeight(size.height);
  };

  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "z") {
      undo();
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "y") {
      redo();
    }
  };

  function undo() {
    editorRef.current.editor.undo();
  }

  function redo() {
    editorRef.current.editor.redo();
  }

  useEffect(() => {
    const editor = editorRef.current.editor;
    if (!editor) {
      return;
    }

    editor.container.addEventListener("keydown", handleKeyDown);

    return () => {
      editor.container.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Resizable
      height={editorHeight}
      width={100}
      handlestyles={{
        right: {
          display: isResizing ? "block" : "none",
        },
      }}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
    >
      <Box className={className}>
        <Box
          className="p-1 flex gap-2 sticky top-0 left-0 z-10"
          sx={{
            backgroundColor: colors.primary[400],
          }}
        >
          <IconButton>
            <UndoIcon />
          </IconButton>
          <IconButton>
            <RedoIcon />
          </IconButton>
        </Box>
        <ReactAce
          mode="html"
          theme="monokai"
          onChange={handleCodeChange}
          value={code}
          highlightActiveLine={true}
          ref={editorRef}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
          }}
          style={{
            width: "100%",
            height: "calc(100% - 45px)",
          }}
        />
      </Box>
    </Resizable>
  );
};

export default CodeEditor;
