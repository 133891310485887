import { Box } from "@mui/material";
import ExoForm from "../exo/ExoForm";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import FormSkeleton from "../form/FormSkeleton";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useFetch from "../../api/useFetch";

const StepContractVars = ({
  dataCollection,
  updateValidation,
  updateDataCollection,
  activeStep,
  setActiveStep,
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const [startDataCollection, setStartDataCollection] = useState({});

  const [fields, setFields] = useState(null);

  // get whole preset if selected or changed
  useEffect(() => {
    if (!config || !dataCollection.preset) return;
    updateDataCollection({ vars: {} });
    DBRequest({
      config,
      path: `contractpresets/${dataCollection.preset.id}`,
      method: API_GET,
      onResponse: createFields,
    });
  }, [dataCollection.preset, config]);

  function createFields(data) {
    // prepare preset vars fields and values
    var newPresetFields = [];
    var presetValues = {};
    var presetValidationSchema = {};
    const presetVars = [...data.data.defaultVars, ...data.data.customVars];

    const autorenewing = presetVars.find(
      (obj) => obj.identifier === "%%autorenewing%%"
    );
    const isAutorenewing = autorenewing && autorenewing.defaultValue === "1";

    presetVars.forEach((element) => {
      // hidden fields
      if (
        element.type === "hidden" ||
        (isAutorenewing && element.identifier === "%%endDate%%") ||
        element.identifier === "%%autorenewing%%"
      )
        return (presetValues[element.id] =
          element.value || element.defaultValue);

      // normal fields
      newPresetFields.push({
        label: t(element.displayName),
        key: element.id,
        type: element.type,
        options: element.options,
      });
      if (element.type !== "customMultiSelect") {
        presetValidationSchema[element.id] = Yup.string().required(
          `${element.displayName} ${t("is required")}`
        );
      } else {
        presetValidationSchema[element.id] = Yup.array()
          .min(1)
          .required(`${element.displayName} ${t("is required")}`);
      }

      presetValues[element.id] = element.value || element.defaultValue;
    });

    // set fields and values

    setFields({
      preset: newPresetFields,
      validationSchema: Yup.object().shape(presetValidationSchema),
    });
    updateDataCollection({ vars: presetValues });
    setStartDataCollection(presetValues);
  }

  function handleChange(data, key, isValid) {
    updateDataCollection({ [key]: data });
    updateValidation(isValid);
  }

  const presetVarsForm = useRef(null);

  return (
    <Box className="flex flex-col gap-4 pt-2">
      {!fields ? (
        <FormSkeleton n={8} />
      ) : (
        <ExoForm
          ref={presetVarsForm}
          noControls
          noDifferences
          onChange={(e, isValid) => handleChange(e, "vars", isValid)}
          fields={fields.preset}
          startDataCollection={startDataCollection}
          validationSchema={fields.validationSchema}
        />
      )}
    </Box>
  );
};

export default StepContractVars;
