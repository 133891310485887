import { Box, Typography, useTheme } from "@mui/material";
import useFetch from "../../api/useFetch";
import RenderHTML from "../../components/preview/RenderHTML";
import StyledTextContainer from "../../components/preview/StyledTextContainer";
import Div100vh from "react-div-100vh";
import Loader from "../../components/special/Loader";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { tokens } from "../../global/theme/tokens";

const TermsOfService = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const cookies = new Cookies();
  const colorMode = cookies.get("colorMode");
  const { t } = useTranslation();
  const { apiData } = useFetch("terms-of-service");

  return (
    <Div100vh>
      <img
        src={
          colorMode === "dark"
            ? "/assets/uploads/background-dark.webp"
            : "/assets/uploads/background-bright.webp"
        }
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: "0",
          left: "0",
          objectPosition: "32%",
          zIndex: "-5",
        }}
      />
      <Box className="p-2 sm:py-5 w-full h-full overflow-y-auto flex flex-col items-center gap-4 md:gap-8">
        {!apiData ? (
          <Loader active={true} />
        ) : (
          <Box
            className=" backdrop-blur-lg rounded-lg p-4"
            sx={{ maxWidth: "800px;", bgcolor: colors.selected }}
          >
            <Typography variant="h2" className="pb-4">
              {t("Terms of Service")}
            </Typography>
            <StyledTextContainer
              fullWidth
              backgroundColor={"transparent"}
              className="w-full"
            >
              <RenderHTML HTML={apiData.data} />
            </StyledTextContainer>
          </Box>
        )}
      </Box>
    </Div100vh>
  );
};

export default TermsOfService;
