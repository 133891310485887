import { Box } from "@mui/material";
import WorkDayColumn from "./WorkDayColumn";
import moment from "moment/moment";
import { useEffect, useRef, useState } from "react";
import WorkDayHourLabel from "./WorkDayHourLabel";
import uuid from "react-uuid";

const WorkDayGrid = ({
  workdays,
  setWorkdays,
  snapToGrid,
  snapRatio,
  hourStart,
  hourEnd,
  res,
  resHeight,
  edit,
  labelFormat = "YYYY-MM-DD dddd",
  customColumnLabel,
  noInfo,
  onDateClick,
  userId,
}) => {
  const [workdayKeys, setWorkdayKeys] = useState([]);
  const [enableTouchScroll, setEnableTouchScroll] = useState(true);
  const exoWorkdayRef = useRef(null);
  const labelWidth = 40;
  const lineWidth = 1;
  const dayLabelHeight = 55;

  useEffect(() => {
    if (workdays) setWorkdayKeys(Object.keys(workdays.data));
  }, [workdays]);

  function handleUpdate(updatedTime, dateKey) {
    var newWorkdays = workdays.data;

    const updatedIndex = newWorkdays[dateKey].findIndex(
      (time) => time.id === updatedTime.id
    );

    newWorkdays[dateKey][updatedIndex] = {
      ...newWorkdays[dateKey][updatedIndex],
      ...updatedTime,
    };

    setWorkdays({ data: { ...newWorkdays } });
    setWorkdayKeys([...Object.keys(workdays.data)]);
  }

  function handleAdd(addTime, dateKey) {
    var newWorkdays = workdays.data;
    const existingEntryIndex = newWorkdays[dateKey].findIndex(
      (obj) => obj.id === addTime.id
    );
    if (existingEntryIndex === -1) {
      newWorkdays[dateKey].push({
        ...addTime,
        id: uuid(),
      });
    } else {
      newWorkdays[dateKey][existingEntryIndex] = addTime;
    }
    setWorkdays({ data: { ...newWorkdays } });
    setWorkdayKeys([...Object.keys(workdays.data)]);
  }

  function handleDelete(deleteTime, dateKey) {
    var newWorkdays = workdays.data;
    const updatedIndex = newWorkdays[dateKey].findIndex(
      (time) => time.id === deleteTime.id
    );
    newWorkdays[dateKey][updatedIndex] = {
      ...newWorkdays[dateKey][updatedIndex],
      ...deleteTime,
    };
    setWorkdays({ data: { ...newWorkdays } });
    setWorkdayKeys([...Object.keys(workdays.data)]);
  }

  return (
    <Box
      className="flex flex-row w-full h-full overflow-y-auto"
      ref={exoWorkdayRef}
      sx={{
        overflowY: enableTouchScroll ? "auto" : "hidden",
      }}
    >
      <Box
        className="flex flex-col h-full"
        sx={{
          paddingTop: `${dayLabelHeight + resHeight / 2}px`,
          gap: `${resHeight * (res - 1)}px`,
        }}
      >
        {[...Array(hourEnd - hourStart)].map((e, i) => (
          <WorkDayHourLabel
            key={i}
            width={labelWidth}
            resHeight={resHeight}
            hour={i + hourStart}
          />
        ))}
      </Box>
      {workdayKeys.map((date, i) => (
        <WorkDayColumn
          userId={userId}
          onDateClick={onDateClick}
          dayLabelHeight={dayLabelHeight}
          edit={edit}
          setEnableTouchScroll={setEnableTouchScroll}
          exoWorkdayRef={exoWorkdayRef}
          workdays={workdays.data}
          index={i}
          key={i}
          workdayKey={date}
          columnDate={date}
          dayLabel={customColumnLabel ? date : moment(date).format(labelFormat)}
          columnMoment={moment(date)}
          onUpdate={handleUpdate}
          onAdd={handleAdd}
          onDelete={handleDelete}
          snapToGrid={snapToGrid}
          snapRatio={snapRatio}
          hourStart={hourStart}
          hourEnd={hourEnd}
          res={res}
          resHeight={resHeight}
          noInfo={noInfo}
        />
      ))}
    </Box>
  );
};

export default WorkDayGrid;
