import {
  Box,
  Dialog,
  IconButton,
  Link,
  useTheme,
  Typography,
  Button,
} from "@mui/material";
import { AccessGroup } from "./access-editor/AccessGroup";
import { useEffect, useState } from "react";
import {
  API_GET,
  API_PATCH,
  API_POST,
  DBRequest,
  useConfig,
} from "../../../api/api";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/special/Loader";

export const AccessRoleEditor = ({ selected, onSave }) => {
  const config = useConfig();
  const { t } = useTranslation();

  const [accessGroups, setAccessGroups] = useState(null);
  const [activeRules, setActiveRules] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    DBRequest({
      config,
      path: "access-rules",
      method: API_GET,
      onResponse:
        cleanUpJansBullshitCodeBecouseILikeHimSoMuchAndDontWantToKillHim,
    });
  }, [config]);

  useEffect(() => {
    if (selected) {
      // create a list of current ids
      var newActiveRules = [];
      selected.accessRules.forEach((rule) => {
        newActiveRules.push(rule.id);
      });

      setActiveRules(newActiveRules);
    }
  }, [selected]);

  function cleanUpJansBullshitCodeBecouseILikeHimSoMuchAndDontWantToKillHim(
    data
  ) {
    const baseData = data.data;
    var cleanAccessGroups = [];

    baseData.forEach((group) => {
      var cleanRules = {};

      group.items.forEach((rule) => {
        const splittetKey = rule.permissionKey.split(":");
        if (!cleanRules[splittetKey[0]]) {
          // create rule entry
          cleanRules[splittetKey[0]] = {
            label: rule.name,
            comment: rule.comment,
            options: {
              [splittetKey[1]]: rule.id,
            },
          };
        } else {
          // add rule option
          cleanRules[splittetKey[0]].options = {
            ...cleanRules[splittetKey[0]].options,
            [splittetKey[1]]: rule.id,
          };
        }
      });

      // add items to group
      const cleanGroup = {
        label: group.label,
        rules: cleanRules,
      };
      cleanAccessGroups.push(cleanGroup);
    });
    setAccessGroups(cleanAccessGroups);
  }

  function handleChange(id, options) {
    // Extract numbers to remove from the object
    const numbersToRemove = Object.values(options);

    // Filter out the numbers
    var filteredArray = activeRules.filter(
      (number) => !numbersToRemove.includes(number)
    );
    // add the selected id
    if (id !== 0) {
      filteredArray.push(id);
    }
    setActiveRules(filteredArray);
  }

  function handleSave() {
    if (!selected) return window.alert("You need to selecte a Role");
    DBRequest({
      config,
      path: `access-roles/${selected.id}/access-rules`,
      method: API_POST({ ruleIds: activeRules }),
      onLoading: setIsSaving,
      onResponse: storeSavedRules,
    });
  }

  function storeSavedRules() {
    var newRules = [];
    activeRules.forEach((rule) => {
      newRules.push({ id: rule });
    });

    onSave({ ...selected, accessRules: newRules });
  }

  return (
    <Box className="flex flex-col overflow-hidden w-full">
      <Loader active={isSaving} />
      <Box className="flex flex-col gap-2 w-full h-full overflow-y-auto pr-1">
        {accessGroups &&
          accessGroups.map((group) => (
            <AccessGroup
              key={group.id}
              group={group}
              activeRules={activeRules}
              onChange={handleChange}
            />
          ))}
      </Box>
      <Box className="p-2 flex flex-row-reverse  justify-between">
        <Button variant="contained" color="success" onClick={handleSave}>
          {t("Save")}
        </Button>
      </Box>
    </Box>
  );
};
