import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { Box, Button, useTheme, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import ExoTable from "../exo/ExoTable";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../label/MoneyLabel";
import useFetch from "../../api/useFetch";
import ExoTableRecipient from "../exo/ExoTableRecipient";

const SelectInvoice = ({
  apiUrl,
  onSelect,
  filter = "",
  selected = {},
  button,
  buttonIcon,
  buttonLabel,
  hasOpenAmount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { apiData, isLoading } = useFetch(apiUrl || `invoices?${filter}`);

  //tabel
  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "100px", // width of column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Title"), // header of column
      key: "title", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "auto", // width of column
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Recipient"), // header of column
      key: "recipient", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "auto", // width of column
      cell: ({ row }) => <ExoTableRecipient row={row} />, // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
      hiddenMobile: true,
    },
    {
      header: t("Netto"), // header of column
      key: "amount", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "170px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => (
        <Typography className=" monospace">
          <MoneyLabel money={row.amount} />
        </Typography>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Brutto"), // header of column
      key: "bruttoAmount", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "170px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => (
        <Typography className=" monospace">
          <MoneyLabel money={row.bruttoAmount} />
          {row.discountRate > 0 && (
            <Typography
              className="flex justify-end gap-1 monospace"
              variant="h6"
            >
              {t("Discount")}
              <MoneyLabel
                money={
                  row.bruttoAmount - (row.bruttoAmount * row.discountRate) / 100
                }
              />
            </Typography>
          )}
        </Typography>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Open"), // header of column
      key: "openAmount", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "200px", // width of column
      hiddenMobile: !hasOpenAmount, // hides the column on mobile
      hiddenDesktop: !hasOpenAmount, // hides the column on desktop
      cell: ({ row }) => (
        <Typography className=" monospace">
          <MoneyLabel money={row.openAmount} />
          {row.discountRate > 0 && (
            <Typography
              className="flex justify-end gap-1 monospace"
              variant="h6"
            >
              {t("Discount")}
              <MoneyLabel
                money={
                  row.openAmount - (row.bruttoAmount * row.discountRate) / 100
                }
              />
            </Typography>
          )}
        </Typography>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
  ];

  return (
    <ExoTable
      className="h-full w-full"
      data={apiData}
      isLoading={isLoading}
      columns={Columns}
    />
  );
};

export default SelectInvoice;
