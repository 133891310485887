import { Box, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { tokens } from "../../global/theme/tokens";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ExoForm from "../../components/exo/ExoForm";
import { API_GET, API_POST, DBRequest, useConfig } from "../../api/api";
import { useEffect, useState } from "react";
import Loader from "../../components/special/Loader";
import Div100vh from "react-div-100vh";
import Cookies from "universal-cookie";

export const GetStarted = () => {
  const { token } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const cookies = new Cookies();
  const config = useConfig();
  const colorMode = cookies.get("colorMode");

  const [isLoading, setIsLoading] = useState(false);
  const defaultStartData = { color: "#5e9ac8" };

  const fields = [
    {
      label: `${t("Color")} (${t("optional")})`,
      type: "color",
      key: "color",
    },
    {
      label: t("Salutation"),
      type: "text",
      key: "salutation",
    },
    {
      label: t("First Name"),
      type: "text",
      key: "firstName",
    },
    {
      label: t("Last Name"),
      type: "text",
      key: "lastName",
    },
    {
      label: t("Mail"),
      type: "text",
      key: "email",
    },
    {
      label: t("Phone"),
      type: "text",
      key: "phone",
    },
    {
      label: t("Is CEO"),
      type: "checkbox",
      key: "isCeo",
    },
  ];

  const validationSchema = Yup.object().shape({
    salutation: Yup.string().required("Salutation is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
  });

  function handleSubmit(data) {
    setIsLoading(true);
    DBRequest({
      config,
      path: "get-started",
      method: API_POST({ token, ...data }),
      onLoading: setIsLoading,
      onResponse: handleRedirect,
    });
  }

  useEffect(() => {
    DBRequest({
      config,
      path: `get-started-token-status/${token}`,
      method: API_GET,
      onError: handleRedirect,
    });
  }, [config]);

  function handleRedirect() {
    window.location.href = "/";
  }

  return (
    <Div100vh>
      <Box className="w-full h-full p-4 flex flex-col gap-6 overflow-y-hidden">
        <Box className="flex flex-col justify-center items-center gap-2">
          <Typography variant="h2" textAlign="center">
            {t("Welcome to")}
          </Typography>
          <img
            className="h-24"
            src={
              colorMode === "dark"
                ? "/assets/svg/exopaper-text-darkmode.svg"
                : "/assets/svg/exopaper-text.svg"
            }
          />
        </Box>
        <Box
          className="h-full overflow-y-auto p-2 mx-auto w-full"
          sx={{ maxWidth: "800px" }}
        >
          <Loader active={isLoading} message={t("Sending Data...")} />

          <ExoForm
            startDataCollection={defaultStartData}
            fields={fields}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            alert={{
              type: "info",
              message: t(
                "The new user receives an e-mail to the specified e-mail address with which they can set a password."
              ),
            }}
          />
        </Box>
      </Box>
    </Div100vh>
  );
};
