import { createElement } from "react";
import { Box } from "@mui/material";
import ExtendTimeCorrection from "./extended-request-versions/ExtendTimeCorrection";
import ExtendTimeCompensation from "./extended-request-versions/ExtendTimeCompensation";

const ExtendRequest = ({ request, onUpdate }) => {
  return createElement(activeTypes[request.type], {
    request: request,
    onUpdate: onUpdate,
  });
};

const activeTypes = {
  timeCorrection: ExtendTimeCorrection,
  timeCompensation: ExtendTimeCompensation,
  vacation: Box,
  sick: Box,
};

export default ExtendRequest;
