import { useState, useEffect } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
} from "@mui/material";
import FormHelperText from "../FormHelperText";
import LabeledBox from "../../special/LabeledBox";
import { useTranslation } from "react-i18next";
import { DBRequest, useConfig } from "../../../api/api";

const TypePhase = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
}) => {
  const [phases, setPhases] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const config = useConfig();

  useEffect(() => {
    setIsLoading(true);
    if (
      !dataCollection[field.projectKey] ||
      !dataCollection[field.projectKey].id
    ) {
      return;
    }
    DBRequest({
      config,
      path: `projects/${dataCollection[field.projectKey].id}/phases`,
      onResponse: setPhases,
      onLoading: setIsLoading,
    });
  }, [dataCollection[field.projectKey], config]);

  function handleChangeSelect(e) {
    if (e.target.value == "" || !phases.data)
      updateValidationOnChange(field.key, {});
    phases.data.forEach((phase) => {
      if (phase.id === e.target.value)
        updateValidationOnChange(field.key, phase);
    });
  }

  return (
    <FormControl fullWidth>
      {!phases ? (
        <LabeledBox label={field.label} variant="blocked">
          {t("Select Project First")}
        </LabeledBox>
      ) : (
        <>
          <InputLabel id={field.key}>{field.label}</InputLabel>
          <Select
            labelId={field.key}
            value={
              dataCollection[field.key] ? dataCollection[field.key].id : ""
            }
            label={field.label}
            onChange={handleChangeSelect}
            error={Boolean(errors[field.key])}
            sx={{
              "& .MuiSelect-select": {
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "row",
                gap: "8px",
                padding: "0px 32px 0px 14px",
                height: "53px!important",
              },
            }}
          >
            <MenuItem
              value={""}
              className="flex flex-row gap-2 justify-start items-center"
            >
              {t("No Phase")}
            </MenuItem>
            {phases.data &&
              phases.data.map((phase) => (
                <MenuItem
                  key={phase.id}
                  value={phase.id}
                  className="flex flex-row gap-2 justify-start items-center"
                >
                  {phase.name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText
            error={Boolean(errors[field.key])}
            helperText={errors[field.key]}
          />
        </>
      )}
    </FormControl>
  );
};

export default TypePhase;
