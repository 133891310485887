import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";

import Loader from "../../components/special/Loader";
import SingleBankAccount from "./bank-accounts-slider/SingleBank";
import MultiBankAccount from "./bank-accounts-slider/MulitBank";
import NoBankAccount from "./bank-accounts-slider/NoBank";
import SkeletonCard from "../../components/cards/SkeletonCard";
import TypeSkeleton from "../../components/form/form-fields/TypeSkeleton";

const BankAccountsSlider = ({
  handleBankUpdate,
  isLoading,
  bankAccounts,
  setOpen,
}) => {
  const renderPhases = {
    loading: <BankAccountsSkeleton />,
    noBank: <NoBankAccount setOpen={setOpen} />,
    singleBank: (
      <SingleBankAccount
        onBankUpdate={handleBankUpdate}
        bankAccounts={bankAccounts}
      />
    ),
    multiBank: (
      <MultiBankAccount
        onBankUpdate={handleBankUpdate}
        bankAccounts={bankAccounts}
      />
    ),
  };

  const [currentPhase, setcurrentPhase] = useState("loading");

  useEffect(() => {
    if (isLoading) return;

    if (bankAccounts.data.length === 0) setcurrentPhase("noBank");
    if (bankAccounts.data.length === 1) setcurrentPhase("singleBank");
    if (bankAccounts.data.length > 1) setcurrentPhase("multiBank");
  }, [bankAccounts]);

  return (
    <Box className="flex justify-center items-center">
      {renderPhases[currentPhase]}
    </Box>
  );
};

const BankAccountsSkeleton = () => {
  return (
    <Box className="w-full h-full flex flex-col items-center">
      <Box className="w-full flex flex-col overflow-hidden rounded-lg items-center p-5">
        <Skeleton
          className="my-2"
          sx={{
            width: "100%",
            transform: "scale(1)",
            borderRadius: "0.5rem",
            maxWidth: "400px",
            aspectRatio: "86/54",
          }}
        />
      </Box>
      <Box className="flex w-full flex-col gap-4">
        <TypeSkeleton n={15} />
      </Box>
    </Box>
  );
};

export default BankAccountsSlider;
