import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState, useEffect } from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

export const Headings = ({ onToggle, editorState }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const [value, setValue] = useState(blockType);
  useEffect(() => {
    setValue(blockType);
  }, [editorState]);

  const options = [
    { label: "P", style: "unstyled" },
    { label: "H1", style: "header-one" },
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: <FormatListBulletedIcon />, style: "unordered-list-item" },
    { label: <FormatListNumberedIcon />, style: "ordered-list-item" },
  ];

  return (
    <ToggleButtonGroup value={value}>
      {options.map((element, index) => (
        <ToggleButton
          key={element.style}
          value={element.style}
          aria-label={element.style}
          onClick={() => onToggle(element.style)}
        >
          <div className="w-4 h-4 flex justify-center items-center">
            {element.label}
          </div>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
