export function updateOrCreate(data, updatedOrNewElement, dir = false) {
  const elementsToCheck = data.data || data;

  var newElements = [...elementsToCheck];

  const existing = newElements.find((obj) => obj.id === updatedOrNewElement.id);
  if (existing) {
    newElements.forEach((element, index) => {
      if (element.id === updatedOrNewElement.id) {
        newElements[index] = updatedOrNewElement;
      }
    });
  } else {
    if (dir) {
      newElements.push(updatedOrNewElement);
    } else {
      newElements.unshift(updatedOrNewElement);
    }
  }

  if (data.data) {
    return { ...data, data: [...newElements] };
  }
  return [...newElements];
}
