import React, { useRef, useState } from "react";
import { OpenDocuments } from "./OpenDocuments";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../../label/MoneyLabel";
import { NewTransactionRelation } from "../../transactions/relations/NewTransactionRelation";
import { Box, Typography } from "@mui/material";
import { updateOrCreate } from "../../special/updateOrCreate";
import { ExoTableUser } from "../../exo/ExoTableUser";
import DateLabel from "../../label/DateLabel";
import { PayrollPreviewElement } from "../../transactions/preview-elements/PayrollPreviewElement";
import PersonIcon from "@mui/icons-material/Person";

export const OpenPayrolls = () => {
  const { t } = useTranslation();
  const openDocumentsRef = useRef(null);
  const [selectedElement, setSelectedElement] = useState(null);

  const element = {
    label: t("Open payrolls"),
    Icon: PersonIcon,
    apiUrl: "payrolls/open?openAmount[nq]=0",
    columns: [
      {
        header: t("Monat"), // header of column
        key: "endDate", // key of column in row-object
        thAlign: "left", // header text alignment
        tdAlign: "left", // cell text alignment
        sortable: true, // enable sort function for column
        width: "100px", // width of column
        hiddenMobile: true,
        cell: ({ row }) => (
          <Box className="monospace">
            <DateLabel format="MM/YYYY" date={row.endDate} />
          </Box>
        ), // custom xml for cells in the column. you get the row as object
        onClick: handleClick,
      },
      {
        header: t("Employee"), // header of column
        key: "user", // key of column in row-object
        thAlign: "left", // header text alignment
        tdAlign: "left", // cell text alignment
        sortable: true, // enable sort function for column
        cell: ({ row }) => <ExoTableUser user={row.user} />, // custom xml for cells in the column. you get the row as object
        onClick: handleClick,
      },
      {
        header: t("Open amount"),
        key: "openAmount",
        thAlign: "right",
        tdAlign: "right",
        width: "170px",
        sortable: true,
        cell: ({ row }) => (
          <Typography>
            <b>
              <MoneyLabel money={row.openAmount} />
            </b>
          </Typography>
        ),
        onClick: handleClick,
      },
    ],
  };

  function handleClick(row, col) {
    setSelectedElement(row);
  }

  function handleUpdatedElement(updatedElement) {
    openDocumentsRef.current.setApiData((prev) => {
      if (updatedElement.openAmount === 0)
        return {
          ...prev,
          data: prev.data.filter((obj) => obj.id !== updatedElement.id),
        };
      return updateOrCreate(prev, updatedElement);
    });
  }

  return (
    <>
      <OpenDocuments
        ref={openDocumentsRef}
        {...element}
        selectedElement={selectedElement}
      />
      <NewTransactionRelation
        open={Boolean(selectedElement)}
        RelationPreview={PayrollPreviewElement}
        selectedElement={selectedElement}
        onClose={() => setSelectedElement(null)}
        relationType="payroll"
        transactionArrayKey="payrolls"
        onUpdate={handleUpdatedElement}
      />
    </>
  );
};
