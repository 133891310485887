import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import useFetch from "../../api/useFetch";
import TypeSkeleton from "../../components/form/form-fields/TypeSkeleton";
import Header from "../../components/special/Header";
import { tokens } from "../../global/theme/tokens";
import UserTimeOverview from "./user-entries/UserTimeOverview";

const TimeManager = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { apiData } = useFetch(`users/time-tracking/overview`);

  return (
    <>
      <Header title={t("Time Manager")} />
      {apiData ? (
        <Box
          className="flex flex-col h-full pb-2 md:pb-0 overflow-auto px-1 sm:px-0 rounded-lg backdrop-blur-lg"
          sx={{
            bgcolor: colors.glass,
          }}
        >
          {apiData.data.map((data) => (
            <UserTimeOverview key={data.id} data={data} />
          ))}
        </Box>
      ) : (
        <TypeSkeleton n={10} />
      )}
    </>
  );
};

export default TimeManager;
