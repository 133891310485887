import { useTranslation } from "react-i18next";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../../../api/api";
import ExoDialog from "../../ExoDialog";
import ExoForm from "../../ExoForm";
import * as Yup from "yup";

export const PhaseForm = ({
  projectId,
  open,
  onClose,
  onChange,
  startDataCollection,
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const fields = [
    {
      label: t("Name"),
      key: "name",
      type: "text",
    },
  ];

  function handleSubmit(data) {
    const isUpdate = Boolean(data.id);

    const requestData = { project: { id: projectId }, ...data };

    DBRequest({
      config,
      path: isUpdate ? `project-phases/${data.id}` : `project-phases`,
      method: isUpdate ? API_PATCH(requestData) : API_POST(requestData),
      onResponse: handleData,
    });
  }

  function handleData(data) {
    onChange(data.data);
    onClose();
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Is required")),
  });

  return (
    <ExoDialog open={open} limitWidth>
      <ExoForm
        header={t("New Phase")}
        startDataCollection={startDataCollection}
        fields={fields}
        onCancle={onClose}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      />
    </ExoDialog>
  );
};
