import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../global/theme/tokens";
import { renderHours } from "./worktime";

export const TimeAccountCard = ({
  minutes,
  isLoading,
  size = "default",
  label,
  height,
  color = "default",
  width,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const colorMap = {
    default: {
      positive: colors.greenAccent[400],
      negative: colors.grey[300],
    },
    card: {
      positive: colors.card,
      negative: colors.card,
    },
    glass: {
      positive: colors.glass,
      negative: colors.glass,
    },
  };
  const sizeMap = {
    default: {
      timeSize: "35px",
      label: "14px",
      height: "90px",
    },
    small: {
      timeSize: "25px",
      label: "13px",
      height: "70px",
    },
  };
  return (
    <Box
      className="px-4 rounded-xl flex flex-col items-center justify-center backdrop-blur-lg"
      style={{
        height: height || sizeMap[size].height,
        minWidth: width || "auto",
        backgroundColor:
          minutes > 0 ? colorMap[color].positive : colorMap[color].negative,
      }}
    >
      <Box
        className="text-center whitespace-nowrap"
        sx={{ fontSize: sizeMap[size].timeSize }}
      >
        {minutes >= 0 ? "+" : "-"}
        {renderHours(minutes)} h
      </Box>
      <Box className="text-center" sx={{ fontSize: sizeMap[size].label }}>
        {label}
      </Box>
    </Box>
  );
};
