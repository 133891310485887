import { Box } from "@mui/material";
import { ProjectPhase } from "./ProjectPhase";
import useFetch from "../../../../api/useFetch";
import { useTranslation } from "react-i18next";
import { PhaseForm } from "./PhaseForm";
import { useState } from "react";
import { updateOrCreate } from "../../../special/updateOrCreate";
import { ProjectAddElementButton } from "../ProjectAddElementButton";
import { ProjectSkeleton } from "../ProjectSkeleton";

export const ProjectPhaseStack = ({ project, editmode }) => {
  const { t } = useTranslation();

  const { apiData, setApiData } = useFetch(`projects/${project.id}/phases`);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(null);

  function handleChange(data) {
    setApiData(updateOrCreate(apiData, data, true));
  }

  function handleDelete(phaseToDelete) {
    const newPhases = apiData.data.filter((obj) => obj.id !== phaseToDelete.id);
    setApiData({ ...apiData, data: newPhases });
  }
  if (!apiData) return <ProjectSkeleton />;
  return (
    <Box className="flex flex-col gap-10 pt-4">
      {apiData.data.map((phase, index) => (
        <ProjectPhase
          project={project}
          key={index}
          editmode={editmode}
          phase={phase}
          onEdit={setEdit}
          onPhaseChange={handleChange}
          onDelete={handleDelete}
          nr={index + 1}
        />
      ))}
      {editmode && (
        <ProjectAddElementButton
          label={t("Add Phase")}
          onClick={() => setOpen(true)}
        />
      )}
      <PhaseForm
        projectId={project.id}
        open={open || edit}
        onClose={() => {
          setEdit(null);
          setOpen(false);
        }}
        onChange={handleChange}
        startDataCollection={edit}
      />
    </Box>
  );
};
