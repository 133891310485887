import { tokens } from "../global/theme/tokens";
import { Box, Typography, useTheme } from "@mui/material";
import Cookies from "universal-cookie";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { API_GET, useConfig } from "../api/api";
import { useTranslation } from "react-i18next";

const Logout = ({ isOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  function logout() {
    delAllCookies();
  }

  return (
    <Box
      className="flex flex-row justify-start items-center gap-2 pl-5 py-3"
      onClick={logout}
      sx={{
        "& svg": {
          color: colors.redAccent[600],
        },
        "& p": {
          color: colors.redAccent[600],
        },
        ":hover": {
          cursor: "pointer",
          "& p": {
            color: colors.redAccent[500] + "!important",
          },
          "& svg": {
            color: colors.redAccent[500],
          },
        },
        ".active & svg": {
          color: colors.redAccent[400],
        },
        ".active & p": {
          color: colors.greenAccent[400],
        },
      }}
    >
      <LogoutOutlinedIcon />
      <Typography
        className="nav-text"
        sx={{ opacity: isOpen ? 1 : 0, transition: "opacity 0.3s" }}
      >
        {t("Logout")}
      </Typography>
    </Box>
  );
};

export function delAllCookies() {
  const cookies = new Cookies();

  const cookiesToNotDel = ["colorMode", "lng"];

  const currentCookies = cookies.getAll();
  for (const [key, value] of Object.entries(currentCookies)) {
    if (!cookiesToNotDel.includes(key)) {
      cookies.remove(key, { path: "/" });
    }
  }
  window.location.reload();
}

export default Logout;
