import { useTranslation } from "react-i18next";
import CardContainer from "./layout/CardContainer";
import PDFPreview from "../special/PDFPreview";

const PdfCard = ({ src, rowSpan = 2, colSpan = 1, className }) => {
  const { t } = useTranslation();
  return (
    <CardContainer
      rows={rowSpan}
      span={colSpan}
      sx={{ padding: 0, maxHeight: "600px" }}
      className={className}
    >
      <PDFPreview src={src} />
    </CardContainer>
  );
};

export default PdfCard;
