import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import CardContainer from "./layout/CardContainer";

const TextCard = ({
  span = 1,
  text = "EMPTY",
  textAlign = "center",
  variant = "h2",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <CardContainer span={span}>
      <Typography
        variant={variant}
        textAlign={textAlign}
        className="flex gap-4 items-center"
      >
        {text}
      </Typography>
    </CardContainer>
  );
};

export default TextCard;
