import { useState, useEffect } from "react";
import {
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import TypeSkeleton from "./TypeSkeleton";
import FormHelperText from "../FormHelperText";

const TypeIndustryId = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const [listItems, setListItems] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "industries",
      method: API_GET,
      onResponse: setListItems,
      onLoading: setIsLoading,
    });
  }, [config]);

  function handleChange(e) {
    updateValidationOnChange(field.key, e.target.value);
  }

  return (
    <FormControl fullWidth>
      {isLoading ? (
        <TypeSkeleton />
      ) : (
        <>
          <InputLabel id="industryId-select">{field.label}</InputLabel>
          <Select
            label={field.label}
            value={dataCollection[field.key] ? dataCollection[field.key] : ""}
            onChange={handleChange}
            error={Boolean(errors[field.key])}
          >
            <MenuItem
              value={""}
              className="flex flex-row gap-2 justify-start items-center"
            >
              Unselect
            </MenuItem>
            {listItems.data.map((element) => (
              <MenuItem key={element.id} value={element.id}>
                {element.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            error={Boolean(errors[field.key])}
            helperText={errors[field.key]}
          />
        </>
      )}
    </FormControl>
  );
};

export default TypeIndustryId;
