import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../../api/api";
import TypeSkeleton from "./TypeSkeleton";

const TypeAccountingPeriod = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const { t } = useTranslation();
  const config = useConfig();
  const [accountringPeriod, setAccountringPeriod] = useState(null);

  useEffect(() => {
    if (config) setAccountringPeriod(config.accounting.accountingPeriod);
  }, [config]);

  function handleChange(e) {
    updateValidationOnChange(field.key, e.target.value);
  }

  if (!accountringPeriod) return <TypeSkeleton />;

  return (
    <FormControl fullWidth>
      <InputLabel id={"accounting-period-" + field.key}>
        {field.label}
      </InputLabel>
      <Select
        labelId={"accounting-period-" + field.key}
        value={dataCollection[field.key] || ""}
        label={field.label}
        onChange={handleChange}
      >
        {accountringPeriod &&
          accountingPeriodMap[accountringPeriod].map((item) => (
            <MenuItem value={item.value}>{t(item.label)}</MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export const accountingPeriodMap = {
  1: [
    {
      label: "January",
      value: 1,
    },
    {
      label: "Februrary",
      value: 2,
    },
    {
      label: "March",
      value: 3,
    },
    {
      label: "April",
      value: 4,
    },
    {
      label: "May",
      value: 5,
    },
    {
      label: "June",
      value: 6,
    },
    {
      label: "July",
      value: 7,
    },
    {
      label: "August",
      value: 8,
    },
    {
      label: "September",
      value: 9,
    },
    {
      label: "October",
      value: 10,
    },
    {
      label: "November",
      value: 11,
    },
    {
      label: "December",
      value: 12,
    },
  ],
  3: [
    {
      label: "1. Quarter",
      value: 1,
    },
    {
      label: "2. Quarter",
      value: 4,
    },
    {
      label: "3. Quarter",
      value: 7,
    },
    {
      label: "4. Quarter",
      value: 10,
    },
  ],
  6: [
    {
      label: "1. Half Year",
      value: 1,
    },
    {
      label: "2. Half Year",
      value: 7,
    },
  ],
  12: [],
};

export default TypeAccountingPeriod;
