import { Box, Typography } from "@mui/material";

const IconInfo = ({ icon, text }) => {
  return (
    <Box className="flex flex-row justify-start items-center gap-2">
      {icon}
      <Typography variant="p">{text}</Typography>
    </Box>
  );
};

export default IconInfo;
