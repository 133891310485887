import { Box, Typography } from "@mui/material";
import CardContainer from "./layout/CardContainer";
import DateLabel from "../label/DateLabel";
import { useTranslation } from "react-i18next";

const InfoCard = ({ infos = [], customContent, span, sx }) => {
  return (
    <CardContainer span={span} sx={sx}>
      {infos.map((info, index) => (
        <InfoLine
          key={index}
          label={info.label}
          value={info.value}
          type={info.type}
        />
      ))}
      {customContent}
    </CardContainer>
  );
};

const InfoLine = ({ label, value, type = "text" }) => {
  const { t } = useTranslation();
  const className = type === "number" || type === "date" ? " monospace" : "";
  return (
    <Box className="flex flex-row gap-2 items-center">
      <Typography variant="h6">{t(label)}</Typography>
      <Typography className={className} sx={{ fontWeight: 500 }}>
        {type === "date" && <DateLabel date={value} />}
        {type === "text" || type === "number" ? value : ""}
      </Typography>
    </Box>
  );
};

export default InfoCard;
