import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import MoneyLabel from "../../label/MoneyLabel";
import ExoTableRecipient from "../../exo/ExoTableRecipient";
import { useTranslation } from "react-i18next";

export const ReceiptPreviewElement = ({ element }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  if (!element) return;

  return (
    <Box
      className="rounded-lg px-3 py-2 flex justify-between"
      sx={{ bgcolor: colors.card }}
    >
      <Box>
        <Typography>
          <b>{element.title}</b>
        </Typography>
        <Typography variant="h6" className="pb-2">
          {element.receiptNr}
        </Typography>
        <ExoTableRecipient row={element} />
      </Box>

      <Box>
        <Typography variant="h4">
          <b>
            <MoneyLabel money={element.openAmount} />
          </b>
        </Typography>
        <Typography variant="h6">{t("open amount")}</Typography>
      </Box>
    </Box>
  );
};
