import {
  Box,
  Button,
  Collapse,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import ExoForm from "../../exo/ExoForm";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../global/theme/tokens";

const DefaultVar = ({ element, onChange, onClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);
  function handleSubmit(data) {
    onChange({ ...element, ...data });
    setOpen(false);
  }
  return (
    <Box>
      <Box className="flex flex-row justify-start items-center gap-2">
        {element.isEditable && element.type !== "hidden" && (
          <IconButton onClick={() => setOpen(!open)} size="small">
            <EditIcon />
          </IconButton>
        )}

        <Button
          onClick={() => onClick(element.identifier)}
          variant="outlined"
          className="w-full"
          sx={{
            bgcolor: colors.bgInput,
          }}
        >
          <Box className="flex flex-row justify-between items-center w-full">
            <Box>{element.displayName}</Box>
            {element.isEditable &&
              element.type !== "hidden" &&
              (element.defaultValue ? (
                <CheckBoxIcon />
              ) : (
                <Tooltip title="no default Value" placement="right" arrow>
                  <CheckBoxOutlineBlankIcon onClick={() => setOpen(true)} />
                </Tooltip>
              ))}
          </Box>
        </Button>
      </Box>
      <Collapse in={open}>
        <Box
          className="w-full rounded-lg p-2"
          sx={{ borderColor: colors.bgInput, borderWidth: "1px" }}
        >
          <ExoForm
            noEnter={!open}
            startDataCollection={element}
            fields={[
              {
                label: t("Default Value"),
                key: "defaultValue",
                type: element.type,
                options: element.options,
              },
            ]}
            onCancle={() => setOpen(false)}
            onSubmit={handleSubmit}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default DefaultVar;
