import { useState } from "react";
import { API_POST, DBRequest, useConfig } from "../../../api/api";
import ExoDialog from "../../exo/ExoDialog";
import { SelectTransaction } from "../../select/SelectTransaction";
import Feedback from "../../special/Feedback";

export const NewTransactionRelation = ({
  onUpdate,
  onClose,
  open,
  filter = "openAmount[ne]=0",
  RelationPreview,
  selectedElement,
  relationType, // invoice, payroll, receipt, paymentReminder
  transactionArrayKey,
}) => {
  const config = useConfig();

  function handleCreateRelation(transaction) {
    const relation = {
      relationType: relationType,
      relations: [selectedElement.id],
      mode: "add",
    };
    DBRequest({
      config,
      path: "transactions/" + transaction.id + "/relations",
      method: API_POST(relation),
      onResponse: handleResponse,
      onFeedback: handleFeedback,
    });
  }

  function handleResponse(res) {
    const array = res.data[transactionArrayKey];
    const updatedElement = array.find((obj) => obj.id === selectedElement.id);
    onClose();
    onUpdate(updatedElement);
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });

  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <>
      <ExoDialog
        open={open}
        onClose={onClose}
        limitWidth
        maxWidth="900px"
        className="flex flex-col h-full overflow-hidden gap-2"
      >
        {RelationPreview && <RelationPreview element={selectedElement} />}
        <SelectTransaction filter={filter} onSelect={handleCreateRelation} />
      </ExoDialog>
      <Feedback setState={setAlertState} state={alertState} />
    </>
  );
};
