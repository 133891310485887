import { FormControlLabel, Switch } from "@mui/material";

const FilterItem = ({ item, onChange, checked }) => {
  return (
    <FormControlLabel
      control={<Switch onChange={onChange} checked={checked} />}
      label={item.label}
    />
  );
};

export default FilterItem;
