import { Box, Dialog, Button, useTheme, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useState, useEffect, useMemo } from "react";
import Feedback from "../../components/special/Feedback";
import AllSerialMailsTemplates from "./AllSerialMailTemplates";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import { API_GET, DBRequest } from "../../api/api";

// icons
import SubjectIcon from "@mui/icons-material/Subject";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import PreviewIcon from "@mui/icons-material/Preview";
import ExoTable from "../../components/exo/ExoTable";
import MailPreviewer from "../../components/preview/MailPreviewer";
import Loader from "../../components/special/Loader";

const AllSerialMailsTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "",
    alertText: "",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  // templates
  const [openTemplates, setOpenTemplates] = useState(false);

  // data
  const [serialMails, setSerialMails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    DBRequest(
      "serialmails",
      API_GET,
      setSerialMails,
      setIsLoading,
      handleAlertOpen,
      true
    );
  }, []);

  function handleClickTemplates() {
    setOpenTemplates(true);
  }
  function handleClickNewSerialMail() {}

  // table
  const Columns = [
    {
      header: "ID",
      key: "id",
      thAlign: "center",
      tdAlign: "center",
      width: "100px",
      sortable: true,
      cell: ({ row }) => <Box>{"SM" + pad(row.id, 5)}</Box>,
    },
    {
      header: "Name",
      key: "name",
      thAlign: "left",
      tdAlign: "left",
      sortable: true,
    },
    {
      header: "Mails",
      key: "recipientCount",
      thAlign: "center",
      tdAlign: "center",
      width: "60px",
      sortable: true,
      hiddenMobile: true,
    },
    {
      header: "",
      key: "template",
      thAlign: "right",
      tdAlign: "right",
      cell: ({ row }) => (
        <Button
          className="flex flex-row gap-2"
          onClick={() => openPreviewTemplate(row.templateId, row.content)}
        >
          Preview
          <PreviewIcon />
        </Button>
      ),
    },
  ];

  const [openPreview, setOpenPreview] = useState(false);
  const [previewId, setPreviewId] = useState(0);
  const [previewContent, setPreviewContent] = useState("");
  function openPreviewTemplate(id, content) {
    setPreviewId(id);
    setPreviewContent(content);
    setOpenPreview(true);
  }

  return (
    <Box className="h-full flex flex-col">
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={"Templates"}
          icon={<SubjectIcon />}
          handleClick={handleClickTemplates}
        />
        <ExoTableToolbarItem
          text={"New Serial Mail"}
          icon={<FiberNewIcon />}
          handleClick={handleClickNewSerialMail}
        />
      </ExoTableToolbar>
      <ExoTable
        data={serialMails}
        columns={Columns}
        isLoading={isLoading}
        extendElement={({ row }) => <Extended row={row} />}
      />
      <Dialog
        open={openTemplates}
        onClose={() => setOpenTemplates(false)}
        sx={{
          "& .MuiDialog-paper": {
            backgroundImage: "unset!important",
            overflow: "auto",
            borderRadius: "15px",
            maxWidth: "95%",
            minWidth: "500px",
            padding: "16px",
          },
        }}
      >
        <Box className="p-2 relative">
          <AllSerialMailsTemplates onFeedback={handleAlertOpen} />
        </Box>
      </Dialog>
      <PreviewMail
        id={previewId}
        content={previewContent}
        open={openPreview}
        setOpen={setOpenPreview}
      />
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

const Extended = ({ row }) => {
  return (
    <Box>
      <Typography variant="h2">{row.title}</Typography>
    </Box>
  );
};

const PreviewMail = ({ id, content, open, setOpen }) => {
  const [template, setTemplate] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      DBRequest(
        "templates/" + id,
        API_GET,
        setTemplate,
        setIsLoading,
        () => {},
        true
      );
    }
  }, [id]);
  return (
    <Box>
      {isLoading ? (
        <Loader active={isLoading && open} />
      ) : (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <Box className="w-full">
            <MailPreviewer style={template.styleJson} autoscale>
              {template.data.content}
            </MailPreviewer>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

// leading ZERO
function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export default AllSerialMailsTable;
