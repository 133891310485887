import { Box, Typography } from "@mui/material";
import React from "react";
import { AttachmentPreview } from "./attachment/AttachmentPreview";
import NoDataAnimation from "../animation/NoDataAnimation";
import { useTranslation } from "react-i18next";

export const ExoAttachments = ({ attachments }) => {
  const { t } = useTranslation();
  if (!attachments || attachments == false)
    return (
      <>
        <NoDataAnimation size={"100px"} />
        <Typography>{t("No Dokument Basis")}</Typography>
      </>
    );
  return (
    <Box
      className="grid grid-cols-2 w-full gap-1 overflow-y-auto"
      sx={{ maxHeight: "250px" }}
    >
      {attachments.map((item) => (
        <AttachmentPreview key={item.id} item={item} />
      ))}
    </Box>
  );
};
