import React from "react";
import { useConfig } from "./api/api";
import { Badge, Box, Chip, useTheme } from "@mui/material";
import { tokens } from "./global/theme/tokens";

export const DevHighlighting = (props) => {
  const config = useConfig();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const isDev = Boolean(config && config.versionTag);

  return (
    <Box
      {...props}
      sx={{
        ...props.sx,
      }}
    >
      {isDev && (
        <Chip
          label={config.versionTag}
          color="warning"
          className=" fixed left-1/2 top-0 -translate-x-1/2"
        />
      )}
      {props.children}
    </Box>
  );
};
