import { Box, Button, FormControlLabel, Switch, Tooltip } from "@mui/material";
import { ToolButton } from "./ToolButton";
import { useTranslation } from "react-i18next";

export const Toolbar = ({ tools, setInEditMode, inEditMode }) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    setInEditMode(event.target.checked);
  };

  return (
    <Box className="flex justify-between items-end w-full">
      <Box className="flex gap-4">
        {tools.map((tool, index) => (
          <ToolButton key={index} tool={tool} />
        ))}
      </Box>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={inEditMode}
            onChange={handleChange}
          />
        }
        label={t("Edit Mode")}
      />
    </Box>
  );
};
