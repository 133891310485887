import Cookies from "universal-cookie";
import axios from "axios";
import { useAppConfig } from "../AppConfig";
import { useEffect, useState } from "react";

const cookies = new Cookies();

// Define a custom hook to use the configuration
export const useConfig = () => {
  const { config } = useAppConfig();

  // Initialize state to store API constants
  const [apiConstants, setApiConstants] = useState(null);

  useEffect(() => {
    // Check if config is available
    if (!apiConstants && config) {
      setApiConstants(config);
      cookies.set("decimalSeparator", config.decimalSeparator, {
        path: "/",
      });
    }
  }, [config]);

  // Return the computed API constants
  return apiConstants;
};

export const API_GET = {
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: "Bearer " + cookies.get("accessToken"),
  },
  method: "GET",
  mode: "cors",
};

export const API_DELETE = {
  // in URL ".../id"
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: "Bearer " + cookies.get("accessToken"),
  },
  method: "DELETE",
  mode: "cors",
};

export function API_POST(data = "") {
  return {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    },
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data),
  };
}

export function API_POST_NO_TOKEN(data = "") {
  return {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data),
  };
}

export function API_PATCH(data = "") {
  data._method = "PATCH";
  return {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    },
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data),
  };
}

export function API_PUT(data = "") {
  data._method = "PUT";
  return {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + cookies.get("accessToken"),
    },
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data),
  };
}

function checkAuthentication(status) {
  if (status === 401) window.location.reload();
}

export function DBRequest({
  config,
  path = "",
  method,
  onResponse = () => {},
  onError = () => {},
  onLoading = () => {},
  onFeedback = () => {},
  noAuth,
  customPath,
  debug,
}) {
  if (!customPath && !config) return;
  onLoading(true);
  const URL = customPath ? path : config.API_URL + path;
  fetch(URL, method || API_GET)
    .then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          onFeedback(error.message, "error", error);
          onError(error, res.status);
          if (method.headers.Authorization && !noAuth)
            checkAuthentication(res.status);
          throw Error(error.message, res.status);
        });
      } else return res.json();
    })
    .then((response) => {
      debug && console.log(response);
      onResponse(response, true);
      onLoading(false);
      onFeedback(response.message, "success");
    })
    .catch((error, status) => {
      onLoading(false);
    });
}

export function DBGET({
  url,
  path = "",
  onResponse = () => {},
  onLoading = () => {},
  onFeedback = () => {},
  debug,
}) {
  axios
    .get(url + path)
    .then((response) => {
      onResponse(response.data, true);
      onLoading(false);
      onFeedback(response.data.message, "success");
      debug && console.log(response.data);
    })
    .catch((error) => {
      onLoading(false);
      onResponse(error, false);
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      const errorStatus = error.response ? error.response.status : null;
      onFeedback(errorMessage, "error", error);
      checkAuthentication(errorStatus);
      console.error(errorMessage, error);
    });
}

export function DBPOST({
  url,
  path = "",
  data,
  onResponse = () => {},
  onError = () => {},
  onLoading = () => {},
  onFeedback = () => {},
  debug,
}) {
  axios
    .post(url + path, data)
    .then((response) => {
      onResponse(response.data, true);
      onLoading(false);
      onFeedback(response.data.message, "success");
      debug && console.log(response.data);
    })
    .catch((error) => {
      onLoading(false);
      onResponse(error, false);
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      const errorStatus = error.response ? error.response.status : null;
      onFeedback(errorMessage, "error", error);
      checkAuthentication(errorStatus);
      console.error(errorMessage, error);
    });
}

export function DBRequestModules({
  config,
  path,
  method,
  onResponse = () => {},
  onLoading = () => {},
  onFeedback = () => {},
  debug,
}) {
  if (!config) return;
  onLoading(true);
  fetch(config.API_MODULES + path, method || API_GET)
    .then((res) => {
      if (!res.ok) {
        return res.json().then((error) => {
          onFeedback(error.message, "error", error);
          checkAuthentication(res.status);
          throw Error(res.status + " | " + error.message);
        });
      } else return res.json();
    })
    .then((response) => {
      debug && console.log(response);
      onResponse(response);
      onLoading(false);
      onFeedback("Success", "success");
    })
    .catch((error) => {
      console.log(error.message, error.trace);
    });
}

export function DBUploadData({
  config = {},
  path,
  formData,
  onProgressChange = () => {},
  onFinished = () => {},
  onResponse,
  onFeedback = () => {},
  debug = false,
  onError = () => {},
}) {
  onFinished(false);
  const headers = {
    Authorization: "Bearer " + cookies.get("accessToken"),
    "Content-Type": "multipart/form-data",
  };

  axios
    .post(config.API_URL + path, formData, {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgressChange(percentCompleted);
        // Update progress bar or UI with the `percentCompleted` value
      },
    })
    .then((response) => {
      // Handle the server's response
      onFeedback(response);
      onFinished(true);
      onResponse(response);
      debug && console.log("DEBUG", response);
    })
    .catch((error) => {
      // Handle errors
      onFeedback(error);
      onError(error);
      debug && console.log("DEBUG", error);
    });
}

export function DBDownloadData(
  apiConfig,
  url,
  setPercent,
  setFinished,
  handleFeedback,
  debug = false
) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", apiConfig.API_URL + url, true);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("accept", "application/json");
  xhr.setRequestHeader("Authorization", "Bearer " + cookies.get("accessToken"));

  xhr.addEventListener("progress", (event) => {
    const percent = (event.loaded / event.total) * 100;
    setPercent(percent);
  });

  xhr.addEventListener("load", () => {
    const res = JSON.parse(xhr.responseText);
    handleFeedback(res.message, "success");
    setFinished(true);
    debug && console.log(res.data);
  });

  xhr.addEventListener("error", (error) => {
    handleFeedback(error.message, "error");
    setFinished(true);
    console.log(error.message);
  });

  xhr.send();
}

export async function SearchRequest({
  baseUrl,
  path,
  data = {},
  method = "get",
  onResponse = () => {},
  onError = () => {},
  onLoading = () => {},
}) {
  onLoading(true);
  const token = cookies.get("searchToken");
  await axios({
    method: method,
    url: `${baseUrl}/${path}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      onResponse(response.data);
      onLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      onError(error);
      onLoading(false);
    });
}
