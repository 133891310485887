import { useTranslation } from "react-i18next";
import NewDescription from "../../../new/NewDescription";

export const PositionDescription = ({
  label,
  key,
  data,
  moduleKey,
  onChange,
  showVars,
  descriptionVars,
  imageType,
  imageApiFilter,
}) => {
  const { t } = useTranslation();

  return (
    <NewDescription
      label={t(label)}
      onSave={onChange}
      startHTML={data}
      className="col-span-2 md:col-span-3"
      descriptionVars={descriptionVars}
      showVars={showVars}
      imageType={imageType}
      imageApiFilter={imageApiFilter}
    />
  );
};
