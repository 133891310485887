import { Box, Chip, Tooltip } from "@mui/material";

export const Categories = ({ data, width }) => {
  return (
    <Box className="flex flex-row overflow-x-auto gap-1">
      {data.map((category, index) => (
        <Tooltip key={"cat" + category.id} title={category.description}>
          <Chip label={category.name} variant="outlined" />
        </Tooltip>
      ))}
    </Box>
  );
};
