import { Box, useTheme, Typography, IconButton, Dialog } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import moment from "moment";
import ExoWorkday from "../../components/exo/ExoWorkday";
import { tokens } from "../../global/theme/tokens";
import CardContainer from "../../components/cards/layout/CardContainer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import NewTimeCorrection from "../../components/new/NewTimeCorrection";
import ExoAvatar from "../../components/exo/ExoAvatar";
import useFetch from "../../api/useFetch";
import ExoDialog from "../../components/exo/ExoDialog";
import { hasPermissions } from "../../auth/hasPermissions";
import Header from "../../components/special/Header";

const UserTimeDetails = ({ id, noBackground, noPicture, noLegend }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { userId } = useParams();
  const { t } = useTranslation();
  const [kwMoment, setKwMoment] = useState(moment().isoWeek(moment().week()));

  // Function to handle subtracting a week
  const handleSubtractWeek = () => {
    setKwMoment((prevMoment) => prevMoment.subtract(1, "week").clone()); // Use clone to prevent mutating state directly
  };

  // Function to handle adding a week
  const handleAddWeek = () => {
    setKwMoment((prevMoment) => prevMoment.add(1, "week").clone()); // Use clone to prevent mutating state directly
  };

  // Edit
  const [editDate, setEditDate] = useState(null);
  const exoWorkDayRef = useRef(null);
  function handleDateClick(date) {
    if (!hasPermissions("time-manager", "write")) return;
    setEditDate(date.format("YYYY-MM-DD"));
  }
  function handleCreatedCorrection() {
    const startOfWeek = moment(editDate).startOf("isoWeek");
    setEditDate(null);
    exoWorkDayRef.current.updateDbData(startOfWeek.format("YYYY-MM-DD"));
  }

  // user
  const { apiData } = useFetch(`users/${id || userId}`, true);

  return (
    <>
      {userId && (
        <Header
          title={
            apiData && `${apiData.data.firstName} ${apiData.data.lastName}`
          }
        />
      )}
      <Box
        className={`${
          noBackground ? "" : "backdrop-blur-md"
        } flex h-full w-full p-2 md:p-4 rounded-lg overflow-hidden`}
        sx={{ bgcolor: noBackground ? "transparent" : colors.glass }}
      >
        <Box className="h-full w-full flex flex-col gap-2 overflow-hidden">
          <CardContainer
            className="justify-between"
            sx={{ flexDirection: "row" }}
          >
            <IconButton onClick={handleSubtractWeek}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Box className="flex flex-row gap-4 justify-center items-center">
              {!noPicture && (
                <ExoAvatar
                  name={
                    apiData
                      ? `${apiData.data.firstName} ${apiData.data.lastName}`
                      : ""
                  }
                  picture={
                    apiData && apiData.data.picture
                      ? apiData.data.picture.temporaryUrl
                      : ""
                  }
                />
              )}
              <Typography variant="h4">
                {`${t("Week")} ${kwMoment.format("WW")} - ${t(
                  kwMoment.format("MMMM")
                )}`}
              </Typography>
            </Box>

            <IconButton onClick={handleAddWeek}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </CardContainer>
          <ExoWorkday
            ref={exoWorkDayRef}
            dateStart={kwMoment
              .startOf("week")
              .add(1, "days")
              .format("YYYY-MM-DD")}
            daySpan={7}
            userId={id || userId}
            labelFormat="dddd"
            onDateClick={handleDateClick}
            noLegend={noLegend}
          />
        </Box>
        <ExoDialog open={editDate}>
          <NewTimeCorrection
            date={editDate}
            onCreate={handleCreatedCorrection}
            onCancle={() => setEditDate(null)}
            userId={id || userId}
            autoAccept
          />
        </ExoDialog>
      </Box>
    </>
  );
};

export default UserTimeDetails;
