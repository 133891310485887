import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import ExoExplorer from "../../components/exo/ExoExplorer";
import { useTranslation } from "react-i18next";

const Explorer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  return (
    <>
      <Header title={t("Explorer")} />
      <ExoExplorer />
    </>
  );
};

export default Explorer;
