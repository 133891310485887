import { Table, Typography, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useState } from "react";
import { API_DELETE, DBRequest, useConfig } from "../../api/api";
import CardGrid from "../../components/cards/layout/CardGrid";
import PictureNameCard from "../../components/cards/PictureNameCard";
import CardContainer from "../../components/cards/layout/CardContainer";
import { CardContentText } from "../../components/cards/content/CardContentText";
import { CardContentLink } from "../../components/cards/content/CardContentLink";
import MoneyCard from "../../components/cards/MoneyCard";
import SkeletonCard from "../../components/cards/SkeletonCard";
import AddressCard from "../../components/cards/AddressCard";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTranslation } from "react-i18next";
import ExoDialog from "../../components/exo/ExoDialog";
import NewCompany from "../../components/new/NewCompany";
import useFetch from "../../api/useFetch";
import { MultiContactCard } from "../../components/cards/MultiContactCard";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import InfoCard from "../../components/cards/InfoCard";

const ExtendCompany = ({ row, onUpdate, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`companies/${row.id}`);

  // menu
  function handleDelete() {
    DBRequest({
      config,
      path: `companies/${apiData.data.id}`,
      method: API_DELETE,
      onResponse: handleDeleteRow,
    });
  }

  function handleDeleteRow(type, message) {
    onDelete(row);
  }

  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: handleEdit,
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  //edit company
  const [open, setOpen] = useState(false);
  const [startData, setStartData] = useState(null);
  function handleEdit() {
    setStartData(apiData.data);
    setOpen(true);
  }

  function handleUpdate(data) {
    setApiData(data);
    onUpdate(data.data);
  }

  const infos = [
    {
      label: t("Nr"),
      value: row.nr,
    },
  ];

  return (
    <CardGrid>
      {!apiData ? (
        <CompanySkeleton />
      ) : (
        <>
          <SpeedDialMenu actions={actions} />
          <PictureNameCard
            span={1}
            pictureUrl={apiData.data.logo && apiData.data.logo.thumbnailUrl}
            name={apiData.data.name}
            type="company"
          />
          <InfoCard infos={infos} />
          <MoneyCard title={t("Sales")} amount={apiData.data.salesTotal} />
          <MultiContactCard
            label={t("Phonenumbers")}
            contactArray={apiData.data.telephoneNumbers}
            nameKey="phoneType"
            valueKey="phoneNumber"
            Icon={PhoneIcon}
            linkPrefix="tel:"
          />
          <MultiContactCard
            label={t("E-Mailaddresses")}
            span={2}
            contactArray={apiData.data.emails}
            nameKey="emailType"
            valueKey="emailAddress"
            Icon={EmailIcon}
            linkPrefix="mailto:"
          />
          <AddressCard address={apiData.data.address} />
        </>
      )}
      <ExoDialog open={open} limitWidth>
        <NewCompany
          fullWidth
          onClose={() => setOpen(false)}
          onUpdate={handleUpdate}
          startData={startData}
        />
      </ExoDialog>
    </CardGrid>
  );
};

const CardCompanyData = ({ company }) => {
  const { t } = useTranslation();
  return (
    <CardContainer span={2}>
      <Table>
        <tbody>
          <tr>
            <td>
              <Typography variant="h6">{t("Company Nr")}</Typography>
            </td>
            <td>
              <CardContentText text={company.nr} />
            </td>
          </tr>
          {company.url && (
            <tr>
              <td>
                <Typography variant="h6">{t("Website")}</Typography>
              </td>
              <td>
                <CardContentLink href={company.url} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </CardContainer>
  );
};

const CompanySkeleton = () => {
  return (
    <>
      <SkeletonCard height={186} />
      <SkeletonCard height={186} span={2} />
      <SkeletonCard height={186} />
      <SkeletonCard height={104} />
    </>
  );
};

export default ExtendCompany;
