import { Box, useTheme, Typography, Button } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import TypeDivider from "../../../form/form-fields/TypeDivider";
import { useConfig } from "../../../../api/api";
import { useEffect, useState } from "react";
import { PositionNumber } from "./PositionNumber";
import { useTranslation } from "react-i18next";
import CalculateIcon from "@mui/icons-material/Calculate";
import { numberToString } from "../../../special/numberConverter";
import MoneyLabel from "../../../label/MoneyLabel";
import LabeledBox from "../../../special/LabeledBox";

export const PositionProgressPaymentCalculation = ({
  data,
  onChange,
  label,
  onFocus,
  position,
  openAmount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();
  const config = useConfig();

  const [calculatedPrice, setCalculatedPrice] = useState(0);

  function handleChange(value, key) {
    onChange(value, key);
    const newPrice = calcPrice({ ...position, [key]: value });
    setCalculatedPrice(newPrice);
  }

  function calcNewPrice() {
    onChange(calcPrice(position));
  }

  function calcPrice(newPosition) {
    const progressPrice =
      Math.round(
        parseFloat(openAmount) *
          (parseFloat(newPosition.markupRate) / 100) *
          100
      ) / 100;

    if (progressPrice !== NaN) return progressPrice;

    return 0;
  }

  return (
    <Box className="col-span-2 md:col-span-3 lg:col-span-4">
      <TypeDivider color={colors.grey[600]} width={"1px"} />
      <Typography className="pt-3">
        {t("Calculation progress payment position")}
      </Typography>
      <Box className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-3">
        <LabeledBox label={t("Open before invoice")}>
          <MoneyLabel money={openAmount} />
        </LabeledBox>
        <PositionNumber
          data={0}
          label={"Progress Rate"}
          onFocus={onFocus}
          onChange={(e) => handleChange(e, "markupRate")}
          unit={"%"}
          decimalPlaces={1}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={calcNewPrice}
          className="flex gap-4"
        >
          <CalculateIcon />
          {`${
            calculatedPrice
              ? numberToString(calculatedPrice, 2)
              : numberToString(0, 2)
          } ${config ? config.currency.currencySymbol : ""} ${t("transfer")}`}
        </Button>
      </Box>
    </Box>
  );
};
