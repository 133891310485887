import { useEffect } from "react";
import ExoPositions from "../exo/ExoPositions";

const StepAdditionalPositions = ({
  dataCollection,
  updateValidation,
  updateDataCollection,
  imageType,
  imageApiFilter,
}) => {
  function handlePositionChange(positions) {
    updateDataCollection({ positions: positions });
  }

  // validation
  useEffect(() => {
    var pos = dataCollection.positions;
    if (pos == undefined) pos = [];
    var valPositions = true;

    for (let index = 0; index < pos.length; index++) {
      if (
        !pos[index].isGroup &&
        (!pos[index].name ||
          isNaN(pos[index].price) ||
          pos[index].price == 0 ||
          pos[index].amount == 0 ||
          isNaN(pos[index].amount))
      ) {
        valPositions = false;
      }
    }
    updateValidation(valPositions);
  }, [dataCollection]);

  return (
    <ExoPositions
      onPositionChange={handlePositionChange}
      startPositions={dataCollection.positions}
      imageType={imageType}
      imageApiFilter={imageApiFilter}
    />
  );
};

export default StepAdditionalPositions;
