import { Box, Button, IconButton, useTheme } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { tokens } from "../../../global/theme/tokens";

const CustomVar = ({ element, onDelVar, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box className="flex flex-row justify-start items-center gap-2">
      <IconButton
        onClick={() => onDelVar(element.id || element.uuid)}
        size="small"
      >
        <DeleteOutlineIcon />
      </IconButton>
      <Button
        onClick={() => onClick(element.identifier)}
        variant="outlined"
        className="w-full"
        sx={{ bgcolor: colors.bgInput }}
      >
        {element.displayName}
      </Button>
    </Box>
  );
};

export default CustomVar;
