import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { useState } from "react";

const Pagination = ({
  data,
  setData,
  setLoading,
  filter = {},
  className,
  numberCount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const config = useConfig();
  if (!data.meta) return;
  const updateData = (URL) => {
    if (URL == null) return;
    setLoading(true);
    DBRequest({
      config: { ...config, API_URL: "" },
      path: URL,
      method: API_GET,
      onResponse: setData,
      onLoading: setLoading,
    });
  };

  function handleEntriesPerPageChange(newPerPage) {
    setLoading(true);
    setEntriesPerPage(newPerPage);
    DBRequest({
      config: { ...config, API_URL: "" },
      path: `${data.meta.path}?sortOrder=${
        filter.order ? "asc" : "desc"
      }&sortBy=${filter.name}&${filter.filter}&perPage=${newPerPage}`,
      method: API_GET,
      onResponse: setData,
      onLoading: setLoading,
    });
  }

  return (
    <Box
      className={
        "w-full rounded-xl flex justify-center items-center gap-2 relative " +
        className
      }
      sx={{
        "& button": {
          minWidth: "0px!important",
        },
        "& .current-page": {
          color: colors.selected,
        },
      }}
    >
      {data.meta.links.map((page, index) => {
        if (
          (index < numberCount + 1 || !numberCount) &&
          index + 1 !== data.meta.links.length
        )
          return (
            <Button
              key={"paginate-number-" + index}
              variant="text"
              onClick={() => updateData(page.url)}
              disabled={page.url == null || page.active}
              sx={{ bgcolor: page.active ? "current-page" : "" }}
            >
              {page.label}
            </Button>
          );
        if (index + 1 === data.meta.links.length) {
          return (
            <Button
              key={"paginate-number-" + index}
              variant="text"
              onClick={() => updateData(page.url)}
              disabled={page.url == null || page.active}
              sx={{ bgcolor: page.active ? "current-page" : "" }}
            >
              {page.label}
            </Button>
          );
        }
      })}
      <EntriesPerPageController
        onChange={handleEntriesPerPageChange}
        current={entriesPerPage}
      />
    </Box>
  );
};

const EntriesPerPageController = ({ onChange, current }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const options = [50, 100, 150];
  return (
    <Box className=" flex-row gap-2 absolute bottom-1 hidden sm:flex right-4">
      {options.map((value, index) => (
        <Button
          key={index}
          onClick={() => onChange(value)}
          variant="outlined"
          color="secondary"
          sx={{
            padding: "0px 7px",
            backgroundColor:
              current === value ? colors.selected : "transparent",
          }}
        >
          {value}
        </Button>
      ))}
    </Box>
  );
};
export default Pagination;
