import { Box, Typography } from "@mui/material";
import DisplayLabel from "./base-components/Label";
import MoneyLabel from "../../../components/label/MoneyLabel";

const DisplayMoney = ({ label, value }) => {
  return (
    <Box className=" cursor-pointer hover:opacity-75 transition-opacity">
      <DisplayLabel text={label} />
      <Typography variant="h4">
        <MoneyLabel money={value} />
      </Typography>
    </Box>
  );
};

export default DisplayMoney;
