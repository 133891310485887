import React from "react";
import ExoAvatar from "../../ExoAvatar";
import moment from "moment";
import Typography from "@mui/material/Typography";
import { renderHours } from "../../../../scenes/time-tracking/worktime";
import { Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { SettingsPopover } from "../../project/SettingsPopover";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useMethodColor } from "../time-functions/useMethodColors";

export const TimeElement = ({ time }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  // color
  var colorToUse = useMethodColor(time.method);

  const itemIsDeleted = time.method === "delete";
  const itemIsAdded = time.method === "add";
  const itemIsUpdated = time.method === "update";

  const client = time.project ? time.project.client : {};
  const name = time.project
    ? time.project.clientType === "company"
      ? client.name
      : `${client.firstName} ${client.lastName}`
    : t("Internal");

  const timeDiff = moment(time.timeEnd).diff(moment(time.timeStart), "m");

  const timeLabel =
    time.comment || (time.workPackage && time.workPackage.title);

  const settings = [
    {
      Icon: DeleteIcon,
      label: t("Delete"),
      onClick: () => {},
      color: "error",
    },
  ];

  const project = time.project || { client: {}, name: t("DELETED") };

  return (
    <Box
      className="flex w-full items-center justify-between rounded-md px-2 py-1 border-[1px]"
      sx={{
        bgcolor: colorToUse.primary,
        borderColor: colorToUse.secondary,
        opacity: itemIsDeleted ? 0.3 : 1,
      }}
    >
      <Box className="flex items-center gap-2">
        <ExoAvatar
          size="30px"
          picture={client.logo && client.logo.temporaryUrl}
          name={name}
          type={project.clientType || "company"}
        />
        <Box className="flex flex-col">
          <Typography sx={{ fontSize: "12px" }} variant="h6">
            {timeLabel ? project.name : ""}
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {timeLabel || project.name}
          </Typography>
        </Box>
      </Box>
      <Box className="flex items-center">
        <Typography className="monospace">{renderHours(timeDiff)}</Typography>
        {false && <SettingsPopover settings={settings} />}
      </Box>
    </Box>
  );
};
