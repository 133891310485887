import { Box } from "@mui/material";
import ExoAvatar from "./ExoAvatar";

const ExoTableRecipient = ({ row }) => {
  return (
    <Box className="flex flex-row gap-2 justify-start sm:justify-start items-center w-full">
      {row.recipientType === "company" && (
        <ExoAvatar
          picture={row.recipient.logo && row.recipient.logo.thumbnailUrl}
          name={row.recipient.name}
          size="35px"
          type="company"
        />
      )}
      {row.recipientType === "contact" && (
        <ExoAvatar
          name={row.recipient.firstName + " " + row.recipient.lastName}
          size="35px"
          colorPoolId={row.recipient.colorPoolId}
        />
      )}
      <Box className="hidden md:block">
        {row.recipientType === "contact" &&
          row.recipient.firstName + " " + row.recipient.lastName}
        {row.recipientType === "company" && row.recipient.name}
      </Box>
    </Box>
  );
};

export default ExoTableRecipient;
