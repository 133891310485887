import MoreTimeIcon from "@mui/icons-material/MoreTime";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import UpdateIcon from "@mui/icons-material/Update";
import SickIcon from "@mui/icons-material/Sick";

export const requestTypesIcons = {
  timeCorrection: <MoreTimeIcon />,
  timeCompensation: <UpdateIcon />,
  vacation: <FlightTakeoffIcon />,
  sick: <SickIcon />,
};

export const requestTypesStrings = {
  timeCorrection: "Time Correction",
  timeCompensation: "Time Compensation",
  vacation: "Vacation",
  sick: "Sick",
};
