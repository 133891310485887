import { Box, Skeleton, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import DBSearch from "../special/DBSearch";
import CompanyRadio from "../radio/CompanyRadio";
import NewCompany from "../new/NewCompany";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import ExoProcessing from "../exo/ExoProcessing";

const SelectCompanies = ({
  onSelect,
  filter = "",
  preSelected,
  dataCollection,
}) => {
  const { t } = useTranslation();
  const config = useConfig();
  // request
  const [searchResults, setSearchResults] = useState(null);

  // aktions
  const [selected, setSelected] = useState(preSelected || []);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (dataCollection) setSelected(dataCollection);
  }, [dataCollection]);

  function handleChange(newElement) {
    const selectedIndex = selected.findIndex(
      (selectedObj) => selectedObj.id === newElement.id
    );
    if (selectedIndex === -1) {
      // Object not in the selected array, add it

      handleSearchResultSelect(newElement);
    } else {
      // Object already in the selected array, remove it
      const updatedArray = [...selected];
      updatedArray.splice(selectedIndex, 1);
      setSelected(updatedArray);
      onSelect(updatedArray);
    }
  }

  function handleSearchResultSelect(element) {
    DBRequest({
      config,
      path: `companies/${element.id}`,
      onResponse: (res) => {
        setSelected([...selected, res.data]);
        onSelect([...selected, res.data], res.data);
      },
      onLoading: setIsProcessing,
    });
  }

  function isSelected(id) {
    return selected.some((selectedObj) => selectedObj.id === id);
  }

  // new company
  const [openNewCompany, setOpenNewCompany] = useState(false);
  function handleNewCompany(data) {
    var newElements = searchResults;

    const newHit = {
      id: data.data.id,
      label: data.data.name,
      image: data.data.logo ? data.data.logo.temporaryUrl : "",
    };
    newElements.unshift(newHit);
    setSearchResults(newElements);
  }

  return (
    <Box className=" flex flex-col gap-2 h-full overflow-y-auto pr-2 w-full">
      <Box className="sticky top-0 z-10">
        <DBSearch param={"companies"} onSearch={setSearchResults} />
      </Box>
      {!searchResults ? (
        <>
          {[...Array(8)].map((element, index) => (
            <Skeleton key={"skeleton-select-company-" + index} height={64} />
          ))}
        </>
      ) : (
        <>
          <CompanyRadio
            company={{
              logo: {},
              icon: <AddIcon />,
              id: 0,
              name: t("New Company"),
            }}
            radioKey="companyRadio"
            key={"select-company-" + 0}
            onClick={() => {
              setOpenNewCompany(true);
            }}
          />
          {searchResults.map((element, index) => (
            <CompanyRadio
              logo={element.image}
              company={{ name: element.label }}
              key={"select-company-" + element.id}
              onClick={(e) => handleChange(element)}
              checked={isSelected(element.id)}
            />
          ))}
          <Dialog
            onClose={() => setOpenNewCompany(false)}
            open={openNewCompany}
          >
            <NewCompany
              onClose={() => setOpenNewCompany(false)}
              onCreate={handleNewCompany}
            />
          </Dialog>
        </>
      )}
      <ExoProcessing open={isProcessing} label={t("Loading Company")} />
    </Box>
  );
};

export default SelectCompanies;
