import { Box, Button, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DBUploadData, useConfig } from "../../../../api/api";
import ExoDialog from "../../../../components/exo/ExoDialog";
import Loader from "../../../../components/special/Loader";
import SingleFileUpload from "../../../../components/upload/SingleFileUpload";
import { tokens } from "../../../../global/theme/tokens";

const UploadSigendContract = ({ open, onClose, onUpload, contract }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  // selected
  const [file, setFile] = useState({});
  const [isFinished, setIsFinished] = useState(true);
  const [percent, setPercent] = useState(null);

  function handleUpload() {
    setIsFinished(false);
    const formData = new FormData();
    if (Object.keys(file).length > 0) formData.append(`file`, file);

    DBUploadData({
      config,
      path: `employee-contracts/${contract.id}/signed`,
      formData,
      onProgressChange: setPercent,
      onFinished: setIsFinished,
      onResponse: (data) => {
        onUpload(data);
        onClose();
      },
    });
  }

  return (
    <ExoDialog open={open} onClose={onClose} limitWidth>
      <Box className="h-full flex flex-col overflow-y-auto">
        <Box className="p-4 flex  w-full h-full overflow-y-auto">
          <SingleFileUpload
            setFile={setFile}
            uploadedMedia={file}
            label={t("Upload signed Contract")}
          />
        </Box>

        <Box className="flex flex-row justify-between pt-0 p-4">
          <Button onClick={onClose} variant="outlined" color="error">
            {t("Cancle")}
          </Button>
          <Button
            onClick={handleUpload}
            disabled={!Object.keys(file).length}
            variant="contained"
            color="success"
          >
            {t("Upload")}
          </Button>
        </Box>
        <Loader active={!isFinished} />
      </Box>
    </ExoDialog>
  );
};

export default UploadSigendContract;
