import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const PositionsRowEmpty = () => {
  const { t } = useTranslation();
  return (
    <tr>
      <td colSpan={9999} className=" h-10">
        <Typography variant="body1" textAlign="center">
          {t("No Position")}
        </Typography>
      </td>
    </tr>
  );
};
