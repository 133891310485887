import { Box, Typography } from "@mui/material";
import ExoInfiniteScroll from "../exo/ExoInfiniteScroll";
import DateLabel from "../label/DateLabel";
import Transaction from "../transactions/Transaction";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useRef, useState } from "react";
import TypeSkeleton from "../form/form-fields/TypeSkeleton";

export const SelectTransaction = ({ filter, onSelect }) => {
  const config = useConfig();
  const { t } = useTranslation();
  const scrollContainerRef = useRef(null);

  const entrysPerPage = 50;

  // transactions
  const [transactions, setTransactions] = useState(null);
  var dateLog = "";
  var newDate = true;

  useEffect(() => {
    fetchTransactions();
  }, [filter, config]);

  function fetchTransactions() {
    DBRequest({
      config,
      path: `transactions?${filter || ""}perPage=${entrysPerPage}`,
      method: API_GET,
      onResponse: setTransactions,
    });
  }

  return (
    <Box
      className="flex flex-col gap-2 h-full overflow-y-auto"
      ref={scrollContainerRef}
    >
      {!transactions ? (
        <TypeSkeleton n={30} />
      ) : (
        <ExoInfiniteScroll
          scrollContainerRef={scrollContainerRef}
          apiData={transactions}
          setApiData={setTransactions}
          className="flex flex-col gap-1 px-2 pb-2"
        >
          {transactions.data.map((element, index) => {
            const elementDate = element.bookingDate;
            if (elementDate !== dateLog) {
              dateLog = elementDate;
              newDate = true;
            } else {
              newDate = false;
            }
            return (
              <Fragment key={index}>
                {elementDate === dateLog && newDate && (
                  <Box className="pt-2 px-2">
                    <Typography variant="p">
                      <DateLabel date={elementDate} />
                    </Typography>
                  </Box>
                )}

                <Box className="px-2">
                  <Transaction
                    onlyPreview
                    key={index}
                    data={element}
                    onClick={onSelect}
                  />
                </Box>
              </Fragment>
            );
          })}
        </ExoInfiniteScroll>
      )}
    </Box>
  );
};
