import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { cardBreakPoints } from "./breakPoints";

const CardContainer = ({
  className = "",
  children,
  span = 1,
  rows = 1,
  sx,
  onClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const spanSx = calcSx();

  function calcSx() {
    var newSx = {};
    cardBreakPoints.forEach((breakPoint) => {
      newSx[`@media (min-width: ${breakPoint.size}px)`] = {
        gridColumn: `span ${
          span > breakPoint.cols ? breakPoint.cols : span
        }/span ${span > breakPoint.cols ? breakPoint.cols : span}`,
        gridRow: `span ${
          rows > breakPoint.rows ? breakPoint.rows : rows
        }/span ${rows > breakPoint.rows ? breakPoint.rows : rows}`,
      };
    });
    return newSx;
  }

  return (
    <Box
      onClick={onClick}
      className={
        "flex flex-col justify-center items-center gap-2 p-2 rounded-lg backdrop-blur-lg " +
        className
      }
      sx={{
        backgroundColor: colors.card,
        ...sx,
        ...spanSx,
      }}
    >
      {children}
    </Box>
  );
};
export default CardContainer;
