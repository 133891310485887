import React, { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// icons
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";

export const Inline = ({ onToggle, editorState }) => {
  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const inlineStyleString = currentInlineStyle.toJS();

  const [inline, setInline] = useState(inlineStyleString);

  useEffect(() => {
    setInline(inlineStyleString);
  }, [editorState]);

  const options = [
    { label: <FormatBoldIcon />, style: "BOLD" },
    { label: <FormatItalicIcon />, style: "ITALIC" },
    { label: <FormatUnderlinedIcon />, style: "UNDERLINE" },
  ];

  return (
    <ToggleButtonGroup value={inline}>
      {options.map((element, index) => (
        <ToggleButton
          key={element.style}
          value={element.style}
          aria-label={element.style}
          onClick={() => onToggle(element.style)}
        >
          <div className="w-4 h-4 flex justify-center items-center">
            {element.label}
          </div>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
