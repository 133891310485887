import { useTranslation } from "react-i18next";
import ExoDialog from "../../../../../components/exo/ExoDialog";
import ExoForm from "../../../../../components/exo/ExoForm";
import { API_PATCH, DBRequest, useConfig } from "../../../../../api/api";
import Loader from "../../../../../components/special/Loader";
import { useState } from "react";

export const FormRenameFile = ({ open, onClose, startData, onChange, wpe }) => {
  const { t } = useTranslation();
  const config = useConfig();
  const [isLoading, setisLoading] = useState(false);

  const fields = [
    {
      label: t("Filename"),
      type: "text",
      key: "label",
    },
  ];

  function handleSubmit(data) {
    DBRequest({
      config,
      path: `work-packages/elements/file/${wpe.id}/file`,
      method: API_PATCH({ path: data.path, name: data.label }),
      onResponse: (res) => {
        onClose();
        onChange(res, data.path);
      },
      onLoading: setisLoading,
    });
  }

  return (
    <ExoDialog open={open} limitWidth onClose={onClose}>
      <Loader active={isLoading} />
      <ExoForm
        header={t("Rename")}
        fields={fields}
        startDataCollection={startData}
        onSubmit={handleSubmit}
        onCancle={onClose}
        noDifferences
      />
    </ExoDialog>
  );
};
