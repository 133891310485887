import { useTranslation } from "react-i18next";
import Header from "../../components/special/Header";
import ExoContractPreset from "../../components/exo/ExoContractPreset";

const EmploymentContractPresets = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t("Work Contract Templates")} />
      <ExoContractPreset type="employeeContract" />
    </>
  );
};

export default EmploymentContractPresets;
