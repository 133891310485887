import React from "react";
import Header from "../../components/special/Header";
import { useTranslation } from "react-i18next";
import { generateMediaQuerysForDashboardGrid } from "../../components/dashboard-modules/dashboardGridManagement";
import { Box } from "@mui/material";
import { PendingDocuments } from "../../components/dashboard-modules/PendingDocuments";

export const PendingFinancials = () => {
  const { t } = useTranslation();
  const mediaQuery = generateMediaQuerysForDashboardGrid();

  return (
    <>
      <Header title={t("Pending Financials")} />
      <Box
        className="grid px-2 md:px-0 gap-4 overflow-auto"
        sx={{
          gridAutoRows: "600px",
          ...mediaQuery,
        }}
      >
        <PendingDocuments />
      </Box>
    </>
  );
};
