import { Box, Typography } from "@mui/material";
import { useState } from "react";
import tinycolor from "tinycolor2";

const generateShades = (hex, intensityDark = 4, intensityLight = 4) => {
  const bright = tinycolor(hex);
  const dark = tinycolor(hex);
  const shades = [hex];

  for (let i = 4; i >= 1; i--) {
    shades.push(dark.darken(i * intensityDark).toHexString()); // Generate darker shades
    shades.unshift(bright.lighten(i * intensityLight).toHexString()); // Generate brighter shades
  }

  return shades;
};

export const ColorPreview = ({ hexColor = "#1c1d22" }) => {
  const [color, setColor] = useState(hexColor);
  const shades = generateShades(color);

  function handleColorChange(e) {
    setColor(e.target.value);
  }

  return (
    <Box className="flex flex-col justify-center items-center gap-3">
      <Typography variant="h2">Color Shades</Typography>
      <Box className="flex flex-row gap-2">
        {shades.map((shade, index) => (
          <Box className=" flex flex-col justify-center items-center gap-2">
            <Box
              key={index}
              className="w-20 h-20 rounded-full"
              sx={{ backgroundColor: shade }}
            ></Box>
            {shade}
          </Box>
        ))}
      </Box>
      <input type="color" value={color} onChange={handleColorChange} />
    </Box>
  );
};

export default generateShades;
