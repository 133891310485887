import {
  useTheme,
  Box,
  Typography,
  Skeleton,
  Collapse,
  Button,
  Tooltip,
  IconButton,
  Link,
} from "@mui/material";
import { useRef, useState } from "react";
import { tokens } from "../../global/theme/tokens";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { API_DELETE, API_POST, DBRequest, useConfig } from "../../api/api";
import Feedback from "../special/Feedback";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { useTranslation } from "react-i18next";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { ExoVariants } from "../exo/ExoVariants";
import ExoDialog from "../exo/ExoDialog";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import MoneyLabel from "../label/MoneyLabel";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { NewInvoiceRelation } from "./relations/NewInvoiceRelation";
import { NewPayrollRelation } from "./relations/NewPayrollRelation";
import { NewReceiptRelation } from "./relations/NewReceiptRelation";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const Transaction = ({
  data,
  isConnected,
  onClick = () => {},
  onChange = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onlyPreview,
  selected,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const [open, setOpen] = useState(false);
  function handleClick() {
    onClick(data);
    setOpen(!open);
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });

  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
    if (type === "success") formRef.current.reset();
  }

  // actions
  const [openForm, setOpenForm] = useState(false);
  function handleCreateRelation(relationData) {
    const relation = {
      relationType: relationData.type,
      relations: [relationData[relationData.type].id],
      mode: "add",
    };
    DBRequest({
      config,
      path: "transactions/" + data.id + "/relations",
      method: API_POST(relation),
      onResponse: onChange,
      onFeedback: handleFeedback,
    });
    setOpenForm(false);
  }

  function handleDelRelation(relationData, type) {
    const relation = {
      relationType: type,
      relations: [relationData.id],
      mode: "remove",
    };
    DBRequest({
      config,
      path: "transactions/" + data.id + "/relations",
      method: API_POST(relation),
      onResponse: onChange,
      onFeedback: handleFeedback,
    });
  }

  const variants = [
    {
      label: t("Invoice"),
      Icon: ReceiptIcon,
      content: (
        <TransactionAssignmentContainer data={data}>
          <NewInvoiceRelation
            onClose={() => setOpenForm(false)}
            formRef={formRef}
            onSubmit={handleCreateRelation}
          />
        </TransactionAssignmentContainer>
      ),
    },
    {
      label: t("Receipt"),
      Icon: RequestQuoteIcon,
      content: (
        <TransactionAssignmentContainer data={data}>
          <NewReceiptRelation
            onClose={() => setOpenForm(false)}
            formRef={formRef}
            onSubmit={handleCreateRelation}
          />
        </TransactionAssignmentContainer>
      ),
    },
    {
      label: t("Salary"),
      Icon: PersonIcon,
      content: (
        <TransactionAssignmentContainer data={data}>
          <NewPayrollRelation
            onClose={() => setOpenForm(false)}
            formRef={formRef}
            onSubmit={handleCreateRelation}
          />
        </TransactionAssignmentContainer>
      ),
    },
  ];

  return (
    <Box
      className={` rounded-lg backdrop-blur-lg transition-transform w-full overflow-hidden`}
      sx={{
        backgroundColor: selected ? colors.selected : colors.card,
        ":hover": {
          transform: "scale(1.01)",
        },
      }}
    >
      <TransactionInfo data={data} onClick={handleClick} />
      {!onlyPreview && (
        <>
          <Collapse in={open}>
            {open && (
              <Box>
                <Box
                  className=" w-full rounded-full mb-2"
                  sx={{
                    backgroundColor: colors.grey[300],
                    height: "1px",
                    width: "100%",
                  }}
                ></Box>
                {!isConnected && (
                  <TransactionToolbar
                    onDelete={onDelete}
                    onEdit={onEdit}
                    transaction={data}
                  />
                )}
                <Box className="flex flex-col md:flex-row gap-2 md:gap-0 justify-between">
                  <Box className="flex flex-col gap-1 px-4">
                    {TransactionInfoList.map((info, index) => (
                      <InfoText
                        label={info.label}
                        value={data[info.key]}
                        key={data.id + "-info-" + index}
                        hidden={Boolean(data[info.key] === "0")}
                        isConnected={isConnected}
                      />
                    ))}
                  </Box>
                  <Box className="flex flex-col gap-2 p-2">
                    <Button
                      className="flex flex-row gap-1"
                      onClick={() => setOpenForm(true)}
                    >
                      <NoteAddIcon />
                      {t("New Assignment")}
                    </Button>
                    {data.invoices &&
                      data.invoices.map((assignment, index) => (
                        <Assignment
                          type="invoice"
                          label={t("Invoice")}
                          title={assignment.title}
                          onDelete={handleDelRelation}
                          data={assignment}
                          key={"invoice-" + assignment.id}
                          amount={assignment.amount + assignment.taxAmount}
                        />
                      ))}
                    {data.receipts &&
                      data.receipts.map((assignment, index) => (
                        <Assignment
                          type="receipt"
                          label={t("Receipt")}
                          title={assignment.title}
                          onDelete={handleDelRelation}
                          data={assignment}
                          key={"receipt-" + assignment.id}
                          amount={
                            assignment.amount +
                            assignment.amount * (assignment.taxRate / 100)
                          }
                        />
                      ))}
                    {data.payrolls &&
                      data.payrolls.map((assignment, index) => (
                        <Assignment
                          type="payroll"
                          title={t("Payroll")}
                          label={t("Payroll")}
                          onDelete={handleDelRelation}
                          data={assignment}
                          amount={assignment.amount}
                          key={"payroll-" + assignment.id}
                        />
                      ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Collapse>
          <ExoDialog
            onClose={() => setOpenForm(false)}
            open={openForm}
            limitWidth
            maxWidth="1400px"
          >
            <ExoVariants variants={variants} />
          </ExoDialog>
          <Feedback setState={setAlertState} state={alertState} />
        </>
      )}
    </Box>
  );
};

const Assignment = ({ data, onDelete, amount, label, type, title }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const typeMap = {
    invoice: -1,
    receipt: 1,
    payroll: 1,
    paymentReminder: -1,
  };

  const iconMap = {
    invoice: ReceiptIcon,
    receipt: RequestQuoteIcon,
    payroll: PersonIcon,
    paymentReminder: NotificationsActiveIcon,
  };

  const Icon = iconMap[type];

  const openAmount = parseFloat(data.openAmount);
  const assingedAmount = parseFloat(data.assignedAmount);

  const isOnlyTransaction =
    Math.round((assingedAmount * typeMap[type] + parseFloat(amount)) * 100) /
      100 ===
    0;

  const nr = data.nr || data.receiptNr || data.id;

  var color = colors.grey[400];

  if (openAmount !== 0) color = colors.error;

  return (
    <Box
      className="flex flex-col gap-1 p-1 w-full md:w-96"
      sx={{
        borderRadius: "10px",
        borderWidth: "1px",
        borderColor: color + "80",
        backgroundColor: color + "20",
        maxWidth: "100%",
      }}
    >
      <Box className="flex flex-row justify-between gap-1 px-2">
        <Box className="block">
          <Typography>
            <Tooltip title={t(type)}>
              <Icon fontSize="inherit" className="mr-2" />
            </Tooltip>
            {title}
          </Typography>
          <Typography variant="h6">
            {t("Nr")}: {nr}
          </Typography>
        </Box>
        <Box className="block">
          <Typography textAlign="right">
            <b>
              <MoneyLabel money={isOnlyTransaction ? amount : assingedAmount} />
            </b>
            {!isOnlyTransaction && (
              <>
                {" / "}
                <MoneyLabel money={amount} />
              </>
            )}
          </Typography>
          {openAmount !== 0 && (
            <Tooltip title={t("Not accounted")} placement="right">
              <Typography textAlign="right" variant="h6">
                <CloseIcon fontSize="inherit" className="mr-2" />
                <MoneyLabel money={openAmount} />
              </Typography>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box className=" flex flex-row gap-2 justify-between">
        <Tooltip title={t("Show file")} placement="right">
          <Link href={data.file && data.file.temporaryUrl} target="_blank">
            <IconButton color="info" className=" opacity-50">
              <VisibilityIcon />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title={t("Detatch")} placement="left">
          <IconButton
            onClick={() => onDelete(data, type)}
            color="error"
            className=" opacity-50"
          >
            <RemoveCircleIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

const InfoText = ({ label, value, hidden, isConnected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  if (hidden || !value) return;
  return (
    <Box className="mb-2 block">
      <Typography
        className="block"
        variant="p"
        sx={{ color: colors.grey[700] }}
      >
        <b>{t(label)}</b>
      </Typography>
      <Typography
        className="block"
        variant="p"
        sx={{ color: colors.grey[700] }}
      >
        {!isConnected ? t(value) : value}
      </Typography>
    </Box>
  );
};

const TransactionInfoList = [
  {
    label: "IBAN",
    key: "transactionPartnerIban",
  },
  {
    label: "Booking Text",
    key: "bookingText",
  },
];

export const TransactionsSkeleton = () => {
  return (
    <Box className="flex flex-col gap-1">
      {[...Array(30)].map((e, i) => (
        <Skeleton key={i} height={50} sx={{ transform: "scale(1)" }} />
      ))}
    </Box>
  );
};

const TransactionToolbar = ({ onDelete, onEdit, transaction }) => {
  const config = useConfig();

  function handleDelete() {
    DBRequest({
      config,
      path: `transactions/${transaction.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(transaction),
    });
  }

  return (
    <Box>
      <IconButton onClick={() => onEdit(transaction)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

const TransactionInfo = ({ data, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const hasDocuments = Boolean(
    (data.invoices && data.invoices.length) ||
      (data.receipts && data.receipts.length) ||
      (data.payrolls && data.payrolls.length)
  );
  const hasOpenAmount = parseFloat(data.openAmount).toFixed(2) !== "0.00";

  return (
    <Box
      className="py-1 px-3"
      sx={{
        borderLeft: hasOpenAmount
          ? `4px solid ${colors.redAccent[400]}`
          : `4px solid ${colors.grey[400]}`,
        cursor: onClick ? "pointer" : "auto",
      }}
    >
      <Box className="flex flex-row gap-2 justify-between " onClick={onClick}>
        <Box className="w-full">
          <Box className="flex flex-row justify-between items-start w-full gap-2">
            <Typography variant="p">
              <b>
                {data.transactionPartnerName
                  ? data.transactionPartnerName
                  : data.bookingText}
              </b>
            </Typography>
            <Box>
              {hasDocuments && (
                <>
                  {hasOpenAmount ? (
                    <Tooltip title={t("Not accounted")}>
                      <Typography>
                        <MoneyLabel money={data.openAmount} />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t("Accounted")}>
                      <PriceCheckIcon />
                    </Tooltip>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Typography variant="p" sx={{ color: colors.grey[600] }}>
            {t(data.bookingText)} | {data.intendedUse}
          </Typography>
        </Box>
        <Box className="flex justify-end items-center">
          <Typography
            noWrap
            variant="p"
            textAlign="right"
            fontWeight={600}
            sx={{
              minWidth: "100px",

              color: data.amount >= 0 ? colors.success : colors.error,
            }}
          >
            <MoneyLabel money={data.amount} symbolKey="currencyIsoCode" />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const TransactionAssignmentContainer = ({ children, data }) => {
  return (
    <Box className="flex flex-col h-full gap-2">
      <TransactionInfo data={data} />
      {children}
    </Box>
  );
};

export default Transaction;
