import { Box, Typography } from "@mui/material";
import DisplayLabel from "./base-components/Label";
import DateLabel from "../../../components/label/DateLabel";

const DisplayDate = ({ label, value }) => {
  return (
    <Box className=" cursor-pointer hover:opacity-75 transition-opacity">
      <DisplayLabel text={label} />
      <Typography variant="h4">
        <DateLabel date={value} />
      </Typography>
    </Box>
  );
};

export default DisplayDate;
