import SelectDelivery from "../../select/SelectDelivery";

const TypeDelivery = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  function handleChange(data) {
    updateValidationOnChange(
      [field.key],
      dataCollection[field.key]
        ? { ...dataCollection[field.key], ...data }
        : data
    );
  }

  return (
    <SelectDelivery
      startData={dataCollection[field.key]}
      recipient={dataCollection[field.recipientKey || "recipient"]}
      defaultType={field.defaultType}
      onSelect={handleChange}
      options={field.options}
      allowCC
    />
  );
};

export default TypeDelivery;
