import {
  useTheme,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import Feedback from "../../components/special/Feedback";
import { API_DELETE, API_PATCH, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import PreviewPositions from "../../components/preview/PreviewPositions";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import TextCard from "../../components/cards/TextCard";
import CardGrid from "../../components/cards/layout/CardGrid";
import PictureNameCard from "../../components/cards/PictureNameCard";
import FileCard from "../../components/cards/FilesCard";
import DownloadIcon from "@mui/icons-material/Download";
import Share from "../../components/special/Share";
import PopUpContainer from "../../components/popup/PopUpContainer";
import CardContainer from "../../components/cards/layout/CardContainer";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StyledTextContainer from "../../components/preview/StyledTextContainer";
import RenderHTML from "../../components/preview/RenderHTML";
import SkeletonCard from "../../components/cards/SkeletonCard";
import { useTranslation } from "react-i18next";
import DateLabel from "../../components/label/DateLabel";
import ExoResendMail from "../../components/exo/ExoResendMail";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import NewOffer from "../../components/new/NewOffer";
import useFetch from "../../api/useFetch";
import { ExoAttachments } from "../../components/exo/ExoAttachments";
import moment from "moment";
import ExoAlert from "../../components/exo/ExoAlert";
import DesignServicesIcon from "@mui/icons-material/DesignServices";

const ExtendOffer = ({ row, onUpdate, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`offers/${row.id}`);

  const [status, setStatus] = useState(0);
  const [open, setOpen] = useState(false);
  const [startData, setStartData] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);

  function handleChange(e) {
    const newStatus = e.target.value;
    setStatus(newStatus);
    if (newStatus === 1) {
      setAlertOpen(true);
    } else {
      requestStatusChange(newStatus, false);
    }
  }

  function requestStatusChange(reqStatus, sendOrderConfirmation) {
    setAlertOpen(false);
    const reqData = {
      status: reqStatus,
      sendOrderConfirmation,
    };
    DBRequest({
      config,
      path: `offers/${apiData.data.id}/status`,
      method: API_PATCH(reqData),
      onFeedback: handleAlertOpen,
      onResponse: () => {
        setApiData({
          ...apiData,
          data: { ...apiData.data, status: reqStatus },
        });
        onUpdate({ ...row, status: reqStatus });
      },
    });
  }

  const files = [
    {
      label: t("Offer"),
      icon: <DownloadIcon />,
      url: apiData && apiData.data.file ? apiData.data.file.temporaryUrl : "",
    },
    {
      label: t("Signed Offer"),
      icon: <DownloadIcon />,
      url:
        apiData && apiData.data.signedOffer
          ? apiData.data.signedOffer.temporaryUrl
          : "",
    },
  ];

  function handleFeedback(type, message) {
    onDelete(row);
  }

  // menu
  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: () => handleEdit(apiData && apiData.data),
    },
    {
      icon: <DesignServicesIcon />,
      name: t("Edit as new document"),
      onClick: () => handleEditAsNew(apiData && apiData.data),
    },
    {
      icon: <ContentCopyIcon />,
      name: t("Copy"),
      onClick: handleCopy,
    },
    {
      icon: <ForwardToInboxIcon />,
      name: t("Send Again"),
      onClick: () => setOpenResendMail(true),
      hidden: apiData && apiData.data.file ? false : true,
    },
    {
      icon: <PrintIcon />,
      name: t("Print"),
      onClick: () => handlePrint(apiData && apiData.data.file.temporaryUrl),
      hidden: apiData && apiData.data.file ? false : true,
    },
    {
      icon: <ShareIcon />,
      name: t("Share"),
      onClick: () => setOpenShare(true),
      hidden: apiData && apiData.data.file ? false : true,
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];
  function handleEdit() {
    setStartData(apiData.data);
    setOpen(true);
  }
  function handleEditAsNew() {
    setStartData({ ...apiData.data, id: null, nr: null });
    setOpen(true);
  }
  function handleCopy() {
    const dataCopied = {};
    dataCopied.positions = apiData.data.positions;
    dataCopied.note = apiData.data.note;
    dataCopied.title = apiData.data.title;
    // due date calculation
    const offerValidUntilDays = parseInt(config.offer.offerValidUntilDays);
    dataCopied.validUntil = moment().add(offerValidUntilDays, "days").format();

    setStartData(dataCopied);
    setOpen(true);
  }

  function handleUpdate(data) {
    onUpdate(data.data);
  }

  // share
  const [openShare, setOpenShare] = useState(false);
  function handleCloseShare() {
    setOpenShare(!openShare);
  }

  // print
  function handlePrint(pdfUrl) {
    // Open a new window or iframe with the PDF URL
    const printWindow = window.open(pdfUrl, "_blank");

    // Wait for the PDF to load
    printWindow.onload = () => {
      // Trigger the print dialog
      printWindow.print();
    };
  }

  // delete
  function handleDelete() {
    DBRequest({
      config,
      path: `offers/${apiData.data.id}`,
      method: API_DELETE,
      onFeedback: handleFeedback,
    });
  }

  //rensend mail
  const [openResendMail, setOpenResendMail] = useState(false);

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }
  return (
    <Box className="flex flex-col gap-4 relative overflow-y-auto">
      {!apiData ? (
        <OfferSkeleton />
      ) : (
        <>
          <ExoResendMail
            recipient={apiData.data.recipient}
            type="offers"
            id={apiData.data.id}
            open={openResendMail}
            onClose={() => setOpenResendMail(false)}
          />
          <SpeedDialMenu actions={actions} />
          <TextCard text={apiData.data.title} />
          <CardGrid>
            <PictureNameCard
              title={t("Recipient")}
              pictureUrl={
                apiData.data.recipient.logo &&
                apiData.data.recipient.logo.thumbnailUrl
              }
              name={
                apiData.data.recipient.firstName
                  ? apiData.data.recipient.firstName +
                    " " +
                    apiData.data.recipient.lastName
                  : apiData.data.recipient.name
              }
              type={apiData.data.recipientType}
            />
            <CardContainer>
              <Box className="flex flex-col justify-center items-center gap-2 p-4 rounded-lg">
                <Box className="flex flex-row gap-2">
                  <Typography variant="h6">{t("Nr")}</Typography>
                  <Typography variant="h5" className=" monospace">
                    {apiData.data.nr}
                  </Typography>
                </Box>
                <Box className="flex flex-row gap-2">
                  <Typography variant="h6">{t("Date")}</Typography>
                  <Typography variant="h5" className="monospace">
                    <DateLabel date={apiData.data.date} />
                  </Typography>
                </Box>
                <Box className="flex flex-row gap-2 pb-2">
                  <Typography variant="h6">{t("Valid Until")}</Typography>
                  <Typography variant="h5" className="monospace">
                    <DateLabel date={apiData.data.validUntil} />
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t("Status")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>{t("Sent")}</MenuItem>
                    <MenuItem value={1}>{t("Accepted")}</MenuItem>
                    <MenuItem value={2}>{t("Finished")}</MenuItem>
                    <MenuItem value={3}>{t("Rejected")}</MenuItem>
                    <MenuItem value={4}>{t("Created")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </CardContainer>
            <FileCard files={files} />

            <PictureNameCard
              title={t("Supervisor")}
              pictureUrl={
                apiData.data.authorPicture &&
                apiData.data.authorPicture.thumbnailUrl
              }
              name={apiData.data.author}
            />
            <CardContainer>
              <ExoAttachments attachments={apiData.data.attachments} />
            </CardContainer>
            <CardContainer
              span={99}
              sx={{
                height: "100vh-600px",
                display: "block",
                padding: "0px",
              }}
            >
              <PreviewPositions positions={apiData.data.positions} />
            </CardContainer>
            {apiData.data.note && (
              <CardContainer span={99}>
                <StyledTextContainer backgroundColor="transparent" fullWidth>
                  <RenderHTML HTML={apiData.data.note} />
                </StyledTextContainer>
              </CardContainer>
            )}
          </CardGrid>
        </>
      )}
      <NewOffer
        onUpdate={handleUpdate}
        onClose={() => setOpen(false)}
        open={open}
        startData={startData}
      />
      <PopUpContainer open={openShare} onClose={handleCloseShare}>
        <Share
          title={`${t("ShareOffer")} ${apiData && apiData.data.nr}`}
          url={
            apiData && apiData.data.file ? apiData.data.file.temporaryUrl : ""
          }
        />
      </PopUpContainer>
      <ExoAlert
        open={alertOpen}
        alert={{
          message: t(
            "Should an order confirmation be sent to the customer by e-mail?"
          ),
          type: "info",
          submitLabel: t("yes"),
          cancleLabel: t("no"),
        }}
        onSubmit={() => requestStatusChange(status, true)}
        onClose={() => requestStatusChange(status, false)}
      />
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

const OfferSkeleton = () => {
  return (
    <CardGrid>
      <SkeletonCard height={52} span={4} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={156} span={4} />
    </CardGrid>
  );
};

export default ExtendOffer;
