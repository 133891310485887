import { Box } from "@mui/material";
import BankCard from "../BankCard";
import { useEffect } from "react";

const SingleBankAccount = ({ onBankUpdate, bankAccounts }) => {
  useEffect(() => {
    onBankUpdate(bankAccounts.data[0]);
  }, []);

  return (
    <Box className="flex justify-center items-center p-5 w-full">
      <BankCard data={bankAccounts.data} single={true} />
    </Box>
  );
};

export default SingleBankAccount;
