import { Box } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";

const MarqueeText = ({ text, width, onClick }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    const containerWidth = containerRef.current.offsetWidth;
    const textWidth = textRef.current.scrollWidth;

    // Check if the text is larger than the container
    if (textWidth > containerWidth) {
      setShouldScroll(true);
    } else {
      setShouldScroll(false);
    }
  }, [text, width]);

  return (
    <Box
      ref={containerRef}
      onClick={onClick}
      className="overflow-hidden whitespace-nowrap"
      sx={{
        cursor: onClick ? "pointer" : "unset",
        width,
        position: "relative",
      }}
    >
      <Box
        ref={textRef}
        className={`inline-block ${shouldScroll ? "marquee-scroll" : ""}`}
        sx={{
          ...(shouldScroll && {
            animation: "marquee 10s linear infinite",
            paddingLeft: "100%",
          }),
          "@keyframes marquee": {
            "0%": {
              transform: `translateX(-${width})`,
            },
            "20%": {
              transform: `translateX(-${width})`,
            },
            "65%": {
              transform: `translateX(calc(-100% + ${width}))`,
            },
            "80%": {
              transform: `translateX(calc(-100% + ${width}))`,
            },
            "100%": {
              transform: `translateX(-${width})`,
            },
          },
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

export default MarqueeText;
