import { tokens } from "./tokens";

const nivoChartTheme = (mode, customColorTheme) => {
  const colors = tokens(mode, customColorTheme);

  return {
    background: "transparent",
    textColor: colors.grey[100],
    fontSize: 12,
    axis: {
      domain: {
        line: {
          stroke: colors.grey[600],
          strokeWidth: 2,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: colors.grey[100],
        },
      },
      ticks: {
        line: {
          stroke: colors.glass,
          strokeWidth: 1,
        },
        text: {
          fontSize: 11,
          fill: colors.grey[800],
        },
      },
    },
    grid: {
      line: {
        stroke: "red",
        strokeWidth: 1,
      },
    },
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: colors.grey[100],
        },
      },
      text: {
        fontSize: 11,
        fill: colors.grey[100],
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: colors.grey[100],
        },
      },
    },
    annotations: {
      text: {
        fontSize: 13,
        fill: "#333333",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      link: {
        stroke: "#000000",
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      outline: {
        stroke: "#000000",
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      symbol: {
        fill: "#000000",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
    },
    tooltip: {
      container: {
        background: colors.primary[300],
        color: colors.grey[900],
        fontSize: 12,
      },
      basic: {},
      chip: {},
      table: {},
      tableCell: {},
      tableCellValue: {},
    },
  };
};

export default nivoChartTheme;
