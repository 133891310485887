import NoDataAnimation from "../animation/NoDataAnimation";
import PDFPreview from "../special/PDFPreview";
import CardContainer from "./layout/CardContainer";
const FilePreviewCard = ({ file, rowSpan = 2, colSpan = 1 }) => {
  const hasNoFile = !file;

  if (hasNoFile)
    return (
      <CardContainer
        span={colSpan}
        rows={rowSpan}
        sx={{ padding: 0, maxHeight: "500px" }}
      >
        <NoDataAnimation />
      </CardContainer>
    );
  return (
    <CardContainer
      span={colSpan}
      rows={rowSpan}
      sx={{ padding: 0, maxHeight: "500px" }}
    >
      {file && file.mimeType === "application/pdf" ? (
        <PDFPreview src={file.temporaryUrl} />
      ) : (
        <img
          className="w-full h-full object-contain max-h-96"
          src={file && file.temporaryUrl}
          alt={file && file.title}
        />
      )}
    </CardContainer>
  );
};

export default FilePreviewCard;
