import React, { useState } from "react";
import ExoForm from "../../../components/exo/ExoForm";
import { useTranslation } from "react-i18next";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../../api/api";
import ExoAlert from "../../../components/exo/ExoAlert";

export const NetworkStorageForm = ({
  startDataCollection,
  onCreate,
  onClose,
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const [alert, setAlert] = useState(null);

  const fields = [
    {
      label: t("Name"),
      key: "name",
      type: "text",
    },
    {
      label: t("Host"),
      key: "host",
      type: "text",
    },
    {
      label: t("Root Directory"),
      key: "rootDirectory",
      type: "text",
    },
    {
      label: t("Project Directory"),
      key: "projectRootDirectory",
      type: "text",
    },
    {
      label: t("Username"),
      key: "username",
      type: "text",
    },
    {
      label: t("Password"),
      key: "password",
      type: "password",
    },
    {
      label: t("Test Connection"),
      type: "checkConnection",
    },
  ];

  function handleCheckConnection(data) {
    DBRequest({
      config,
      path: `network-storages/test-connection`,
      method: API_POST(data),
      onResponse: () => handleCreateDisk(data),
      onError: () =>
        setAlert({
          message: t("Connection Failed"),
          cancleLabel: t("Schließen"),
        }),
    });
  }

  function handleResponse(data) {
    onCreate(data.data);
    onClose();
  }

  function handleCreateDisk(data) {
    const isUpdate = Boolean(startDataCollection && startDataCollection.id);
    DBRequest({
      config,
      path: isUpdate
        ? `network-storages/${startDataCollection.id}`
        : `network-storages`,
      method: isUpdate ? API_PATCH(data) : API_POST(data),
      onResponse: handleResponse,
    });
  }

  return (
    <>
      <ExoForm
        header={t("Network Storage")}
        fields={fields}
        onSubmit={handleCheckConnection}
        onCancle={onClose}
        noDifferences
        startDataCollection={startDataCollection}
      />
      <ExoAlert
        alert={alert || {}}
        open={Boolean(alert)}
        onClose={() => setAlert(null)}
      />
    </>
  );
};
