import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const InfoControlls = ({ onSelect, onClose }) => {
  const { t } = useTranslation();
  return (
    <Box className=" absolute bottom-0 left-0 w-full p-2">
      <Box className="flex flex-row-reverse justify-between mt-2">
        <Button onClick={onSelect} variant="contained" color="success">
          {t("Select")}
        </Button>
        {onClose && <Button onClick={onClose}>{t("Cancle")}</Button>}
      </Box>
    </Box>
  );
};

export default InfoControlls;
