import { useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import CustomPosition from "./entries/CustomPosition";
import PredefinedPosition from "./entries/PredefinedPosition";
import { EntryContainer } from "./EntryContainer";

const Entry = ({
  col,
  index,
  setItems,
  item,
  onFocus,
  descriptionVars,
  showVars,
  imageType,
  imageApiFilter,
  onChange,
  setExpand,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  if (col.hidden) return;

  return (
    <EntryContainer col={col}>
      {item.positionableType === "custom" && (
        <CustomPosition
          imageType={imageType}
          imageApiFilter={imageApiFilter}
          onFocus={onFocus}
          col={col}
          item={item}
          onChange={onChange}
          setItems={setItems}
          descriptionVars={descriptionVars}
          showVars={showVars}
        />
      )}
      {item.positionableType !== "custom" && (
        <PredefinedPosition
          onFocus={onFocus}
          col={col}
          item={item}
          onChange={onChange}
          setItems={setItems}
        />
      )}
    </EntryContainer>
  );
};

export default Entry;
