import { Box, useTheme } from "@mui/material";
import ExoTable from "../ExoTable";
import MediaPreview from "./MediaPreview";
import { formatBytes } from "./MediaInfo";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";

const MediaTable = ({ media, isLoading, onSelect, selectedItems }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const columns = [
    {
      header: t("Filename"), // header of column
      key: "title", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      onClick: (row, column) => onSelect(row), // function on cell click
      cell: ({ row }) => (
        <Box
          className="flex flex-row gap-2 justify-start items-center rounded-lg"
          sx={{
            borderWidth: "1px",
            borderColor: Boolean(
              selectedItems.findIndex((selected) => selected.id === row.id) !=
                -1
            )
              ? colors.primary[600]
              : colors.primary[100],
            ":hover": {
              borderColor: Boolean(
                selectedItems.findIndex((selected) => selected.id === row.id) !=
                  -1
              )
                ? colors.primary[600]
                : colors.primary[400],
            },
          }}
        >
          <MediaPreview
            src={row.thumbnailUrl}
            mimeType={row.mimeType}
            onlyIcons={false}
          />
          {row.title}
        </Box>
      ),
    },
    {
      header: t("Filetype"), // header of column
      key: "mimeType", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100px", // width of column
      cell: ({ row }) => row.mimeType.split("/")[1], // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Filesize"), // header of column
      key: "fileSize", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100px", // width of column
      cell: ({ row }) => formatBytes(row.fileSize), // custom xml for cells in the column. you get the row as object
    },
  ];

  return (
    <Box className="w-full">
      <ExoTable
        noBackground
        columns={columns}
        data={media}
        isLoading={isLoading}
        noPagination
      />
    </Box>
  );
};

export default MediaTable;
