import EntryEditable from "./EntryEditable";
import EntryConst from "./EntryConst";

const PredefinedPosition = ({ col, item, index, onChange }) => {
  return (
    <>
      {col.custom && <>{col.custom(item, col.unit)}</>}
      {(col.key === "position" || col.key === "name" || col.key === "unit") && (
        <EntryConst
          value={item[col.key]}
          unit=""
          className={col.className}
          item={col.key === "name" && item}
        />
      )}
      {(col.key === "amount" ||
        col.key === "taxRate" ||
        col.key === "price") && (
        <EntryEditable col={col} item={item} onChange={onChange} />
      )}
    </>
  );
};

export default PredefinedPosition;
