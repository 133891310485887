import React from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";

const LabeledBox = ({
  label,
  children,
  onClick,
  className,
  variant = "default",
  sx,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      onClick={onClick}
      className={`transition-colors cursor-pointer block relative ${className}`}
      sx={{
        ":hover": {
          cursor: "pointer",
          borderColor: colors.primary[800],
        },
        backgroundColor: colors.bgInput,
        minHeight: "53px",
        padding: "15px 14px!important",
        border: "0px solid " + colors.primary[300],
        borderRadius: "10px",
        opacity: variant === "default" ? 1 : 0.4,
        ...sx,
      }}
    >
      <Box
        className={children && "absolute left-0 top-0"}
        sx={{
          transform: children && "matrix(0.75, 0, 0, 0.75, 14, -9)",
          transformOrigin: "top left",
          fontSize: children ? "auto" : "13.7px",
          display: "block",
          textAlign: "start",
          fontWeight: 400,
        }}
      >
        {label}
      </Box>

      {children}
    </Box>
  );
};

export default LabeledBox;
