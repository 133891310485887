import { useTheme } from "@emotion/react";
import { useState } from "react";
import { tokens } from "../../../global/theme/tokens";
import { Box } from "@mui/material";
import ExoColorPicker from "../../special/ExoColorPicker";

const TypeColor = ({
  field,
  updateValidationOnChange,
  dataCollection,
  objectKey,
  errors,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);

  const handleColorSelect = (color) => {
    updateValidationOnChange(field.key, color);
  };

  return (
    <>
      <Box className="flex gap-2" onClick={() => setOpen(true)}>
        <Box
          sx={{
            width: "53px",
            minWidth: "53px",
            height: "53px",
            backgroundColor: objectKey
              ? dataCollection[objectKey][field.key]
              : dataCollection[field.key] || colors.bgInput,
            borderRadius: "10px",
            borderWidth: "0px",
            borderColor: colors.primary[300],
            ":hover": {
              borderColor: colors.primary[900],
              cursor: "pointer",
            },
          }}
        ></Box>
        <Box
          className="w-full flex justify-start items-center px-3"
          sx={{
            height: "53px",
            borderRadius: "10px",
            borderWidth: "0px",
            backgroundColor: colors.bgInput,
            borderColor: colors.primary[300],
            ":hover": {
              borderColor: colors.primary[900],
              cursor: "pointer",
            },
          }}
        >
          {field.label}
        </Box>
      </Box>
      <ExoColorPicker
        open={open}
        onSelect={handleColorSelect}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default TypeColor;
