import { WPEFiles } from "./types/WPEFiles";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ChecklistIcon from "@mui/icons-material/Checklist";

export const wpeList = [
  {
    element: WPEFiles,
    label: "Files",
    type: "file",
    Icon: InsertDriveFileIcon,
  },
  {
    element: WPEFiles,
    label: "Tasks",
    type: "list",
    Icon: ChecklistIcon,
  },
];
