import { Box } from "@mui/material";
import DataModule from "../DataModules";
import NoDataAnimation from "../../../components/animation/NoDataAnimation";
import { useTranslation } from "react-i18next";

const EmployeeAddress = ({ employeeInfo }) => {
  const { t } = useTranslation();

  if (!employeeInfo.address)
    return (
      <Box className="w-full h-full flex justify-center items-center">
        <NoDataAnimation />
      </Box>
    );

  const dataModules = [
    {
      type: "text",
      label: t("Street"),
      value: employeeInfo.address.street,
    },
    {
      type: "text",
      label: t("House Number"),
      value: employeeInfo.address.houseNumber,
    },
    {
      type: "text",
      label: t("ZIP Code"),
      value: employeeInfo.address.zipCode,
    },
    {
      type: "text",
      label: t("City"),
      value: employeeInfo.address.city,
    },
    {
      type: "text",
      label: t("Country"),
      value: employeeInfo.address.country,
    },
    {
      type: "text",
      label: t("Addition"),
      value: employeeInfo.address.addressAddition,
    },
  ];

  return (
    <Box className="flex flex-col gap-3 items-start">
      {dataModules.map((display, index) => (
        <DataModule
          key={index}
          type={display.type}
          label={display.label}
          value={display.value}
        />
      ))}
    </Box>
  );
};

export default EmployeeAddress;
