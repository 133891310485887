import { Box, IconButton, Link, Typography } from "@mui/material";
import CardContainer from "./layout/CardContainer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Padding } from "@mui/icons-material";
import NoDataAnimation from "../animation/NoDataAnimation";

export const MultiContactCard = ({
  contactArray,
  nameKey,
  valueKey,
  Icon,
  linkPrefix,
  span,
  label,
}) => {
  return (
    <CardContainer
      span={span}
      sx={{
        td: {
          padding: "2px 2px",
        },
      }}
    >
      {label && <Typography variant="h6">{label}</Typography>}
      {contactArray && contactArray.length ? (
        <table>
          <tbody>
            {contactArray.map((element) => (
              <ListItem
                Icon={Icon}
                name={element[nameKey]}
                value={element[valueKey]}
                linkPrefix={linkPrefix}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <NoDataAnimation size={"60px"} />
      )}
    </CardContainer>
  );
};

const ListItem = ({ Icon, name, value, linkPrefix }) => {
  return (
    <tr>
      <td>
        <Typography textAlign="right">{name}</Typography>
      </td>
      <td>
        <ArrowForwardIosIcon fontSize="15px" className=" opacity-25" />
      </td>
      <td>
        <Typography textAlign="left">{value}</Typography>
      </td>
      {Icon && linkPrefix && (
        <td>
          <Link href={linkPrefix + value}>
            <IconButton>
              <Icon />
            </IconButton>
          </Link>
        </td>
      )}
    </tr>
  );
};
