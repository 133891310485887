import React, { useEffect } from "react";
import { TypeTemplate } from "../form/form-fields/TypeTemplate";

export const StepTemplate = ({
  dataCollection,
  updateValidation,
  updateDataCollection,
  templateType,
  templateKey = "templateId",
}) => {
  function handleChange(key, value) {
    updateDataCollection(value);
  }

  useEffect(() => {
    updateValidation(Boolean(dataCollection.templateId));
  }, [dataCollection.templateId]);

  const field = {
    label: "",
    key: templateKey,
    templateType: templateType,
  };

  return (
    <TypeTemplate
      field={field}
      dataCollection={dataCollection}
      updateValidationOnChange={handleChange}
    />
  );
};
