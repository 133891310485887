import { useTheme, Box, InputBase } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { SearchRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

const DBSearch = ({
  param,
  onSearch,
  noInitialLoad,
  sort = "label",
  children,
  limit = 25,
  sx,
  className,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const scrollContainerRef = useRef(null);

  const cookies = new Cookies();
  const prefix = cookies.get("searchPrefix");

  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [hits, setHits] = useState([]);

  useEffect(() => {
    if (!noInitialLoad) handleSearch({ target: { value: "" } });
  }, [config]);

  function handleSearch(event, newPage, keepHits = false) {
    if (!config) return;

    const searchData = {
      q: event ? event.target.value : search,
      sort: [`${sort}:asc`],
      page: newPage || 1,
      hitsPerPage: limit,
    };
    SearchRequest({
      baseUrl: config.searchUrl,
      path: `indexes/${prefix}${param}/search`,
      method: "post",
      data: searchData,
      onResponse: (res) => {
        onSearch(keepHits ? [...hits, ...res.hits] : res.hits);
        setSearchResult(res);
        setHits(keepHits ? [...hits, ...res.hits] : res.hits);
      },
    });
    if (event) setSearch(event.target.value);
  }

  return (
    <Box
      ref={scrollContainerRef}
      sx={sx}
      className={
        "relative flex flex-col gap-2 h-full w-full overflow-y-auto pr-2"
      }
    >
      <Box
        className="flex backdrop-blur-lg py-1 sticky top-0 z-10"
        borderRadius="3px"
        backgroundColor={colors.bgInput}
      >
        <InputBase
          sx={{ ml: 2, flex: 1 }}
          placeholder={t("Search")}
          value={search}
          onChange={handleSearch}
          className="w-full"
        />
      </Box>
      {children && searchResult && (
        <InfiniteScroll
          scrollContainerRef={scrollContainerRef}
          searchResult={searchResult}
          onLoadMore={(newPage) => handleSearch(null, newPage, true)}
        >
          {children}
        </InfiniteScroll>
      )}
    </Box>
  );
};

const InfiniteScroll = ({
  children,
  scrollContainerRef,
  searchResult,
  onLoadMore,
  triggerDist = 500,
  className,
}) => {
  const [requestedPage, setRequestedPage] = useState(1);

  useEffect(() => {
    // Ensure the scrollContainerRef is defined
    if (scrollContainerRef && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      // Function to handle scroll event
      const handleScroll = () => {
        const remainingScroll =
          container.scrollHeight - container.clientHeight - container.scrollTop;

        const totalPages = searchResult.totalPages;
        const currentPage = searchResult.page;

        if (
          triggerDist > remainingScroll &&
          currentPage < totalPages &&
          currentPage === requestedPage
        ) {
          setRequestedPage(currentPage + 1);
          onLoadMore(currentPage + 1);
        }
      };

      // Attach scroll event listener to the container
      container.addEventListener("scroll", handleScroll);

      // Cleanup function
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrollContainerRef, searchResult]);

  return <>{children}</>;
};

export default DBSearch;
