import { Box, Button, Skeleton } from "@mui/material";
import WorkDayGrid from "./workday/WorkDayGrid";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { WorkDayOverview } from "./workday/WorkDayOverview";
import { Legend } from "./workday/Legend";

const ExoWorkday = forwardRef(
  (
    {
      workdays,
      dateStart,
      daySpan,
      edit,
      onCancle,
      labelFormat = "YYYY-MM-DD dddd",
      onSubmit = (e, d) => console.log(e, d),
      snapToGrid = true,
      snapRatio = 1,
      hourStart = 7,
      hourEnd = 18,
      res = 4,
      resHeight = 20,
      userId,
      projectId,
      corrections,
      customColumnLabel,
      noInfo,
      onDateClick,
      noLegend,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const config = useConfig();

    const [data, setData] = useState(null);

    useEffect(() => {
      if (config && !corrections && dateStart) updateDbData();
      if (config && corrections) updateDbDataCorrections();
    }, [config, dateStart, corrections]);

    useEffect(() => {
      if (workdays) {
        setData(workdays);
      }
    }, [workdays]);

    function updateDbData() {
      if (workdays) return;
      const newDateEnd = moment(dateStart, "YYYY-MM-DD")
        .add(daySpan || 1, "day")
        .format("YYYY-MM-DD");

      var filter = "";
      if (userId) filter += `&userId=${userId}`;
      if (projectId) filter += `&projectId=${projectId}`;

      DBRequest({
        config,
        path: `timetrackings/timesperday?startDate=${dateStart}&endDate=${newDateEnd}${filter}`,
        method: API_GET,
        onResponse: handleSetData,
      });
    }

    function updateDbDataCorrections() {
      const startDate = moment(corrections[0].timeStart).format("YYYY-MM-DD");
      const newDateEnd = moment(startDate, "YYYY-MM-DD")
        .add(1, "day")
        .format("YYYY-MM-DD");

      var filter = "";
      if (userId) filter += `&userId=${userId}`;
      DBRequest({
        config,
        path: `timetrackings/timesperday?startDate=${startDate}&endDate=${newDateEnd}${filter}`,
        method: API_GET,
        onResponse: handleCreateWorkDayComparison,
      });
    }

    function handleCreateWorkDayComparison(res) {
      var newWorkDays = {};
      const day = Object.keys(res.data)[0];
      // old times
      newWorkDays[t("Current")] = res.data[day];

      // edited times
      // find times that are not modified
      var currentTimes = [...res.data[day]];
      corrections.forEach((corr) => {
        const corrStart = moment(corr.timeStart).format();
        const corrEnd = moment(corr.timeEnd).format();

        currentTimes.forEach((curr, index) => {
          if (corr.id === curr.id) return currentTimes.splice(index, 1);

          const currStart = moment(curr.timeStart).format();
          const currEnd = moment(curr.timeEnd).format();
          if (corrStart === currStart && corrEnd === currEnd)
            return currentTimes.splice(index, 1);
        });
      });
      // extend corrections with not modified times
      newWorkDays[t("Requested")] = [...currentTimes, ...corrections];

      setData({ data: newWorkDays });
    }

    function handleSetData(res) {
      setData(res);
    }

    function handleSubmit() {
      var timeElementsWithMethod = [];

      const timeKeys = Object.keys(data.data);
      timeKeys.forEach((key) => {
        data.data[key].forEach((time) => {
          if (time.method)
            timeElementsWithMethod.push({
              id: time.method === "add" ? null : time.id,
              method: time.method,
              timeStart: time.timeStart,
              timeEnd: time.timeEnd,
              projectId: time.project ? time.project.id : null,
              taskGroupId: time.taskGroup ? time.taskGroup.id : null,
              comment: time.comment,
              workPackageId: time.workPackage ? time.workPackage.id : null,
            });
        });
      });

      onSubmit(timeElementsWithMethod, data);
    }

    // exposed functions
    useImperativeHandle(ref, () => ({
      updateDbData,
    }));

    // expand display hour limit
    const [hourLimit, setHourLimit] = useState({
      start: hourStart,
      end: hourEnd,
    });

    useEffect(() => {
      if (!data) return;
      findHourDisplayLimits();
    }, [hourStart, hourEnd, data]);

    function findHourDisplayLimits() {
      const daysObject = data.data;
      const days = Object.keys(daysObject);

      var lowestTime = hourStart;
      var highestTime = hourEnd;

      days.forEach((day) => {
        const times = daysObject[day];
        times.forEach((time) => {
          // start
          const timeStart = moment(time.timeStart);
          const timeStartHour = parseFloat(timeStart.format("H"));
          if (timeStartHour < lowestTime) lowestTime = timeStartHour;

          // end
          const timeEnd = moment(time.timeEnd);
          const timeEndHour = parseFloat(timeEnd.format("H")) + 1;
          if (timeEndHour > highestTime) highestTime = timeEndHour;
        });
      });
      setHourLimit({ start: lowestTime, end: highestTime });
    }

    return (
      <Box className="h-full w-full flex flex-col overflow-hidden">
        {!data ? (
          <Box className="flex flex-col gap-1">
            {[...Array(hourLimit.end - hourLimit.start)].map((e, i) => (
              <Skeleton
                key={i}
                height={resHeight * res - 4}
                sx={{
                  transform: "none",
                }}
              />
            ))}
          </Box>
        ) : (
          <Box className="flex h-full w-full gap-4 overflow-hidden">
            {(!daySpan || daySpan === 1) && (
              <WorkDayOverview
                workdays={data.data}
                userId={userId}
                hasCorrections={Boolean(corrections)}
              />
            )}
            <WorkDayGrid
              edit={edit}
              workdays={data}
              setWorkdays={setData}
              onCancle={onCancle}
              snapToGrid={snapToGrid}
              snapRatio={snapRatio}
              hourStart={hourLimit.start}
              hourEnd={hourLimit.end}
              res={res}
              resHeight={resHeight}
              labelFormat={labelFormat}
              customColumnLabel={customColumnLabel}
              noInfo={noInfo}
              onDateClick={onDateClick}
              userId={userId}
            />
          </Box>
        )}
        {!noLegend && <Legend />}
        {edit && (
          <Box className="p-2 flex flex-row justify-between">
            <Button variant="outlined" color="error" onClick={onCancle}>
              {t("Cancle")}
            </Button>
            <Button variant="contained" color="success" onClick={handleSubmit}>
              {t("Submit")}
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);

export default ExoWorkday;
