import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  InputBase,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import LabeledBox from "../../special/LabeledBox";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { createElement, useEffect, useState } from "react";
import { tokens } from "../../../global/theme/tokens";
import uuid from "react-uuid";

const TypeNameValueArray = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const { t } = useTranslation();

  const nameKey = field.nameKey || "name";
  const valueKey = field.valueKey || "value";

  const radioItems = field.radioItems;

  useEffect(() => {
    if (!dataCollection[field.key]) return;
  }, [dataCollection[field.key]]);

  function generateUuids() {
    var newElements = [];

    dataCollection[field.key].forEach((element) => {
      newElements.push({ ...element, uuid: uuid() });
    });

    updateValidationOnChange([field.key], newElements);
  }

  function handleChange(newElement) {
    // if the element is created in the frontend it only has a uuid. The backend validation checks on update if the id is existing...
    const idTypeToUpdate = newElement.id ? "id" : "uuid";
    const existingElementIndex = dataCollection[field.key].findIndex(
      (obj) => obj[idTypeToUpdate] === newElement[idTypeToUpdate]
    );

    var newElements = dataCollection[field.key];
    newElements[existingElementIndex] = newElement;

    updateValidationOnChange([field.key], newElements);
  }

  function handleAdd() {
    var newArray = [];

    if (dataCollection[field.key]) newArray = [...dataCollection[field.key]];

    // radio stuff

    var defaultRadioParams = {};
    radioItems.forEach((element) => {
      defaultRadioParams[element.key] = !Boolean(newArray.length);
    });

    newArray.push({
      ...defaultRadioParams,
      [nameKey]: "",
      [valueKey]: "",
      uuid: uuid(),
    });

    updateValidationOnChange([field.key], newArray);
  }

  function handleDelete(element) {
    const idTypeToUpdate = element.id ? "id" : "uuid";
    const updatedArray = dataCollection[field.key].filter(
      (obj) => obj[idTypeToUpdate] !== element[idTypeToUpdate]
    );

    updateValidationOnChange([field.key], updatedArray);
  }

  // radio stuff
  function handleRadioChange(key, elementToUpdate) {
    const idTypeToUpdate = elementToUpdate.id ? "id" : "uuid";

    var newElements = [];

    dataCollection[field.key].forEach((element) => {
      newElements.push({
        ...element,
        [key]: element[idTypeToUpdate] === elementToUpdate[idTypeToUpdate],
      });
    });

    updateValidationOnChange([field.key], newElements);
  }

  return (
    <LabeledBox
      label={field.label}
      sx={{
        padding: "10px 0px",
        cursor: "unset!important",
        transition: "height 0.5s ease",
      }}
    >
      <Box className="overflow-hidden rounded-lg">
        {dataCollection[field.key] &&
          dataCollection[field.key].map((element) => (
            <NameValueElement
              key={element.id ? element.id : element.uuid}
              element={element}
              onChange={handleChange}
              placeholder={field.placeholder}
              nameKey={nameKey}
              valueKey={valueKey}
              onDelete={handleDelete}
              radioItems={radioItems}
              onRadioChange={handleRadioChange}
            />
          ))}
        <Box className="pt-2">
          <Button
            className="flex items-center gap-4"
            color="secondary"
            onClick={handleAdd}
            sx={{
              paddingLeft: "5px",
            }}
          >
            <AddCircleIcon color="success" />
            {field.addLabel || t("Add")}
          </Button>
        </Box>
      </Box>
    </LabeledBox>
  );
};

const NameValueElement = ({
  onChange,
  element,
  placeholder = {},
  onDelete,
  nameKey,
  valueKey,
  radioItems,
  onRadioChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [toggelRemove, setToggelRemove] = useState(false);

  const deleteWidth = "100px";

  function handleNameChange(e) {
    const newElement = { ...element, [nameKey]: e.target.value };
    onChange(newElement);
  }
  function handleValueChange(e) {
    const newElement = { ...element, [valueKey]: e.target.value };
    onChange(newElement);
  }

  return (
    <Box
      className="flex items-center gap-2 relative"
      sx={{
        transform: toggelRemove
          ? `translateX(-${deleteWidth})`
          : `translateX(0px)`,
        transition: "transform 0.4s",
      }}
    >
      <IconButton onClick={() => setToggelRemove(true)} size="small">
        <RemoveCircleIcon color="error" />
      </IconButton>
      <Box className="flex items-center">
        {radioItems &&
          radioItems.map((item, index) => (
            <Tooltip placement="left" title={t(item.label)} key={item.key}>
              <IconButton
                onClick={() => onRadioChange(item.key, element)}
                size="small"
              >
                {createElement(item.Icon, {
                  sx: { opacity: element[item.key] ? 0.8 : 0.3 },
                  color: element[item.key] ? "success" : "",
                })}
              </IconButton>
            </Tooltip>
          ))}
      </Box>
      <Box
        className="flex items-center w-full"
        sx={{
          borderBottom: `1px solid ${colors.selected}`,
        }}
      >
        <Box
          className="flex items-center"
          sx={{
            "::after": {
              content: "''",
              width: "1px",
              height: "31px",
              background: `linear-gradient(0deg, ${colors.selected} 0%, ${colors.selected}00 90%)`,
            },
          }}
        >
          <InputBase
            onChange={handleNameChange}
            value={element[nameKey]}
            className="w-full ml-3"
            sx={{ input: { padding: 0 } }}
            placeholder={placeholder.name || "Placeholder"}
          />

          <KeyboardArrowRightIcon className=" opacity-25" />
        </Box>

        <InputBase
          onChange={handleValueChange}
          value={element[valueKey]}
          className="w-full ml-3"
          sx={{ input: { padding: 0 } }}
          placeholder={placeholder.value || "Placeholder"}
        />
      </Box>

      <Box
        className="overflow-hidden absolute cursor-pointer"
        sx={{
          right: `-${deleteWidth}`,
          height: "32px",
          minWidth: deleteWidth,
          transition: "min-width 0.2s ease",
          bgcolor: colors.error,
        }}
      >
        {toggelRemove ? (
          <ClickAwayListener onClickAway={() => setToggelRemove(false)}>
            <Box
              onClick={() => onDelete(element)}
              className="w-full left-0 flex items-center justify-center"
              sx={{ position: "absolute", width: deleteWidth, height: "32px" }}
            >
              {t("delete")}
            </Box>
          </ClickAwayListener>
        ) : (
          <Box
            onClick={() => onDelete(element)}
            className="w-full left-0 flex items-center justify-center"
            sx={{
              position: "absolute",
              width: deleteWidth,
              height: "32px",
            }}
          >
            {t("delete")}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TypeNameValueArray;
