import { SelectReceipt } from "../../select/SelectReceipt";

export const NewReceiptRelation = ({ onSubmit }) => {
  function handleSubmit(receipt) {
    onSubmit({ receipt, type: "receipt" });
  }

  return (
    <SelectReceipt
      apiUrl={"receipts/open"}
      onSelect={handleSubmit}
      hasOpenAmount
    />
  );
};
