import { Box, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ExoForm from "../../components/exo/ExoForm";
import { API_PATCH, DBRequest, useConfig } from "../../api/api";
import { useTranslation } from "react-i18next";
import findDifferences from "../../components/exo/findDifferencesInDataCollection";

const DBSettingTab = ({
  data,
  api = "NOAPI",
  onFeedback = () => {},
  onResponse = () => {},
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const formRef = useRef(null);
  const [fields, setFields] = useState([]);
  const [startData, setStartData] = useState({});

  useEffect(() => {
    var newFields = [];
    var newStartData = {};
    data.forEach((setting) => {
      newFields.push({
        label: t(setting.displayName),
        key: setting.id,
        type: setting.type || "text",
        options: setting.options,
        noUnselect: setting.noUnselect,
      });
      newStartData[setting.id] = setting.value;
    });
    setStartData(newStartData);
    setFields(newFields);
  }, []);

  function handleChange() {
    if (formRef && formRef.current) console.log(formRef.current.isValid());
  }

  function handleSubmit() {
    DBRequest({
      config,
      path: api,
      method: API_PATCH(
        findDifferences(startData, formRef.current.dataCollection)
      ),
      onResponse,
      onFeedback,
    });
  }

  return (
    <Box className="h-full w-full p-4 flex flex-col justify-between gap-4">
      <ExoForm
        ref={formRef}
        className="h-full flex flex-col gap-4 overflow-y-auto pt-2"
        fields={fields}
        startDataCollection={startData}
        noControls
        onChange={handleChange}
      />
      <Box className="flex flex-row justify-end">
        <Button variant="contained" color="success" onClick={handleSubmit}>
          {t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default DBSettingTab;
