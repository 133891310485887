import { useTheme, Box, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import ExoTable from "../../components/exo/ExoTable";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { RequestStatus } from "../../components/special/Status";
import ExoAvatar from "../../components/exo/ExoAvatar";
import ExtendRequest from "./ExtendRequest";
import {
  requestTypesIcons,
  requestTypesStrings,
} from "../../components/special/requestTypes";
import { useTranslation } from "react-i18next";
import DateTimeLabel from "../../components/label/DateTimeLabel";
import { ExoTableUser } from "../../components/exo/ExoTableUser";

const Request = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  // tabel
  const [requests, setRequests] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "employeerequests?sortOrder=desc&sortBy=id",
      method: API_GET,
      onResponse: setRequests,
      onLoading: setIsLoading,
      debug: true,
    });
  }, [config]);

  const columns = [
    {
      header: t("Employee"), // header of column
      key: "user", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "center", // cell text alignment
      sortable: true, // enable sort function for column
      width: "200px", // width of column
      cell: ({ row }) => <ExoTableUser user={row.user} />, // custom xml for cells in the column. you get the row as object
      hiddenMobile: true, // hides the column on mobile
      onClick: (row, column) => console.log(row, column), // function on cell click
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "center", // header text alignment
      tdAlign: "center", // cell text alignment
      width: "120px",
      sortable: true, // enable sort function for column
      cell: ({ row }) => <RequestStatus number={row.status}></RequestStatus>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Type"),
      key: "type",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      cell: ({ row }) => (
        <Box className="flex flex-row gap-2">
          {requestTypesIcons[row.type]}
          {t(requestTypesStrings[row.type])}
        </Box>
      ),
      hiddenMobile: true,
    },
    {
      header: t("Requested"),
      key: "createdAt",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "150px",
      cell: ({ row }) => <DateTimeLabel dateTime={row.createdAt} />,
    },
    {
      header: t("Updated"),
      key: "updatedAt",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "150px",
      cell: ({ row }) => <DateTimeLabel dateTime={row.updatedAt} />,
    },
  ];

  function handleUpldateRequests(updatedRequest) {
    const newRequests = requests.data.map((obj) =>
      obj.id == updatedRequest.data.id ? updatedRequest.data : obj
    );
    setRequests({ ...requests, data: newRequests });
  }

  return (
    <>
      <Header title={t("Request")} />

      <ExoTable
        columns={columns}
        data={requests}
        isLoading={isLoading}
        extendElement={({ row, onUpdate }) => (
          <ExtendRequest request={row} onUpdate={onUpdate} />
        )}
      />
    </>
  );
};

export default Request;
