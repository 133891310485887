import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import { ExoTableUser } from "../../exo/ExoTableUser";
import MoneyLabel from "../../label/MoneyLabel";
import moment from "moment";

export const PayrollPreviewElement = ({ element }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  if (!element) return;
  const startDate = moment(element.startDate).format("MM/YYYY");

  return (
    <Box
      className="rounded-lg px-3 py-2 flex justify-between"
      sx={{ bgcolor: colors.card }}
    >
      <Box>
        <Typography>
          <b>{t("Payroll")}</b>
        </Typography>
        <Typography variant="h6" className="pb-2">
          {startDate}
        </Typography>
        <ExoTableUser user={element.user} />
      </Box>

      <Box>
        <Typography variant="h4">
          <b>
            <MoneyLabel money={element.openAmount} />
          </b>
        </Typography>
        <Typography variant="h6">{t("open amount")}</Typography>
      </Box>
    </Box>
  );
};
