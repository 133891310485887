import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AccessRoleList } from "./AccessRoleList";
import useFetch from "../../../api/useFetch";
import { AccessRoleEditor } from "./AccessRoleEditor";
import { useState } from "react";
import { updateOrCreate } from "../../../components/special/updateOrCreate";

const AccessManager = () => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);

  const { apiData, setApiData } = useFetch("access-roles");

  function handleSavedRole(role) {
    setApiData(updateOrCreate(apiData, role));
  }

  return (
    <Box className="flex flex-col lg:flex-row gap-4 h-full overflow-hidden w-full">
      <AccessRoleList
        selected={selected}
        onSelect={setSelected}
        apiData={apiData}
        setApiData={setApiData}
      />
      <AccessRoleEditor selected={selected} onSave={handleSavedRole} />
    </Box>
  );
};

export default AccessManager;
