import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import moment from "moment";

const Teams = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const dateString = "2024-02-02";
  const dateMoment = moment(dateString);
  const newMoment = dateMoment.utcOffset(-1);

  return (
    <>
      <Header
        title="Teams"
        subtitle="Here you can manage your teams and see exactly who did what."
      />
      <Box className="h-full bg-red-900">{newMoment.format("YYYY-MM-DD")}</Box>
    </>
  );
};

export default Teams;
