import React from "react";
import ExoTable from "../exo/ExoTable";
import { Box, Button, Typography } from "@mui/material";
import MoneyLabel from "../label/MoneyLabel";
import { useTranslation } from "react-i18next";
import useFetch from "../../api/useFetch";
import DateLabel from "../label/DateLabel";

export const SelectReceipt = ({ filter, onSelect, apiUrl, hasOpenAmount }) => {
  const { t } = useTranslation();

  const { apiData } = useFetch(apiUrl || `receipts?${filter}`);

  const Columns = [
    {
      header: "Nr", // header of column
      key: "receiptNr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "20px", // width of column
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Title"), // header of column
      key: "title", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Date"), // header of column
      key: "date", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "120px", // width of column
      cell: ({ row }) => (
        <Box className="monospace">
          <DateLabel date={row.receiptDate} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
      hiddenMobile: true, // hides the column on mobile
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Tax Rate"), // header of column
      key: "taxRate", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => <Box className="monospace">{row.taxRate} %</Box>, // custom xml for cells in the column. you get the row as object
      hiddenMobile: true, // hides the column on mobile
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Brutto"), // header of column
      key: "amount", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "120px", // width of column
      cell: ({ row }) => (
        <Box className="monospace">
          <MoneyLabel money={row.amount} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Open"), // header of column
      key: "openAmount", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "200px", // width of column
      hiddenMobile: !hasOpenAmount, // hides the column on mobile
      hiddenDesktop: !hasOpenAmount, // hides the column on desktop
      cell: ({ row }) => (
        <Typography className=" monospace">
          <MoneyLabel money={row.openAmount} />
        </Typography>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
  ];
  return (
    <ExoTable
      data={apiData}
      columns={Columns}
      isLoading={!apiData}
      className="h-full w-full"
    />
  );
};
