import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useFetch from "../../../api/useFetch";
import ExoTable from "../../exo/ExoTable";
import { Box, Typography } from "@mui/material";
import MoneyLabel from "../../label/MoneyLabel";
import { t } from "i18next";

export const OpenDocuments = forwardRef(
  ({ apiUrl, label, columns, Icon, openKey = "openAmount" }, ref) => {
    const { apiData, setApiData } = useFetch(apiUrl);

    // exposed functions
    useImperativeHandle(ref, () => ({ setApiData }));

    const [statistics, setStatistics] = useState(0);
    useEffect(() => {
      if (apiData && apiData.data) {
        calculateStatistics();
      }
    }, [apiData]);

    function calculateStatistics() {
      let sum = 0;

      apiData.data.forEach((element) => {
        sum += parseFloat(element[openKey]);
      });
      setStatistics({ totalOpenAmount: sum, count: apiData.data.length });
    }

    return (
      <Box className="w-full h-full flex flex-col">
        <Box className="pb-2 flex justify-between px-4">
          <Box className="flex flex-row gap-2 items-center">
            {Icon && <Icon />}
            <Box>
              <Typography variant="h6">{label}</Typography>
              <Typography variant="h4">
                <MoneyLabel money={statistics.totalOpenAmount} />
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography variant="h6">{t("Count")}</Typography>
            <Typography variant="h4" className="monospace">
              {statistics.count}
            </Typography>
          </Box>
        </Box>
        <ExoTable
          data={apiData}
          noBackground
          isLoading={Boolean(!apiData)}
          columns={columns || []}
        />
      </Box>
    );
  }
);
