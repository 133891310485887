import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import TypeContactId from "./TypeContactId";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ContactsIcon from "@mui/icons-material/Contacts";
import { useTranslation } from "react-i18next";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import LabeledBox from "../../special/LabeledBox";
import TypeCompany from "./TypeCompany";
import TypeContact from "./TypeContact";

const TypeClient = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
}) => {
  const { t } = useTranslation();
  const type = dataCollection[`${field.key}Type`];

  function handleChange(key, value) {
    updateValidationOnChange(key, value);
  }

  function handleChangeType(event, newType) {
    if (newType !== type && newType) {
      updateValidationOnChange(
        `${field.key}Type`,
        { [`${field.key}Type`]: newType, [field.key]: null },
        true
      );
    }
  }

  return (
    <Box className="flex flex-row gap-2">
      <ToggleButtonGroup value={type} exclusive onChange={handleChangeType}>
        <ToggleButton value="company" sx={{ width: "53px" }}>
          <Tooltip title={t("Company")}>
            <ApartmentIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="contact" sx={{ width: "53px" }}>
          <Tooltip title={t("Contact")}>
            <ContactsIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="internal" sx={{ width: "53px" }}>
          <Tooltip title={t("Internal")}>
            <MoneyOffIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      {type === "company" && (
        <TypeCompany
          updateValidationOnChange={handleChange}
          dataCollection={dataCollection}
          errors={errors}
          field={{ ...field, label: t("Company") }}
        />
      )}
      {type === "contact" && (
        <TypeContact
          updateValidationOnChange={handleChange}
          dataCollection={dataCollection}
          errors={errors}
          field={{ ...field, label: t("Contact") }}
        />
      )}
      {type === "internal" && (
        <LabeledBox className="w-full" label={t("Internal")} />
      )}
    </Box>
  );
};

export default TypeClient;
