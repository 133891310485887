import { Box, Link, Typography } from "@mui/material";
import MediaPreview from "../exo/explorer/MediaPreview";
import CardContainer from "./layout/CardContainer";
import NoDataAnimation from "../animation/NoDataAnimation";
import { useTranslation } from "react-i18next";

const ImagesCard = ({ images, span }) => {
  const { t } = useTranslation();
  return images.length === 0 ? (
    <CardContainer>
      <NoDataAnimation size={"100px"} />
      <Typography>{t("No Image")}</Typography>
    </CardContainer>
  ) : (
    <CardContainer
      span={span}
      className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-2 xl:grid-cols-3"
    >
      {images.map((image, index) => (
        <Link
          href={image.temporaryUrl}
          className="flex w-full justify-center items-center"
        >
          <MediaPreview
            key={index}
            src={image.thumbnailUrl}
            size="100px"
            onlyIcons={false}
          />
        </Link>
      ))}
    </CardContainer>
  );
};

export default ImagesCard;
