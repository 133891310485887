function UpdateClock(timeStart) {
  if (timeStart != "") {
    var tickDateTime;
    tickDateTime = new Date();
    tickDateTime =
      tickDateTime.getFullYear() +
      "-" +
      ("00" + (tickDateTime.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + tickDateTime.getDate()).slice(-2) +
      " " +
      ("00" + tickDateTime.getHours()).slice(-2) +
      ":" +
      ("00" + tickDateTime.getMinutes()).slice(-2) +
      ":" +
      ("00" + tickDateTime.getSeconds()).slice(-2);

    var currentMinutes = dateDiffMinutes(timeStart, tickDateTime);
    return currentMinutes;
  }
  return 0;
}

function dateDiffMinutes(datetime1, datetime2) {
  var date1 = new Date(datetime1.split(" ")[0] + "T" + datetime1.split(" ")[1]);
  var date2 = new Date(datetime2.split(" ")[0] + "T" + datetime2.split(" ")[1]);
  var unixDate1 = Math.floor(date1.getTime() / 1000);
  var unixDate2 = Math.floor(date2.getTime() / 1000);

  var unixDiff = Math.abs(unixDate2 - unixDate1);

  return Math.floor(unixDiff / 60);
}

export function renderHours(minutes, prefix = false) {
  var fix = "+";
  if (minutes < 0) {
    minutes *= -1;
    fix = "-";
  }
  var hours = Math.floor(minutes / 60);
  minutes = Math.floor(minutes % 60);

  if (prefix) {
    return fix + leadingZero(hours, 2) + ":" + leadingZero(minutes, 2);
  }
  return leadingZero(hours, 2) + ":" + leadingZero(minutes, 2);
}

export function leadingZero(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export default UpdateClock;
