import generateShades from "./ColorShades";

function calcColorTheme(baseColors, mode = "dark") {
  var baseColorsToCalc = baseColors;
  if (baseColors === "0" || !baseColors) {
    baseColorsToCalc = {
      primary: "#4b6e7a",
      grey: "#5a5d6e",
      greenAccent: "#a6bb22",
      redAccent: "#b94646",
      blueAccent: "#3273bc",
    };
  }

  var colorTheme = {};
  Object.keys(baseColorsToCalc).forEach((key) => {
    const colorValue = baseColorsToCalc[key];
    const shades = generateShades(colorValue, 2, 5);
    if (mode === "dark") {
      colorTheme[key] = {
        100: shades[8],
        200: shades[7],
        300: shades[6],
        400: shades[5],
        500: shades[4],
        600: shades[3],
        700: shades[2],
        800: shades[1],
        900: shades[0],
      };
    } else {
      colorTheme[key] = {
        900: shades[8],
        800: shades[7],
        700: shades[6],
        600: shades[5],
        500: shades[4],
        400: shades[3],
        300: shades[2],
        200: shades[1],
        100: shades[0],
      };
    }
  });

  return colorTheme;
}

export default calcColorTheme;
