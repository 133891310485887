import { useRef, useState } from "react";
import UploadWrapper from "../../../../../components/exo/explorer/UploadWrapper";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileItem } from "./FileItem";
import { WPEContainer } from "../WPEContainer";
import ExoProcessing from "../../../../../components/exo/ExoProcessing";
import { FormRenameFile } from "./FormRenameFile";
import { WPEUploadPreview } from "./UploadPreview";
import Feedback from "../../../../../components/special/Feedback";

export const WPETypeFile = ({ element, onChange, onDelete }) => {
  const { t } = useTranslation();

  const uploadRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const containerVariants = {
    visible: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  function handleUploadedFile(files) {
    const newFiles = [...element.files, ...files.data.data];
    onChange({ ...element, files: newFiles });
  }

  function handleFileChange(res, oldPath) {
    const newFiles = element.files.map((obj) =>
      obj.path === oldPath ? res.data : obj
    );
    onChange({ ...element, files: newFiles });
  }

  function handleFileDelete(item) {
    const newFiles = element.files.filter((obj) => obj.path !== item.path);
    onChange({ ...element, files: newFiles });
  }

  // upload via click
  function handleUploadClick() {
    if (uploadRef && uploadRef.current) {
      uploadRef.current.clickFileInput();
    }
  }

  // edit

  const [editFile, setEditFile] = useState(null);

  return (
    <UploadWrapper
      ref={uploadRef}
      onUpload={handleUploadedFile}
      noBackground
      UploadPreviewElement={WPEUploadPreview}
      previewProps={{ element, onAlert: setAlert }}
    >
      <Button
        onClick={handleUploadClick}
        sx={{ position: "absolute" }}
        className="top-1 right-1 z-10"
      >
        {t("Upload")}
      </Button>
      <WPEContainer
        title={element.name}
        sx={{ minHeight: "300px" }}
        className="w-full flex flex-col gap-1"
        framerVariants={containerVariants}
      >
        {element.files &&
          element.files.map((item, index) => (
            <FileItem
              key={item.temporaryUrl}
              item={item}
              index={index}
              wpe={element}
              onChange={handleFileChange}
              onDelete={handleFileDelete}
              onLoading={setIsLoading}
              onEdit={setEditFile}
              hasStatus={Boolean(element.folderStatusMap)}
            />
          ))}
        <ExoProcessing open={isLoading} label={t("Changing Status")} />
      </WPEContainer>
      <FormRenameFile
        open={Boolean(editFile)}
        startData={editFile}
        wpe={element}
        onChange={handleFileChange}
        onClose={() => setEditFile(null)}
      />
      <Feedback state={alert} setState={setAlert} />
    </UploadWrapper>
  );
};
