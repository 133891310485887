import { load } from "@finapi/web-form";
import { useCallback } from "react";

export const FinApiWebForm = ({
  url,
  token,
  onLoaded,
  onComplete,
  onFail,
  onAbort,
  onLoadError,
}) => {
  const createWebForm = useCallback(async (target) => {
    load(
      target,
      {
        token: token,
        targetUrl: url,
        layoutConfig: "xs",
        backToAppUrl: window.location.href,
        customerSupportUrl: "https://exopaper.de",
        language: "de",
      },
      {
        onLoaded,
        onComplete,
        onFail,
        onAbort,
        onLoadError,
      }
    );
  }, []);

  const containerRef = (container) => {
    if (container) {
      createWebForm(container);
    }
  };

  return <div ref={containerRef}></div>;
};
