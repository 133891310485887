import { useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import generateShades from "../../global/theme/ColorShades";

const FailureCross = ({ width = "120", height = "120" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const shades = generateShades(colors.error, 5, 3);

  const pathLength = 50;

  return (
    <svg
      width={width + "px"}
      height={height + "px"}
      className="cross__svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 133 133"
    >
      <g
        className="cross-group"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <circle
          className="filled-circle"
          fill={shades[3]}
          cx={133 / 2}
          cy={133 / 2}
          r="54.5"
        />
        <circle
          className="white-circle"
          fill={shades[6]}
          cx={133 / 2}
          cy={133 / 2}
          r="55.5"
        />
        <circle
          className="outline-circle"
          stroke={shades[3]}
          strokeWidth="4"
          cx={133 / 2}
          cy={133 / 2}
          r="54.5"
        />
        <path
          className="cross__path cross__path--right"
          fill="none"
          d={`M${(133 - pathLength) / 2},${(133 - pathLength) / 2} L${
            (133 + pathLength) / 2
          },${(133 + pathLength) / 2}`}
          stroke={shades[6]}
          strokeWidth={5}
        />
        <path
          className="cross__path cross__path--left"
          fill="none"
          d={`M${(133 + pathLength) / 2},${(133 - pathLength) / 2} L${
            (133 - pathLength) / 2
          },${(133 + pathLength) / 2}`}
          stroke={shades[6]}
          strokeWidth="5.5"
        />
      </g>
    </svg>
  );
};

export default FailureCross;
