import { Button } from "@mui/material";

export const ToolButton = ({ tool }) => {
  const Icon = tool.Icon;
  if (tool.hidden) return;
  return (
    <Button
      onClick={tool.onClick}
      variant="contained"
      color="secondary"
      className="flex items-center gap-2 backdrop-blur-lg"
    >
      <Icon />
      {tool.label}
    </Button>
  );
};
