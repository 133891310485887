import React from "react";
import ExoCategories from "../../components/exo/ExoCategories";
import { Box } from "@mui/material";

export const Files = () => {
  return (
    <Box className="flex flex-col w-full">
      <ExoCategories path={"file-types"} />
    </Box>
  );
};
