import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import BallotIcon from "@mui/icons-material/Ballot";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

export const searchResultMap = {
  companies: {
    label: "Companies",
    Icon: ApartmentOutlinedIcon,
    to: "/companies/",
  },
  contacts: { label: "Contacts", Icon: ContactsOutlinedIcon, to: "/contacts/" },

  //contracts: { label: "Contracts", Icon: GavelOutlinedIcon },
  //files: { label: "Files", Icon: FolderOutlinedIcon },
  invoices: { label: "Invoices", Icon: ReceiptOutlinedIcon, to: "/invoices/" },
  offers: { label: "Offers", Icon: DescriptionOutlinedIcon, to: "/offers/" },
  orders: { label: "Orders", Icon: BallotIcon, to: "/orders/" },
  products: {
    label: "Products",
    Icon: InventoryIcon,
    to: "/products/",
  },
  projects: {
    label: "Projects",
    Icon: AccountTreeOutlinedIcon,
    to: "/projects/management/",
  },
  services: {
    label: "Services",
    Icon: SupervisedUserCircleIcon,
    to: "/services/",
  },
  users: { label: "Employees", Icon: PeopleOutlinedIcon, to: "/employees/" },
  //receipts: { label: "Receipts", Icon: PeopleOutlinedIcon },
  //dunning_processes: { label: "Payment Reminder", Icon: PeopleOutlinedIcon },
};
