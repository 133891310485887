import { Box } from "@mui/material";
import DataModule from "../DataModules";
import NoData from "../info-displays/base-components/NoData";
import NoDataAnimation from "../../../components/animation/NoDataAnimation";
import { useTranslation } from "react-i18next";

const EmployeePersonalData = ({ employeeInfo }) => {
  const { t } = useTranslation();

  if (!employeeInfo.personal)
    return (
      <Box className="w-full h-full flex justify-center items-center">
        <NoDataAnimation />
      </Box>
    );

  const dataModules = [
    {
      type: "date",
      label: t("Birthday"),
      value: employeeInfo.personal.birthday,
    },
    {
      type: "text",
      label: t("Birthplace"),
      value: employeeInfo.personal.birthplace,
    },
    {
      type: "text",
      label: t("Nationality"),
      value: employeeInfo.personal.nationality,
    },
    {
      type: "text",
      label: t("Gender"),
      value: employeeInfo.personal.gender,
    },
    {
      type: "text",
      label: t("Family Status"),
      value: employeeInfo.personal.familyStatus,
    },
    {
      type: "text",
      label: t("Parental Property"),
      value: employeeInfo.personal.parentalProperty ? "Yes" : "No",
    },
    {
      type: "text",
      label: t("Religion"),
      value: employeeInfo.personal.religion,
    },
    {
      type: "text",
      label: t("Highest Degree"),
      value: employeeInfo.personal.highestDegree,
    },
    {
      type: "text",
      label: t("Highest Professional Education"),
      value: employeeInfo.personal.highestProfessionalEducation,
    },
    {
      type: "text",
      label: t("Severely Disabled"),
      value: employeeInfo.personal.severelyDisabled ? "Yes" : "No",
    },
  ];

  return (
    <Box className="flex flex-col gap-3 items-start">
      {dataModules.map((display, index) => (
        <DataModule
          key={index}
          type={display.type}
          label={display.label}
          value={display.value}
        />
      ))}
    </Box>
  );
};

export default EmployeePersonalData;
