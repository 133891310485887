import React, { useEffect, useRef, useState } from "react";
import EntryEditable from "../../position-modules/entries/EntryEditable";
import {
  numberToString,
  stringToNumber,
} from "../../../special/numberConverter";
import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const PositionNumber = ({
  data,
  onFocus,
  unit,
  onChange,
  label,
  defaultValue,
  decimalPlaces,
}) => {
  const { t } = useTranslation();

  const textFieldRef = useRef(null);

  const [value, setValue] = useState(
    defaultValue ? numberToString(defaultValue) : data
  );
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (data === undefined && defaultValue) {
      onChange(defaultValue);
    }
    if (!isFocused) setValue(numberToString(data, decimalPlaces));
    if (data !== stringToNumber(value + ""))
      setValue(numberToString(data, decimalPlaces));
  }, [data]);

  function handleFocus() {
    if (parseFloat(value) == 0) setValue("");
    onFocus(textFieldRef);
    setIsFocused(true);
  }
  function handleBlur(e) {
    onChange(stringToNumber(e.target.value));
    setValue(
      numberToString(
        stringToNumber(e.target.value, decimalPlaces),
        decimalPlaces
      )
    );
    setIsFocused(false);
  }

  function handleChange(e) {
    onChange(stringToNumber(e.target.value));
    setValue(e.target.value);
  }

  return (
    <Box className="flex flex-col">
      <TextField
        label={t(label)}
        value={value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
        }}
      />
    </Box>
  );
};
