import { Avatar, Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Typography from "@mui/material/Typography";
import ExoAvatar from "../exo/ExoAvatar";
import { useEffect, useState } from "react";
const CompanyRadio = ({ company, onClick = () => {}, checked, logo }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const picture = company.logo ? company.logo.thumbnailUrl : logo || "";
  const name = company.name;

  const [selected, setSelected] = useState(checked);

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  function handleClick() {
    onClick(company);
  }

  return (
    <Box
      onClick={handleClick}
      className="flex flex-row items-center gap-4 px-1 py-1 rounded-lg peer-checked:border-2 cursor-pointer"
      sx={{
        backgroundColor: selected ? colors.selected : colors.bgInput,
      }}
    >
      <ExoAvatar
        icon={company.icon}
        picture={picture}
        size="64px"
        type="company"
        name={name}
      />
      <Typography variant="h5">{name}</Typography>
    </Box>
  );
};

export default CompanyRadio;
