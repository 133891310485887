import { Box, Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { tokens } from "../../../../global/theme/tokens";
import ExoExplorer from "../../ExoExplorer";
import ExoDialog from "../../ExoDialog";
import LabeledBox from "../../../special/LabeledBox";
import { useTranslation } from "react-i18next";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export const PositionImage = ({
  data,
  onChange,
  label,
  imageType,
  imageApiFilter,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  function handleSelect(image) {
    setOpen(false);
    onChange(image);
  }

  return (
    <>
      <Box className="flex w-full gap-2 items-start">
        <Button
          className=" rounded-md cursor-pointer "
          onClick={data ? () => onChange(null) : () => setOpen(true)}
          sx={{
            backgroundColor: colors.bgInput,
            padding: 0,
            width: "56px",
            height: "56px",
            minWidth: "56px",
          }}
        >
          {data ? (
            <Box className="w-full h-full relative rounded-md">
              <Box
                className="absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity rounded-md"
                sx={{
                  backgroundColor: `${colors.error}50`,
                  borderColor: colors.error,
                  borderWidth: "1px",
                }}
              >
                <RemoveCircleIcon sx={{ fontSize: "25px", opacity: 0.6 }} />
              </Box>
              <img
                src={data.temporaryUrl}
                className="object-cover rounded-md w-full h-full"
              />
            </Box>
          ) : (
            <Box className="flex w-full h-full items-center justify-center">
              <AddPhotoAlternateIcon sx={{ fontSize: "40px", opacity: 0.2 }} />
            </Box>
          )}
        </Button>
        <Button sx={{ padding: 0 }} className="w-full">
          <LabeledBox
            onClick={() => setOpen(true)}
            className="w-full "
            label={t(label)}
          ></LabeledBox>
        </Button>
      </Box>

      <ExoDialog open={open} onClose={() => setOpen(false)}>
        <ExoExplorer
          preSelected={data ? [data] : []}
          mediaType={imageType}
          filter={imageApiFilter}
          onSelect={handleSelect}
        />
      </ExoDialog>
    </>
  );
};
