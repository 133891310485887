import { Box, Typography } from "@mui/material";

export const CardContentText = ({ title, text }) => {
  return (
    <Box className="flex flex-row gap-2 h-6 items-center">
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h5" className=" monospace">
        {text}
      </Typography>
    </Box>
  );
};
