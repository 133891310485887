import { Skeleton } from "@mui/material";
import CardContainer from "./layout/CardContainer";

const SkeletonCard = ({
  className = "",
  span = 1,
  rows = 1,
  height = 150,
  sx,
}) => {
  return (
    <CardContainer
      sx={{ padding: "0", backgroundColor: "transparent" }}
      span={span}
      rows={rows}
    >
      <Skeleton
        className={className}
        sx={{
          width: "100%",
          transform: "scale(1)",
          height: `${height}px`,
          borderRadius: "0.5rem",
          ...sx,
        }}
      />
    </CardContainer>
  );
};
export default SkeletonCard;
