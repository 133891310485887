import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../global/theme/tokens";
import { Box, Dialog, IconButton, Typography, Button } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import FormHelperText from "../FormHelperText";
import MoneyLabel from "../../label/MoneyLabel";
import LabeledBox from "../../special/LabeledBox";
import { useTranslation } from "react-i18next";
import { SelectReceipt } from "../../select/SelectReceipt";

const TypeReceipt = ({
  filter = "assignment[doesnothave][]=transactions",
  updateValidationOnChange = () => {},
  dataCollection,
  errors,
  field,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  //popup
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelected(dataCollection[field.key]);
  }, [dataCollection]);

  // actions
  const [selected, setSelected] = useState(dataCollection[field.key]);
  function handleSelect(receipt) {
    setOpen(false);
    setSelected(receipt);
    updateValidationOnChange(field.key, receipt);
  }

  return (
    <Box>
      {!selected ? (
        <Box
          onClick={() => setOpen(true)}
          className="flex flex-row pr-4 pl-1 cursor-pointer justify-between items-center"
          sx={{
            backgroundColor: colors.bgInput,
            borderRadius: "10px",
            borderWidth: "0px",
            height: "53px",
            borderColor: colors.primary[300],
            ":hover": {
              borderColor: colors.grey[900],
            },
          }}
        >
          <Typography variant="p" className="px-2">
            {field.label}
          </Typography>
        </Box>
      ) : (
        <LabeledBox
          label={field.label}
          className="flex flex-row pr-4 pl-1 justify-between items-center"
          sx={{
            backgroundColor: colors.bgInput,
            borderRadius: "10px",
            borderWidth: "0px",
            height: "53px",
            borderColor: colors.primary[300],
            ":hover": {
              borderColor: colors.grey[900],
            },
          }}
        >
          <Box className="flex flex-row justify-start items-center gap-1 w-full">
            <IconButton onClick={() => setSelected(null)}>
              <RemoveCircleOutlineIcon />
            </IconButton>
            <Box className="w-full flex flex-row justify-between items-center gap-3">
              <Typography variant="p">{selected.title}</Typography>
              <Typography variant="p">
                <b>
                  <MoneyLabel money={selected.amount} />
                </b>
              </Typography>
            </Box>
          </Box>
        </LabeledBox>
      )}
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box
          className="flex flex-col gap-2"
          sx={{ width: "80vw", height: "90vh" }}
        >
          <SelectReceipt onSelect={handleSelect} filter={filter} />
        </Box>
      </Dialog>
    </Box>
  );
};

export default TypeReceipt;
