import { Snackbar, Alert, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";

const Feedback = ({ state, setState }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const handleClose = () => {
    setState({ ...state, alertOpen: false });
  };

  const { alertOpen, alertType, alertText } = state || {};

  return (
    <Snackbar
      sx={{
        zIndex: "9999",
        ".MuiPaper-root": {
          bgcolor: colors[alertType] + "30",
          borderColor: colors[alertType],
          borderWidth: "1px",
          display: "flex",
          flexDirection: "row",
          backdropFilter: "blur(10px)",
        },
      }}
      autoHideDuration={3000}
      open={alertOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      className="flex gap-2 items-center"
    >
      <Alert onClose={handleClose} severity={alertType} sx={{ width: "100%" }}>
        {alertText}
      </Alert>
    </Snackbar>
  );
};

export default Feedback;
