import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import "react-circular-progressbar/dist/styles.css";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import Skeleton from "@mui/material/Skeleton";
import ProjectTimeCard from "./ProjectTimeCard";
import TimeDashboard from "./TimeDashboard";
import { useTranslation } from "react-i18next";
import { TimeAnimation } from "../../components/animation/TimeAnimation";
import { useTimeTracking } from "../../components/time-tracking/AppTimeProvider";
import { ProjectList } from "./project-list/ProjectList";

const TimeTracking = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  // GET DATA -------------------------------------------
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    // get projects
    DBRequest({
      config,
      path: "projects/own?status[eq]=0",
      method: API_GET,
      onResponse: setProjects,
    });

    // get time
    DBRequest({
      config,
      path: "user/timetracking",
      method: API_GET,
      onResponse: handleTimeTrackingUserResponse,
    });
  }, [config]);

  // active project --------------------------------------------
  const [projects, setProjects] = useState(null);
  const { activeProject, clockOut, clockIn, secondsIn } = useTimeTracking();

  // time -----------------------------------------------
  const [timeTrackingUser, setTimeTrackingUser] = useState();

  // prepare db time response data
  function handleTimeTrackingUserResponse(response, state) {
    var newData = {};

    // projects today
    newData.projectsToday = response.data.projectsToday;

    //time in Project
    newData.timeInProject = 0;

    // saving data
    setTimeTrackingUser({ ...newData });
  }

  useEffect(() => {
    if (activeProject && timeTrackingUser) updateTimeTrackingUser(secondsIn);
  }, [secondsIn]);

  function updateTimeTrackingUser(updateInSec) {
    const updateTick = Math.round(updateInSec / 60);

    // calc projectIndex for projectsToday
    const projectIndex = timeTrackingUser.projectsToday.findIndex(
      (project) => project.id === activeProject.id
    );

    // update projects today
    var newProjectsToday = [...timeTrackingUser.projectsToday];

    const tick = Number((updateTick / 60).toFixed(2));

    if (projectIndex != -1) {
      newProjectsToday[projectIndex].y = tick;
    } else {
      newProjectsToday.push({
        x: activeProject.name,
        y: tick,
        id: activeProject.id,
      });
    }

    // updateTimeTrackingUser
    setTimeTrackingUser({
      ...timeTrackingUser,
      projectsToday: newProjectsToday,
    });
  }

  return (
    <>
      <Header title={t("Timetracking")} />
      <Box className="flex flex-col h-full overflow-y-auto md:overflow-hidden">
        <TimeDashboard
          timeTrackingUser={timeTrackingUser}
          isLoading={Boolean(!timeTrackingUser)}
        />
        <Box className="h-full w-full flex flex-col relative overflow-hidden">
          {!timeTrackingUser || !projects ? (
            <Box className="flex justify-around sm:justify-start flex-wrap gap-2 h-full">
              {[...Array(10)].map((e, i) => (
                <Skeleton
                  key={i}
                  variant="rounded"
                  width={240}
                  height={373}
                  sx={{ minWidth: "240px!important" }}
                />
              ))}
            </Box>
          ) : (
            <>
              <ProjectList projects={projects.data} />
              {activeProject && timeTrackingUser && (
                <Box
                  className="transition-opacity flex items-start justify-center rounded-xl absolute top-0 left-0 w-full h-full backdrop-blur-md z-10 "
                  sx={{
                    minHeight: "500px",
                    backgroundColor: colors.card,
                  }}
                >
                  <Box
                    className="flex justify-center items-center"
                    sx={{ minHeight: "500px" }}
                  >
                    <TimeAnimation className="absolute z-100" size="400px" />
                    <ProjectTimeCard
                      key={activeProject.id}
                      project={activeProject}
                      timeInProject={timeTrackingUser.timeInProject}
                      selected={true}
                      onDeselect={clockOut}
                      onSelect={clockIn}
                      backgroundColor={colors.primary[300]}
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TimeTracking;
