import {
  useTheme,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import Transaction, {
  TransactionsSkeleton,
} from "../../components/transactions/Transaction";
import Loader from "../../components/special/Loader";
import ExoAPIFilter from "../../components/exo/ExoAPIFilter";

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import DateLabel from "../../components/label/DateLabel";
import ExoInfiniteScroll from "../../components/exo/ExoInfiniteScroll";
import { useTranslation } from "react-i18next";

import MoneyLabel from "../../components/label/MoneyLabel";
import { NewTransaction } from "./NewTransaction";

const Transactions = ({ bankAccount, scrollContainerRef }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const entrysPerPage = 50;

  const config = useConfig();
  const [transactions, setTransactions] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  var dateLog = "";

  const [edit, setEdit] = useState(null);

  // filter

  const [currentFilter, setCurrentFilter] = useState({
    string: "orderBy=desc&sortBy=bookingDate&",
  });

  useEffect(() => {
    fetchTransactions();
  }, [bankAccount, currentFilter, config]);

  function fetchTransactions() {
    DBRequest({
      config,
      path: `bankaccount/${bankAccount.id}/transactions?${
        currentFilter.string || ""
      }perPage=${entrysPerPage}`,
      method: API_GET,
      onResponse: setTransactions,
      onLoading: setIsLoading,
    });
  }

  function handleTransactionChange(newTransaction) {
    const updatedData = transactions.data.map((obj) => {
      if (obj.id === newTransaction.data.id) {
        return newTransaction.data; // Replace the object with the updated object
      }
      return obj; // Keep the original object
    });
    setTransactions({ ...transactions, data: updatedData });
  }

  const filters = {
    filter: {
      label: t("Filter"),
      items: [
        {
          label: t("Not fully accounted"),
          value: "openAmount[ne]=0",
          active: false,
        },
      ],
    },
    order: {
      label: t("Order by"),
      items: [
        {
          label: t("By Booking Date"),
          value: "bookingDate",
          active: true,
        },
        {
          label: t("By Amount"),
          value: "amount",
          active: false,
        },
        {
          label: t("By IBAN"),
          value: "iban",
          active: false,
        },
      ],
    },
  };

  function handleDelete(transaction) {
    const newTransactions = transactions.data.filter(
      (obj) => obj.id !== transaction.id
    );

    setTransactions({ ...transactions, data: newTransactions });
  }

  return (
    <>
      <Box
        className="rounded-xl m-auto backdrop-blur-lg"
        sx={{
          maxWidth: "900px",
          backgroundColor: colors.glass,
        }}
      >
        <Loader active={isLoadingMore} message="Loading more..." />
        {isLoading ? (
          <TransactionsSkeleton />
        ) : (
          <>
            <Box
              className=" flex flex-row justify-between p-3 sticky top-0 rounded-t-lg mb-4 z-30 backdrop-blur-lg"
              sx={{
                borderBottom: "2px solid " + colors.primary[400],
                boxShadow: "0 10px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <ExoAPIFilter
                filters={filters}
                onChange={setCurrentFilter}
                currentFilters={currentFilter}
              />

              <Box className="flex flex-row gap-1 items-center">
                <Typography
                  className="pl-5"
                  variant="h5"
                  fontWeight={600}
                  sx={
                    bankAccount.balance > 0
                      ? { color: colors.success }
                      : { color: colors.error }
                  }
                >
                  <MoneyLabel money={bankAccount.balance} />
                </Typography>
              </Box>
            </Box>
            <ExoInfiniteScroll
              scrollContainerRef={scrollContainerRef}
              apiData={transactions}
              setApiData={setTransactions}
              className="flex flex-col gap-1 px-2 pb-2"
            >
              {!bankAccount.connected && (
                <NewTransaction
                  startData={edit}
                  bankAccount={bankAccount}
                  onCreate={(data) => {
                    setTransactions(data);
                    setEdit(null);
                  }}
                  onClose={() => setEdit(null)}
                />
              )}
              {transactions.data.map((element, index) => {
                const elementDate = element.bookingDate;
                if (elementDate !== dateLog) {
                  dateLog = elementDate;
                  return (
                    <Fragment key={index}>
                      <Box className="pt-2 pl-1">
                        <Typography variant="p">
                          <DateLabel date={elementDate} />
                        </Typography>
                      </Box>
                      <Transaction
                        onChange={handleTransactionChange}
                        key={index}
                        data={element}
                        onEdit={setEdit}
                        onDelete={handleDelete}
                        isConnected={bankAccount.connected}
                      />
                    </Fragment>
                  );
                } else {
                  return (
                    <Transaction
                      onChange={handleTransactionChange}
                      key={index}
                      data={element}
                      onEdit={setEdit}
                      onDelete={handleDelete}
                      isConnected={bankAccount.connected}
                    />
                  );
                }
              })}
            </ExoInfiniteScroll>
          </>
        )}
      </Box>
    </>
  );
};

export default Transactions;
