import { Box, useTheme, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { tokens } from "../../../../../global/theme/tokens";
import MediaPreview from "../../../../../components/exo/explorer/MediaPreview";
import { FileStatus } from "../../../../../components/special/Status";
import {
  API_DELETE,
  API_PATCH,
  DBRequest,
  useConfig,
} from "../../../../../api/api";
import FolderIcon from "@mui/icons-material/Folder";
import { SettingsPopover } from "../../../../../components/exo/project/SettingsPopover";
import { t } from "i18next";

export const FileItem = ({
  item,
  index,
  wpe,
  onChange,
  onDelete,
  onLoading,
  onEdit,
  hasStatus,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();

  const itemVariant = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  function handleStatusChange(newStatus) {
    DBRequest({
      config,
      path: `work-packages/elements/file/${wpe.id}/file`,
      method: API_PATCH({ path: item.path, newStatus }),
      onResponse: (res) => onChange(res, item.path),
      onLoading: onLoading,
    });
  }

  function handleDeleteChange() {
    DBRequest({
      config,
      path: `work-packages/elements/file/${wpe.id}/file/${item.path}`,
      method: API_DELETE,
      onResponse: (res) => onDelete(item),
    });
  }

  const settings = [
    {
      label: t("Rename"),
      onClick: () => onEdit(item),
      color: "info",
    },
    {
      label: t("Delete"),
      onClick: handleDeleteChange,
      color: "error",
    },
  ];

  return (
    <motion.div variants={itemVariant}>
      <Box
        className="rounded-lg px-3 py-1 w-full flex gap-4 h-32 relative"
        sx={{
          backgroundColor: colors.bgInput,
          border: "1px solid transparent",
          ":hover": {
            backgroundColor: colors.info + "30",
            borderColor: colors.info,
          },
        }}
      >
        <SettingsPopover
          settings={settings}
          className=" absolute top-1 right-1"
        />
        <MediaPreview
          allowPreview
          src={item.temporaryUrl}
          size="118px"
          mimeType={item.mimeType}
        />
        <Box className="flex flex-col items-start h-full justify-between w-full">
          <Box className="flex flex-col items-start gap-2">
            <Typography>{item.label}</Typography>
            {hasStatus && (
              <FileStatus
                onChange={handleStatusChange}
                folderStatusMap={wpe.folderStatusMap}
                status={item.status}
              />
            )}
          </Box>
          <Box className="w-full flex justify-end gap-2 items-center">
            <FolderIcon className=" opacity-30" />
            <Typography variant="h6">{item.directory}</Typography>
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
};
