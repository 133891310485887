import { useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import TypeCheckbox from "../../../form/form-fields/TypeCheckbox";

export const PositionCheckbox = ({ data, onChange, label }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  function handleChange(e, value) {
    onChange(Boolean(parseInt(value)));
  }
  const field = {
    label: t(label),
    key: "checkbox",
  };
  return (
    <TypeCheckbox
      field={field}
      updateValidationOnChange={handleChange}
      dataCollection={{ checkbox: data ? "1" : "0" }}
      errors={{}}
    />
  );
};
