export const cardBreakPoints = [
  {
    size: 0,
    cols: 1,
    rows: 1,
  },
  {
    size: 768,
    cols: 2,
    rows: 2,
  },
  {
    size: 1024,
    cols: 3,
    rows: 3,
  },
  {
    size: 1280,
    cols: 4,
    rows: 4,
  },
  {
    size: 1536,
    cols: 5,
    rows: 5,
  },
];
