import { Box, useTheme } from "@mui/material";
import React from "react";
import {
  CircleMarker,
  LayerGroup,
  MapContainer,
  TileLayer,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { tokens } from "../../global/theme/tokens";

const ExoMap = ({ startCenter, positions = [], zoom = 15, className }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  // center of map
  const center = startCenter || [0, 0];
  const mapEndpoints = {
    dark: "http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
    light: "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
  };

  const filter = {
    dark: "brightness(3)",
    light: "none",
  };

  return (
    <Box
      className={
        "flex absolute top-0 left-0 w-full h-full overflow-hidden " + className
      }
      sx={{
        "& .leaflet-control-attribution.leaflet-control": {
          display: "none",
        },
        "& .leaflet-control-container": {
          display: "none",
        },
        "& .leaflet-tile-container>img": {
          filter: filter[theme.palette.mode],
        },
      }}
    >
      <MapContainer
        doubleClickZoom={false}
        center={center}
        zoom={zoom}
        maxZoom={18}
        style={{ height: "100%", width: "100%", zIndex: "0" }}
      >
        <TileLayer
          url={
            mapEndpoints[theme.palette.mode] ||
            "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          }
        />
        <LayerGroup>
          {positions.map((pos, index) => (
            <Location position={pos.position} type={pos.type || " default"} />
          ))}
        </LayerGroup>
      </MapContainer>
    </Box>
  );
};

const Location = ({ position, type }) => {
  return <CircleMarker center={position} />;
};

export default ExoMap;
