import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import TimeHolder from "./TimeHolder";
import { renderHours } from "../../../../scenes/time-tracking/worktime";
import EditIcon from "@mui/icons-material/Edit";
import { useMethodColor } from "../time-functions/useMethodColors";

const TimeSelectionBox = ({
  dateToPixel,
  selection,
  marginLeft,
  onMouseDown,
  onTouchStart,
  mouseIsDown,
  onCancle,
  onSubmit,
  onDelete,
  onUpdate,
  onEdit,
  selectionRef,
  snapSelectionToGrid,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  // color

  const startMoment = moment(selection.timeStart);
  const endMoment = moment(selection.timeEnd);
  const timeInMinutes = endMoment.diff(startMoment, "minutes");

  const top = dateToPixel(selection.timeStart);
  const height = Math.abs(dateToPixel(selection.timeEnd) - top);
  const timeStart = startMoment.hours() * 60 + startMoment.minutes();

  const padding = 10;

  function isValidId(str) {
    // Use parseInt to attempt to convert the string to an integer
    const number = parseInt(str, 10);

    // Check if the result is a valid integer and not NaN
    return !isNaN(number) && Number.isInteger(number);
  }
  const method =
    isValidId(selection.id) && selection.method !== "add" ? "update" : "add";

  const colorToUse = useMethodColor("edit");

  useEffect(() => {
    snapSelectionToGrid();
  }, []);

  return (
    <Box
      className="absolute flex px-2 w-full "
      style={{
        top: `${top}px`,
        height: `${height}px`,
        width: "100%",
        zIndex: 1,
        paddingLeft: `${marginLeft + padding}px`,
        paddingRight: `${padding}px`,
      }}
    >
      <Box
        ref={selectionRef}
        className="relative h-full w-full rounded-lg flex justify-center items-center flex-col backdrop-blur-sm"
        sx={{
          bgcolor: colorToUse.primary,
          borderColor: colorToUse.secondary,
          borderWidth: `1px`,
        }}
      >
        {selection.id && (
          <Box className="flex gap-2 absolute left-0 -top-10">
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </Box>
        )}
        <TimeHolder
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          type="upper"
          timeInMinutes={timeStart}
          sx={{
            bgcolor: `${colors.glass}`,
          }}
          className=" backdrop-blur-sm left-1/2 -translate-x-1/2 absolute top-0 -translate-y-1/2 cursor-grab rounded-full px-2 py-1"
        />

        <Box
          className="px-3 py-2 rounded-lg cursor-move z-10 backdrop-blur-sm"
          sx={{
            bgcolor: colors.glass,
            opacity: height >= 80 ? 1 : 0,
            transition: "opacity 0.3s",
          }}
          onMouseDown={(e) => {
            onMouseDown(e, "move");
          }}
          onTouchStart={(e) => {
            onTouchStart(e, "move");
          }}
        >
          <Typography variant="h3" textAlign="center" className="monospace">
            {renderHours(timeInMinutes)} h
          </Typography>
        </Box>

        <Box className="w-full absolute overflow-hidden top-0 left-0 flex flex-row justify-between items-end h-12 z-0">
          <Button
            className="bottom-2 left-2"
            color="error"
            variant="outlined"
            onClick={onCancle}
            sx={{
              transform: !mouseIsDown
                ? `translateX(0px)`
                : `translateX(-200px)`,
              transition: "0.4s",
            }}
          >
            {t("Cancle")}
          </Button>
          <Button
            className="bottom-2 right-2"
            variant="contained"
            color="success"
            onClick={
              selection.id
                ? () =>
                    onUpdate({
                      ...selection,
                      method,
                    })
                : onSubmit
            }
            sx={{
              transform: !mouseIsDown ? `translateX(0px)` : `translateX(200px)`,
              transition: "0.4s",
            }}
          >
            {selection.id ? t("Update") : t("Create")}
          </Button>
        </Box>
        <TimeHolder
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          type="lower"
          timeInMinutes={timeStart + timeInMinutes}
          sx={{
            bgcolor: `${colors.glass}`,
          }}
          className="backdrop-blur-sm left-1/2 -translate-x-1/2 absolute bottom-0 translate-y-1/2  cursor-grab rounded-full px-2 py-1"
        />
      </Box>
    </Box>
  );
};

export default TimeSelectionBox;
