import { useEffect, useState } from "react";
import { renderHours } from "../../../scenes/time-tracking/worktime";
import { InputAdornment, TextField } from "@mui/material";
import { numberToString, stringToNumber } from "../../special/numberConverter";

export const TypeHours = ({
  field,
  dataCollection,
  errors,
  updateValidationOnChange,
  startDataCollection,
}) => {
  const [value, setValue] = useState(renderHours(0));
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!startDataCollection || !startDataCollection[field.key]) return;
    setValue(renderHours(startDataCollection[field.key] / 60));
  }, [startDataCollection]);

  function handleFocus() {
    setFocused(true);
    if (!dataCollection[field.key] || dataCollection[field.key] === 0) {
      setValue("");
    } else {
      setValue(numberToString((dataCollection[field.key] || 0) / 60 / 60));
    }
  }

  function handleBlur(value) {
    setFocused(false);
    if (value.target.value === "" || stringToNumber(value.target.value) === 0)
      return setValue(renderHours(0));
    if (stringToNumber(value.target.value) !== 0) {
      updateValidationOnChange(
        [field.key],
        stringToNumber(value.target.value) * 60 * 60
      );
      setValue(renderHours(stringToNumber(value.target.value) * 60));
    }
  }

  function handleTextChange(value) {
    if (stringToNumber(value.target.value) !== 0 && value.target.value !== "") {
      updateValidationOnChange(
        [field.key],
        stringToNumber(value.target.value) * 60 * 60
      );
      setValue(value.target.value);
    } else {
      updateValidationOnChange([field.key], 0);
      setValue("");
    }
  }

  return (
    <TextField
      label={field.label}
      value={value}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleTextChange}
      error={Boolean(errors[field.key])}
      helperText={errors[field.key]}
      InputProps={{
        endAdornment: <InputAdornment position="end">h</InputAdornment>,
      }}
    />
  );
};
