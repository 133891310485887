import { useEffect, useState, Fragment } from "react";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../global/theme/tokens";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Pagination from "../../special/Pagination";
import Loader from "../../special/Loader";
import FormHelperText from "../FormHelperText";
import NoDataAnimation from "../../animation/NoDataAnimation";
import { useTranslation } from "react-i18next";
import DateLabel from "../../label/DateLabel";
import ExoDialog from "../../exo/ExoDialog";
import TypeSkeleton from "./TypeSkeleton";
import Transaction from "../../transactions/Transaction";
import MoneyLabel from "../../label/MoneyLabel";

const TypeTransactionArray = ({
  filter,
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  //popup
  const [open, setOpen] = useState(false);

  // actions
  const [selection, setSelection] = useState(null);

  useEffect(() => {
    if (!selection) setSelection(dataCollection[field.key]);
  }, [dataCollection[field.key]]);

  function handleSelect(transaction) {
    setOpen(false);

    if (selection) {
      const hasTransaction = Boolean(
        selection.find((obj) => obj.id === transaction.id)
      );
      if (hasTransaction) {
        // remove transaction
        handleDeselect(transaction);
      } else {
        // add transaction
        updateDataCollection([...selection, transaction]);
      }
    } else {
      // create array with first transaction
      updateDataCollection([transaction]);
    }
  }

  function handleDeselect(transaction) {
    const newSelection = selection.filter((obj) => obj.id != transaction.id);
    updateDataCollection(newSelection);
  }

  function updateDataCollection(newSelection) {
    setSelection(newSelection);
    updateValidationOnChange(field.key, newSelection);
  }

  useEffect(() => {
    if (!dataCollection[field.key]) setSelection(null);
  }, [dataCollection[field.key]]);

  return (
    <Box
      sx={{
        gridColumn: field.colspan
          ? `span ${field.colspan} / span ${field.colspan}`
          : "unset",
      }}
      className="flex flex-col gap-1"
    >
      {selection &&
        selection.map((transaction) => (
          <TransactionPreview
            key={transaction.id}
            transaction={transaction}
            onDeselect={handleDeselect}
          />
        ))}
      {
        <Box
          onClick={() => setOpen(true)}
          className="flex flex-row pr-4 pl-1 cursor-pointer justify-between items-center"
          sx={{
            borderRadius: "10px",
            height: "56px",
            backgroundColor: colors.bgInput,
          }}
        >
          <Typography variant="p" className="px-2">
            {field.label}
          </Typography>
        </Box>
      }
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
      <ExoDialog open={open} onClose={() => setOpen(false)}>
        <TransactionList
          onSelect={handleSelect}
          filter={filter}
          open={open}
          selection={selection}
        />
      </ExoDialog>
    </Box>
  );
};

const TransactionPreview = ({ transaction, onDeselect }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  return (
    <Box
      className="flex flex-row pr-4 pl-1 justify-between items-center py-1"
      sx={{
        borderRadius: "10px",
        borderWidth: "0px",
        minHeight: "53px",
        backgroundColor: colors.bgInput,
        borderColor: colors.primary[300],
        ":hover": {
          borderColor: colors.grey[900],
        },
      }}
    >
      <Box className="flex flex-row justify-start items-center gap-1">
        <IconButton onClick={() => onDeselect(transaction)}>
          <RemoveCircleOutlineIcon />
        </IconButton>
        <Box className="flex flex-col justify-center items-start">
          <Typography variant="p" className="flex gap-4">
            <DateLabel date={transaction.bookingDate} />
            <b>{transaction.transactionPartnerName}</b>
          </Typography>
          <Typography variant="p" sx={{ color: colors.grey[600] }}>
            {transaction.intendedUse}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography
          variant="p"
          className="min-w-fit block"
          textAlign="right"
          sx={
            parseFloat(transaction.openAmount) +
              parseFloat(transaction.assignedAmount) >=
            0
              ? { color: colors.success }
              : { color: colors.error }
          }
        >
          {transaction.openAmount !== transaction.amount &&
            t("available") + " "}
          <b>
            <MoneyLabel
              money={
                parseFloat(transaction.openAmount) +
                parseFloat(transaction.assignedAmount)
              }
            />
          </b>
        </Typography>
        {transaction.openAmount !== transaction.amount && (
          <Typography variant="h6" textAlign="right">
            {t("of") + " "}
            <MoneyLabel money={transaction.amount} />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const TransactionList = ({ onSelect, filter, open, selection }) => {
  const config = useConfig();
  const { t } = useTranslation();

  // transactions
  const [transactions, setTransactions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  var dateLog = "";
  var newDate = true;

  useEffect(() => {
    if (!open || !config) return;
    DBRequest({
      config,
      path: `transactions?sortOrder=desc&sortBy=bookingDate&perPage=100${
        filter ||
        `&openAmount[ne]=0&bookingDate[gt]=${config.accounting.accountingStartDate}`
      }`,
      method: API_GET,
      onResponse: setTransactions,
      onLoading: setIsLoading,
    });
  }, [open, config]);

  function checkIfSelected(transaction) {
    if (!selection) return false;
    return Boolean(selection.find((obj) => obj.id === transaction.id));
  }

  return (
    <Box className="flex flex-col gap-2 h-full overflow-y-auto">
      {isLoading ? (
        <TypeSkeleton n={30} />
      ) : (
        <>
          {transactions.data.map((element, index) => {
            const elementDate = element.bookingDate;
            if (elementDate !== dateLog) {
              dateLog = elementDate;
              newDate = true;
            } else {
              newDate = false;
            }
            return (
              <Fragment key={index}>
                {elementDate === dateLog && newDate && (
                  <Box className="pt-2 px-2">
                    <Typography variant="p">
                      <DateLabel date={elementDate} />
                    </Typography>
                  </Box>
                )}

                <Box className="px-2">
                  <Transaction
                    key={index}
                    data={element}
                    onClick={onSelect}
                    selected={checkIfSelected(element)}
                  />
                </Box>
              </Fragment>
            );
          })}
          {transactions.data.length < 1 && (
            <Box className="h-full flex justify-center items-center">
              <NoDataAnimation />
            </Box>
          )}
        </>
      )}

      <Pagination
        filter={{
          order: false,
          name: "bookingDate",
          filter:
            filter ||
            `openAmount[ne]=0&bookingDate[gt]=${
              config && config.accounting.accountingStartDate
            }`,
        }}
        data={transactions}
        setData={setTransactions}
        setLoading={setIsLoading}
      />
    </Box>
  );
};

export default TypeTransactionArray;
