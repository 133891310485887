import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export const WPEPreview = ({ element, editmode, Icon, onDelete }) => {
  return (
    <Box className="flex items-center justify-between">
      <Box className="flex items-center gap-2">
        <Icon />
        <Typography>{element.name}</Typography>
      </Box>
      {editmode && (
        <IconButton onClick={() => onDelete(element)}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};
