import SelectInvoice from "../../select/SelectInvoice";

export const NewInvoiceRelation = ({ onSubmit, formRef, onClose }) => {
  function handleSubmit(invoice) {
    onSubmit({ invoice, type: "invoice" });
  }

  return (
    <SelectInvoice
      hasOpenAmount
      apiUrl={"invoices/open"}
      onSelect={handleSubmit}
    />
  );
};
