import { Box, Tooltip } from "@mui/material";
import BallotIcon from "@mui/icons-material/Ballot";
import { useTranslation } from "react-i18next";

const EntryConst = ({ unit, value, className, item }) => {
  const { t } = useTranslation();

  return (
    <Box className={"flex items-center " + className} sx={{ height: "26px" }}>
      {item && item.isOrderPosition && (
        <Tooltip title={t("Position from order")}>
          <BallotIcon className="mr-2" />
        </Tooltip>
      )}
      {value} {unit}
    </Box>
  );
};

export default EntryConst;
