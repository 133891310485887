import { useTheme } from "@emotion/react";
import { tokens } from "../../../global/theme/tokens";
import * as Yup from "yup";
import { Box } from "@mui/material";
import ExoForm from "../../exo/ExoForm";
import { useEffect, useState } from "react";

const VarEditor = ({ onCancle, onCreate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const [fields, setFields] = useState([]);

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required("Name is required"),
    defaultValue: Yup.string().required("Default Value is required"),
    type: Yup.string().required("Type is required"),
  });
  useEffect(() => {
    handleChange({});
  }, []);

  function handleChange(data) {
    const defaultFields = [
      {
        label: "Name",
        type: "text",
        key: "displayName",
      },
      {
        label: "Type",
        type: "presetVarType",
        key: "type",
      },
      {
        label: "Default Value",
        type: data.type || "text",
        key: "defaultValue",
      },
    ];
    setFields(defaultFields);
  }

  return (
    <Box
      className="p-2 rounded-lg"
      sx={{
        backgroundColor: colors.primary[200],
      }}
    >
      <ExoForm
        fields={fields}
        onSubmit={onCreate}
        onCancle={onCancle}
        onChange={handleChange}
        validationSchema={validationSchema}
        submitText="Create"
      />
    </Box>
  );
};

export default VarEditor;
