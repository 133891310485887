import ExoTabs from "../exo/ExoTabs";
import { useTranslation } from "react-i18next";
import { OpenInvoices } from "./open-documents/OpenInvoices";
import { OpenReceipts } from "./open-documents/OpenReceipts";
import { OpenPayrolls } from "./open-documents/OpenPayrolls";
import ModuleLayout from "./ModuleLayout";

export const PendingDocuments = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("Invoices"),
      content: <OpenInvoices />,
    },
    {
      label: t("Receipts"),
      content: <OpenReceipts />,
    },
    {
      label: t("Payrolls"),
      content: <OpenPayrolls />,
    },
  ];

  return (
    <ModuleLayout colSpan={3} rowSpan={1}>
      <ExoTabs tabs={tabs} noBackground />
    </ModuleLayout>
  );
};
