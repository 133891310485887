import ExoStepper, { FinalPage } from "../../../components/exo/ExoStepper";
import { useTranslation } from "react-i18next";
import { API_POST, DBRequest, useConfig } from "../../../api/api";
import StepOrder from "../../../components/steps/StepOrder";
import SelectPositions from "../../../components/select/SelectPositions";
import StepInvoice from "../../../components/steps/StepInvoice";

const NewProjectPositionsRelation = ({
  project,
  onClose,
  reloadApiData,
  type = "order",
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const orderSteps = [
    {
      label: t("Order"),
      valid: false,
      content: ({
        updateDataCollection,
        activeStep,
        setActiveStep,
        step,
        dataCollection,
        updateValidation,
      }) => (
        <StepOrder
          totalAmount
          apiUrl={"orders/has-no-project-in-position"}
          onClose={onClose}
          dataCollection={dataCollection}
          updateDataCollection={updateDataCollection}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          step={step}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t("Positions"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepPositions
          allowAll
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          type={type}
        />
      ),
    },
  ];

  const invoiceSteps = [
    {
      label: t("Invoice"),
      valid: false,
      content: ({
        updateDataCollection,
        activeStep,
        setActiveStep,
        step,
        dataCollection,
        updateValidation,
      }) => (
        <StepInvoice
          apiUrl={"invoices/has-no-project-in-position"}
          onClose={onClose}
          dataCollection={dataCollection}
          updateDataCollection={updateDataCollection}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          step={step}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t("Positions"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepPositions
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          type={type}
        />
      ),
    },
  ];

  const typeMap = {
    order: {
      relationType: "orderPositions",
      steps: orderSteps,
      label: t("Link Order to Project"),
    },
    invoice: {
      relationType: "invoicePositions",
      steps: invoiceSteps,
      label: t("Link Invoice to Project"),
    },
  };

  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setIsLoading,
      handleError,
    }) => {
      var positionIds = [];
      if (dataCollection.positions) {
        dataCollection.positions.forEach((element) => {
          positionIds.push(element.id);
        });
      }
      const requestData = {
        relationType: typeMap[type].relationType,
        mode: "add",
        relations: positionIds,
      };
      DBRequest({
        config,
        path: `projects/${project.id}/relations`,
        method: API_POST(requestData),
        onResponse: (data) => {
          handleResponse(data, true);
          reloadApiData();
        },
        onLoading: setIsLoading,
        onError: handleError,
      });
    }, // your operation with the data
    content: (response) => <FinalPage response={response} />, // component with final operations and the feedback
  };

  return (
    <ExoStepper
      onClose={onClose}
      data={typeMap[type].steps}
      finalStep={finalStep}
      label={typeMap[type].label}
    />
  );
};

const StepPositions = ({
  dataCollection,
  updateValidation,
  updateDataCollection,
  type,
  allowAll,
}) => {
  const { t } = useTranslation();

  //load positions
  function handleSelectedPositions(positions) {
    updateDataCollection({ positions: positions });
    updateValidation(true);
  }
  return (
    <SelectPositions
      allowAll={allowAll}
      positions={dataCollection[type] ? dataCollection[type].positions : []}
      isLoading={!Boolean(dataCollection[type])}
      onSelect={handleSelectedPositions}
    />
  );
};

export default NewProjectPositionsRelation;
