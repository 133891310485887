import { Button, useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { useTranslation } from "react-i18next";

export const PositionCopy = ({ data, onCreate, label, position }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  function handleChange() {
    onCreate(position);
  }

  return (
    <Button color="secondary" variant="contained" onClick={handleChange}>
      {t(label)}
    </Button>
  );
};
