import {
  Box,
  Dialog,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  API_DELETE,
  API_GET,
  API_PATCH,
  API_POST,
  DBRequest,
  useConfig,
} from "../../api/api";
import { useTranslation } from "react-i18next";
import ExoForm from "../../components/exo/ExoForm";
import { tokens } from "../../global/theme/tokens";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExoAlert from "../../components/exo/ExoAlert";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import * as Yup from "yup";

const ExoCategories = ({ path }) => {
  const { t } = useTranslation();
  const config = useConfig();

  const formRef = useRef(null);

  const [categories, setCategories] = useState(null);
  const [startData, setStartData] = useState(null);

  useEffect(() => {
    DBRequest({
      config,
      path: path,
      method: API_GET,
      onResponse: setCategories,
      debug: true,
    });
  }, [config]);

  // create
  const [openCreate, setOpenCreate] = useState(false);
  function handleCreate(data) {
    DBRequest({
      config,
      path: path,
      method: API_POST(data),
      onResponse: handleAddNewCategory,
    });
    setOpenCreate(false);
  }
  function handleAddNewCategory(data) {
    var newCategories = [...categories.data];
    newCategories.push(data.data);
    setCategories({ ...categories, data: newCategories });
    formRef.current.reset();
  }

  // delete
  function handleDelete(id) {
    DBRequest({
      config,
      path: `${path}/${id}`,
      method: API_DELETE,
      onResponse: () => handleRemoveDeletedCategory(id),
    });
  }
  function handleRemoveDeletedCategory(id) {
    var newCategories = [];
    categories.data.forEach((element) => {
      if (id !== element.id) newCategories.push(element);
    });
    setCategories({ ...categories, data: newCategories });
  }

  // update
  const [openUpdate, setOpenUpdate] = useState(false);
  function handleUpdate(data) {
    DBRequest({
      config,
      path: `${path}/${data.id}`,
      method: API_PATCH(data),
      onResponse: handleUpdateUpdatedCategory,
    });
    setOpenUpdate(false);
  }
  function handleUpdateUpdatedCategory(data) {
    setStartData(null);
    var newCategories = [];
    categories.data.forEach((element) => {
      if (data.data.id !== element.id) {
        newCategories.push(element);
      } else {
        newCategories.push(data.data);
      }
    });
    setCategories({ ...categories, data: newCategories });
  }

  const fields = [
    {
      label: t("Name"),
      key: "name",
      type: "text",
    },
    {
      label: `${t("Description")} (${t("optional")})`,
      key: "description",
      type: "text",
    },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Name is required")),
  });

  return (
    <>
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Category")}
          icon={<BookmarkAddIcon />}
          onClick={() => setOpenCreate(true)}
        />
      </ExoTableToolbar>
      <Box className="flex flex-col gap-2 w-full">
        {categories &&
          categories.data.map((category, index) => (
            <CategoryItem
              category={category}
              key={index}
              onDelete={handleDelete}
              onEdit={(e) => {
                setStartData(e);
                setOpenUpdate(true);
              }}
            />
          ))}
      </Box>

      <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
        <Box className="p-4">
          <ExoForm
            header={t("New Category")}
            ref={formRef}
            fields={fields}
            onSubmit={handleCreate}
            submitText={t("Create")}
            onCancle={() => setOpenCreate(false)}
            validationSchema={validationSchema}
          />
        </Box>
      </Dialog>
      <Dialog open={openUpdate} onClose={() => setOpenUpdate(false)}>
        <Box className="p-4">
          <ExoForm
            startDataCollection={startData}
            header={t("Edit Category")}
            ref={formRef}
            fields={fields}
            onSubmit={handleUpdate}
            submitText={t("Save")}
            onCancle={() => setOpenUpdate(false)}
          />
        </Box>
      </Dialog>
    </>
  );
};

const CategoryItem = ({ category, onDelete, onEdit }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const [alertOpen, setAlertOpen] = useState(false);
  const deleteAlert = {
    message:
      "Are you sure you want to delete the Category? All assigned Items will lose this category!",
    submitLabel: "Yes",
    cancleLabel: "No",
  };

  return (
    <Box
      className="p-2 rounded-lg flex flex-row justify-between items-start backdrop-blur-lg"
      sx={{ bgcolor: colors.glass }}
    >
      <Box className="flex flex-col gap-1">
        <Typography>{category.name}</Typography>
        <Typography variant="h6">{category.description}</Typography>
      </Box>
      <Box>
        <IconButton onClick={() => onEdit(category)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => setAlertOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <ExoAlert
        open={alertOpen}
        onSubmit={() => {
          setAlertOpen(false);
          onDelete(category.id);
        }}
        onClose={() => setAlertOpen(false)}
        alert={deleteAlert}
      />
    </Box>
  );
};

export default ExoCategories;
