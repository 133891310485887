import { Box, Typography } from "@mui/material";
import FilterItem from "./FilterItem";
import { createElement } from "react";

const FilterGroup = ({ group, onChange, groupKey, activeFilters }) => {
  console.log("FITLER", activeFilters);
  return (
    <Box className="flex flex-col">
      <Typography variant="h6" className="uppercase">
        {group.label}
      </Typography>
      <Box className="flex flex-col pl-2 pt-2-">
        {group.items.map((filter, index) => {
          const activeFilterIndex = activeFilters.findIndex(
            (item) => item.value === filter.value
          );
          return createElement(FilterItem, {
            item: filter,
            onChange: () => onChange(groupKey, index),
            key: index,
            checked: Boolean(activeFilterIndex !== -1),
          });
        })}
      </Box>
    </Box>
  );
};

export default FilterGroup;
