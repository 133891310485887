import { useTranslation } from "react-i18next";

export const InfoTypes = (invoice) => {
  const { t } = useTranslation();

  const infosDefault = [
    {
      label: t("Invoice Nr"),
      value: invoice.nr,
    },
    {
      label: t("Date"),
      value: invoice.date,
      type: "date",
    },
    {
      label: t("Source"),
      value: t(invoice.sourceType),
    },
  ];
  const infosOrder = [
    {
      label: t("Order Nr"),
      value: invoice.source && invoice.source.nr,
    },
    {
      label: t("Title"),
      value: invoice.source && invoice.source.title,
    },
  ];
  const infosContract = [
    {
      label: t("Contract Nr"),
      value: invoice.source && invoice.source.nr,
    },
    {
      label: t("Title"),
      value: invoice.source && invoice.source.title,
    },
  ];

  const typeMap = {
    order: [...infosDefault, ...infosOrder],
    contract: [...infosDefault, ...infosContract],
    custom: infosDefault,
  };

  return typeMap[invoice.sourceType];
};
