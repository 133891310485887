import { Box, Button, Dialog, Typography } from "@mui/material";
import FormFields from "../form/FormFields";
import { useEffect, useRef, useState } from "react";
import { API_POST, DBNoSetData, DBRequest, useConfig } from "../../api/api";
import * as Yup from "yup";
import Feedback from "../special/Feedback";
import ExoForm from "../exo/ExoForm";
import { useTranslation } from "react-i18next";
import ExoBankAccounts from "../exo/ExoBankAccounts";
import { ExoVariants } from "../exo/ExoVariants";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreateIcon from "@mui/icons-material/Create";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import uuid from "react-uuid";

const NewBankAccount = ({ onCreate, onClose }) => {
  const { t } = useTranslation();

  const variantsRef = useRef(null);

  // CUSTOM BANK ACCOUNT
  const customValidationSchema = Yup.object().shape({
    color: Yup.string().required("Color is required"),
    bankName: Yup.string().required("Bank name is required"),
    accountHolder: Yup.string().required("Account holder is required"),
    iban: Yup.string().required("IBAN is required"),
    bic: Yup.string().required("BIC is required"),
  });
  const customFields = [
    {
      label: t("Bank Card Color"),
      type: "color",
      key: "color",
    },
    {
      label: t("Bank Name"),
      type: "text",
      key: "bankName",
    },
    {
      label: t("Account Holder"),
      type: "text",
      key: "accountHolder",
    },
    {
      label: t("IBAN"),
      type: "iban",
      key: "iban",
    },
    {
      label: t("BIC"),
      type: "text",
      key: "bic",
    },
  ];
  // CASH ACCOUNT
  const cashValidationSchema = Yup.object().shape({
    color: Yup.string().required("Color is required"),
    bankName: Yup.string().required("Bank name is required"),
    iban: Yup.string().required("IBAN is required"),
  });
  const cashFields = [
    {
      label: t("Account Color"),
      type: "color",
      key: "color",
    },
    {
      label: t("Account Name"),
      type: "text",
      key: "bankName",
    },
  ];
  const config = useConfig();
  function handleSubmit(data) {
    DBRequest({
      config,
      path: "bankaccounts",
      method: API_POST(data),
      onResponse: handleCreate,
    });
  }

  function handleCreate(data) {
    onCreate(data.data);
    onClose();
  }

  function handleClose() {
    variantsRef.current.reset();
    onClose();
  }

  const variants = [
    {
      Icon: CreateIcon,
      label: t("Custom Account"),
      key: "custom",
      content: (
        <ExoForm
          className=" w-full flex flex-col gap-4"
          onSubmit={handleSubmit}
          fields={customFields}
          validationSchema={customValidationSchema}
          onCancle={handleClose}
          header={t("New Bank Account")}
          startDataCollection={{ type: "DEPOSIT" }}
        />
      ),
    },
    {
      Icon: PointOfSaleIcon,
      label: t("Cash Account"),
      key: "cash",
      content: (
        <ExoForm
          className=" w-full flex flex-col gap-4"
          onSubmit={handleSubmit}
          fields={cashFields}
          validationSchema={cashValidationSchema}
          onCancle={handleClose}
          header={t("New Cash Account")}
          startDataCollection={{ iban: uuid(), color: "#638ccf", type: "CASH" }}
          noDifferences
        />
      ),
    },
    {
      Icon: AccountBalanceIcon,
      label: t("Auto Import"),
      key: "auto",
      content: (
        <ExoBankAccounts onClose={handleClose} onNewAccounts={onCreate} />
      ),
    },
  ];

  return <ExoVariants ref={variantsRef} variants={variants} />;
};

export default NewBankAccount;
