import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import LanIcon from "@mui/icons-material/Lan";
import ReplayIcon from "@mui/icons-material/Replay";
import { tokens } from "../../../global/theme/tokens";
import { API_POST, DBRequest, useConfig } from "../../../api/api";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const TypeCheckConnection = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const config = useConfig();
  const { t } = useTranslation();

  const [isConnected, setIsConnected] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  function checkConnection() {
    if (isChecking) return;
    DBRequest({
      config,
      path: `network-storages/test-connection`,
      method: API_POST(dataCollection),
      onLoading: setIsChecking,
      onResponse: handleConnectionSuccess,
      onError: () => setIsConnected(false),
    });
  }

  function handleConnectionSuccess() {
    setIsConnected(true);
  }

  return (
    <Box className="flex justify-between">
      <Typography
        variant="h6"
        textAlign="center"
        className="monospace flex items-center gap-2 uppercase"
        sx={{
          color: isChecking
            ? "unset"
            : isConnected
            ? colors.success
            : colors.error,
        }}
      >
        <LanIcon />
        {isChecking
          ? t("Checking Connection") + "..."
          : isConnected
          ? t("Connected")
          : t("Connection failed")}
      </Typography>
      <Button
        onClick={checkConnection}
        className="flex gap-2"
        variant="contained"
        color="secondary"
      >
        {t("Test Connection")}
        <ReplayIcon />
      </Button>
    </Box>
  );
};
