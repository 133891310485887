import { useParams } from "react-router-dom";
import ExtendDeliveryNote from "./extendDeliveryNote";

export const ShowDeliveryNote = () => {
  const { id } = useParams();

  return (
    <ExtendDeliveryNote row={{ id, onUpdate: () => {}, onDelete: () => {} }} />
  );
};
