import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import TemplateVarSwitcher from "./TemplateVarSwitcher";
import RenderHTML from "../preview/RenderHTML";
import ExoTabs from "../exo/ExoTabs";
import StyledTextContainer from "../preview/StyledTextContainer";
import MailPreviewer from "../preview/MailPreviewer";

const TemplatePreview = ({
  data,
  edit = false,
  className = "",
  onSelect = (select) => {},
  paginate = true,
  scaleable = false,
  background = false,
  onEdit = () => {},
  onChange = () => {},
  fit = false,
  sx,
}) => {
  const containerRef = useRef(null);
  const [fixedDim, setFixedDim] = useState(null);
  const [template, setTemplate] = useState({});
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (!data || !containerRef) return;
    setTemplate(data);
    handleResize();
  }, [data]);

  function calcScale(containerWidth, containerHeight) {
    if (fit) {
      const styles = JSON.parse(data.styleJson);
      const pageWidth = styles.page.size;
      const newWidthScale = containerWidth / pageWidth;

      const newHeightScale = containerHeight / (pageWidth * 1.4142);
      const newScale =
        newWidthScale > newHeightScale ? newHeightScale : newWidthScale;
      setScale(newScale);
      calcDim(newScale, pageWidth);
    }
  }

  function calcDim(scale, pageWidth) {
    const height = scale * (pageWidth * 1.4142);
    const width = scale * pageWidth;
    setFixedDim({ height, width });
  }
  const handleResize = () => {
    // Update containerWidth when the container is resized
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      calcScale(containerWidth, containerHeight);
    }
  };
  useEffect(() => {
    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial setup
    handleResize();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);

  return (
    <Box
      ref={containerRef}
      className={" w-full " + className}
      sx={{
        ...sx,
        justifyContent: "start",
        padding: "0px",

        "& div": {
          overflow: fixedDim && "hidden",
        },
      }}
    >
      <Box
        className="flex h-full w-full "
        sx={{
          height: fixedDim ? fixedDim.height : "auto",
        }}
      >
        {template.templateFormat === "Mail" && (
          <MailPreviewer template={template} onEdit={onEdit} />
        )}
        {template.templateFormat === "A4" && (
          <A4
            edit={edit}
            template={template}
            scale={scale}
            onSelect={onSelect}
            paginate={paginate}
            scaleable={scaleable}
            background={background}
            onClick={onEdit}
            onChange={onChange}
          />
        )}
        {template.templateFormat === "Web" && (
          <RenderPage
            page={1}
            edit={edit}
            template={template}
            scale={scale}
            onSelect={onSelect}
            scaleable={scaleable}
            background={background}
            onEdit={onEdit}
          />
        )}
      </Box>
    </Box>
  );
};

const A4 = ({
  template,
  scale,
  onSelect,
  edit,
  scaleable,
  background,
  onClick,
  onChange,
  paginate,
}) => {
  if (!paginate)
    return (
      <RenderPage
        page={1}
        edit={edit}
        template={template}
        scale={scale}
        onSelect={onSelect}
        scaleable={scaleable}
        background={background}
        aspectRatio={0.7071}
        onClick={onClick}
        onChange={onChange}
      />
    );
  const tabs = [
    {
      label: "Page 1",
      content: (
        <RenderPage
          page={1}
          edit={edit}
          template={template}
          scale={scale}
          onSelect={onSelect}
          scaleable={scaleable}
          background={background}
          aspectRatio={0.7071}
          onClick={onClick}
          onChange={onChange}
        />
      ),
    },
    {
      label: "Page 2",
      content: (
        <RenderPage
          page={2}
          edit={edit}
          template={template}
          scale={scale}
          onSelect={onSelect}
          scaleable={scaleable}
          background={background}
          aspectRatio={0.7071}
          onClick={onClick}
          onChange={onChange}
        />
      ),
    },
  ];
  return <ExoTabs tabs={tabs} />;
};

const RenderPage = ({
  page,
  template,
  onSelect,
  edit,
  background,
  scaleable,
  aspectRatio,
  onClick,
  scale,
  onChange = () => {},
}) => {
  const [height, setHeight] = useState({ header: 0, content: 0, footer: 0 });

  useEffect(() => {
    if (!template.styleJson) return;
    const styles = JSON.parse(template.styleJson);

    handleChange(page, styles);
  }, [template, page]);

  const headerRef = useRef(null);
  const footerRef = useRef(null);

  const [zIndex, setZIndex] = useState(10);

  function handleChange(page, styles) {
    var headerHeight = headerRef.current.clientHeight;
    var footerHeight = footerRef.current.clientHeight;
    // check if the height has changed
    if (
      styles.page["headerP" + page + "Height"] === headerHeight &&
      styles.page["footerP" + page + "Height"] === footerHeight
    )
      return;

    // get height of hidden page
    if (headerHeight === 0 && footerHeight === 0) {
      headerHeight = getHeightOfHiddenContainer(headerRef);
      footerHeight = getHeightOfHiddenContainer(footerRef);
    }

    // calc content height
    const contentHeight =
      styles.page.size / aspectRatio -
      styles.papermargin.top -
      styles.papermargin.bottom -
      headerHeight -
      footerHeight;

    setHeight({
      header: headerHeight,
      content: contentHeight,
      footer: footerHeight,
    });
    onChange(page, headerHeight, footerHeight);
  }

  function getHeightOfHiddenContainer(containerRef) {
    // Clone the hidden container
    const clonedContainer = containerRef.current.cloneNode(true);

    // Set the cloned container to be hidden
    clonedContainer.style.visibility = "hidden";
    clonedContainer.style.position = "absolute";
    clonedContainer.style.height = "auto";

    // Append the cloned container to the DOM
    document.body.appendChild(clonedContainer);

    // Get the height of the cloned container
    const containerHeight = clonedContainer.clientHeight;

    // Remove the cloned container from the DOM
    document.body.removeChild(clonedContainer);

    return containerHeight;
  }

  const hoverHighlightClass =
    "hover:bg-green-100 hover:cursor-pointer transition-colors";

  return (
    <StyledTextContainer
      presetScale={scale}
      sx={{ zIndex: zIndex }}
      styleJson={template.styleJson}
      scaleable={scaleable}
      background={background}
      aspectRatio={aspectRatio}
      onClick={onClick}
      className
    >
      <RenderHTML
        innerRef={headerRef}
        onClick={() => onSelect("headerP" + page)}
        className={"w-full  " + (edit && hoverHighlightClass)}
        HTML={TemplateVarSwitcher(template["headerP" + page], template.vars)}
      />
      <RenderHTML
        onClick={() => onSelect("content")}
        className={"w-full  " + (edit && hoverHighlightClass)}
        sx={{
          top: height.header + "px",
          height: height.content + "px",
          overflowY: "auto!important",
          "::-webkit-scrollbar": {
            display: "block",
            width: "0px",
          },
        }}
        HTML={TemplateVarSwitcher(template.content, template.vars)}
      />
      <RenderHTML
        innerRef={footerRef}
        onClick={() => onSelect("footerP" + page)}
        className={"absolute bottom-0 w-full " + (edit && hoverHighlightClass)}
        HTML={TemplateVarSwitcher(template["footerP" + page], template.vars)}
      />
    </StyledTextContainer>
  );
};

export default TemplatePreview;
