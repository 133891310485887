import { Box, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import ModuleLayout from "./ModuleLayout";
import { API_GET, DBRequestModules, useConfig } from "../../api/api";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MuiBarChart } from "../charts/MuiBarChart";
import NumberIcon from "./display/NumberIcon";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { tokens } from "../../global/theme/tokens";
import { renderHours } from "../../scenes/time-tracking/worktime";
import useFetch from "../../api/useFetch";
import { useTimeTracking } from "../time-tracking/AppTimeProvider";

const WokrtimeBarchart = () => {
  const { t } = useTranslation();
  const { secondsIn } = useTimeTracking();
  const config = useConfig();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const [activeMoment, setCurrentMoment] = useState(moment());
  const [stats, setStats] = useState();
  const { apiData } = useFetch("user/timetracking");

  useEffect(() => {
    // Check if config is available before making the DB request
    if (config) {
      const format = "Y-m-d";

      const activeMomentCopy = activeMoment.clone();
      const startDate = activeMomentCopy
        .subtract(1, "week")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");

      const endDate = activeMomentCopy
        .add(1, "week")
        .endOf("isoWeek")
        .format("YYYY-MM-DD");

      DBRequestModules({
        config,
        path: `employeeworktime?startDate=${startDate}&endDate=${endDate}&format=${format}`,
        method: API_GET,
        onResponse: handleResponse,
      });
    }
  }, [config, activeMoment]);

  function handleResponse(data) {
    var newData = [];
    var hoursBefore = 0;
    var hoursActive = 0;
    const today = moment().format("YYYY-MM-DD");
    const hoursIn = secondsIn / 60 / 60;
    data.forEach((element, index) => {
      const hoursWorked =
        today === element.date
          ? parseFloat(element.hoursWorked) + hoursIn
          : element.hoursWorked;

      const entry = {
        hoursWorked,
        date: t(moment(element.date).format("ddd")),
      };
      newData.push(entry);

      // hour calc
      if (index < 7) {
        hoursBefore += hoursWorked;
      } else {
        hoursActive += hoursWorked;
      }
    });
    const weekBefore = newData.slice(0, 7);
    const weekActive = newData.slice(7);

    // get max hours in week
    var hoursMax = 0;
    weekActive.forEach((element) => {
      if (element.hoursWorked > hoursMax) hoursMax = element.hoursWorked;
    });

    // check a that state of the week we are
    const isCurrentWeek = activeMoment.week() == moment().week();

    var dayOfWeek = isCurrentWeek ? moment().day() : 5;
    if (dayOfWeek === 0 || dayOfWeek > 5) dayOfWeek = 5; // sunday is 0 => 7

    // calc avg hours per day (5 Day Week)
    const avgHoursBefore = hoursBefore / 5;
    const avgHoursActive = hoursActive / dayOfWeek;

    const trend =
      avgHoursBefore && Math.round((avgHoursActive / avgHoursBefore - 1) * 100);

    setStats({
      isCurrentWeek,
      trend: trend,
      avgHoursBefore,
      avgHoursActive,
      hoursBefore,
      hoursActive,
      beforeDataSet: weekBefore,
      activeDataSet: weekActive,
      hoursMax,
    });
  }

  const valueFormatter = (value) => `${renderHours(value * 60)} h`;

  // Function to handle subtracting a week
  const handleSubtractWeek = () => {
    setCurrentMoment((prevMoment) => prevMoment.subtract(1, "week").clone()); // Use clone to prevent mutating state directly
  };

  // Function to handle adding a week
  const handleAddWeek = () => {
    setCurrentMoment((prevMoment) => prevMoment.add(1, "week").clone()); // Use clone to prevent mutating state directly
  };

  return (
    <ModuleLayout colSpan={1} rowSpan={1}>
      {!stats ? (
        <Box className="flex flex-row gap-8 w-full h-full">
          {[...Array(7)].map((e, index) => (
            <Skeleton
              key={index}
              sx={{ transform: "scale(1)" }}
              className="w-full"
            />
          ))}
        </Box>
      ) : (
        <Box className="flex flex-col h-full w-full overflow-hidden gap-2">
          <Box className="absolute bottom-0 right-1 opacity-50 flex justify-between items-center gap-2 w-52 z-50">
            <IconButton onClick={handleSubtractWeek}>
              <ArrowLeftIcon />
            </IconButton>
            <Typography>{`${activeMoment.week()}/${activeMoment.year()} ${
              stats.isCurrentWeek ? t("current") : ""
            }`}</Typography>
            <IconButton onClick={handleAddWeek}>
              <ArrowRightIcon />
            </IconButton>
          </Box>
          <NumberIcon
            label={t("Working Week")}
            type="hour"
            number={stats.hoursActive * 60}
            Icon={AccessTimeIcon}
            color={colors.grey[800]}
          />
          <MuiBarChart
            limit={
              apiData
                ? stats.hoursMax > apiData.data.minutesPerDay / 60
                  ? stats.hoursMax
                  : apiData.data.minutesPerDay / 60
                : null
            }
            line={apiData && apiData.data.minutesPerDay / 60}
            data={stats.activeDataSet}
            tooltipLabelX={"test"}
            xKey="date"
            yKey="hoursWorked"
            valueFormatter={valueFormatter}
          />
        </Box>
      )}
    </ModuleLayout>
  );
};

export default WokrtimeBarchart;
