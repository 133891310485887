import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { API_POST, DBRequest, useConfig } from "../../../api/api";
import { useTranslation } from "react-i18next";
import useFetch from "../../../api/useFetch";
import SkeletonCard from "../../../components/cards/SkeletonCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { OrderStatus } from "../../../components/special/Status";
import PreviewPositions from "../../../components/preview/PreviewPositions";
import NewProjectPositionsRelation from "../project-orders/NewProjectPositionsRelation";
import ExoDialog from "../../../components/exo/ExoDialog";
import { useState } from "react";
import Loader from "../../../components/special/Loader";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../../components/exo/ExoTableToolbar";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import NoDataAnimation from "../../../components/animation/NoDataAnimation";

const OrdersTab = ({ project, onDelete, onChange }) => {
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, isLoading, setIsLoading, reloadApiData } = useFetch(
    `projects/${project.id}/orders`
  );
  const [open, setOpen] = useState(false);

  function handleRemove(positions) {
    var positionIds = [];
    positions.forEach((element) => {
      positionIds.push(element.id);
    });
    const requestData = {
      relationType: "orderPositions",
      mode: "remove",
      relations: positionIds,
    };
    DBRequest({
      config,
      path: `projects/${project.id}/relations`,
      method: API_POST(requestData),
      onResponse: reloadApiData,
      onLoading: setIsLoading,
    });
  }

  return (
    <Box className="w-full flex flex-col relative">
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("Add Order")}
          icon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => setOpen(true)}
        />
      </ExoTableToolbar>
      <Loader active={isLoading} />
      {!apiData ? (
        <SkeletonCard height="55" />
      ) : (
        <>
          {apiData.data.length === 0 && (
            <Box className="w-full flex justify-center">
              <NoDataAnimation />
            </Box>
          )}
          {apiData.data.map((data) => (
            <Order key={data.id} data={data} onRemove={handleRemove} />
          ))}
        </>
      )}
      <ExoDialog open={open}>
        <NewProjectPositionsRelation
          project={project}
          onClose={() => setOpen(false)}
          reloadApiData={reloadApiData}
        />
      </ExoDialog>
    </Box>
  );
};

const Order = ({ data, onRemove }) => {
  const { t } = useTranslation();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className="flex justify-between items-center w-full pr-4">
          {data.title}
          <Box sx={{ maxWidth: "200px" }}>
            <OrderStatus number={data.status} />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <PreviewPositions positions={data.positions} />
        <Box className="mt-2">
          <ExoTableToolbarItem
            text={t("Remove Order")}
            icon={<RemoveCircleIcon />}
            onClick={() => onRemove(data.positions)}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrdersTab;
