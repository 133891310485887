import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconLabelNumber } from "./FinancialTab";
import ExoCostCenterInfo from "../../../components/exo/ExoCostCenterInfo";
import ExoCostCenter from "../../../components/exo/ExoCostCenter";
import { AccountBalance, Business, MonetizationOn } from "@mui/icons-material";
import { tokens } from "../../../global/theme/tokens";

export const ExpensesTab = ({ project }) => {
  const { t } = useTranslation();
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  return (
    <Box className="w-full flex flex-col gap-4">
      <CostOverfiew project={project} />
      <CostCenterList
        project={project}
        onSelect={setSelectedCostCenter}
        label={t("Direct Costscenters")}
        costCenters={project.directCostCenters || []}
      />
      <CostCenterList
        project={project}
        onSelect={setSelectedCostCenter}
        label={t("Indirect Costscenters")}
        costCenters={project.indirectCostCenters || []}
      />
      {selectedCostCenter && (
        <ExoCostCenterInfo
          costCenter={selectedCostCenter}
          open={Boolean(selectedCostCenter)}
          onClose={() => setSelectedCostCenter(null)}
        />
      )}
    </Box>
  );
};

const CostCenterList = ({ label, costCenters, onSelect, project }) => {
  const share =
    (project.totalSalaryExpenses + project.totalOtherExpenses) /
    project.sumOfAllDirectCostCenters;
  return (
    <Box>
      <Typography variant="h5" className="pb-1">
        {label}
      </Typography>
      <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-4">
        {costCenters.map((costCenter) => (
          <ExoCostCenter
            key={costCenter.id}
            costCenter={costCenter}
            onClick={onSelect}
            share={share}
          />
        ))}
      </Box>
    </Box>
  );
};

const CostOverfiew = ({ project }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();
  const [costs, setCosts] = useState({});

  useEffect(() => {
    var directCosts = 0;
    var indirectCosts = 0;

    // direct cost centers
    project.directCostCenters.forEach((costCenter) => {
      directCosts += costCenter.totalCost;
    });

    // indirect centers
    const share =
      (project.totalSalaryExpenses + project.totalOtherExpenses) /
      project.sumOfAllDirectCostCenters;

    project.indirectCostCenters.forEach((costCenter) => {
      indirectCosts += costCenter.totalCost * share;
    });

    setCosts({
      direct: directCosts,
      indirect: indirectCosts,
    });
  }, [project]);
  return (
    <>
      <Typography variant="h3" className="pb-1">
        {t("Costs")}
      </Typography>

      <Box className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-around w-full gap-2">
        <IconLabelNumber
          label={t("Direct Costs")}
          number={costs.direct}
          Icon={Business}
        />
        <IconLabelNumber
          label={t("Indirect Costs")}
          number={costs.indirect || 0}
          Icon={AccountBalance}
        />
        <IconLabelNumber
          label={t("Own Costs")}
          number={costs.indirect + costs.direct || 0}
          Icon={MonetizationOn}
          color={colors.error}
        />
      </Box>
    </>
  );
};
