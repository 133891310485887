import React, { useEffect, useState } from "react";
import RichTextEditor from "../editor/RichTextEditor";

export const StepRichtText = ({
  dataCollection,
  updateValidation,
  updateDataCollection,
  dataKey = "content",
}) => {
  useEffect(() => {}, []);

  const [startHTML, setStartHTML] = useState("");
  const [currentHTML, setCurrentHTML] = useState("");

  useEffect(() => {
    if (currentHTML !== dataCollection[dataKey])
      setStartHTML(dataCollection[dataKey]);
  }, [dataCollection[dataKey]]);

  function handleChange(data) {
    setCurrentHTML(data);
    updateValidation(Boolean(data));
    updateDataCollection({ [dataKey]: data });
  }

  return <RichTextEditor startHTML={startHTML} setHTML={handleChange} />;
};
