import { useEffect, useRef, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import TypeSkeleton from "./TypeSkeleton";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { tokens } from "../../../global/theme/tokens";
import TaxGroup from "../../new/receipt-modules/TaxGroup";
import LabeledBox from "../../special/LabeledBox";
import { useTranslation } from "react-i18next";
import ExoDialog from "../../exo/ExoDialog";

const TypeTaxAssignment = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
  onPreventEnter,
}) => {
  const { t } = useTranslation();

  // data
  const [taxGroups, setTaxGroups] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "taxassignmentgroups",
      method: API_GET,
      onResponse: setTaxGroups,
      onLoading: setIsLoading,
    });
  }, [config]);

  // select
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }

  useEffect(() => {
    onPreventEnter(open);
  }, [open]);

  // actions
  function handleChange(taxAccount) {
    updateValidationOnChange([field.key], taxAccount);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && open) {
        event.preventDefault(); // Prevent default form submission behavior
        handleClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dataCollection, open]);

  return (
    <>
      {isLoading ? (
        <TypeSkeleton />
      ) : (
        <>
          <LabeledBox
            className="col-span-2"
            onClick={handleOpen}
            label={field.label}
          >
            {dataCollection[field.key] ? dataCollection[field.key].label : ""}
          </LabeledBox>

          <ExoDialog open={open} onClose={handleClose}>
            <Box className="grid col-span-2 md:grid-cols-3 gap-0 relative h-full">
              <Button
                className="right-2 bottom-2"
                variant="contained"
                sx={{ position: "absolute" }}
                color="success"
                onClick={handleClose}
              >
                {t("Select")}
              </Button>

              <Box className="flex flex-col gap-4 py-1 col-span-2 overflow-y-auto pb-12">
                {taxGroups.data.map((taxGroup, index) => (
                  <TaxGroup
                    key={taxGroup.id}
                    currentId={
                      dataCollection[field.key]
                        ? dataCollection[field.key].id
                        : null
                    }
                    taxGroup={taxGroup}
                    onChange={handleChange}
                  />
                ))}
              </Box>

              <Box className="p-4 hidden md:block">
                <Typography variant="h2" textAlign="center">
                  {t("Info")}
                </Typography>
                <Box className="text-justify">
                  {dataCollection[field.key] &&
                    dataCollection[field.key].description}
                </Box>
              </Box>
            </Box>
          </ExoDialog>
        </>
      )}
    </>
  );
};

export default TypeTaxAssignment;
