import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../components/special/Header";
import ExoCategories from "../../components/exo/ExoCategories";

const Categories = () => {
  const { t } = useTranslation();
  const { type } = useParams();

  return (
    <>
      <Header title={t("Categories")} />
      <ExoCategories path={`${type}categories`} />
    </>
  );
};

export default Categories;
