import { useTheme, Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import PersonRadio from "../radio/PersonRadio";
import DBSearch from "../special/DBSearch";

const SelectUser = ({ onSelect, filter = "", preSelected = {} }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  // request
  const [employees, setEmployees] = useState(null);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: `users?${filter}`,
      method: API_GET,
      onResponse: setEmployees,
    });
  }, [config]);

  // aktions
  const [selectedId, setSelectedId] = useState(null);
  function handleChange(employee) {
    onSelect(employee);
    setSelectedId(employee.id);
  }

  useEffect(() => {
    if (!selectedId && employees) {
      const newSelected = employees.data.find(
        (obj) => obj.id === preSelected.id
      );
      if (newSelected) handleChange(newSelected);
    }
  }, [preSelected, employees]);

  return (
    <Box className=" flex flex-col gap-2 h-full overflow-y-auto pr-2">
      {!employees ? (
        <>
          {[...Array(8)].map((element, index) => (
            <Skeleton key={"skeleton-select-employee-" + index} height={204} />
          ))}
        </>
      ) : (
        <>
          {employees.data.map((element, index) => (
            <PersonRadio
              person={element}
              key={"select-employee-" + element.id}
              onClick={(e) => handleChange(e, element)}
              checked={selectedId === element.id ? true : false}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default SelectUser;
