import { Box, useTheme, IconButton, Typography, Link } from "@mui/material";
import Button from "@mui/material/Button";
import { tokens } from "../../global/theme/tokens";
import ExoAvatar from "../../components/exo/ExoAvatar";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DisplayText from "./info-displays/DisplayText";
import DisplayMail from "./info-displays/DisplayMail";
import DisplayPhone from "./info-displays/DisplayPhone";
import DisplayLocation from "./info-displays/DisplayLocation";
import DisplayDate from "./info-displays/DisplayDate";
import DisplayBool from "./info-displays/DisplayBool";
import DisplayButton from "./info-displays/DisplayButton";
import DisplayAvatar from "./info-displays/DisplayAvatar";
import DisplayMoney from "./info-displays/DisplayMoney";

const DataModule = ({ type, label, value }) => {
  const displayMapping = {
    text: DisplayText,
    mail: DisplayMail,
    phone: DisplayPhone,
    location: DisplayLocation,
    date: DisplayDate,
    bool: DisplayBool,
    button: DisplayButton,
    avatar: DisplayAvatar,
    money: DisplayMoney,
  };

  const Element = displayMapping[type];

  return <Element label={label} value={value} />;
};

export default DataModule;
