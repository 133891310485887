import {
  Box,
  Dialog,
  IconButton,
  Link,
  useTheme,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { tokens } from "../../../../global/theme/tokens";

export const AccessRule = ({ rule, onChange, activeRules }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function isChecked(id) {
    if (id === 0) {
      var isChecked = true;
      ["read", "write"].forEach((key) => {
        if (rule.options[key] && activeRules.includes(rule.options[key])) {
          isChecked = false;
        }
      });
      return isChecked;
    } else {
      return activeRules.includes(id);
    }
  }

  return (
    <tr>
      <td>
        <Box className="flex flex-row gap-3 justify-start items-center">
          <Typography variant="p">{rule.label}</Typography>
          {rule.comment && (
            <Tooltip title={rule.comment}>
              <HelpOutlineIcon
                fontSize="small"
                sx={{ color: colors.primary[400] }}
              />
            </Tooltip>
          )}
        </Box>
      </td>
      {["nothing", "read", "write"].map((key) => (
        <CheckRule
          value={rule.options[key] ? rule.options[key] : 0}
          checked={isChecked(rule.options[key] ? rule.options[key] : 0)}
          onChange={() =>
            onChange(rule.options[key] ? rule.options[key] : 0, rule.options)
          }
          disabled={!rule.options[key] && key !== "nothing"}
        />
      ))}
    </tr>
  );
};

const CheckRule = ({ value, checked, onChange, disabled }) => {
  return (
    <td>
      {!disabled && (
        <Box className="flex justify-center items-center">
          <Radio checked={checked} onChange={onChange} value={value} />
        </Box>
      )}
    </td>
  );
};
