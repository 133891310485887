import Lottie from "lottie-react";
import noData from "../../assets/lottie/noData.json";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../global/theme/tokens";
const NoDataAnimation = ({ size }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box
      className=" max-w-full "
      sx={{
        width: size || "240px",
        "& path": {
          fill: colors.primary[600],
        },
      }}
    >
      <Lottie className="w-full" animationData={noData} />
    </Box>
  );
};

export default NoDataAnimation;
