import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import { tokens } from "../../global/theme/tokens";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import NewContract from "../../components/new/NewContract";
import ExoTable from "../../components/exo/ExoTable";
import { ContractStatus } from "../../components/special/Status";
import ExtendContract from "./ExtendContract";
import Header from "../../components/special/Header";
import ExoTableRecipient from "../../components/exo/ExoTableRecipient";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../../components/label/MoneyLabel";
import { NavLink } from "react-router-dom";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import useFetch from "../../api/useFetch";

const Contracts = () => {
  const { t } = useTranslation();

  const { apiData, setApiData, isLoading } = useFetch("contracts");

  // dialog
  const [open, setOpen] = useState(false);
  const handleClick = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
  };

  // table
  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <ContractStatus number={row.status}></ContractStatus>, // custom xml for cells in the column. you get the row as object
    },

    {
      header: t("Title"),
      hiddenMobile: true,
      key: "title",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
    },
    {
      header: t("Recipient"),
      key: "recipient",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      className: "w-full md:min-w-[270px]",
      cell: ({ row }) => <ExoTableRecipient row={row} />,
    },
    {
      header: t("Rhythm"),
      key: "rhythm",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "150px",
      hiddenMobile: true,
      cell: ({ row }) => <Box>{getRhythm(row.paymentRhythm)}</Box>,
      sortable: true, // enable sort function for column
    },
    {
      header: t("Price"),
      key: "price",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      cell: ({ row }) => <MoneyLabel money={row.price} />,
      sortable: true, // enable sort function for column
    },
  ];
  function getRhythm(number) {
    switch (parseInt(number)) {
      case 1:
        return t("Monthly");
      case 2:
        return t("Every Two Months");
      case 3:
        return t("Quarterly");
      case 6:
        return t("Semiannual");
      case 12:
        return t("Annual");
      default:
        return number;
    }
  }

  return (
    <>
      <Header
        title={t("Contracts")}
        subtitle="Here you can find all contracts and easily set up new ones."
      />
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Contract")}
          icon={<NoteAddIcon />}
          onClick={handleClick}
        />
        <NavLink to={"/contract-presets"}>
          <ExoTableToolbarItem
            text={t("New Preset")}
            icon={<HistoryEduIcon />}
            onClick={() => setOpen(true)}
          />
        </NavLink>
      </ExoTableToolbar>
      <ExoTable
        data={apiData}
        isLoading={isLoading}
        columns={Columns}
        extendElement={({ row, onUpdate }) => (
          <ExtendContract row={row} onUpdate={onUpdate} />
        )}
      />
      <NewContract
        open={open}
        setContracts={setApiData}
        contracts={apiData}
        onClose={handleClick}
      />
    </>
  );
};

export default Contracts;
