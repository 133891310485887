import { useState } from "react";
import { DBUploadData, useConfig } from "../../../../../api/api";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import TypeCustomSelect from "../../../../../components/form/form-fields/TypeCustomSelect";
import { ItemToUpload } from "./ItemToUpload";
import { useTranslation } from "react-i18next";

export const WPEUploadPreview = ({
  filesToUpload,
  onRemove,
  onUpload,
  onCancle,
  fileType,
  previewType,
  noBackground,
  onAlert,
  element,
}) => {
  const config = useConfig();
  const { t } = useTranslation();

  const [progress, setProgress] = useState(100);
  const [finished, setFinished] = useState(true);

  function handleUpload() {
    onAlert(null);
    const formData = new FormData();

    if (fileType) formData.append(`fileType`, fileType);

    filesToUpload.forEach((file, index) => {
      formData.append(`files[]`, file); // Use a unique field name for each file
    });

    formData.append(`status`, status);

    DBUploadData({
      config,
      path: `work-packages/elements/file/${element.id}/file`,
      formData: formData,
      onProgressChange: setProgress,
      onFinished: setFinished,
      onResponse: handleFinishedUpload,
      onError: handleError,
    });
  }

  function handleError(err) {
    setFinished(true);
    onAlert({ type: "error", message: err.response.data.message });
  }

  function handleFinishedUpload(data) {
    onUpload(data);
    onCancle();
  }

  // status
  const [status, setStatus] = useState("");
  function handleStatusChange(e, v) {
    setStatus(v);
  }
  const statusArray = element.folderStatusMap || [];
  const options = statusArray.map((obj) => {
    return { label: obj.label, value: obj.label };
  });

  return (
    <Box className="min-h-full w-full flex flex-col gap-2">
      {!finished ? (
        <Box className="flex flex-row gap-2 relative justify-center items-center h-full w-full">
          {progress < 100 ? (
            <Box className="flex flex-col gap-4 justify-center items-center w-full">
              <Box sx={{ width: "80%", maxWidth: "400px" }}>
                <LinearProgress
                  variant="determinate"
                  value={Math.round(progress)}
                  color="primary"
                />
              </Box>
              <Typography variant="h3">
                {t("Uploading")} {Math.round(progress)}%
              </Typography>
            </Box>
          ) : (
            <Box className="flex flex-col gap-4 justify-center items-center">
              <span className="loader"></span>
              <Typography variant="h3">{t("Processing Upload")}</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <>
          {options && Boolean(options.length) && (
            <TypeCustomSelect
              field={{ label: t("Status"), key: "status", options }}
              dataCollection={{ status }}
              updateValidationOnChange={handleStatusChange}
              errors={{}}
            />
          )}
          <Box className="h-full flex flex-col gap-1 overflow-y-auto">
            {filesToUpload.map((item, index) => (
              <ItemToUpload
                key={index}
                file={item}
                idx={index}
                onRemove={onRemove}
              />
            ))}
          </Box>
        </>
      )}
      <Box className="w-full flex justify-between">
        <Button variant="outlined" color="error" onClick={onCancle}>
          {t("Cancle")}
        </Button>
        <Button variant="contained" color="success" onClick={handleUpload}>
          {t("Upload")}
        </Button>
      </Box>
    </Box>
  );
};
