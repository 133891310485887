import { useState } from "react";
import RichTextEditor from "../../components/editor/RichTextEditor";
import ExoDialog from "../../components/exo/ExoDialog";
import { FormControls } from "../../components/exo/ExoForm";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

export const ClockOutComment = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");

  function handleSubmit() {
    onSubmit(comment);
  }

  return (
    <ExoDialog
      open={open}
      onClose={onClose}
      className="flex flex-col justify-between"
    >
      <Box className="flex flex-col gap-2 pt-2">
        <Typography variant="h3">{t("Activities")}</Typography>
        <Typography variant="p">
          {t("Briefly describe what you did during your working hours")}
        </Typography>
        <RichTextEditor setHTML={setComment} />
      </Box>
      <FormControls
        isValid={true}
        onSubmit={handleSubmit}
        submitText={t("Clock Out")}
      />
    </ExoDialog>
  );
};
