import { Box, InputBase, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useRef, useState } from "react";

export const SearchField = ({ onChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [value, setValue] = useState("");

  function handleChange(e) {
    onChange(e.target.value);
    setValue(e.target.value);
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  return (
    <Box
      className="flex backdrop-blur-lg"
      borderRadius="3px"
      backgroundColor={colors.bgInput}
    >
      <InputBase
        inputRef={async (input) => {
          await delay(1);
          return input && input.focus();
        }}
        placeholder={t("Search")}
        value={value}
        onChange={handleChange}
        className="w-full px-4 py-1"
        sx={{ fontSize: "18px" }}
        endAdornment={<SearchIcon sx={{ color: colors.grey[400] }} />}
      />
    </Box>
  );
};
