import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { tokens } from "../../global/theme/tokens";
import StyledTextContainer from "./StyledTextContainer";
import RenderHTML from "./RenderHTML";
import TemplateVarSwitcher from "../templates/TemplateVarSwitcher";

const MailPreviewer = ({ template, onEdit }) => {
  console.log(template);
  return (
    <StyledTextContainer
      styleJson={template.styleJson}
      presetScale={1}
      autoOverflow
      onClick={onEdit}
    >
      <Box id={"headerP1-" + template.id}></Box>
      <Box id={"headerP2-" + template.id}></Box>
      <Box id={"footerP1-" + template.id}></Box>
      <Box id={"footerP2-" + template.id}></Box>
      <RenderHTML HTML={TemplateVarSwitcher(template.content, template.vars)} />
    </StyledTextContainer>
  );
};

export default MailPreviewer;
