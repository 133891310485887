import { useTranslation } from "react-i18next";
import useFetch from "../../../api/useFetch";
import { Box, Dialog, IconButton, Link } from "@mui/material";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../../components/exo/ExoTableToolbar";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import ExoTable from "../../../components/exo/ExoTable";
import { GenerateAccountingExport } from "./GenerateAccountingExport";
import { useRef, useState } from "react";
import { AccountingPeriodLabel } from "../../../components/label/AccountingPeriodLabel";
import { ExpandAccounting } from "./ExpandAccounting";

const AccountingTab = () => {
  const { t } = useTranslation();

  const tableRef = useRef(null);

  const { apiData, isLoading } = useFetch("accounting-exports");

  const [open, setOpen] = useState(false);

  function handleNewElement(newElement) {
    setOpen(false);
    if (tableRef.current) tableRef.current.addRow(newElement);
  }

  const Columns = [
    {
      header: t("Title"), // header of column
      key: "startDate", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace">
          <AccountingPeriodLabel date={row.startDate} />
          {` - ${t("Accounting Export")}`}
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];

  return (
    <Box className="w-full flex flex-col gap-0 h-full">
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("Generate")}
          icon={<AutoFixNormalIcon />}
          onClick={() => setOpen(true)}
        />
      </ExoTableToolbar>
      <ExoTable
        ref={tableRef}
        extendElement={({ row, onDelete, onCreate }) => (
          <ExpandAccounting row={row} onDelete={onDelete} onCreate={onCreate} />
        )}
        data={apiData}
        isLoading={isLoading}
        columns={Columns}
        noBackground
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <GenerateAccountingExport
          onResponse={handleNewElement}
          onClose={() => setOpen(false)}
        />
      </Dialog>
    </Box>
  );
};
export default AccountingTab;
