import { useTheme, Box, Snackbar, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";

const ExoProcessing = ({ open, label }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Snackbar
      sx={{
        zIndex: "9999",
      }}
      open={open}
      className="relative"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Box
        className="backdrop-blur-lg rounded-lg py-2 px-4 flex gap-2 justify-start items-center"
        sx={{
          bgcolor: colors.glass,
          borderRadius: "10px",
          borderWidth: "1px",
          borderColor: colors.grey[300],
        }}
      >
        <span className="loader"></span>
        {label && <Typography>{label}</Typography>}
      </Box>
    </Snackbar>
  );
};

export default ExoProcessing;
