import { useTheme, Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import PersonRadio from "../radio/PersonRadio";
import DBSearch from "../special/DBSearch";

const SelectEmployees = ({ onSelect, filter = "", preSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  // request
  const [employees, setEmployees] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: `users?${filter}`,
      method: API_GET,
      onResponse: setEmployees,
      onLoading: setIsLoading,
    });
  }, [config]);

  useEffect(() => {
    if (preSelected && employees) {
      findPreSelectedCompanies();
    }
  }, [preSelected, employees]);

  function findPreSelectedCompanies() {
    var preSelectedEmployees = [];
    preSelected.forEach((element) => {
      const employee = employees.data.find((obj) => obj.id === element.id);
      if (employee) preSelectedEmployees.push(employee);
    });
    setSelected(preSelectedEmployees);
    onSelect(preSelectedEmployees);
  }

  // aktions
  const [selected, setSelected] = useState([]);
  function handleChange(employee) {
    if (!employee) return;
    const selectedIndex = selected.findIndex(
      (selectedObj) => selectedObj.id === employee.id
    );
    if (selectedIndex === -1) {
      // Object not in the selected array, add it
      setSelected([...selected, employee]);
      onSelect([...selected, employee], employee);
    } else {
      // Object already in the selected array, remove it
      const updatedArray = [...selected];
      updatedArray.splice(selectedIndex, 1);
      setSelected(updatedArray);
      onSelect(updatedArray);
    }
  }

  function isSelected(id) {
    return selected.some((selectedObj) => selectedObj.id === id);
  }

  return (
    <Box className=" flex flex-col gap-2 h-full overflow-y-auto pr-2 w-full">
      {isLoading ? (
        <>
          {[...Array(8)].map((element, index) => (
            <Skeleton key={"skeleton-select-employee-" + index} height={204} />
          ))}
        </>
      ) : (
        <>
          {employees.data.map((element, index) => (
            <PersonRadio
              person={element}
              key={"select-employee-" + element.id}
              onClick={(e) => handleChange(e, element)}
              checked={isSelected(element.id)}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default SelectEmployees;
