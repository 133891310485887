import React, { useEffect, useState } from "react";
import TypeCustomSelect from "./TypeCustomSelect";
import useFetch from "../../../api/useFetch";
import TypeSkeleton from "./TypeSkeleton";
import { API_GET, DBRequest, useConfig } from "../../../api/api";

export const TypeNetworkStorage = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const config = useConfig();

  const [options, setOptions] = useState(null);

  useEffect(() => {
    DBRequest({
      config,
      path: `network-storages`,
      method: API_GET,
      onResponse: generateOptions,
    });
  }, [config]);

  function generateOptions(apiData) {
    var newOptions = [];
    apiData.data.forEach((element) => {
      newOptions.push({
        label: element.name,
        value: element.id,
      });
    });
    setOptions(newOptions);
  }

  if (!options) return <TypeSkeleton />;

  return (
    <TypeCustomSelect
      field={{ ...field, options, noUnselect: true }}
      dataCollection={dataCollection}
      errors={errors}
      updateValidationOnChange={updateValidationOnChange}
    />
  );
};
