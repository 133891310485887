import moment from "moment";
import React, { useEffect, useState } from "react";
import { accountingPeriodMap } from "../form/form-fields/TypeAccountingPeriod";
import { useConfig } from "../../api/api";
import { useTranslation } from "react-i18next";

export const AccountingPeriodLabel = ({ date }) => {
  const config = useConfig();
  const [accountringPeriod, setAccountringPeriod] = useState(null);
  const { t } = useTranslation();
  const year = moment(date).format("YYYY");
  const month = moment(date).format("M");

  useEffect(() => {
    if (config) {
      const periodSetting = config.accounting.accountingPeriod;
      var period = "";
      accountingPeriodMap[periodSetting].forEach((element) => {
        if (element.value <= month) period = element.label;
      });
    }
    setAccountringPeriod(period);
  }, [config]);

  return `${year} - ${t(accountringPeriod)}`;
};
