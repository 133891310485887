import { Button } from "@mui/material";
import SuccessCheck from "./SuccessCheck";
import FailureCross from "./FailureCross";

const FeedbackButton = ({
  children,
  onClick = () => {},
  className,
  isLoading = false,
  success = false,
  variant = "",
}) => {
  return (
    <Button
      className={className + " flex justify-center items-center gap-2"}
      onClick={onClick}
      variant={variant}
    >
      {children}
      {isLoading && <span class="loader" />}
      {!isLoading && success === true && (
        <SuccessCheck width="25" height="25" />
      )}
      {!isLoading && success === false && (
        <FailureCross width="25" height="25" />
      )}
    </Button>
  );
};

export default FeedbackButton;
