import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "./api";

const useFetch = (path, debug = false) => {
  const config = useConfig();

  const [apiData, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [serverError, setServerError] = useState(null);
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "",
  });

  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  useEffect(() => {
    if (!config) return;
    reloadApiData();
  }, [config, path]);

  function reloadApiData() {
    DBRequest({
      config,
      path,
      method: API_GET,
      onResponse: setApiData,
      onLoading: setIsLoading,
      onFeedback: handleAlertOpen,
      onError: (error, status) => setServerError({ error, status }),
      debug,
    });
  }

  return {
    apiData,
    setApiData,
    isLoading,
    setIsLoading,
    serverError,
    alertState,
    reloadApiData,
  };
};

export default useFetch;
