import { Box, Collapse, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import TeamMemberTimeCard from "../project-info-cards/TeamMemberTimeCard";
import ExoForm from "../../../components/exo/ExoForm";
import TodayIcon from "@mui/icons-material/Today";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import ExoDialog from "../../../components/exo/ExoDialog";
import ExoWorkday from "../../../components/exo/ExoWorkday";
const TimesTab = ({ project }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const [timesPerUser, setTimesPerUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const config = useConfig();
  const format = "D";

  useEffect(() => {
    if (!config) return;
    updateTimes();
  }, [config]);

  function updateTimes(data = {}) {
    var string = ``;
    if (data.dateStart && data.dateEnd)
      string += `?startDate=${data.dateStart}&endDate=${data.dateEnd}`;
    // "/${taskGroup.id}"${string}"
    DBRequest({
      config,
      path: `time-trackings/project-times-per-user/${project.id}${string}`,
      method: API_GET,
      onResponse: setTimesPerUser,
      onLoading: setIsLoading,
    });
  }

  const fields = [
    {
      label: t("Timespan"),
      key: "date",
      type: "dateRange",
    },
  ];

  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <Box className="flex flex-col gap-2 w-full">
      <Box>
        <IconButton onClick={() => setOpen(!open)}>
          <TodayIcon />
        </IconButton>
        <Collapse in={open}>
          <Box
            className=" mt-2 p-2 rounded-lg"
            sx={{
              backgroundColor: colors.card,
            }}
          >
            <ExoForm
              header={t("Time Range")}
              fields={fields}
              onCancle={() => setOpen(false)}
              onSubmit={updateTimes}
            />
          </Box>
        </Collapse>
      </Box>

      {isLoading ? (
        <Box>{t("Loading...")}</Box>
      ) : (
        <>
          {timesPerUser.data.map((time, index) => (
            <TeamMemberTimeCard
              key={index}
              person={time.user}
              time={time.timeInProject}
              onSelect={setSelectedUser}
            />
          ))}
          <TimeDetails
            userId={selectedUser && selectedUser.id}
            projectId={project.id}
            onClose={() => setSelectedUser(null)}
          />
        </>
      )}
    </Box>
  );
};

const TimeDetails = ({ userId, projectId, onClose }) => {
  const config = useConfig();

  const [allTimes, setAllTimes] = useState(null);
  const [daysToRender, setDaysToRender] = useState(null);
  useEffect(() => {
    if (userId)
      DBRequest({
        config,
        path: `timetrackings/timesperday?&allDaysWithTime=1&userId=${userId}&projectId=${projectId}`,
        method: API_GET,
        onResponse: handleResponse,
        debug: true,
      });
  }, [userId]);

  function handleResponse(res) {
    setAllTimes(res);
    setDaysToRender(res);
  }

  return (
    <ExoDialog open={Boolean(userId && projectId)} onClose={onClose}>
      {allTimes && (
        <ExoWorkday
          workdays={daysToRender}
          daySpan={14}
          labelFormat={"dddd"}
          onlyDaysWithTime
        />
      )}
    </ExoDialog>
  );
};

export default TimesTab;
