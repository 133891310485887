import { Box, Typography, useTheme } from "@mui/material";
import ExoForm from "../../components/exo/ExoForm";
import * as Yup from "yup";
import { API_POST, DBRequest, useConfig } from "../../api/api";
import Feedback from "../../components/special/Feedback";
import { useState } from "react";
import moment from "moment";
import { tokens } from "../../global/theme/tokens";
import { renderHours } from "../time-tracking/worktime";
import { useTranslation } from "react-i18next";

const NewTimeCompensation = ({ onCreate, onCancle, minutesPerDay }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const fields = [
    {
      label: t("Compensation"),
      type: "dateRange",
      key: "compensation",
    },
    {
      label: t("Comment"),
      type: "text",
      key: "comment",
      multiline: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    compensationStart: Yup.string().required(t("Start Date is required")),
    compensationEnd: Yup.string().required(t("End Date is required")),
    comment: Yup.string().required(t("Comment is required")),
  });

  // live calculation
  const [diff, setDiff] = useState(0);

  const format = "DD-MM-YYYY hh:mm";
  const config = useConfig();
  function handleChange(dataCollection) {
    if (dataCollection.compensationStart && dataCollection.compensationEnd) {
      const start = moment(dataCollection.compensationStart, format);
      const end = moment(dataCollection.compensationEnd, format);

      const days = end.diff(start, "days");
      const minutes = days * minutesPerDay;
      setDiff(minutes);
    } else {
      setDiff(0);
    }
  }

  function handleSubmit(values) {
    const data = {
      type: "timeCompensation",
      comment: values.comment,
      values: JSON.stringify({
        startDate: values.compensationStart,
        endDate: values.compensationEnd,
      }),
    };
    DBRequest({
      config,
      path: "employeerequests",
      method: API_POST(data),
      onResponse: handleCreation,
      onFeedback: handleAlertOpen,
    });
  }

  function handleCreation(data) {
    onCreate(data.data);
    onCancle();
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <Box className="p-4 flex flex-col gap-2">
      <Typography variant="h3" textAlign="center">
        {t("New Compensation")}
      </Typography>
      <Box className="flex justify-center items-center mb-2">
        <Box className="p-4 rounded-lg" sx={{ backgroundColor: colors.glass }}>
          <Typography variant="h3">{renderHours(diff, true)} h</Typography>
          <Typography variant="p">{t("Compensation")}</Typography>
        </Box>
      </Box>

      <ExoForm
        fields={fields}
        onSubmit={handleSubmit}
        onCancle={onCancle}
        validationSchema={validationSchema}
        onChange={handleChange}
      />
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

export default NewTimeCompensation;
