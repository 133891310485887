import { Box, IconButton, Link, Typography } from "@mui/material";
import DisplayLabel from "./base-components/Label";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const DisplayPhone = ({ label, value }) => {
  return (
    <Box className=" flex flex-row gap-2 justify-center items-center">
      <Box>
        <DisplayLabel text={label} />

        <Typography
          variant="h4"
          className="flex flex-row gap-2 justify-start items-center"
        >
          {value ? (
            <>
              <Link href={"tel:" + value}>
                <IconButton>
                  <LocalPhoneIcon />
                </IconButton>
              </Link>
              {value}
            </>
          ) : (
            <>----</>
          )}
        </Typography>
      </Box>
    </Box>
  );
};
export default DisplayPhone;
