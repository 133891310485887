import { useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";

export function useMethodColor(method, getMap = false) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const primaryAlpha = "40";
  const secondaryAlpha = "80";

  const colorMap = {
    delete: {
      primary: colors.error + primaryAlpha,
      secondary: colors.error + secondaryAlpha,
    },
    update: {
      primary: colors.info + primaryAlpha,
      secondary: colors.info + secondaryAlpha,
    },
    add: {
      primary: colors.success + primaryAlpha,
      secondary: colors.success + secondaryAlpha,
    },
    default: { primary: colors.card, secondary: colors.selected },
    edit: {
      primary: colors.blueAccent[400] + "50",
      secondary: colors.blueAccent[400] + "80",
    },
  };

  return getMap ? colorMap : colorMap[method || "default"];
}
