import { Box, useTheme, Typography, IconButton } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import StorageIcon from "@mui/icons-material/Storage";
import FolderIcon from "@mui/icons-material/Folder";
import PersonIcon from "@mui/icons-material/Person";
import LanIcon from "@mui/icons-material/Lan";
import ReplayIcon from "@mui/icons-material/Replay";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_DELETE, API_GET, DBRequest, useConfig } from "../../../api/api";
import EditIcon from "@mui/icons-material/Edit";
import { motion } from "framer-motion";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

export const StorageDisk = ({ disk, onEdit, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const config = useConfig();
  const { t } = useTranslation();

  const [isConnected, setIsConnected] = useState(disk.driver === "system");
  const [isChecking, setIsChecking] = useState(false);

  function checkConnection() {
    if (isChecking || disk.driver === "system") return;
    DBRequest({
      config,
      path: `network-storages/${disk.id}/status`,
      method: API_GET,
      onLoading: setIsChecking,
      onResponse: () => setIsConnected(true),
      onError: () => setIsConnected(false),
    });
  }

  useEffect(() => {
    checkConnection();
  }, [config]);

  function handleDelete() {
    DBRequest({
      config,
      path: `network-storages/${disk.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(disk),
    });
  }

  return (
    <Box
      className="flex flex-col rounded-lg p-4 relative"
      sx={{ bgcolor: colors.card, aspectRatio: 0.7 }}
    >
      {disk.driver !== "system" && (
        <>
          <IconButton
            onClick={handleDelete}
            className="top-2 left-2"
            sx={{ position: "absolute" }}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            onClick={() => onEdit(disk)}
            className="top-2 right-2"
            sx={{ position: "absolute" }}
          >
            <EditIcon />
          </IconButton>
        </>
      )}
      <motion.div
        animate={
          isConnected
            ? {
                rotate: [35, 30, 35, 55, 50, 55, 50, 55, 50, 55, 35],
              }
            : {}
        }
        transition={{ repeat: Infinity, repeatDelay: 0, duration: 3 }}
        className="rounded-full w-3/4 aspect-square mx-auto my-4 flex opacity-50 justify-center items-center relative"
        style={{ backgroundColor: colors.selected }}
      >
        <Box
          className="rounded-full w-4 aspect-square mx-auto my-4 z-10"
          sx={{
            bgcolor:
              isChecking || isConnected ? colors.glassSolid : colors.error,
          }}
        />
        <Box
          className=" h-1 w-1/2 absolute top-1/2 left-0 -translate-y-1/2 rounded-r-full"
          sx={{
            bgcolor:
              isChecking || isConnected ? colors.glassSolid : colors.error,
          }}
        />
      </motion.div>
      <Typography variant="h4" textAlign="center" className="pb-2">
        {disk.name}
      </Typography>
      {disk.driver !== "system" && (
        <>
          <Box className="flex flex-col gap-1">
            <Typography
              variant="h6"
              textAlign="center"
              className="monospace flex items-center gap-2"
            >
              <StorageIcon />
              {disk.host}
            </Typography>
            <Typography
              variant="h6"
              textAlign="center"
              className="monospace flex items-center gap-2"
            >
              <FolderIcon />
              {disk.rootDirectory}
            </Typography>
            <Typography
              variant="h6"
              textAlign="center"
              className="monospace flex items-center gap-2"
            >
              <AccountTreeIcon />
              {disk.projectRootDirectory}
            </Typography>
            <Typography
              variant="h6"
              textAlign="center"
              className="monospace flex items-center gap-2"
            >
              <PersonIcon />
              {disk.username}
            </Typography>
            <Box className="flex justify-between items-center">
              <Typography
                variant="h6"
                textAlign="center"
                className="monospace flex items-center gap-2 uppercase"
                sx={{
                  color: isChecking
                    ? "unset"
                    : isConnected
                    ? colors.success
                    : colors.error,
                }}
              >
                <LanIcon />
                {isChecking
                  ? t("Checking Connection") + "..."
                  : isConnected
                  ? t("Connected")
                  : t("Connection failed")}
              </Typography>
              <IconButton onClick={checkConnection}>
                <ReplayIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
