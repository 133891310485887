import { Box, Typography, useTheme, useThemeProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../global/theme/tokens";
import MoneyLabel from "../../../components/label/MoneyLabel";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentCard from "../../../components/cards/PercentCard";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { ShoppingCart } from "@mui/icons-material";

const FinanzialTab = ({ project = {} }) => {
  return (
    <Box className="w-full flex flex-col gap-4">
      <ErningsOverfiew project={project} />
    </Box>
  );
};

export const IconLabelNumber = ({
  label = "",
  number = 0,
  Icon = AttachMoneyIcon,
  color,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box className="flex flex-row gap-2 items-start">
      <Box
        className="rounded-lg  flex justify-center items-center"
        sx={{
          backgroundColor: colors.card,
          minWidth: "40px",
          minHeight: "40px",
        }}
      >
        <Icon
          sx={{
            fontSize: "25px",
            color: color ? color : colors.grey[700],
          }}
        />
      </Box>
      <Box>
        <Typography textAlign="left" variant="h6">
          {label}
        </Typography>
        <Typography variant="h3" fontWeight={500} className="monospace">
          <MoneyLabel money={number} />
        </Typography>
      </Box>
    </Box>
  );
};

const ErningsOverfiew = ({ project }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  const expectedProfit =
    project.orderVolume -
    project.totalOtherExpenses -
    project.totalSalaryExpenses;

  const realProfit =
    project.invoiceVolume -
    project.totalOtherExpenses -
    project.totalSalaryExpenses;

  return (
    <>
      <Typography variant="h3" className="pb-1">
        {t("Profit")}
      </Typography>
      <Box className="grid grid-cols-1 sm:grid-cols-2  justify-around w-full gap-2 col-span-5">
        <IconLabelNumber
          label={t("Order Volume")}
          number={project.orderVolume}
          Icon={ShoppingCart}
        />
        <IconLabelNumber
          label={t("Invoice Volume")}
          number={project.invoiceVolume}
          Icon={ReceiptIcon}
          color={colors.success}
        />
        <PercentCard
          size="130px"
          percent={Math.max(expectedProfit / project.orderVolume, 0) || 0}
          title={t("Estimated Profit")}
          amount={<MoneyLabel money={expectedProfit} />}
          className="col-span-2 sm:col-span-1 row-start-1 "
        />
        <PercentCard
          size="130px"
          percent={Math.max(realProfit / project.invoiceVolume, 0) || 0}
          title={t("Real Profit")}
          amount={<MoneyLabel money={realProfit} />}
          className="col-span-2 sm:col-span-1 row-start-1"
        />
      </Box>
    </>
  );
};
export default FinanzialTab;
