import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import useFetch from "../../../api/useFetch";
import TypeSkeleton from "./TypeSkeleton";

export const TypeFileTypeId = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  value,
}) => {
  const { apiData } = useFetch("file-types");

  function handleChangeSelect(e) {
    updateValidationOnChange(field.key, e.target.value);
  }

  return (
    <FormControl fullWidth>
      {!apiData ? (
        <TypeSkeleton />
      ) : (
        <>
          <InputLabel id={"fileTypeId-select" + field.key}>
            {field.label}
          </InputLabel>
          <Select
            labelId={"fileTypeId-select" + field.key}
            value={value || dataCollection[field.key] || ""}
            label={field.label}
            onChange={handleChangeSelect}
            error={Boolean(errors[field.key])}
            sx={{
              "& .MuiSelect-select": {
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "row",
                gap: "8px",
                padding: "0px 32px 0px 14px",
                height: "53px!important",
              },
            }}
          >
            {apiData.data.map((element) => (
              <MenuItem
                key={"company-" + element.id}
                value={element.id}
                className="flex flex-row gap-2 justify-start items-center"
              >
                {element.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            error={Boolean(errors[field.key])}
            helperText={errors[field.key]}
          />
        </>
      )}
    </FormControl>
  );
};
