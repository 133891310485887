import { Box, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InventoryIcon from "@mui/icons-material/Inventory";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import menuItemList from "../../../global/menuItemList";
import { useTranslation } from "react-i18next";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import DiscountIcon from "@mui/icons-material/Discount";

const Controls = ({
  cols,
  onNewPosition,
  onSelectProduct,
  onSelectService,
  onNewGroup,
  onNewDiscount,
  hideServices,
  hideProducts,
  hideNewPosition,
  hideSum,
  hideDiscount,
  hideGroups,
}) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td colSpan={cols.length}>
        <Box
          className="w-full flex flex-row justify-center items-center h-0 mt-4"
          sx={{
            marginBottom: hideSum ? "15px" : "0px",
          }}
        >
          {!hideNewPosition && (
            <Tooltip title={t("Add Custom Position")} arrow>
              <IconButton onClick={onNewPosition}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          {!hideGroups && (
            <Tooltip title={t("Add Group")} arrow>
              <IconButton onClick={onNewGroup}>
                <GroupWorkIcon />
              </IconButton>
            </Tooltip>
          )}

          {!menuItemList()[2].items[1].hidden && !hideProducts && (
            <Tooltip title={t("Add Product Position")} arrow>
              <IconButton onClick={onSelectProduct}>
                <InventoryIcon />
              </IconButton>
            </Tooltip>
          )}

          {!menuItemList()[2].items[2].hidden && !hideServices && (
            <Tooltip title={t("Add Service Position")} arrow>
              <IconButton onClick={onSelectService}>
                <SupervisedUserCircleIcon />
              </IconButton>
            </Tooltip>
          )}
          {!hideDiscount && (
            <Tooltip title={t("Add Discount")} arrow>
              <IconButton onClick={onNewDiscount}>
                <DiscountIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </td>
    </tr>
  );
};

export default Controls;
