import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";

export const TimeAnimation = ({ size = "100px", className }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className={className}
      sx={{
        width: size,
        maxWidth: "95%",
      }}
    >
      <Box
        className="relative aspect-square w-full"
        sx={{
          ":before, :after": {
            content: "''",
            borderRadius: "50%",
            position: "absolute",
            inset: 0,
            boxShadow: "0 0 10px 2px rgba(0, 0, 0, 0.3) inset",
          },
          ":after": {
            boxShadow: ` 0 2px 0 ${colors.greenAccent[500]} inset`,
            animation: "rotate 2s linear infinite",
          },
        }}
      ></Box>
    </Box>
  );
};
