import { Dialog } from "@mui/material";

const PopUpContainer = ({ open, onClose, children }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {children}
    </Dialog>
  );
};
export default PopUpContainer;
