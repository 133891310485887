import { useEffect, useState } from "react";
import { API_DELETE, API_GET, DBRequest, useConfig } from "../api/api";
import {
  Alert,
  Badge,
  Box,
  IconButton,
  Link,
  Popover,
  Typography,
} from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const Notifications = () => {
  const [notifications, setNotifications] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    updateNoteData();
    const noteUpdateInterval = setInterval(() => {
      updateNoteData();
    }, 1 * 60 * 1000);
    return () => clearInterval(noteUpdateInterval);
  }, [config]);

  function updateNoteData() {
    DBRequest({
      config,
      path: "notifications",
      method: API_GET,
      onResponse: setNotifications,
      onLoading: setIsLoading,
    });
  }

  function handleDelete(id) {
    DBRequest({ config, path: `notifications/${id}`, method: API_DELETE });

    // update data array
    var newArray = [];
    notifications.data.forEach((note, index) => {
      if (note.id !== id) newArray.push({ ...note });
    });
    setNotifications({ ...notifications, data: newArray });
    if (newArray.length < 1) handleClose();
  }

  // Popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (notifications.data && notifications.data.length < 1) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notivication-popover" : undefined;

  return (
    <>
      <Badge
        color="secondary"
        className=" cursor-pointer"
        onClick={handleClick}
        badgeContent={notifications.data ? notifications.data.length : 0}
        invisible={notifications.data ? !notifications.data.length : true}
        sx={{
          "& .MuiBadge-badge": {
            right: 7,
            top: 10,
          },
        }}
      >
        <IconButton aria-describedby={id} onClick={handleClick}>
          {<NotificationsOutlinedIcon />}
        </IconButton>
      </Badge>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <NotificationList
          notifications={notifications}
          isLoading={isLoading}
          onDelete={handleDelete}
        />
      </Popover>
    </>
  );
};

const NotificationList = ({ notifications, isLoading, onDelete }) => {
  return (
    <Box
      className="flex flex-col gap-2 overflow-y-auto p-3"
      sx={{ maxHeight: "calc(100vh - 53px - 30px)" }}
    >
      {!isLoading && (
        <>
          {notifications.data.map((element, index) => (
            <Notification
              key={"Notification-" + index}
              element={element}
              onDelete={onDelete}
            />
          ))}
        </>
      )}
    </Box>
  );
};

const Notification = ({ element, onDelete }) => {
  const handleDeleteNotification = () => {
    onDelete(element.id);
  };
  return (
    <Alert
      severity={element.data.type}
      className=" max-w-md"
      sx={{
        borderRadius: "10px",
        "& .MuiAlert-message": {
          width: "100%",
        },
        "& .MuiAlert-icon": {
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Box className="flex w-full justify-between items-center gap-4">
        <Box>
          <Typography variant="h5" color="i">
            <b>{element.data.title}</b>
          </Typography>
          <Typography variant="p">{element.data.message}</Typography>
        </Box>

        <IconButton className=" float-right" onClick={handleDeleteNotification}>
          {<CloseOutlinedIcon />}
        </IconButton>
      </Box>
    </Alert>
  );
};

export default Notifications;
