import { useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <>
      <Header title="Calendar" subtitle="Full Calendar Interactive Page" />
    </>
  );
};

export default Calendar;
