import { Box } from "@mui/material";
import { useEffect } from "react";
import { Controls } from "../exo/ExoStepper";
import SelectInvoice from "../select/SelectInvoice";
import { useTranslation } from "react-i18next";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

const StepInvoice = ({
  apiUrl,
  dataCollection,
  updateDataCollection,
  activeStep,
  setActiveStep,
  step,
  updateValidation,
  button,
  onClose = () => {},
}) => {
  const { t } = useTranslation();

  function handleSelect(invoice) {
    const insertData = {
      invoice: invoice,
      recipient: invoice.recipient,
      recipientType: invoice.recipientType,
      title: invoice.title,
    };
    updateDataCollection(insertData);

    //next step
    setActiveStep(activeStep + 1);
  }

  useEffect(() => {
    if (dataCollection && dataCollection.invoice) updateValidation(true);
  }, []);

  return (
    <SelectInvoice
      apiUrl={apiUrl}
      onSelect={handleSelect}
      filter={apiUrl ? "" : "status[eq]=0"}
      selected={dataCollection && dataCollection.invoice}
      button={button}
      buttonLabel={t("Settle")}
      buttonIcon={<PointOfSaleIcon />}
    />
  );
};

export default StepInvoice;
