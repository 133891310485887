import { useTheme, Box, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { API_PATCH, DBNoSetData, DBRequest } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import MailPreviewer from "../../components/preview/MailPreviewer";
import Feedback from "../../components/special/Feedback";

const EditTypography = ({ data, setOpen, updateTemplate }) => {
  const [template, setTemplate] = useState(data);

  const syncStyles = () => {
    var newApiData = {};

    newApiData["styleJson"] = template.styleJson;

    DBRequest(
      "templates/" + template.id,
      API_PATCH(newApiData),
      isSaving,
      isSaving,
      handleAlertOpen
    );
    updateTemplate(template.styleJson);
  };
  function isSaving(finished) {
    finished && setOpen(false);
  }

  // Feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "",
    alertText: "",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  function handleInputChange(update) {
    setTemplate({ ...update });
  }

  return (
    <>
      <Box className="grid grid-flow-col gap-4 flex-wrap overflow-auto">
        <Box
          className="overflow-auto"
          sx={{
            height: "90vh",
          }}
        >
          <Box className="pb-4 flex flex-col gap-4 pt-2">
            <Button
              sx={{ width: "100%" }}
              onClick={syncStyles}
              variant="contained"
            >
              Save
            </Button>
          </Box>
          <Properties template={template} onInputChange={handleInputChange} />
        </Box>
        <Box
          className="overflow-auto"
          sx={{
            height: "90vh",
          }}
        >
          <MailPreviewer style={template.styleJson} autoscale noScale={true}>
            {template.content}
          </MailPreviewer>
        </Box>
      </Box>
      <Feedback state={alertState} setState={setAlertState} />
    </>
  );
};

const Properties = ({ template, onInputChange }) => {
  const [expanded, setExpanded] = useState("");

  const styles = JSON.parse(template.styleJson);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box className="flex flex-col overflow-auto">
      {Object.keys(styles).map((style, index) => {
        if (style === "page" || style == "papermargin") {
          return;
        }
        return (
          <Accordion
            key={"PropertyGroup-" + index}
            expanded={expanded === "group-" + index}
            onChange={handleChange("group-" + index)}
          >
            <AccordionSummary>
              <Typography>{style}</Typography>
            </AccordionSummary>
            <MuiAccordionDetails>
              <PropertyGroup
                template={template}
                keyValue={style}
                onInputChange={onInputChange}
              />
            </MuiAccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

const PropertyGroup = ({ template, onInputChange, keyValue }) => {
  const styles = JSON.parse(template.styleJson);
  const [values, setValues] = useState(styles[keyValue]);

  useEffect(() => {
    var newStyles = styles;
    newStyles[keyValue] = values;
    var newTemplate = template;
    newTemplate.styleJson = JSON.stringify(newStyles);
    onInputChange(newTemplate);
  }, [values]);

  return (
    <Box className="flex flex-col gap-4 py-4">
      {Object.keys(values).map((key, index) => (
        <EditTools
          key={"Tools-" + keyValue + "-" + key}
          type={key}
          values={values}
          setValues={setValues}
        />
      ))}
    </Box>
  );
};

const EditTools = ({ type, values, setValues }) => {
  switch (type) {
    default:
      return;
    case "fontFamily":
      return <FontFamily values={values} setValue={setValues} type={type} />;
    case "fontSize":
      return <FontSize values={values} setValue={setValues} type={type} />;
    case "fontWeight":
      return <FontWeight values={values} setValue={setValues} type={type} />;
    case "margin":
      return <Margin values={values} setValue={setValues} type={type} />;
    case "padding":
      return <Padding values={values} setValue={setValues} type={type} />;
    case "color":
      return <Color values={values} setValue={setValues} type={type} />;
    case "url":
      return <FontUrl values={values} setValue={setValues} type={type} />;
    case "size":
      return <Size values={values} setValue={setValues} type={type} />;
    case "lineHeight":
      return <LineHeight values={values} setValue={setValues} type={type} />;
    case "top":
      return <Top values={values} setValue={setValues} type={type} />;
    case "right":
      return <Right values={values} setValue={setValues} type={type} />;
    case "bottom":
      return <Bottom values={values} setValue={setValues} type={type} />;
    case "left":
      return <Left values={values} setValue={setValues} type={type} />;
    case "backgroundColor":
      return (
        <BackgroundColor values={values} setValue={setValues} type={type} />
      );
  }
};

// propertys
const FontSize = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Font Size"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const Margin = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Margin"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const Padding = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Padding"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const FontUrl = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="URL"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const Color = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Color"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const FontWeight = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Font Weight"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const FontFamily = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Font Familie"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const BackgroundColor = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Background Color"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const LineHeight = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Line Height"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const Size = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Size (in px)"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const Top = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Top (in px)"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const Right = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Right (in px)"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const Bottom = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Bottom (in px)"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};
const Left = ({ values, setValue, type }) => {
  const handleChange = (e, key) => {
    var newValues = values;
    newValues[type] = e.currentTarget.value;
    setValue({ ...newValues });
  };

  return (
    <TextField
      className="w-full text-center"
      label="Left (in px)"
      variant="outlined"
      onChange={(e) => handleChange(e, "top")}
      value={values[type]}
    />
  );
};

// Template Content
const Content = ({ code, vars, position, id }) => {
  for (let i = 0; i < vars.length; i++) {
    code = code.replaceAll(vars[i]["value"], vars[i]["preview"]);
  }

  return (
    <div
      id={id}
      className={position ? position + "-0 absolute w-full" : "w-full"}
      dangerouslySetInnerHTML={{
        __html: code ? code : "<h1 style='text-align: center;'>LEER</h1>",
      }}
    ></div>
  );
};

//Accordion
const Accordion = (props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
);

const AccordionSummary = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <MuiAccordionSummary
      className=" p-2 rounded-lg"
      sx={{
        backgroundColor: colors.primary[600],
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "rotate(90deg)",
        },
        "&.Mui-expanded": {
          backgroundColor: colors.greenAccent[800],
        },
      }}
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  );
};

export default EditTypography;
