import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { createElement } from "react";
import { useTranslation } from "react-i18next";
import { wpeList } from "../../exo/project/work-package-elements/wpeList";

const TypeWPEType = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  function handleChange(type) {
    updateValidationOnChange(field.key, type);
  }

  return (
    <Box className="flex justify-between flex-wrap">
      {wpeList.map((option) => (
        <Button onClick={() => handleChange(option.type)}>
          <Box
            className="py-8 w-48 rounded-lg flex flex-col gap-6 justify-center items-center hover:scale-105 transition-transform"
            sx={{
              backgroundColor:
                dataCollection[field.key] === option.type
                  ? colors.selected
                  : colors.card,
            }}
          >
            {createElement(option.Icon, { sx: { fontSize: "100px" } })}
            <Typography variant="h4">{t(option.label)}</Typography>
          </Box>
        </Button>
      ))}
    </Box>
  );
};

export default TypeWPEType;
