import { InputAdornment, TextField } from "@mui/material";

const TypeTaxRate = ({
  field,
  dataCollection,
  errors,
  updateValidationOnChange,
}) => {
  function handleTextChange(value) {
    updateValidationOnChange([field.key], value.target.value);
  }
  return (
    <TextField
      label={field.label}
      value={dataCollection[field.key] || ""}
      onChange={handleTextChange}
      multiline={field.multiline ? true : false}
      error={Boolean(errors[field.key])}
      helperText={errors[field.key]}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  );
};

export default TypeTaxRate;
