import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import ModuleLayout from "./ModuleLayout";
import { API_GET, DBRequestModules, useConfig } from "../../api/api";
import NumberIcon from "./display/NumberIcon";
import SavingsIcon from "@mui/icons-material/Savings";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import { periodStepMap, periodTrendLabel } from "./modulePeriodStep";
import NumberIconChart from "./display/NumberIconChart";

const TaxPrepayment = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const config = useConfig();
  const [period, setPeriod] = useState("quarter");

  useEffect(() => {
    // Check if config is available before making the DB request
    if (config) {
      updateData(period);
    }
  }, [config]);

  function updateData(period) {
    setPeriod(period);
    DBRequestModules({
      config,
      path: `paidtaxes?period=${periodStepMap[period].param}&startDate=${periodStepMap[period].startDate}`,
      method: API_GET,
      onResponse: setData,
    });
  }

  return (
    <ModuleLayout
      colSpan={1}
      rowSpan={1}
      onPeriodChange={updateData}
      periods={["week", "month", "quarter", "year"]}
      isLoading={!data}
    >
      <NumberIconChart
        isLoading={!Boolean(data)}
        Icon={SavingsIcon}
        number={data && data.data.dataset[data.data.dataset.length - 1].y}
        trend={
          data &&
          Math.round(
            ((data.data.dataset[data.data.dataset.length - 1].y /
              data.data.dataset[data.data.dataset.length - 2].y) *
              Math.sign(data.data.dataset[data.data.dataset.length - 2].y) -
              1) *
              100
          )
        }
        label={t("Tax Prepayment")}
        trendLabel={t(periodTrendLabel[period])}
        color={colors.redAccent[600]}
        chartData={data && data.data.dataset}
      />
    </ModuleLayout>
  );
};

export default TaxPrepayment;
