import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useTimeTracking } from "../components/time-tracking/AppTimeProvider";
import { renderHours } from "../scenes/time-tracking/worktime";
import { tokens } from "./theme/tokens";
import TimerIcon from "@mui/icons-material/Timer";
import { motion } from "framer-motion";

export const TimeBadge = ({ className }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { activeProject, secondsIn, clockOut } = useTimeTracking();

  if (!activeProject) return;
  return (
    <Button
      sx={{
        bgcolor: colors.bgInput,
        ":hover": {
          bgcolor: colors.card,
        },
      }}
      onClick={clockOut}
      className={
        className || " flex items-center gap-2 rounded-lg px-3 w-full "
      }
    >
      <Typography> {renderHours(Math.round(secondsIn / 60))} h</Typography>
      <Typography variant="h6" className="hidden sm:block">
        {activeProject.activeWorkPackage
          ? activeProject.activeWorkPackage.title
          : activeProject.name}
      </Typography>
      <motion.div
        animate={{
          rotate: [-15, 15, -15],
          scale: [1, 1.09, 1, 1.09, 1],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.5, 1],
          repeat: Infinity,
          repeatDelay: 0,
        }}
      >
        <TimerIcon color="error" />
      </motion.div>
    </Button>
  );
};
