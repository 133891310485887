import React from "react";
import ExoDialog from "./ExoDialog";
import { useCallback, useEffect, useState } from "react";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { Document, Page } from "react-pdf";
import {
  Box,
  IconButton,
  useTheme,
  Skeleton,
  Typography,
  Link,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { tokens } from "../../global/theme/tokens";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import NoDataAnimation from "../animation/NoDataAnimation";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const resizeObserverOptions = {};

export const ExoPDFViewer = ({
  src,
  open,
  onClose,
  type = "url",
  maxWidth = 2000,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [file, setFile] = useState(src);
  const [numPages, setNumPages] = useState(null); // Initialize numPages as null
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState(null); // Initialize containerWidth as null

  useEffect(() => {
    setFile(src);
  }, [src]);

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onFileChange(event) {
    const { files } = event.target;

    if (files && files[0]) {
      setFile(files[0]); // Just set the file without the null check
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    // Destructure directly
    setNumPages(numPages);
  }

  // scale
  const zoomInCap = 3;
  const zoomOutCap = 0.4;
  const scaleStepSize = 0.2;
  const [scale, setScale] = useState(1); // State to track the scale factor
  const zoomIn = () => {
    // NOT WORKING

    const newScale = scale + scaleStepSize;
    setScale(newScale > zoomInCap ? zoomInCap : newScale); // Increase the scale factor
  };

  const zoomOut = () => {
    const newScale = scale - scaleStepSize;
    setScale(newScale < zoomOutCap ? zoomOutCap : newScale); // Decrease the scale factor
  };
  return (
    <ExoDialog open={open} onClose={onClose}>
      <Box
        className="flex flex-col h-full w-full overflow-y-auto"
        ref={setContainerRef}
      >
        <Box className=" grid grid-cols-3 left-0 top-0 z-10 sticky">
          <Box></Box>
          <Box
            className="  w-full  flex justify-center gap-4 p-1 backdrop-blur-lg rounded-full"
            sx={{
              bgcolor: colors.glass,
            }}
          >
            <IconButton onClick={zoomIn}>
              <ZoomInIcon />
            </IconButton>
            <IconButton onClick={() => setScale(1)}>
              <FitScreenIcon />
            </IconButton>
            <IconButton onClick={zoomOut}>
              <ZoomOutIcon />
            </IconButton>
          </Box>
          <Box className="flex flex-row-reverse">
            <Box
              className="backdrop-blur-lg p-1 rounded-full"
              sx={{
                bgcolor: colors.glass,
              }}
            >
              {type === "url" && (
                <Link href={src} target="_blank">
                  <IconButton>
                    <DownloadIcon />
                  </IconButton>
                </Link>
              )}
            </Box>
          </Box>
        </Box>
        <input type="file" className="hidden" onChange={onFileChange} />
        <Document
          file={file}
          error={
            <Box className=" w-full flex flex-col justify-center items-center h-full">
              <NoDataAnimation size={90} />
              <Typography>{t("Cant load File")}</Typography>
            </Box>
          }
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          loading={
            <Skeleton
              sx={{
                padding: 0,
                margin: 0,
                height: "100%",
                transform: "unset",
                borderRadius: "0px",
              }}
            />
          }
          className="flex flex-col gap-2 relative"
        >
          {Array.from(new Array(numPages || 0), (el, index) => (
            <Box className="flex">
              <Page
                className="block rounded-lg overflow-hidden"
                renderAnnotationLayer={false}
                renderTextLayer={false}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={
                  containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                }
                scale={scale}
              />
            </Box>
          ))}
        </Document>
      </Box>
    </ExoDialog>
  );
};
