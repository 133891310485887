import {
  useTheme,
  Box,
  CircularProgress,
  Tooltip,
  IconButton,
  Badge,
} from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

const DraftIndicator = ({
  draftIsSaved,
  className = "absolute right-2 top-2",
  sx,
  onClick,
  badgeCount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation();

  return (
    <IconButton
      onClick={onClick}
      size="small"
      className={className}
      sx={{ position: "absolute" }}
    >
      <Badge badgeContent={badgeCount} color="secondary">
        {draftIsSaved ? (
          <Tooltip title={t("draft saved")}>
            <CloudCircleIcon color="success" sx={{ fontSize: "30px" }} />
          </Tooltip>
        ) : (
          <Box
            className="flex items-center justify-center relative"
            sx={{ width: "30px", height: "30px" }}
          >
            <CloudSyncIcon color="secondary" />
            <CircularProgress
              size="30px"
              className="absolute top-0 left-0"
              style={{ color: colors.bgInput }}
            />
          </Box>
        )}
      </Badge>
    </IconButton>
  );
};

export default DraftIndicator;
