import { Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import DownloadIcon from "@mui/icons-material/Download";
import CardContainer from "./layout/CardContainer";
import { useTranslation } from "react-i18next";
import NoDataAnimation from "../animation/NoDataAnimation";

const defaultFiles = [
  {
    label: "DEMO",
    icon: <DownloadIcon />,
    url: "https://www.youtube.com/watch?v=xvFZjo5PgG0",
  },
];

const FileCard = ({ files = defaultFiles, span = 1 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const handlePDFClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <CardContainer span={span}>
      <Typography variant="h6" textAlign="center">
        {t("Files")}
      </Typography>
      {files.length === 0 && <NoDataAnimation size="120px" />}
      {files.map((file, index) => (
        <Button
          key={"file-" + index}
          className="flex flex-row gap-2"
          disabled={!file.url}
          onClick={() => handlePDFClick(file.url)}
          endIcon={file.icon}
        >
          {file.label}
        </Button>
      ))}
    </CardContainer>
  );
};

export default FileCard;
