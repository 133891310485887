import { CardContentText } from "./content/CardContentText";
import CardContainer from "./layout/CardContainer";

const AddressCard = ({ span, address = {} }) => {
  return (
    <CardContainer span={span}>
      <CardContentText text={`${address.street} ${address.houseNumber}`} />
      <CardContentText text={`${address.zipCode} ${address.city}`} />
      <CardContentText text={address.country} />
      {address.addressAddition && (
        <CardContentText text={address.addressAddition} />
      )}
    </CardContainer>
  );
};

export default AddressCard;
