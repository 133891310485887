import { Box, IconButton, useTheme, Typography, Dialog } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import ExoTable from "../exo/ExoTable";
import StartIcon from "@mui/icons-material/Start";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import NewService from "../new/NewService";
import { ExoTableToolbar, ExoTableToolbarItem } from "../exo/ExoTableToolbar";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DBSearch from "../special/DBSearch";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../label/MoneyLabel";

const SelectService = ({ onSelect, onClose = () => {} }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  // request
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "services",
      method: API_GET,
      onResponse: setData,
      onLoading: setIsLoading,
    });
  }, [config]);

  // actions
  function handleSelect(data) {
    onSelect(data);
  }

  // new service
  const [open, setOpen] = useState(false);
  function handleNewServiceClick() {
    setOpen(!open);
  }

  function handleNewServiceCreation(service) {
    var newservices = { ...data };
    newservices.data.unshift(service);
    setData({ ...newservices });
  }

  // table
  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100px", // width of column
      cell: ({ row }) => <Box className="monospace">{row.nr}</Box>, // custom xml for cells in the column. you get the row as object
      hiddenMobile: true, // hides the column on mobile
    },
    {
      header: t("Name"), // header of column
      key: "name", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "auto", // width of column
    },
    {
      header: t("Price"), // header of column
      key: "price", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      cell: ({ row }) => <MoneyLabel money={row.price} />, // custom xml for cells in the column. you get the row as object
      sortable: true, // enable sort function for column
      width: "200px", // width of column
    },
    {
      header: "", // header of column
      key: "price", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "100px", // width of column
      cell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleSelect(row);
            onClose();
          }}
          color="success"
        >
          <StartIcon />
        </IconButton>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];

  return (
    <Box className="p-2 h-full w-full flex flex-col">
      <Typography variant="h2" align="center">
        {t("Services")}
      </Typography>
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Service")}
          icon={<PlaylistAddIcon />}
          onClick={handleNewServiceClick}
        />
      </ExoTableToolbar>
      <Box className="h-full flex flex-col">
        <ExoTable data={data} isLoading={isLoading} columns={Columns} />
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <NewService
          onClose={handleNewServiceClick}
          onCreate={handleNewServiceCreation}
        />
      </Dialog>
    </Box>
  );
};

export default SelectService;
