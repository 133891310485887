import React, { useRef, useState } from "react";
import { OpenDocuments } from "./OpenDocuments";
import { useTranslation } from "react-i18next";
import ExoTableRecipient from "../../exo/ExoTableRecipient";
import MoneyLabel from "../../label/MoneyLabel";
import { NewTransactionRelation } from "../../transactions/relations/NewTransactionRelation";
import { InvoicePreviewElement } from "../../transactions/preview-elements/InvoicePreviewElement";
import { Box, Typography } from "@mui/material";
import { updateOrCreate } from "../../special/updateOrCreate";
import ReceiptIcon from "@mui/icons-material/Receipt";

export const OpenInvoices = () => {
  const { t } = useTranslation();
  const openDocumentsRef = useRef(null);
  const [selectedElement, setSelectedElement] = useState(null);

  const element = {
    label: t("Open invoices"),
    apiUrl: "invoices/open?openAmount[nq]=0",
    Icon: ReceiptIcon,
    columns: [
      {
        header: t("Nr"), // header of column
        key: "nr", // key of column in row-object
        thAlign: "left", // header text alignment
        tdAlign: "left", // cell text alignment
        sortable: true, // enable sort function for column
        cell: ({ row }) => (
          <Typography className="monospace whitespace-nowrap">
            {row.nr}
          </Typography>
        ), // custom xml for cells in the column. you get the row as object
        onClick: handleClick,
      },
      {
        header: t("Title"),
        key: "title",
        thAlign: "left",
        tdAlign: "left",
        className: "min-w-[250px]",
        sortable: true,
        onClick: handleClick,
      },
      {
        header: t("Recipient"),
        key: "recipient",
        thAlign: "left", // header text alignment
        tdAlign: "left", // cell text alignment
        width: "100%",
        sortable: true, // enable sort function for column
        hiddenMobile: true,
        cell: ({ row }) => <ExoTableRecipient row={row} />, // custom xml for cells in the column. you get the row as object
        onClick: handleClick,
      },
      {
        header: t("Open amount"),
        key: "openAmount",
        thAlign: "right",
        tdAlign: "right",
        width: "170px",
        sortable: true,
        cell: ({ row }) => (
          <Typography>
            <b>
              <MoneyLabel money={row.openAmount} />
            </b>
          </Typography>
        ),
        onClick: handleClick,
      },
    ],
  };

  function handleClick(row, col) {
    setSelectedElement(row);
  }

  function handleUpdatedElement(updatedElement) {
    openDocumentsRef.current.setApiData((prev) => {
      if (updatedElement.openAmount === 0)
        return {
          ...prev,
          data: prev.data.filter((obj) => obj.id !== updatedElement.id),
        };
      return updateOrCreate(prev, updatedElement);
    });
  }

  return (
    <>
      <OpenDocuments
        ref={openDocumentsRef}
        {...element}
        selectedElement={selectedElement}
      />
      <NewTransactionRelation
        open={Boolean(selectedElement)}
        RelationPreview={InvoicePreviewElement}
        selectedElement={selectedElement}
        onClose={() => setSelectedElement(null)}
        relationType="invoice"
        transactionArrayKey="invoices"
        onUpdate={handleUpdatedElement}
      />
    </>
  );
};
