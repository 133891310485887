import { Draggable } from "react-beautiful-dnd";
import EntryConst from "../position-modules/entries/EntryConst";
import { EntryContainer } from "../position-modules/EntryContainer";
import EntryEditable from "../position-modules/entries/EntryEditable";
import { stringToNumber } from "../../special/numberConverter";

export const PositionsGroup = ({
  items,
  item,
  index,
  columns,
  groupKey,
  collapseModules,
  onChange,
  onFocus,
}) => {
  // change
  function handleChange(e, col) {
    var newItems = items;
    if (col.number) {
      newItems[index][col.key] = stringToNumber(
        e.target.value,
        col.decimalPlaces ? col.decimalPlaces : 2
      );
    } else {
      newItems[index][col.key] = e.target.value;
    }
    onChange([...newItems]);
  }

  return (
    <Draggable draggableId={"item-" + item.id + "-" + index} index={index}>
      {(provided, snapshot) => (
        <>
          <tr
            className="position-group"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {collapseModules && <td></td>}
            {columns.map((col, i) => (
              <GroupColumn
                key={i}
                col={col}
                groupKey={groupKey}
                item={item}
                isLabel={groupKey === col.key}
                isPosition={col.key === "position"}
                onChange={(e) => handleChange(e, col)}
                onFocus={onFocus}
              />
            ))}
          </tr>
          <tr>
            <td colspan="999"></td>
          </tr>
        </>
      )}
    </Draggable>
  );
};

const GroupColumn = ({
  groupKey,
  item,
  isLabel,
  isPosition,
  col,
  onChange,
  onFocus,
}) => {
  if (col.hidden) return;
  if (isPosition)
    return (
      <EntryContainer col={col}>
        <EntryConst value={item.position} unit="" className={col.className} />
      </EntryContainer>
    );
  if (!isLabel) return <td></td>;
  return (
    <EntryContainer col={col}>
      <EntryEditable
        col={col}
        item={item}
        onChange={onChange}
        onFocus={onFocus}
      />
    </EntryContainer>
  );
};
