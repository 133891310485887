import { InputAdornment, TextField } from "@mui/material";

const TypePassword = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  function handleTextChange(value) {
    updateValidationOnChange([field.key], value.target.value);
  }
  return (
    <TextField
      sx={{
        gridColumn: field.colspan
          ? `span ${field.colspan} / span ${field.colspan}`
          : "auto",
      }}
      label={field.label}
      type="password"
      value={dataCollection[field.key] || ""}
      onChange={handleTextChange}
      multiline={field.multiline ? true : false}
      error={Boolean(errors[field.key])}
      helperText={errors[field.key]}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{field.unit}</InputAdornment>
        ),
      }}
    />
  );
};

export default TypePassword;
