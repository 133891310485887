import React from "react";
import { tokens } from "../../../global/theme/tokens";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export const PositionsHeader = ({ columns, collapseModules }) => {
  const { t } = useTranslation();
  return (
    <tr>
      {collapseModules && <Header col={{ width: "37px" }}></Header>}
      {columns.map((col, index) => (
        <Header key={"header-" + col.key} col={col}>
          {col.text}
        </Header>
      ))}
    </tr>
  );
};

const Header = ({ col, children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  if (col.hidden == true) return;
  return (
    <th
      className={col.hideOnMobile ? "hidden md:table-cell" : ""}
      style={{
        textAlign: col.align ? col.align : "left",
        width: col.width ? col.width : "auto",
        fontWeight: "500",
        padding: "5px 10px",
        borderBottom: "2px solid " + colors.primary[500],
      }}
    >
      <Typography variant="p">{children}</Typography>
    </th>
  );
};
