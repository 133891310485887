const breakPoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

const dashboardGridBreakTable = [
  {
    breakPoint: breakPoints["sm"],
    columns: 1,
    rows: 1,
  },
  {
    breakPoint: breakPoints["md"],
    columns: 1,
    rows: 1,
  },
  {
    breakPoint: breakPoints["lg"],
    columns: 2,
    rows: 2,
  },
  {
    breakPoint: breakPoints["xl"],
    columns: 3,
    rows: 3,
  },
  {
    breakPoint: breakPoints["2xl"],
    columns: 4,
    rows: 4,
  },
];

export function generateMediaQuerysForDashboardGridElement(colSpan, rowSpan) {
  var newMedia = {
    gridColumn: `span ${colSpan > 1 ? 1 : colSpan} / span ${
      colSpan > 1 ? 1 : colSpan
    }`,
    gridRow: `span ${1} / span ${1}`,
  };

  dashboardGridBreakTable.forEach((element) => {
    newMedia[`@media (min-width: ${element.breakPoint}px)`] = {
      gridColumn: `span ${
        colSpan > element.columns ? element.columns : colSpan
      } / span ${colSpan > element.columns ? element.columns : colSpan}`,
      gridRow: `span ${
        rowSpan > element.rows ? element.rows : rowSpan
      } / span ${rowSpan > element.rows ? element.rows : rowSpan}`,
    };
  });
  return newMedia;
}

export function generateMediaQuerysForDashboardGrid() {
  var newMedia = {
    gridTemplateColumns: `repeat(1, minmax(0, 1fr))`,
  };
  dashboardGridBreakTable.forEach((element) => {
    newMedia[`@media (min-width: ${element.breakPoint}px)`] = {
      gridTemplateColumns: `repeat(${element.columns}, minmax(0, 1fr))`,
    };
  });
  return newMedia;
}
