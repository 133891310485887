const findDifferences = (oldObj, newObj, useExeptions = true) => {
  if (!oldObj) return newObj;
  const differences = {};
  if (Object.keys(oldObj).length === 0) return newObj;
  for (let key in newObj) {
    // always add these keys
    if (
      useExeptions &&
      (key === "id" ||
        key === "delivery" ||
        key === "positions" ||
        key === "color" ||
        key === "attachments" ||
        key === "telephoneNumbers" ||
        key === "templateId")
    )
      differences[key] = newObj[key];
    // check if value exists and is differernt
    if (oldObj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
      if (oldObj[key] !== newObj[key]) {
        differences[key] = newObj[key];
      }
    }
    if (!oldObj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
      differences[key] = newObj[key];
    }
  }

  return differences;
};

export default findDifferences;
