import { Box, Button, Dialog } from "@mui/material";
import BankAccountsSlider from "./BankAccounsSlider";
import { useEffect, useRef, useState } from "react";
import Transactions from "./Transactions";
import Header from "../../components/special/Header";
import NewBankAccount from "../../components/new/NewBankAccount";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import AddCardIcon from "@mui/icons-material/AddCard";
import { useTranslation } from "react-i18next";
import ExoDialog from "../../components/exo/ExoDialog";

const BankAccounts = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [bankAccounts, setBankAcckounts] = useState({});
  const scrollContainerRef = useRef(null);

  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "bankaccounts",
      method: API_GET,
      onResponse: setBankAcckounts,
      onLoading: setIsLoading,
    });
  }, [config]);

  const handleBankUpdate = (bank) => {
    setActiveBankAccount(bank);
  };

  const [activeBankAccount, setActiveBankAccount] = useState(null);

  // first Account
  function handleCreatedAccount(newBankAccount) {
    setBankAcckounts({
      ...bankAccounts,
      data: [...bankAccounts.data, newBankAccount],
    });
  }

  const [open, setOpen] = useState(false);

  return (
    <>
      <Header title={t("Bank Accounts")} />
      <Box className="overflow-y-auto relative h-full" ref={scrollContainerRef}>
        <BankAccountsSlider
          handleBankUpdate={handleBankUpdate}
          bankAccounts={bankAccounts}
          isLoading={isLoading}
          setOpen={setOpen}
        />
        {activeBankAccount && (
          <Transactions
            bankAccount={activeBankAccount}
            scrollContainerRef={scrollContainerRef}
          />
        )}
      </Box>
      <ExoDialog open={open} limitWidth>
        <NewBankAccount
          onClose={() => setOpen(false)}
          onCreate={handleCreatedAccount}
        />
      </ExoDialog>
    </>
  );
};

export default BankAccounts;
