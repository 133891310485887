import EntryNameEditable from "./EntryNameEditable";
import EntryEditable from "./EntryEditable";
import EntryConst from "./EntryConst";

const CustomPosition = ({
  col,
  item,
  index,
  onChange,
  setItems,
  onFocus = () => {},
  descriptionVars,
  showVars,
  imageType,
  imageApiFilter,
}) => {
  return (
    <>
      {col.editable && (
        <EntryEditable
          col={col}
          item={item}
          index={index}
          onChange={onChange}
          onFocus={onFocus}
        />
      )}
      {!col.editable && (
        <>
          {col.custom && (
            <EntryConst
              unit={""}
              value={col.custom(item, col.unit)}
              className={col.className}
            />
          )}
          {!col.custom && (
            <EntryConst
              unit={col.unit}
              value={item[col.key]}
              className={col.className}
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomPosition;
