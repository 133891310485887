import { useEffect } from "react";
import SelectUser from "../select/SelectEmployee";

const StepUser = ({
  updateDataCollection,
  dataCollection,
  updateValidation,
  type = "contactPerson",
}) => {
  function handleUserSelect(user) {
    updateDataCollection({ [type]: user });
  }

  // validation
  useEffect(() => {
    updateValidation(dataCollection[type] !== undefined);
  }, [dataCollection]);

  return (
    <SelectUser
      onSelect={handleUserSelect}
      preSelected={dataCollection[type]}
    />
  );
};

export default StepUser;
