import { Typography } from "@mui/material";

const FormHelperText = ({ error, helperText }) => {
  if (!error) return;
  return (
    <Typography
      sx={{
        display: error ? "block" : "none",
        fontSize: "0.6428571428571428rem",
        height: "0px",
      }}
      color="error"
    >
      {helperText}
    </Typography>
  );
};

export default FormHelperText;
