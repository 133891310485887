import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Typography from "@mui/material/Typography";
import ExoAvatar from "../exo/ExoAvatar";
import { useEffect, useState } from "react";

const PersonRadio = ({ person, onClick = () => {}, checked = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const picture = person.picture ? person.picture.thumbnailUrl : "";
  const name = person.lastName + " " + person.firstName;
  const position = person.position;

  const [selected, setSelected] = useState(checked);

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  function handleClick() {
    onClick(person);
  }

  return (
    <Box
      onClick={handleClick}
      className="flex flex-row items-center gap-4 px-1 py-1 rounded-lg peer-checked:border-2 cursor-pointer"
      sx={{
        backgroundColor: selected ? colors.selected : colors.bgInput,
      }}
    >
      <ExoAvatar
        icon={person.icon}
        picture={picture}
        size="64px"
        name={name}
        color={person.color}
      />
      <Box>
        <Typography variant="h5">{name}</Typography>
        <Typography
          variant="h5"
          sx={{
            color: colors.blueAccent[500],
          }}
        >
          {position}
        </Typography>
      </Box>
    </Box>
  );
};

// FUNCTIONS
function getInitials(name) {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
}

export default PersonRadio;
