import { Box, useTheme, Typography, Button } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import ExoAvatar from "../../../components/exo/ExoAvatar";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const ProjectGridItem = ({ project }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const name = !project.clientType
    ? t("internal")
    : project.clientType === "company"
    ? project.client.name
    : `${project.client.lastName} ${project.client.firstName}`;

  return (
    <NavLink to={`/projects/management/${project.id}`}>
      <Button
        variant="contained"
        color="secondary"
        sx={{
          transition: "scale 0.3s ease",
        }}
        className=" cursor-pointer w-full flex flex-col backdrop-blur-lg items-center p-4 rounded-lg"
      >
        {project.image ? (
          <img
            src={project.image.temporaryUrl}
            className="w-full h-32 object-contain"
          />
        ) : (
          <ExoAvatar
            picture={project.client.logo && project.client.logo.temporaryUrl}
            type={project.clientType}
            name={name}
            size="128px"
          />
        )}
        <Typography variant="h5" className="pt-4">
          <b>{project.name}</b>
        </Typography>
        <Typography variant="h6">{name}</Typography>
        <Typography
          variant="h5"
          className="pt-4 monospace w-full"
          textAlign="right"
        >
          {project.nr}
        </Typography>
      </Button>
    </NavLink>
  );
};
