import { useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";

const Help = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <>
      <Header title="HELP" subtitle="Here you can find help" />
    </>
  );
};

export default Help;
