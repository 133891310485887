import { Box, useTheme, Typography, IconButton, Button } from "@mui/material";
import MediaGrid from "./explorer/MediaGrid";
import { tokens } from "../../global/theme/tokens";
import { useEffect, useRef, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import Pagination from "../special/Pagination";
import MediaInfo from "./explorer/MediaInfo";
import UploadWrapper from "./explorer/UploadWrapper";
import ExoTabs from "./ExoTabs";
import MediaTable from "./explorer/MediaTable";
import Feedback from "../special/Feedback";
import { useTranslation } from "react-i18next";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WindowIcon from "@mui/icons-material/Window";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { SettingsPopover } from "./project/SettingsPopover";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import ImageIcon from "@mui/icons-material/Image";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SummarizeIcon from "@mui/icons-material/Summarize";

//mediaTypes 7 => ICON

const ExoExplorer = ({
  onSelect,
  onClose,
  mediaType,
  multiSelect,
  preSelected = [],
  maxItems = 5,
  maxSizeKB = 200,
  noControls,
  onSelectionChange,
  noTypeChange,
  filter = "",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState(preSelected);
  const [currentType, setCurrentType] = useState(mediaType || "MEDIA");

  // feedback
  const [errorSelect, setErrorSelect] = useState(null);
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  // get media
  const [media, setMedia] = useState(null);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    handleReload(mediaType);
  }, [mediaType, config]);

  function handleReload(type) {
    const url = `files?type[eq]=${type || "MEDIA"}${
      filter ? `&${filter}` : ""
    }`;

    DBRequest({
      config,
      path: url,
      method: API_GET,
      onResponse: setMedia,
    });
    setCurrentType(type || "MEDIA");
  }

  // actions

  function handleSelectedItem(item) {
    if (multiSelect) {
      // Check if the item is already in the selectedItems array
      const itemIndex = selectedItems.findIndex(
        (selected) => selected.id === item.id
      );

      sizeLimitCheck(itemsLimitCheck() ? { fileSize: 0 } : item);

      if (itemIndex === -1) {
        // If not selected, add it to the array
        if (itemsLimitCheck()) return;
        const newItems = [...selectedItems, item];

        setSelectedItems(newItems);
        if (onSelectionChange) onSelectionChange(newItems);
      } else {
        // If already selected, remove it from the array
        const updatedSelectedItems = [...selectedItems];
        updatedSelectedItems.splice(itemIndex, 1);
        setSelectedItems(updatedSelectedItems);
        if (onSelectionChange) onSelectionChange(updatedSelectedItems);
      }
    } else {
      setSelectedItems([item]);
      if (onSelectionChange) onSelectionChange([item]);
    }
  }

  function itemsLimitCheck() {
    // check if maxItems limit is reached
    if (maxItems < selectedItems.length + 1 && multiSelect) {
      handleFeedback(t("Filelimit reached! max: ") + maxItems, "warning");
      return true;
    }
    return false;
  }

  function sizeLimitCheck(item) {
    // check if maxSize limit is reached
    const currentSize = selectedItems.reduce(
      (sum, item) => sum + item.fileSize,
      0
    );
    const totalSizeKB =
      Math.round(((currentSize + item.fileSize) * 100) / 1042) / 100;
    // Convert bytes to megabytes (1 MB = 1024 * 1024 bytes)
    if (maxSizeKB < totalSizeKB) {
      setErrorSelect(
        t("Files are to large") + ` ${totalSizeKB}/${maxSizeKB} KB`
      );
      return false;
    } else {
      setErrorSelect("");
    }
    return true;
  }

  // media local storage
  const handleDeletedMedia = (item) => {
    var newMedia = { ...media };
    newMedia.data = Array();
    media.data.forEach((element) => {
      if (item.id !== element.id) newMedia.data.push(element);
    });
    setMedia(newMedia);
    setSelectedItems([]);
  };

  function handleNewMedia(data) {
    const newFiles = data.data.data;
    setMedia({ ...media, data: [...newFiles, ...media.data] });
  }

  function handleSelect() {
    onSelect(multiSelect ? selectedItems : selectedItems[0]);
  }

  const tabs = [
    {
      label: <WindowIcon />,
      content: (
        <MediaGrid
          media={media && media.data}
          isLoading={!media}
          onSelect={handleSelectedItem}
          selectedItems={selectedItems}
        />
      ),
    },
    {
      label: <TableRowsIcon />,
      content: (
        <MediaTable
          media={media}
          isLoading={!media}
          onSelect={handleSelectedItem}
          selectedItems={selectedItems}
        />
      ),
    },
  ];

  const uploadRef = useRef(null);
  const handleUploadClick = () => {
    if (uploadRef !== null && uploadRef.current) {
      uploadRef.current.clickFileInput();
    }
  };

  const labelMap = {
    MEDIA: t("Images"),
    ICON: t("Icons"),
    EXPORT: t("Exports"),
    OFFER_ATTACHMENT: t("Offer Attachments"),
    ORDER_ATTACHMENT: t("Order Attachments"),
    INVOICE_ATTACHMENT: t("Invoice Attachments"),
    0: t("Images"),
    7: t("Icons"),
    6: t("Exports"),
    12: t("Offer Attachments"),
    13: t("Order Attachments"),
    14: t("Invoice Attachments"),
  };

  const settings = [
    {
      label: labelMap["MEDIA"],
      Icon: ImageIcon,
      color: "info",
      onClick: () => handleReload("MEDIA"),
    },
    {
      label: labelMap["ICON"],
      Icon: EmojiEmotionsIcon,
      color: "info",
      onClick: () => handleReload("ICON"),
    },
    {
      label: labelMap["EXPORT"],
      Icon: ImportExportIcon,
      color: "info",
      onClick: () => handleReload("EXPORT"),
    },
    {
      label: labelMap["OFFER_ATTACHMENT"],
      Icon: SummarizeIcon,
      color: "info",
      onClick: () => handleReload("OFFER_ATTACHMENT"),
    },
    {
      label: labelMap["ORDER_ATTACHMENT"],
      Icon: SummarizeIcon,
      color: "info",
      onClick: () => handleReload("ORDER_ATTACHMENT"),
    },
    {
      label: labelMap["INVOICE_ATTACHMENT"],
      Icon: SummarizeIcon,
      color: "info",
      onClick: () => handleReload("INVOICE_ATTACHMENT"),
    },
  ];

  return (
    <Box className="h-full flex flex-row relative overflow-hidden w-full">
      <UploadWrapper
        onUpload={handleNewMedia}
        ref={uploadRef}
        fileType={currentType}
      >
        <Box className="h-full w-full flex flex-col overflow-hidden gap-1">
          <Box className="flex flex-row gap-7 w-full h-full overflow-hidden relative">
            <IconButton
              onClick={handleUploadClick}
              sx={{ position: "absolute", zIndex: 100 }}
              className="bottom-1 left-1"
            >
              <AddCircleIcon />
            </IconButton>
            <Box className="flex w-full h-full relative">
              {!noTypeChange && (
                <Box
                  className="absolute right-0 top-0 z-10 flex items-center pr-2 gap-1"
                  sx={{ height: "48px" }}
                >
                  <Typography>{labelMap[currentType]}</Typography>
                  <SettingsPopover settings={settings} label="File Types" />
                </Box>
              )}
              <ExoTabs tabs={tabs} />
            </Box>

            <MediaInfo
              item={
                selectedItems.length
                  ? selectedItems[selectedItems.length - 1]
                  : {}
              }
              onDelete={handleDeletedMedia}
              onSelect={onSelect && handleSelect}
              onClose={onClose}
              noControls={noControls}
            />
          </Box>
          {errorSelect && (
            <Box
              className="p-2 rounded-lg backdrop-blur-lg"
              sx={{
                backgroundColor: colors.glass,
              }}
            >
              <Typography variant="h5" color="warning">
                {errorSelect}
              </Typography>
            </Box>
          )}

          {media && (
            <Box
              sx={{
                backgroundColor: colors.glass,
                minHeight: "33px",
              }}
              className="w-full relative rounded-lg backdrop-blur-lg"
            >
              <Pagination
                data={media}
                setData={setMedia}
                setLoading={(isLoading) => {
                  if (isLoading) setMedia(null);
                }}
              />
            </Box>
          )}
        </Box>
      </UploadWrapper>
      <Feedback state={alertState} setState={setAlertState} />
    </Box>
  );
};

export default ExoExplorer;
