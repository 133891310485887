import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { formatIBAN, replaceSpaces } from "../../special/formatIban";

const TypeIban = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const [value, setValue] = useState(dataCollection[field.key] || "");

  useEffect(() => {
    if (dataCollection[field.key])
      setValue(formatIBAN(dataCollection[field.key]));
  }, []);

  function handleTextChange(value) {
    updateValidationOnChange(
      [field.key],
      replaceSpaces(value.target.value, "")
    );
    setValue(formatIBAN(value.target.value));
  }

  return (
    <TextField
      sx={{
        gridColumn: field.colspan
          ? `span ${field.colspan} / span ${field.colspan}`
          : "auto",
      }}
      label={field.label}
      value={value}
      onChange={handleTextChange}
      multiline={field.multiline ? true : false}
      error={Boolean(errors[field.key])}
      helperText={errors[field.key]}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{field.unit}</InputAdornment>
        ),
      }}
    />
  );
};

export default TypeIban;
