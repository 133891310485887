import { Box, Typography, useTheme } from "@mui/material";
import CardContainer from "./layout/CardContainer";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { numberToString } from "../special/numberConverter";
import { tokens } from "../../global/theme/tokens";

const PercentCard = ({
  percent,
  amount,
  label,
  size = "150px",
  className,
  title,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <CardContainer sx={{ position: "relative" }} className={className}>
      <Box
        className="px-4 pt-2 "
        sx={{
          width: size,
        }}
      >
        <CircularProgressbar
          value={percent}
          maxValue={1}
          text={`${numberToString(percent * 100, 1)}%`}
          circleRatio={0.75}
          styles={buildStyles({
            rotation: 1 / 2 + 1 / 8,
            strokeLinecap: "butt",
            trailColor: colors.glass,
            pathColor: colors.grey[700],
            textColor: colors.text,
          })}
        />
      </Box>
      <Box>
        {title && <Typography variant="h6">{title}</Typography>}
        <Typography variant="h3">
          {label} {amount}
        </Typography>
      </Box>
    </CardContainer>
  );
};

export default PercentCard;
