import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { searchResultMap } from "./searchResultsMap";
import { Navigate, useNavigate } from "react-router-dom";

export const SearchResponseHit = ({ hit, type, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const navigate = useNavigate();

  const Icon = searchResultMap[type].Icon;
  const to = searchResultMap[type].to;

  function handleClick() {
    navigate(to + hit.id);
    onClick();
  }

  return (
    <Box
      onClick={to && handleClick}
      className="p-2 rounded-lg mx-2 flex gap-4 items-center"
      sx={{
        bgcolor: colors.bgInput,
        ":hover": {
          cursor: to ? "pointer" : "default",
          bgcolor: colors.selected,
        },
      }}
    >
      <Box
        className="rounded-lg w-14 h-14 flex justify-center items-center"
        sx={{
          backgroundColor: colors.card,
          minWidth: "56px",
          minHeight: "56px",
        }}
      >
        {hit.image ? (
          <img className="w-full h-full object-contain" src={hit.image} />
        ) : (
          <Icon
            sx={{
              fontSize: "30px",
              color: colors.primary[500],
            }}
          />
        )}
      </Box>
      <Typography variant="h5" sx={{ fontWeight: 500 }}>
        {hit.label} <Typography variant="h6">{hit.subLabel}</Typography>
      </Typography>
    </Box>
  );
};
