import { Box, Typography } from "@mui/material";
import { renderHours } from "../../../../scenes/time-tracking/worktime";

const TimeHolder = ({
  timeInMinutes,
  sx = {},
  className,
  onMouseDown = () => {},
  onTouchStart = () => {},
  type,
}) => {
  return (
    <Box
      onMouseDown={(e) => {
        onMouseDown(e, type);
      }}
      onTouchStart={(e) => {
        onTouchStart(e, type);
      }}
      className={`${className} z-20`}
      sx={{
        ...sx,
      }}
    >
      <Typography textAlign="center" className="monospace">
        {renderHours(timeInMinutes)}
      </Typography>
    </Box>
  );
};

export default TimeHolder;
