import { InputLabel, MenuItem, FormControl, Select, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import TypeSkeleton from "./TypeSkeleton";
import FormHelperText from "../FormHelperText";
import { useTranslation } from "react-i18next";

const TypeProductCategory = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "productcategories",
      method: API_GET,
      onResponse: setCategories,
      onLoading: setIsLoading,
    });
  }, [config]);

  function handleSelect(e) {
    if (e.target.value.includes(undefined)) {
      updateValidationOnChange(field.key, []);
      return;
    }
    updateValidationOnChange(field.key, e.target.value);
  }

  return (
    <>
      {isLoading ? (
        <TypeSkeleton />
      ) : (
        <FormControl fullWidth>
          <InputLabel id="category-label">{field.label}</InputLabel>
          <Select
            name="productCategoriesId"
            labelId="category-label"
            label={field.label}
            error={Boolean(errors[field.key])}
            value={dataCollection[field.key] ? dataCollection[field.key] : []}
            onChange={handleSelect}
            multiple // added to enable multi-select
          >
            <MenuItem>{t("No Category")}</MenuItem>
            {categories.data.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            error={Boolean(errors[field.key])}
            helperText={errors[field.key]}
          />
        </FormControl>
      )}
    </>
  );
};

export default TypeProductCategory;
