import { Box } from "@mui/material";
import React, { useState } from "react";
import useFetch from "../../api/useFetch";
import { StorageDisk } from "./network-storage/StorageDisk";
import { NetworkStorageForm } from "./network-storage/NetworkStorageForm";
import ExoDialog from "../../components/exo/ExoDialog";
import StorageIcon from "@mui/icons-material/Storage";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import { useTranslation } from "react-i18next";
import { updateOrCreate } from "../../components/special/updateOrCreate";

export const NetworkStorage = ({ onFeedback }) => {
  const { t } = useTranslation();

  const { apiData, setApiData } = useFetch(`network-storages`);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(null);

  function handleResponse(data) {
    setApiData(updateOrCreate(apiData, data));
  }

  function handleCloseForm() {
    setOpen(false);
    setEdit(null);
  }

  function handleDelete(itemToDelete) {
    const newItems = apiData.data.filter((obj) => obj.id !== itemToDelete.id);
    setApiData({ ...apiData, data: newItems });
  }

  return (
    <Box className="flex flex-col w-full">
      <ExoTableToolbar>
        <ExoTableToolbarItem
          onClick={() => setOpen(true)}
          text={t("New Network Storage")}
          icon={<StorageIcon />}
        />
      </ExoTableToolbar>
      <Box className="w-full grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
        {apiData &&
          apiData.data.map((disk) => (
            <StorageDisk
              key={disk.id}
              disk={disk}
              onEdit={setEdit}
              onDelete={handleDelete}
            />
          ))}
        <ExoDialog
          open={open || Boolean(edit)}
          onClose={handleCloseForm}
          limitWidth
        >
          <NetworkStorageForm
            onClose={handleCloseForm}
            onCreate={handleResponse}
            startDataCollection={edit}
          />
        </ExoDialog>
      </Box>
    </Box>
  );
};
