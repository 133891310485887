import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import posthog from "posthog-js";
import { pdfjs } from "react-pdf";
import * as Sentry from "@sentry/react";
// Import translations
import enTranslations from "./language/en.json";
import deTranslations from "./language/de.json";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

i18n.use(initReactI18next).init({
  resources: {
    de: { translation: deTranslations },
    en: { translation: enTranslations },
  },
  lng: "de", // Set the default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

// fetch config
const startSentry = async () => {
  try {
    const response = await fetch("/config.json");
    const config = await response.json();
    if (!config.sentryUrl) return; // sentry
    Sentry.init({
      dsn: config.sentryUrl,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  } catch (error) {
    console.error("Error fetching configuration:", error);
    return {};
  }
};

// fetch config
const startPosthog = async () => {
  try {
    const response = await fetch("/config.json");
    const config = await response.json();
    if (!config.posthogUrl || !config.posthogKey) return;
    posthog.init(config.posthogKey, {
      api_host: config.posthogUrl,
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    });
  } catch (error) {
    console.error("Error fetching configuration:", error);
    return {};
  }
};

startPosthog();
startSentry();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
