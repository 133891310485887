import ExoAvatar from "../exo/ExoAvatar";
import CardContainer from "./layout/CardContainer";
import Typography from "@mui/material/Typography";

const ProjectCard = ({ project }) => {
  return (
    <CardContainer>
      <Typography variant="h6">Project</Typography>
      <ExoAvatar
        type={project.clientType}
        picture={
          project.client
            ? project.client.logo
              ? project.client.logo.temporaryUrl
              : ""
            : "/logo.svg"
        }
        name={
          project.clientType === "contact"
            ? project.client.firstName + " " + project.client.lastName
            : project.client.name
        }
      />
      <Typography variant="h4">{project.name}</Typography>
    </CardContainer>
  );
};

export default ProjectCard;
