import React, { useState } from "react";
import MediaPreview from "../explorer/MediaPreview";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { ExoFilePreview } from "../ExoFilePreview";
import MarqueeText from "../../special/MarqueeText";

export const AttachmentPreview = ({ item }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);

  return (
    <Box className="flex justify-center flex-col items-center">
      <Box
        onClick={() => setOpen(true)}
        className="rounded-md relative overflow-hidden cursor-pointer"
        sx={{
          borderColor: colors.selected,
          borderWidth: "1px",
          borderRadius: "5px",
        }}
      >
        <MediaPreview
          size="100px"
          src={item.thumbnailUrl}
          mimeType={item.mimeType}
        />
      </Box>
      <MarqueeText text={item.title} width="100px" />
      <ExoFilePreview
        open={open}
        mimeType={item.mimeType}
        onClose={() => setOpen(false)}
        src={item.temporaryUrl || item.thumbnailUrl}
      />
    </Box>
  );
};
