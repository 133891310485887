import NewSerialMail from "./NewSerialMail";
import AllSerialMailsTable from "./AllSerialMailsTable";
import Header from "../../components/special/Header";

const SerialMails = () => {
  return (
    <>
      <Header
        title="Serial Mails"
        subtitle="Here you can manage your Serial Mails."
      />
      <AllSerialMailsTable />
    </>
  );
};

export default SerialMails;
