import { Box } from "@mui/material";
import TypeSkeleton from "../../../components/form/form-fields/TypeSkeleton";

export const ProjectManagementWorkPackageSkeleton = () => {
  return (
    <Box>
      <TypeSkeleton />
    </Box>
  );
};
