import React, { useEffect, useState } from "react";
import { ExoPDFViewer } from "./ExoPDFViewer";
import ExoDialog from "./ExoDialog";
import MediaPreview from "./explorer/MediaPreview";

export const ExoFilePreview = ({ open, src, onClose, mimeType }) => {
  useEffect(() => {
    checkImageValidity();
  }, [src]);

  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const checkImageValidity = () => {
    const img = new Image();

    img.onload = () => {
      setIsValidUrl(true);
      setIsChecking(false);
    };

    img.onerror = () => {
      setIsValidUrl(false);
      setIsChecking(false);
    };

    img.src = src;
  };

  return (
    <>
      {mimeType === "application/pdf" ? (
        <ExoPDFViewer open={open} onClose={onClose} src={src} />
      ) : (
        isValidUrl && (
          <ExoDialog open={open} onClose={onClose}>
            <img
              src={src}
              alt="preview"
              className="w-full h-full object-contain"
            />
          </ExoDialog>
        )
      )}
    </>
  );
};
