import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";
import TheatersIcon from "@mui/icons-material/Theaters";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import NotesIcon from "@mui/icons-material/Notes";
import AppsIcon from "@mui/icons-material/Apps";
import ImageIcon from "@mui/icons-material/Image";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExoFilePreview } from "../ExoFilePreview";

export const MediaVisualisation = {
  image: ImageIcon,
  application: AppsIcon,
  text: NotesIcon,
  audio: AudiotrackIcon,
  video: TheatersIcon,
  font: FormatShapesIcon,
};

const MediaPreview = ({
  size = "40px",
  src,
  mimeType = "image",
  onlyIcons,
  type,
  allowPreview,
}) => {
  const { t } = useTranslation();
  const PreviewElement = MediaVisualisation[mimeType.split("/")[0]];
  // check validation of the url

  useEffect(() => {
    checkImageValidity();
  }, [src]);

  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const checkImageValidity = () => {
    const img = new Image();

    img.onload = () => {
      setIsValidUrl(true);
      setIsChecking(false);
    };

    img.onerror = () => {
      setIsValidUrl(false);
      setIsChecking(false);
    };

    img.src = src;
  };

  // preview
  const [openPreview, setOpenPreview] = useState(null);
  function handleOpen() {
    setOpenPreview(true);
  }

  return (
    <>
      {isChecking ? (
        <Skeleton
          sx={{
            transform: "none",
            minWidth: size,
            height: size,
            aspectRatio: "1",
          }}
        />
      ) : (
        <>
          {!onlyIcons ? (
            <>
              {isValidUrl ? (
                <RenderPreview
                  src={src}
                  size={size}
                  type={type}
                  onClick={allowPreview && handleOpen}
                />
              ) : (
                <Box sx={{ minWidth: size, height: size }} className="relative">
                  <Typography
                    textAlign={"center"}
                    variant="h6"
                    className="uppercase absolute w-full h-full flex justify-center items-center backdrop-blur-sm"
                  >
                    {mimeType.split("/")[1] || t("No Preview")}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <PreviewElement style={{ fontSize: size }} />
          )}
        </>
      )}
      {allowPreview && (
        <ExoFilePreview
          open={openPreview}
          src={src}
          mimeType={mimeType}
          onClose={() => setOpenPreview(false)}
        />
      )}
    </>
  );
};

const RenderPreview = ({ src, size, type, onClick }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const filterMap = {
    dark: "brightness(0) saturate(100%) invert(99%) sepia(66%) saturate(10%) hue-rotate(3deg) brightness(116%) contrast(84%)",
    light:
      "brightness(0) saturate(100%) invert(26%) sepia(1%) saturate(4686%) hue-rotate(2deg) brightness(117%) contrast(64%)",
  };

  if (onClick)
    return (
      <Button onClick={onClick} sx={{ padding: 0 }}>
        <img
          src={src}
          className="w-full aspect-square object-contain object-center"
          style={{
            borderRadius: "9.5px",
            width: size,
            filter: "unset",
          }}
        />
      </Button>
    );

  return (
    <img
      src={src}
      className="w-full aspect-square object-contain object-center"
      style={{
        borderRadius: "9.5px",
        width: size,
        filter: "unset",
      }}
    />
  );
};

export default MediaPreview;
