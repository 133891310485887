export function extractFiltersFromApiUrl(url) {
  // Decode the URL first
  const decodedUrl = decodeURIComponent(url);

  // Use a regular expression to match parts that contain brackets
  const filterPattern = /[\?&][^&]*\[[^\]]+\]=[^&]*/g;

  // Extract filters using the regex pattern
  const filters = decodedUrl.match(filterPattern);

  // Join the extracted filters back into a single string, replace leading ? with &, or return an empty string
  return filters ? filters.join("").replace(/^\?/, "&") : "";
}
