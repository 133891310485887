import { tokens } from "../../../global/theme/tokens";
import { useState, useEffect } from "react";
import { useTheme, Box, Typography, IconButton } from "@mui/material";

import CustomVar from "./CustomVar";
import VarEditor from "./VarEditor";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DefaultVar from "./DefaultVar";
import SkeletonCard from "../../cards/SkeletonCard";
import uuid from "react-uuid";

const VarList = ({
  dataCollection,
  isLoading,
  onChange = () => {},
  onVarClick = () => {},
  label,
  type,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  // default vars
  const [defaultVars, setDefaultVars] = useState(null);
  const config = useConfig();
  useEffect(() => {
    if (dataCollection === null) {
      DBRequest({
        config,
        path: `contracts/vars?category=${type ? type : "contract"}`,
        method: API_GET,
        onResponse: handleResponse,
      });
    } else {
      setDefaultVars(dataCollection.defaultVars);
      setCustomVars(dataCollection.customVars || []);
    }
  }, [config]);

  function handleResponse(data) {
    setDefaultVars(data.data);
    onChange("defaultVars", data.data);
  }

  const handleDefaultVarChange = (newElement) => {
    const newDefaultVars = defaultVars.map((element) =>
      element.id === newElement.id ? newElement : element
    );
    onChange("defaultVars", newDefaultVars);
    setDefaultVars([...newDefaultVars]);
  };

  // custom vars
  const [customVars, setCustomVars] = useState([]);
  const [index, setIndex] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);

  const handleNewVar = (element) => {
    var newVar = {};
    newVar.uuid = uuid();
    newVar.displayName = element.displayName;
    newVar.identifier = "%%" + camelCase(element.displayName) + "%%";
    newVar.defaultValue = element.defaultValue;
    newVar.type = element.type;
    setIndex(index + 1);
    setCustomVars([...customVars, newVar]);
    setOpenEditor(false);
    onChange("customVars", [...customVars, newVar]);
  };

  const handleDelVar = (id) => {
    var newCustomVars = customVars.filter((obj) => {
      if (obj.uuid) {
        return obj.uuid !== id;
      } else {
        return obj.id !== id;
      }
    });
    setCustomVars(newCustomVars);
    onChange("customVars", newCustomVars);
  };

  // prepair start vars
  useEffect(() => {
    if (dataCollection && dataCollection.defaultVars)
      setDefaultVars(dataCollection.defaultVars);
    if (dataCollection && dataCollection.customVars)
      setCustomVars(dataCollection.customVars);
  }, [dataCollection]);

  return (
    <Box
      className="p-4 w-96 rounded-lg relative flex gap-2 flex-col overflow-y-auto"
      sx={{ backgroundColor: colors.glass }}
    >
      <Typography textAlign="center" variant="h4">
        {label}
      </Typography>
      {defaultVars ? (
        defaultVars.map((element, index) => (
          <DefaultVar
            key={"defaultVar-" + index}
            element={element}
            onChange={handleDefaultVarChange}
            onClick={onVarClick}
          />
        ))
      ) : (
        <>
          <SkeletonCard height={33} />
          <SkeletonCard height={33} />
          <SkeletonCard height={33} />
          <SkeletonCard height={33} />
        </>
      )}
      {customVars.map((element, index) => (
        <CustomVar
          key={"customVar-" + index}
          element={element}
          onDelVar={handleDelVar}
          onClick={onVarClick}
        />
      ))}

      {openEditor ? (
        <VarEditor
          onCancle={() => setOpenEditor(false)}
          onCreate={handleNewVar}
        />
      ) : (
        <Box className="flex justify-center items-center">
          <IconButton onClick={() => setOpenEditor(true)} variant="contained">
            <AddCircleIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

function camelCase(str) {
  const clearedString = str.replace(/[^\w\s]/gi, "").replace(/[äöüÄÖÜ]/g, "");
  return clearedString
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export default VarList;
