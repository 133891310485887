import { useTranslation } from "react-i18next";
import ModuleLayout from "./ModuleLayout";
import NumberIcon from "./display/NumberIcon";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useEffect, useState } from "react";
import { API_GET, DBRequestModules, useConfig } from "../../api/api";
import GroupsIcon from "@mui/icons-material/Groups";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ApartmentIcon from "@mui/icons-material/Apartment";
import MoneyLabel from "../label/MoneyLabel";
import BallotIcon from "@mui/icons-material/Ballot";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

export const OpenFinancials = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const { t } = useTranslation();

  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    DBRequestModules({
      config,
      path: "open-financials",
      method: API_GET,
      onResponse: setApiData,
    });
  }, [config]);

  const bankAccountsSaldo = apiData && apiData.data.totalBankSaldo;
  const openInvoices = apiData && apiData.data.openInvoices;
  const openSalary = apiData && apiData.data.openSalary;
  const openReceipts = apiData && apiData.data.openReceipts;
  const openOrders = apiData && apiData.data.openOrders;

  const totalBusiness = apiData
    ? bankAccountsSaldo +
      openInvoices +
      openOrders -
      (openReceipts + openSalary)
    : 0;

  const withOpenOrders = totalBusiness + openOrders;

  return (
    <ModuleLayout isLoading={!apiData}>
      <NumberIcon
        label={t("Total Business")}
        Icon={ApartmentIcon}
        color={colors.grey[900]}
        number={totalBusiness}
      />
      <Box className="px-5">
        <table className="w-full">
          <tbody>
            <HeaderRow label={t("Available Cash")} />
            <DataRow
              label={t("Bank Accounts")}
              Icon={AccountBalanceOutlinedIcon}
              number={bankAccountsSaldo}
            />
            <HeaderRow label={t("Expected Revenue")} />
            <DataRow
              label={t("Open Invoices")}
              Icon={ReceiptIcon}
              number={openInvoices}
            />
            <DataRow
              label={t("Open Orders")}
              Icon={BallotIcon}
              number={openOrders}
            />
            <HeaderRow label={t("Open Expenses")} />
            <DataRow
              label={t("Open Receipts")}
              Icon={RequestQuoteIcon}
              number={openReceipts}
            />
            <DataRow
              label={t("Open Salary")}
              Icon={GroupsIcon}
              number={openSalary}
            />
          </tbody>
        </table>
      </Box>
    </ModuleLayout>
  );
};

const DataRow = ({ label, Icon, number, color }) => {
  return (
    <tr>
      <td className="flex gap-2">
        <Icon sx={{ color }} />
        {label}
      </td>
      <td className="text-right">
        <MoneyLabel money={number} />
      </td>
    </tr>
  );
};

const HeaderRow = ({ label }) => {
  return (
    <tr>
      <td colSpan={9999} className="flex gap-2 pt-4">
        <Typography variant="h6">{label}</Typography>
      </td>
    </tr>
  );
};
