import { useTheme, Box, Skeleton, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import PersonRadio from "../radio/PersonRadio";
import DBSearch from "../special/DBSearch";
import { useTranslation } from "react-i18next";
const AddMembers = ({
  members,
  onSubmit,
  filter,
  api,
  onClose = () => {},
  className = "p-2",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  // request
  const [employees, setEmployees] = useState(null);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: api || "users?" + filter,
      method: API_GET,
      onResponse: setEmployees,
    });
  }, [config]);

  // process start data
  const [selectedIds, setSelectedIds] = useState([]);
  useEffect(() => {
    const arrayOfIds = members.map((obj) => obj.id);
    setSelectedIds(arrayOfIds);
  }, [members]);

  // aktions
  function handleChange(employee) {
    if (selectedIds.includes(employee.id)) {
      // remove Employee if already selected
      setSelectedIds(selectedIds.filter((item) => item !== employee.id));
    } else {
      // add Employee if not already selected
      setSelectedIds([...selectedIds, employee.id]);
    }
  }

  // submit
  function handleSubmit() {
    const newMember = employees.data.filter((obj) =>
      selectedIds.includes(obj.id)
    );
    onSubmit(selectedIds, newMember);
  }

  return (
    <Box
      className={`${className} flex flex-col gap-2 h-full overflow-y-auto pr-2`}
      sx={{ height: "calc(90vh - 75px)", width: "650px", maxWidth: "100%" }}
    >
      <Typography variant="h2" textAlign="center" className="py-2">
        {t("Team Members")}
      </Typography>

      {!employees ? (
        <>
          {[...Array(8)].map((element, index) => (
            <Skeleton key={"skeleton-select-employee-" + index} height={204} />
          ))}
        </>
      ) : (
        <>
          {employees.data.map((element, index) => (
            <PersonRadio
              person={element}
              key={"select-employee-" + element.id}
              onClick={(e) => handleChange(e, element)}
              checked={selectedIds.includes(element.id)}
            />
          ))}
        </>
      )}
      <Box className="pt-2 flex flex-row justify-between">
        <Button onClick={onClose} color="error" variant="outlined">
          {t("Cancle")}
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="success">
          {t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default AddMembers;
