import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  numberToString,
  stringToNumber,
} from "../../../special/numberConverter";
import BallotIcon from "@mui/icons-material/Ballot";
import { useTranslation } from "react-i18next";

const EntryEditable = ({ col, item, onChange, onFocus = () => {} }) => {
  const textFieldRef = useRef(null);
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isFocused || item[col.key] !== stringToNumber(value))
      setValue(
        col.number
          ? numberToString(item[col.key], col.decimalPlaces)
          : item[col.key]
      );
  }, [item[col.key]]);

  function handleFocus() {
    if (parseFloat(value) == 0) setValue("");
    onFocus(textFieldRef);
    setIsFocused(true);
  }
  function handleBlur(e) {
    onChange(e);
    setValue(
      numberToString(
        stringToNumber(e.target.value, col.decimalPlaces),
        col.decimalPlaces
      )
    );
    setIsFocused(false);
  }

  function handleChange(e) {
    onChange(e);
    setValue(e.target.value);
  }

  return (
    <Box className="flex flex-col">
      <Box className="w-full flex items-center gap-2">
        {item && item.isOrderPosition && col.key === "name" && (
          <Tooltip title={t("Position from order")}>
            <BallotIcon className="mr-2" />
          </Tooltip>
        )}
        <FormControl fullWidth sx={{ m: 0 }}>
          <Input
            ref={textFieldRef}
            onFocus={handleFocus}
            onBlur={col.number ? handleBlur : () => {}}
            size="small"
            className={col.className}
            id={"pos-input-" + col.key}
            value={value}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">{col.unit}</InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default EntryEditable;
