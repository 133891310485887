import {
  useTheme,
  Box,
  Button,
  Dialog,
  Typography,
  IconButton,
} from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useState, useRef } from "react";
import CodeEditor from "../../components/editor/CodeEditor";
import MailPreviewer from "../../components/preview/MailPreviewer";
import { API_PATCH, DBNoSetData, DBRequest, useConfig } from "../../api/api";
import Loader from "../../components/special/Loader";
import EditTypography from "./EditTypography";
import TextFieldsIcon from "@mui/icons-material/TextFields";

const MailTemplateCodeEditor = ({ open, template, onClose, onSave }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [typographyOpen, setTypographyOpen] = useState(false);

  const [codeEditorData, setCodeEditorData] = useState(template.content);
  const [isSaving, setIsSaving] = useState(false);

  const aceEditorRef = useRef(null);
  const config = useConfig();
  function handleSave() {
    setIsSaving(true);
    var data = {};
    data.content = codeEditorData;
    DBRequest(
      config,
      "templates/" + template.id,
      API_PATCH(data),
      () => {},
      () => {},
      handleSaveFeedback
    );
    onSave(codeEditorData, template.id, typographyData.styleJson);
  }

  function handleSaveFeedback(message, status) {
    setIsSaving(false);
    onClose();
  }

  // typography
  const [typographyData, setTypographyData] = useState(template);

  function handleOpenTypography() {
    var newTypographyData = { ...template, content: codeEditorData };
    setTypographyData(newTypographyData);
    setTypographyOpen(true);
  }

  function handleUpdateTemplate(styleJson) {
    var newTypographyData = { ...typographyData, styleJson: styleJson };
    setTypographyData(newTypographyData);
  }

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "100vw",
          minWidth: "95vw",
          height: "90vh",
          position: "relative",
          backgroundImage: "unset",
        },
      }}
    >
      <Loader active={isSaving} message="Saving" />
      <Box
        className="grid grid-cols-2"
        sx={{
          height: "90vh",
        }}
      >
        <Variables
          className="p-2 col-span-1 row-span-1 "
          vars={template.vars}
          editorRef={aceEditorRef}
        ></Variables>
        <Preview
          className="col-span-1 row-span-2 "
          template={typographyData}
          code={codeEditorData}
          setCode={setCodeEditorData}
        ></Preview>
        <Editor
          onClose={onClose}
          onSave={handleSave}
          className="col-span-1 row-span-1 "
          code={codeEditorData}
          setCode={setCodeEditorData}
          aceEditorRef={aceEditorRef}
        ></Editor>
        <IconButton
          className="top-4 right-4 z-10"
          sx={{
            position: "absolute",
            backgroundColor: colors.primary[500],
            ":hover": {
              backgroundColor: colors.greenAccent[500],
            },
          }}
          onClick={handleOpenTypography}
        >
          <TextFieldsIcon />
        </IconButton>
      </Box>
      {typographyOpen && (
        <Box
          className="w-full h-full absolute top-0 left-0 z-50"
          sx={{
            backgroundColor: colors.primary[500],
          }}
        >
          <EditTypography
            data={typographyData}
            setOpen={setTypographyOpen}
            updateTemplate={handleUpdateTemplate}
          />
        </Box>
      )}
    </Dialog>
  );
};

const Variables = ({ className, vars, editorRef }) => {
  function handleClick(textToInsert) {
    const currentPos = editorRef.current.editor.getCursorPosition();
    const text = editorRef.current.editor.getValue();
    const beforeText = text.substring(
      0,
      editorRef.current.editor.session.doc.positionToIndex(currentPos)
    );
    const afterText = text.substring(
      editorRef.current.editor.session.doc.positionToIndex(currentPos),
      text.length
    );

    editorRef.current.editor.setValue(beforeText + textToInsert + afterText);
    editorRef.current.editor.clearSelection();
    editorRef.current.editor.moveCursorToPosition(currentPos);
  }

  return (
    <Box
      className={className}
      sx={{
        height: "30vh",
      }}
    >
      <Typography variant="h1" textAlign="center">
        Variables
      </Typography>
      <Box
        className="flex flex-col gap-2 overflow-auto"
        sx={{
          height: "calc(30vh - 16px - 46px)",
        }}
      >
        {vars.map((element, index) => (
          <Var
            value={element.value}
            name={element.name}
            onClick={handleClick}
          />
        ))}
      </Box>
    </Box>
  );
};

const Var = ({ name, value, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Button
      onClick={() => onClick(value)}
      sx={{
        backgroundColor: colors.primary[400],
      }}
    >
      <Typography variant="h4">{name}</Typography>
    </Button>
  );
};

const Preview = ({ className, template, code, setCode }) => {
  function handleEditableChange(event) {
    setCode(event.target.innerHTML);
  }

  return (
    <Box
      className={className}
      sx={{
        backgroundColor: "white",
        height: "90vh",
        overflowY: "auto",
      }}
    >
      <MailPreviewer
        noScale={true}
        style={template.styleJson}
        autoscale
        editable
        onChange={handleEditableChange}
      >
        {code}
      </MailPreviewer>
    </Box>
  );
};

const Editor = ({
  className,
  aceEditorRef,
  code,
  setCode,
  onSave,
  onClose,
}) => {
  return (
    <Box
      className={className}
      sx={{
        height: "60vh",
        overflowY: "auto",
        position: "relative",
      }}
    >
      <CodeEditor
        editorRef={aceEditorRef}
        data={code}
        setData={setCode}
        height="60vh"
      />
      <Button
        className="bottom-2 right-2 z-10"
        variant="contained"
        sx={{
          position: "absolute",
        }}
        onClick={onSave}
      >
        Save
      </Button>
      <Button
        className="bottom-2 left-2 z-10"
        sx={{
          position: "absolute",
        }}
        onClick={onClose}
      >
        Close
      </Button>
    </Box>
  );
};

export default MailTemplateCodeEditor;
