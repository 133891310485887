import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const TypePresetVarType = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  function handleChange(e) {
    updateValidationOnChange(field.key, e.target.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={"type-preset-var-type-" + field.key}>
        {field.label}
      </InputLabel>
      <Select
        labelId={"type-preset-var-type-" + field.key}
        value={dataCollection[field.key]}
        label={field.label}
        onChange={handleChange}
      >
        <MenuItem value={"date"}>Date</MenuItem>
        <MenuItem value={"money"}>Money</MenuItem>
        <MenuItem value={"text"}>Text</MenuItem>
      </Select>
    </FormControl>
  );
};

export default TypePresetVarType;
