import {
  Box,
  useTheme,
  FormControl,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { tokens } from "../../global/theme/tokens";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import StyledTextContainer from "../preview/StyledTextContainer";

const ExpandableInfo = ({ name, description, icon = <></> }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [expanded, setExpanded] = useState(false);
  const handleIconClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      sx={{
        backgroundImage: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
        borderRadius: "6px!important",
      }}
    >
      <AccordionSummary
        height={20}
        sx={{
          margin: "0px",
          padding: "0px",
          minHeight: "26px!important",
          backgroundColor: "transparent",
          height: "26px!important",
        }}
        onClick={handleIconClick}
        expandIcon={<ExpandMoreIcon />}
      >
        <Box
          sx={{
            height: "26px",
          }}
          className="px-0 flex flex-row items-center rounded-md gap-2"
        >
          {icon}
          {name}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <StyledTextContainer fullWidth backgroundColor="transparent">
          <Box
            className="py-2 pt-2 px-4 rounded-md"
            sx={{
              padding: "10px 15px!important",
              border: "1px solid " + colors.primary[300],
              marginTop: "10px!important",
            }}
            dangerouslySetInnerHTML={{
              __html: description ? description : "No Description",
            }}
          ></Box>
        </StyledTextContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpandableInfo;
