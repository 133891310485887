import { InputLabel, MenuItem, FormControl, Select, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import TypeSkeleton from "./TypeSkeleton";
import FormHelperText from "../FormHelperText";
import { useTranslation } from "react-i18next";

const TypeAccessRoleId = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const { t } = useTranslation();

  const [accessRoles, setAccessRoles] = useState(null);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "access-roles",
      method: API_GET,
      onResponse: setAccessRoles,
    });
  }, [config]);

  function handleSelectAccessRole(e) {
    updateValidationOnChange(field.key, e.target.value);
  }

  return (
    <>
      {!accessRoles ? (
        <TypeSkeleton />
      ) : (
        <FormControl fullWidth>
          <InputLabel id="accesRoleId-select">{field.label}</InputLabel>
          <Select
            labelId="accesRoleId-select"
            value={dataCollection[field.key] || ""}
            label={field.label}
            onChange={handleSelectAccessRole}
            error={Boolean(errors[field.key])}
            sx={{
              "& .MuiSelect-select": {
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "row",
                gap: "8px",
              },
            }}
          >
            <MenuItem
              value={""}
              className="flex flex-row gap-2 justify-start items-center"
            >
              {t("Unselect")}
            </MenuItem>
            {accessRoles.data.map((element) => (
              <MenuItem
                key={"accesRoleId-" + element.id}
                value={element.id}
                className="flex flex-row gap-2 justify-start items-center"
              >
                {element.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            error={Boolean(errors[field.key])}
            helperText={errors[field.key]}
          />
        </FormControl>
      )}
    </>
  );
};

export default TypeAccessRoleId;
