import { Box } from "@mui/material";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";

import ExoForm from "../exo/ExoForm";
import * as Yup from "yup";
import findDifferences from "../exo/findDifferencesInDataCollection";
import Loader from "../special/Loader";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import StarIcon from "@mui/icons-material/Star";
import ReceiptIcon from "@mui/icons-material/Receipt";

const NewContact = ({ onClose, onCreate, onUpdate, startData, fullWidth }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const fields = [
    {
      label: `${t("Color")} (${t("optional")})`,
      type: "color",
      key: "color",
    },
    {
      label: t("Salutation"),
      type: "text",
      key: "salutation",
    },
    {
      label: t("First Name"),
      type: "text",
      key: "firstName",
    },
    {
      label: t("Last Name"),
      type: "text",
      key: "lastName",
    },
    {
      label: `${t("Company")} (${t("optional")})`,
      type: "company",
      key: "company",
    },
    {
      label: `${t("Website")} (${t("optional")})`,
      type: "text",
      key: "url",
    },
    {
      label: `${t("Position")} (${t("optional")})`,
      type: "text",
      key: "position",
    },
    {
      label: `${t("E-Mails")} (${t("optional")})`,
      type: "nameValueArray",
      key: "emails",
      addLabel: t("Add E-Mail"),
      nameKey: "emailType",
      valueKey: "emailAddress",
      placeholder: {
        name: t("Label"),
        value: t("E-Mail"),
      },
      radioItems: [
        {
          key: "isPrimary",
          Icon: StarIcon,
          label: "Favorite",
        },
        {
          key: "isInvoiceMail",
          Icon: ReceiptIcon,
          label: "Invoice E-Mail",
        },
      ],
    },
    {
      label: `${t("Phonenumbers")} (${t("optional")})`,
      type: "nameValueArray",
      key: "telephoneNumbers",
      addLabel: t("Add phonenumber"),
      nameKey: "phoneType",
      valueKey: "phoneNumber",
      placeholder: {
        name: t("Label"),
        value: t("Phonenumber"),
      },
      radioItems: [
        {
          key: "isPrimary",
          Icon: StarIcon,
          label: "Favorite",
        },
      ],
    },
    {
      label: t("Address"),
      type: "address",
      key: "address",
    },
  ];
  const config = useConfig();
  function handleSubmit(data) {
    setIsLoading(true);
    DBRequest({
      config,
      path: startData && startData.id ? `contacts/${data.id}` : "contacts",
      method:
        startData && startData.id
          ? API_PATCH(findDifferences(startData, data))
          : API_POST(data),
      onResponse: handleResponse,
    });
  }

  function handleResponse(data) {
    setIsLoading(false);
    onClose();
    if (startData && startData.id) {
      onUpdate(data);
    } else {
      onCreate(data);
    }
  }

  const phoneSchema = Yup.object().shape({
    phoneType: Yup.string().required("Phone type is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
  });
  const emailSchema = Yup.object().shape({
    emailType: Yup.string().required("Is required"),
    emailAddress: Yup.string().required("Is required"),
  });

  const validationSchema = Yup.object().shape({
    color: Yup.string().required(t("Color is required")),
    salutation: Yup.string().required(t("Name is required")),
    firstName: Yup.string().required(t("Short Name is required")),
    lastName: Yup.string().required(t("Short Name is required")),
    address: Yup.object().shape({
      street: Yup.string().required(t("Address is required")),
    }),
    telephoneNumbers: Yup.array().of(phoneSchema).nullable(),
    emails: Yup.array().of(emailSchema).nullable(),
  });

  return (
    <Box
      className="p-2 max-w-full overflow-y-auto h-full"
      sx={{ width: fullWidth ? "100%" : "500px" }}
    >
      <Loader active={isLoading} />
      <ExoForm
        noDifferences
        onSubmit={handleSubmit}
        fields={fields}
        validationSchema={validationSchema}
        startDataCollection={startData || { color: "#5e9ac8" }}
        onCancle={onClose}
        header={t("New Contact")}
      />
    </Box>
  );
};

export default NewContact;
