import { Box } from "@mui/material";
import ExoForm from "../../components/exo/ExoForm";
import * as Yup from "yup";
import { API_POST, DBRequest, useConfig } from "../../api/api";
import Feedback from "../../components/special/Feedback";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const NewVacation = ({ onCreate, onCancle }) => {
  const { t } = useTranslation();

  const fields = [
    {
      label: t("Vacation"),
      type: "dateRange",
      key: "vacation",
    },
    {
      label: t("Comment"),
      type: "text",
      key: "comment",
      multiline: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    vacationStart: Yup.string().required(t("Start Date is required")),
    vacationEnd: Yup.string().required(t("End Date is required")),
    comment: Yup.string().required(t("Comment is required")),
  });
  const config = useConfig();

  function handleSubmit(values) {
    const data = {
      type: "vacation",
      comment: values.comment,
      values: JSON.stringify({
        startDate: values.vacationStartDate,
        endDate: values.vacationEndDate,
      }),
    };

    DBRequest({
      config,
      path: "employeerequests/vacation",
      method: API_POST(data),
      onResponse: handleCreation,
      onFeedback: handleAlertOpen,
    });
  }

  function handleCreation(data) {
    onCreate(data.data);
    onCancle();
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <Box className="p-4">
      <ExoForm
        debug
        noDifferences
        fields={fields}
        onSubmit={handleSubmit}
        onCancle={onCancle}
        header={t("New Vacation")}
        validationSchema={validationSchema}
      />
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

export default NewVacation;
