import { useState, useEffect } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
} from "@mui/material";
import FormHelperText from "../FormHelperText";
import LabeledBox from "../../special/LabeledBox";
import { useTranslation } from "react-i18next";
import { DBRequest, useConfig } from "../../../api/api";

const TypeWorkPackage = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
}) => {
  const { t } = useTranslation();

  const phase = dataCollection[field.phaseKey];

  function handleChangeSelect(e) {
    if (e.target.value == "" || !phase)
      updateValidationOnChange(field.key, null);
    phase.workPackages.forEach((workPackage) => {
      if (workPackage.id === e.target.value)
        updateValidationOnChange(field.key, workPackage);
    });
  }

  return (
    <FormControl fullWidth>
      {!phase ? (
        <LabeledBox label={field.label} variant="blocked">
          {t("Select Phase First")}
        </LabeledBox>
      ) : (
        <>
          <InputLabel id={field.key}>{field.label}</InputLabel>
          <Select
            labelId={field.key}
            value={
              dataCollection[field.key] ? dataCollection[field.key].id : ""
            }
            label={field.label}
            onChange={handleChangeSelect}
            error={Boolean(errors[field.key])}
            sx={{
              "& .MuiSelect-select": {
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "row",
                gap: "8px",
                padding: "0px 32px 0px 14px",
                height: "53px!important",
              },
            }}
          >
            <MenuItem
              value={""}
              className="flex flex-row gap-2 justify-start items-center"
            >
              {t("No Work Package")}
            </MenuItem>
            {phase &&
              phase.workPackages.map((workPackage) => (
                <MenuItem
                  key={workPackage.id}
                  value={workPackage.id}
                  className="flex flex-row gap-2 justify-start items-center"
                >
                  {workPackage.title}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText
            error={Boolean(errors[field.key])}
            helperText={errors[field.key]}
          />
        </>
      )}
    </FormControl>
  );
};

export default TypeWorkPackage;
