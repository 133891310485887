import { useTheme, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  API_DELETE,
  API_GET,
  API_PATCH,
  DBRequest,
  useConfig,
} from "../../api/api";
import { tokens } from "../../global/theme/tokens";

import StartIcon from "@mui/icons-material/Start";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import Feedback from "../../components/special/Feedback";
import DownloadIcon from "@mui/icons-material/Download";
import TextCard from "../../components/cards/TextCard";
import CardContainer from "../../components/cards/layout/CardContainer";
import PictureNameCard from "../../components/cards/PictureNameCard";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import PopUpContainer from "../../components/popup/PopUpContainer";
import Share from "../../components/special/Share";
import { CardContentInfo } from "../../components/cards/content/CardContentInfo";
import SkeletonCard from "../../components/cards/SkeletonCard";
import { numberToString } from "../../components/special/numberConverter";
import ExoForm from "../../components/exo/ExoForm";
import { useTranslation } from "react-i18next";
import DateLabel from "../../components/label/DateLabel";
import CardGrid from "../../components/cards/layout/CardGrid";
import PdfCard from "../../components/cards/PdfCard";
import FileCard from "../../components/cards/FilesCard";

const ExtendContract = ({ row, onUpdate, onClickEdit, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const [contractBody, setContractBody] = useState("");
  const { t } = useTranslation();
  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  const IconSize = 35;

  const [contract, setContract] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: `contracts/${row.id}`,
      method: API_GET,
      onResponse: handleResponse,
    });
  }, [config, row]);

  function handleResponse(res) {
    const newContractBody = templateVarSwitcher(
      res.data.contractPreset.body,
      res.data.contractVariables
    );
    setContractBody(newContractBody);

    setContract(res.data);
    setIsLoading(false);
  }

  const actions = [
    {
      icon: <PrintIcon />,
      name: t("Print"),
      onClick: () =>
        handlePrint(
          contract.unsignedContract
            ? contract.unsignedContract.temporaryUrl
            : ""
        ),
      hidden: !contract.unsignedContract,
    },
    {
      icon: <ShareIcon />,
      name: t("Share"),
      onClick: () => setOpenShare(true),
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  // share
  const [openShare, setOpenShare] = useState(false);
  function handleCloseShare() {
    setOpenShare(!openShare);
  }

  // print
  function handlePrint(pdfUrl) {
    // Open a new window or iframe with the PDF URL
    const printWindow = window.open(pdfUrl, "_blank");

    // Wait for the PDF to load
    printWindow.onload = () => {
      // Trigger the print dialog
      printWindow.print();
    };
  }

  function handleDelete() {
    DBRequest({
      config,
      path: `contracts/${contract.id}`,
      method: API_DELETE,
      onFeedback: handleFeedback,
    });
  }

  function handleFeedback(type, message) {
    onDelete(row);
  }

  const pdfFiles = [
    {
      label: t("Contract"),
      icon: <DownloadIcon />,
      url:
        contract &&
        contract.unsignedContract &&
        contract.unsignedContract.temporaryUrl,
    },
    {
      label: t("Signed contract"),
      icon: <DownloadIcon />,
      url:
        contract &&
        contract.signedContract &&
        contract.signedContract.temporaryUrl,
    },
  ];
  console.log(contract);

  return isLoading ? (
    <ContractSkeleton />
  ) : (
    <CardGrid>
      <SpeedDialMenu actions={actions} />
      <PdfCard
        className="hidden xl:flex"
        colSpan={1}
        rowSpan={4}
        src={
          contract.unsignedContract && contract.unsignedContract.temporaryUrl
        }
      />
      <TextCard span={3} text={contract.title} />

      <PictureNameCard
        pictureUrl={
          contract.recipient.logo && contract.recipient.logo.thumbnailUrl
        }
        name={
          contract.recipientType === "contact"
            ? contract.recipient.firstName + " " + contract.recipient.lastName
            : contract.recipient.name
        }
        span={1}
        type={contract.recipientType}
        color={contract.recipient.color}
      />

      <CardContainer
        span={2}
        sx={{ flexDirection: "row", justifyContent: "space-evenly" }}
      >
        <CardContentInfo
          title={t("Start")}
          Icon={StartIcon}
          className="monospace"
        >
          <DateLabel date={contract.startDate} />
        </CardContentInfo>
        <CardContentInfo title={t("Last Invoice")} Icon={InsertInvitationIcon}>
          {contract.lastInvoiceDate ? (
            <DateLabel date={contract.lastInvoiceDate} />
          ) : (
            t("No Invoice")
          )}
        </CardContentInfo>
      </CardContainer>

      <CardContainer span={1}>
        <CardContentInfo
          className="monospace"
          title={t("Worked")}
          Icon={AccessTimeIcon}
        >
          {contract.hoursInProject} h
        </CardContentInfo>
      </CardContainer>

      <CardContainer span={1}>
        <TrendingUpIcon sx={{ fontSize: IconSize }} />
        <Box className=" flex flex-col justify-center items-center ">
          <Typography variant="h2" className="monospace">
            {`${numberToString(contract.totalInvoiceAmount, 2)} ${
              config && config.currency.currencySymbol
            }`}
          </Typography>
          <Typography variant="p" sx={{ opacity: "0.5" }}>
            {t("Sales")}
          </Typography>
        </Box>
      </CardContainer>

      <CardContainer span={1}>
        <CardContentInfo
          title={t("Hourly Rate")}
          className="monospace"
          Icon={AvTimerIcon}
        >
          {`${numberToString(
            contract.hoursInProject === 0
              ? 0
              : contract.totalInvoiceAmount / contract.hoursInProject,
            2
          )} ${config && config.currency.currencySymbol}/h`}
        </CardContentInfo>
      </CardContainer>

      <CardContainer span={1}>
        <Edit
          data={{ data: contract }}
          onFeedback={handleAlertOpen}
          onUpdate={onUpdate}
          contract={contract}
        />
      </CardContainer>
      <FileCard files={pdfFiles} />

      <Feedback setState={setAlertState} state={alertState} />
      {!isLoading && (
        <PopUpContainer open={openShare} onClose={handleCloseShare}>
          <Share
            title={"Share Contract " + contract.nr}
            url={
              contract &&
              (contract.unsignedContract
                ? contract.unsignedContract.temporaryUrl
                : "")
            }
          />
        </PopUpContainer>
      )}
    </CardGrid>
  );
};

const Edit = ({ contract, onFeedback, onUpdate }) => {
  const config = useConfig();
  const { t } = useTranslation();

  function handleSave(dataCollection) {
    DBRequest({
      config,
      path: `contracts/${contract.id}`,
      method: API_PATCH(dataCollection),
      onResponse: handleResponse,
      onFeedback: onFeedback,
    });
  }

  function handleResponse(response) {
    onUpdate(response.data);
  }

  const fields = [
    {
      label: t("Status"),
      type: "customSelect",
      key: "status",
      options: [
        { label: t("Sent"), value: 0 },
        { label: t("Accepted"), value: 1 },
        { label: t("Rejected"), value: 2 },
      ],
    },
    {
      label: t("Payment Rhythm"),
      type: "customSelect",
      key: "paymentRhythm",
      options: [
        { label: t("Monthly"), value: 1 },
        { label: t("Bimonthly"), value: 2 },
        { label: t("Quarterly"), value: 3 },
        { label: t("Semiannual"), value: 6 },
        { label: t("Annual"), value: 12 },
      ],
    },
  ];

  const alert = {
    type: "warning",
    message: t(
      "Are you sure you want to change the contract information? A change in the contract requires the renewed consent of the other party. The modified contract will be automatically sent to the other party by mail."
    ),
    submitLabel: t("Yes Make Change"),
  };
  return (
    <Box className="w-full">
      <ExoForm
        startDataCollection={contract}
        fields={fields}
        onSubmit={handleSave}
        alert={alert}
      />
    </Box>
  );
};

const ContractSkeleton = () => {
  return (
    <>
      <Box className="col-span-2 h-full">
        <SkeletonCard />
      </Box>
      <Box className="col-span-3 h-full flex flex-col gap-2">
        <SkeletonCard height={28} />
        <SkeletonCard height={104} />
        <Box className="grid grid-cols-4 gap-2">
          <SkeletonCard height={104} span={2} />
          <SkeletonCard height={104} span={2} />
          <SkeletonCard height={118} />
          <SkeletonCard height={118} />
          <SkeletonCard height={118} />
          <SkeletonCard height={118} />
          <SkeletonCard height={85} span={4} />
        </Box>
      </Box>
    </>
  );
};

const templateVarSwitcher = (html, vars = []) => {
  var code = html;
  for (let i = 0; i < vars.length; i++) {
    code = code.replaceAll(vars[i]["identifier"], vars[i]["value"]);
  }
  return code;
};

export default ExtendContract;
