import { Box, Typography } from "@mui/material";
import { useConfig } from "../../api/api";
import { numberToString } from "../special/numberConverter";

const MoneyLabel = ({
  money,
  symbolKey = "currencySymbol",
  variant = "body",
}) => {
  const config = useConfig();
  return (
    <Typography
      variant={variant}
      className="monospace whitespace-nowrap"
    >{`${numberToString(money)} ${
      config !== null ? config.currency[symbolKey] : ""
    }`}</Typography>
  );
};

export default MoneyLabel;
