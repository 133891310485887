import { Skeleton } from "@mui/material";

const MediaGridItemSkeleton = () => {
  return (
    <Skeleton
      className="aspect-square"
      sx={{
        transform: "unset",
        borderRadius: "12px",
      }}
    />
  );
};

export default MediaGridItemSkeleton;
