import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Popover,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";

export const SettingsPopover = ({
  settings,
  className,
  sx,
  label = "Actions",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "setting" : undefined;
  return (
    <Box className={className} sx={sx}>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="right-start"
        sx={{ paddingLeft: "5px", zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{ bgcolor: colors.card }}
            className="backdrop-blur-lg rounded-lg flex flex-col gap-2 p-2 shadow-md "
          >
            <Typography variant="h6">{t(label)}</Typography>
            {settings.map((setting, index) => (
              <SettingButton
                key={index}
                setting={setting}
                onClose={handleClose}
              />
            ))}
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

const SettingButton = ({ setting, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const Icon = setting.Icon;

  function handleClick() {
    onClose();
    setting.onClick();
  }

  return (
    <Button
      onClick={handleClick}
      className="flex gap-2"
      variant={setting.variant || "text"}
      color={setting.color || "secondary"}
      sx={{
        border: "1px solid transparent",
        ":hover": {
          bgcolor: colors[setting.color || "info"] + "30",
          borderColor: colors[setting.color || "info"],
        },
      }}
    >
      {Icon && <Icon className="opacity-60" />}
      <Typography className="w-full" textAlign="left">
        {setting.label}
      </Typography>
    </Button>
  );
};
