import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../global/theme/tokens";

export const ExoTableToolbar = (props) => {
  return (
    <Box className="gap-4 py-1 mb-2 flex justify-start items-center rounded-lg">
      {props.children}
    </Box>
  );
};

export const ExoTableToolbarItem = ({ text, icon, onClick, className }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className={className}>
      <Button
        onClick={onClick}
        className="flex justify-center items-center gap-1 backdrop-blur-lg "
        variant="contained"
        color="secondary"
      >
        {icon}
        <Typography>{text}</Typography>
      </Button>
    </Box>
  );
};
