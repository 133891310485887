import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import useFetch from "../../api/useFetch";
import { FinApiWebForm } from "./fin-api/FinApiWebForm";
import { API_GET, DBRequest, useConfig } from "../../api/api";

const ExoBankAccounts = ({ onClose, onNewAccounts }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const { apiData } = useFetch("bankaccounts/create-webform");

  function triggerAccountLoad() {
    DBRequest({
      config,
      path: "bankaccounts/webform-status",
      method: API_GET,
      onResponse: handleResponse,
    });
  }

  function handleResponse(data) {
    onNewAccounts(data.data);
    onClose();
  }

  return (
    <Box className="w-full h-full flex justify-center items-center">
      {!apiData ? (
        <span className="loader"></span>
      ) : (
        <FinApiWebForm
          url={config.finApiWebformUrl}
          token={apiData.data.token}
          onComplete={triggerAccountLoad}
          onLoadError={onClose}
          onAbort={triggerAccountLoad}
          onFail={onClose}
        />
      )}
    </Box>
  );
};

export default ExoBankAccounts;
