import { Box, Button } from "@mui/material";
import SingleFileUpload from "../upload/SingleFileUpload";
import { useState } from "react";
import Loader from "./Loader";
import { DBUploadData, useConfig } from "../../api/api";
import ExoForm from "../exo/ExoForm";
import * as Yup from "yup";

const ImportCSVTransactions = ({ onClose, onCreate }) => {
  const [file, setFile] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const config = useConfig();
  function handleImport() {
    const formData = new FormData();
    formData.append(`file`, file); // Use a unique field name for each file
    formData.append(`bankname`, formDataCollection.bankname);
    setIsProcessing(false);

    DBUploadData({
      config,
      path: "transactions/csv",
      formData,
      onFeedback: handleFeedback,
    });
  }

  function handleFeedback(data) {
    setIsProcessing(false);
    if (data.status === 201) window.location.reload(false);
  }

  // form
  const [formDataCollection, setFormDataCollection] = useState({});
  const [isValid, setIsValid] = useState(false);
  const fields = [
    {
      label: "Bank",
      type: "customSelect",
      key: "bankname",
      options: [
        {
          label: "Sparkasse",
          value: "sparkasse",
        },
        {
          label: "Sparda",
          value: "sparda",
        },
        {
          label: "Volksbank",
          value: "volksbank",
        },
      ],
    },
  ];

  function handleFormChange(dataCollection, valid) {
    setIsValid(valid);
    setFormDataCollection(dataCollection);
  }

  return (
    <Box className="flex flex-col p-4 gap-4 overflow-hidden items-center">
      <Loader active={isProcessing} />
      <Box className="w-full">
        <ExoForm
          fields={fields}
          noControls
          onChange={handleFormChange}
          validationSchema={Yup.object().shape({
            bankname: Yup.string().required("Bankname is required"),
          })}
        />
      </Box>
      <Box
        className="flex flex-col items-center justify-center"
        sx={{
          aspectRatio: "1/1.41",
          width: "300px",
        }}
      >
        <SingleFileUpload
          setFile={setFile}
          uploadedMedia={file}
          label="Drop your CSV File here."
        />
      </Box>
      <Box className=" flex justify-between w-full">
        <Button onClick={onClose}>Cancle</Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleImport}
          disabled={!isValid}
        >
          Import
        </Button>
      </Box>
    </Box>
  );
};

export default ImportCSVTransactions;
