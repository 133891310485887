import { useTheme, Box, Dialog, Button } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import RichTextEditor from "../editor/RichTextEditor";
import { useRef, useState } from "react";
import VarList from "../editor/presetEditor/VarList";
import { EditorState, ContentState, Modifier } from "draft-js";
import { useTranslation } from "react-i18next";
import ExoDialog from "../exo/ExoDialog";

const PopUpTextEditor = ({
  startHTML,
  onSave,
  open,
  onClose,
  descriptionVars,
  showVars,
  imageType,
  imageApiFilter,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [text, setText] = useState("");
  const editorRef = useRef(null);

  function prepereSave() {
    onSave(text);
    onClose();
  }

  const handleRTEClickVar = (text) => {
    if (!editorRef.current) return;
    // Get the current editor state
    const editorState = editorRef.current.props.editorState;

    // Get the current selection
    const selection = editorState.getSelection();

    // Create a new ContentState with the pasted text
    const newContentState = ContentState.createFromText(text);

    // Create a new ContentState with the pasted text inserted at the current selection
    const newContentStateWithPaste = Modifier.insertText(
      editorState.getCurrentContent(),
      selection,
      text
    );

    // Create a new EditorState with the pasted text inserted
    const newEditorState = EditorState.push(
      editorState,
      newContentStateWithPaste,
      "insert-characters"
    );

    // Update the editor state
    editorRef.current.props.onChange(newEditorState);

    // Set the selection to the end of the pasted text
    const newSelection = selection.merge({
      anchorOffset: selection.getAnchorOffset() + text.length,
      focusOffset: selection.getAnchorOffset() + text.length,
    });
    const newEditorStateWithSelection = EditorState.forceSelection(
      newEditorState,
      newSelection
    );
    editorRef.current.props.onChange(newEditorStateWithSelection);
  };

  return (
    <ExoDialog open={open} onClose={onClose}>
      <Box
        className="flex flex-col gap-2 overflow-hidden p-4"
        sx={{
          width: "100vw",
          height: "90vh",
        }}
      >
        <Box className="w-full h-full flex flex-row gap-2 overflow-hidden">
          {showVars && (
            <VarList
              dataCollection={descriptionVars}
              onVarClick={handleRTEClickVar}
            />
          )}

          <RichTextEditor
            imageType={imageType}
            imageApiFilter={imageApiFilter}
            allowImages
            setHTML={setText}
            startHTML={startHTML}
            editorRef={editorRef}
          />
        </Box>
        <Box
          sx={{ height: "50px" }}
          className="flex flex-row justify-between items-center px-2 w-full"
        >
          <Button onClick={onClose} variant="outlined" color="error">
            {t("Cancle")}
          </Button>
          <Button onClick={prepereSave} variant="contained" color="success">
            {t("Save")}
          </Button>
        </Box>
      </Box>
    </ExoDialog>
  );
};

export default PopUpTextEditor;
