import { useTheme } from "@emotion/react";
import { tokens } from "../../../global/theme/tokens";
import { useState } from "react";
import { Box, Dialog } from "@mui/material";
import ExoAvatar from "../../exo/ExoAvatar";
import FormHelperText from "../FormHelperText";
import ExoExplorer from "../../exo/ExoExplorer";

const TypeLogo = ({
  field,
  updateValidationOnChange,
  dataCollection,
  errors,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);

  function handleSelect(media) {
    updateValidationOnChange(field.key, media);
    setOpen(false);
  }

  return (
    <Box>
      <Box
        onClick={() => setOpen(true)}
        className="w-full flex justify-start items-center px-3 gap-3"
        sx={{
          height: "53px",
          borderRadius: "10px",
          borderWidth: "1px",
          backgroundColor: colors.bgInput,
          borderColor: colors.primary[300],
          ":hover": {
            borderColor: colors.primary[900],
            cursor: "pointer",
          },
        }}
      >
        <ExoAvatar
          type="company"
          picture={
            dataCollection[field.key]
              ? dataCollection[field.key].temporaryUrl
              : ""
          }
          size="30"
        />
        {field.label}
      </Box>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ width: "80vw", height: "80vh" }}>
          <ExoExplorer onSelect={handleSelect} />
        </Box>
      </Dialog>
    </Box>
  );
};

export default TypeLogo;
