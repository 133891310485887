import { Box, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import { useTranslation } from "react-i18next";
import { ReceiptsTab } from "./receipts/ReceiptsTab";
import { useState } from "react";
import PayrollsTab from "./payrolles/PayrollsTab";

const Expenses = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("Receipts"),
      element: <ReceiptsTab />,
      key: "receipts",
    },
    {
      label: t("Payrolls"),
      element: <PayrollsTab />,
      key: "payrolls",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].key);

  return (
    <>
      <Box className="flex gap-0 md:gap-8 md:items-center justify-between md:justify-start pr-2 md:flex-row flex-col pb-4 md:pb-0">
        <Header title={t("Expenses")} />
        <ToggleButtonGroup
          size="small"
          value={activeTab}
          exclusive
          onChange={(e) => setActiveTab(e.target.value)}
          aria-label="Project Status"
          className="backdrop-blur-lg"
        >
          {tabs.map((tab) => (
            <ToggleButton key={tab.key} value={tab.key}>
              {tab.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      {tabs.map((tab) => (
        <TabElement
          key={tab.key}
          element={tab.element}
          active={tab.key === activeTab}
        />
      ))}
    </>
  );
};

const TabElement = ({ element, active }) => {
  if (!active) return;
  return element;
};

export default Expenses;
