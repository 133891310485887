import { Box, Button, useTheme } from "@mui/material";
import { ProjectWorkPackage } from "./ProjectWorkPackage";
import CardGrid from "../../../cards/layout/CardGrid";
import { tokens } from "../../../../global/theme/tokens";
import { ProjectAddElementButton } from "../ProjectAddElementButton";
import { WorkPackageForm } from "./WorkPackageForm";
import { updateOrCreate } from "../../../special/updateOrCreate";
import { useState } from "react";

const ProjectWorkPackageStack = ({
  phase,
  onPhaseChange,
  editmode,
  project,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(null);

  function handleChange(data) {
    onPhaseChange({
      ...phase,
      workPackages: updateOrCreate(phase.workPackages, data, true),
    });
  }

  function handleDelete(itemToDelete) {
    const newWorkPackages = phase.workPackages.filter(
      (obj) => obj.id !== itemToDelete.id
    );
    onPhaseChange({ ...phase, workPackages: newWorkPackages });
  }
  if (!editmode && phase.workPackages && !phase.workPackages.length) return;
  return (
    <CardGrid className="px-4 py-4 shadow-lg">
      {phase.workPackages.map((workPackage) => (
        <ProjectWorkPackage
          project={project}
          key={workPackage.id}
          workPackage={workPackage}
          onChange={handleChange}
          onDelete={handleDelete}
          editmode={editmode}
          onEdit={setEdit}
        />
      ))}
      {editmode && <ProjectAddElementButton onClick={() => setOpen(true)} />}
      <WorkPackageForm
        phaseId={phase.id}
        open={open || edit}
        onClose={() => {
          setEdit(null);
          setOpen(false);
        }}
        onChange={handleChange}
        startDataCollection={edit}
      />
    </CardGrid>
  );
};

export default ProjectWorkPackageStack;
