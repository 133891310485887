import { useTheme } from "@emotion/react";
import { tokens } from "../../global/theme/tokens";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Box } from "@mui/material";

const SmallProgressBar = ({ size = "30px", className = "", percent = 0.3 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box sx={{ width: size, height: size }} className={className}>
      <CircularProgressbar
        value={percent}
        maxValue={1}
        strokeWidth={25}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor:
            percent >= 1 ? colors.greenAccent[400] : colors.blueAccent[400],
          trailColor: percent < 0 ? colors.redAccent[400] : colors.glass,
        })}
      />
    </Box>
  );
};

export default SmallProgressBar;
