import { useTheme } from "@mui/material";
import { chart, tokens } from "../../global/theme/tokens";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { Box } from "@mui/material";
import nivoChartTheme from "../../global/theme/nivoChartTheme";

const RadialChart = ({ data }) => {
  const theme = useTheme();
  const chartTheme = nivoChartTheme(
    theme.palette.mode,
    theme.palette.colorTheme
  );
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="relative z-20 w-full h-full aspect-square"
      sx={{
        "& rect": {
          fill: "transparent!important",
        },
      }}
    >
      <ResponsiveRadialBar
        data={data}
        theme={chartTheme}
        startAngle={-130}
        endAngle={130}
        innerRadius={0.85}
        padding={0}
        valueFormat=" >-,.0~r"
        margin={{ top: 0, right: 5, bottom: -15, left: 5 }}
        colors={[
          colors.greenAccent[500],
          colors.greenAccent[700],
          colors.greenAccent[800],
          colors.greenAccent[900],
        ]}
        borderColor={{
          from: "color",
          modifiers: [["darker", "0"]],
        }}
        enableTracks={false}
        enableRadialGrid={false}
        enableCircularGrid={false}
        radialAxisStart={null}
        circularAxisOuter={null}
        labelsSkipAngle={0}
        legends={[]}
        animate={false}
      />
    </Box>
  );
};

export default RadialChart;
