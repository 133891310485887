import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";

export const SelectFromNameValueArray = ({
  array,
  nameKey,
  valueKey,
  onSelect,
  label,
}) => {
  const { t } = useTranslation();
  return (
    <Box className="h-full w-full flex flex-col gap-2 items-center">
      <Typography variant="h3" className="pt-5 pb-5" textAlign="center">
        {t(label)}
      </Typography>
      {array &&
        array.map((element) => (
          <ListItem
            key={element[nameKey] + element[valueKey]}
            name={element[nameKey]}
            value={element[valueKey]}
            element={element}
            onClick={onSelect}
          />
        ))}
    </Box>
  );
};

const ListItem = ({ name, value, onClick, element }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Button
      onClick={() => onClick(value, element)}
      sx={{
        border: "1px solid transparent",
        ":hover": {
          bgcolor: colors["info"] + "30",
          borderColor: colors["info"],
        },
      }}
    >
      <Typography textAlign="right" className="pr-4">
        {name}
      </Typography>
      <ArrowForwardIosIcon fontSize="15px" className=" opacity-25" />
      <Typography className="pl-4" textAlign="left">
        {value}
      </Typography>
    </Button>
  );
};
