import React from "react";
import ExoExplorer from "../exo/ExoExplorer";

export const StepFiles = ({
  dataCollection,
  updateValidation,
  updateDataCollection,
  dataKey,
  type,
  filter,
}) => {
  function handleChange(e) {
    updateDataCollection({ [dataKey]: e });
  }
  return (
    <ExoExplorer
      preSelected={dataCollection[dataKey] || []}
      multiSelect
      maxItems={20}
      maxSizeKB={100000}
      noControls
      onSelectionChange={handleChange}
      mediaType={type}
      noTypeChange
      filter={filter}
    />
  );
};
