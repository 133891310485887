import { useTheme, Box } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useState } from "react";
import ExoTable from "../../../components/exo/ExoTable";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../../components/exo/ExoTableToolbar";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import NewReceipt from "../../../components/new/NewReceipt";
import { useTranslation } from "react-i18next";
import DateLabel from "../../../components/label/DateLabel";
import MoneyLabel from "../../../components/label/MoneyLabel";
import ExoDialog from "../../../components/exo/ExoDialog";
import ExtendReceipt from "./ExtendReceipt";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import { updateOrCreate } from "../../../components/special/updateOrCreate";
import useFetch from "../../../api/useFetch";

export const ReceiptsTab = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { apiData, setApiData } = useFetch("receipts");

  const [newReceipt, setNewReceipt] = useState(false);

  const Columns = [
    {
      header: <PriceCheckIcon />, // header of column
      key: "transaction", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "33px", // width of column
      cell: ({ row }) => (
        <Box>
          {row.transactions && row.transactions.length > 0 && (
            <PriceCheckIcon />
          )}
        </Box>
      ),
    },
    {
      header: <RequestPageIcon />, // header of column
      key: "file", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "33px", // width of column
      cell: ({ row }) => <Box>{row.file && <RequestPageIcon />}</Box>,
    },
    {
      header: t("Title"), // header of column
      key: "title", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: false, // hides the column on mobile
    },
    {
      header: t("Date"), // header of column
      key: "receiptDate", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "150px", // width of column
      cell: ({ row }) => (
        <Box className="monospace">
          <DateLabel date={row.receiptDate} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
      hiddenMobile: true, // hides the column on mobile
    },

    {
      header: t("Costs"), // header of column
      key: "amount", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "140px", // width of column
      cell: ({ row }) => (
        <Box
          className="monospace"
          sx={{
            fontWeight: "600",
            color:
              row.amount < 0 ? colors.money.positive : colors.money.negative,
          }}
        >
          <MoneyLabel money={row.amount} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];

  // actions
  const [receiptToEdit, setReceiptToEdit] = useState(null);
  function handleClickEdit(data) {
    setReceiptToEdit({
      ...data,
      receipt: {
        amount: data.amount,
        position: data.position,
        taxAssignment: data.taxAssignment,
        taxRate: data.taxRate,
        costCenter: data.costCenter,
        note: data.note,
      },
    });
    setNewReceipt(true);
  }

  function handleCreateReceipt(newReceipt) {
    setApiData(updateOrCreate(apiData, newReceipt));
    setNewReceipt(false);
  }

  return (
    <>
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Receipt")}
          icon={<NoteAddIcon />}
          onClick={() => {
            setReceiptToEdit(null);
            setNewReceipt(true);
          }}
        />
      </ExoTableToolbar>
      <ExoTable
        data={apiData}
        isLoading={!Boolean(apiData)}
        columns={Columns}
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendReceipt
            row={row}
            onUpdate={onUpdate}
            onClickEdit={handleClickEdit}
            onDelete={onDelete}
          />
        )}
      />

      <ExoDialog open={newReceipt}>
        <NewReceipt
          onCreate={handleCreateReceipt}
          onClose={() => setNewReceipt(false)}
          receipt={receiptToEdit}
          open={newReceipt}
        />
      </ExoDialog>
    </>
  );
};
