import { Box } from "@mui/material";
import ExoAvatar from "../../exo/ExoAvatar";
import { useTranslation } from "react-i18next";
import useFetch from "../../../api/useFetch";
import LabeledBox from "../../special/LabeledBox";
import ExoDialog from "../../exo/ExoDialog";
import { useState } from "react";
import SelectCompany from "../../select/SelectCompany";

const TypeCompany = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
}) => {
  const { t } = useTranslation();
  const { apiData } = useFetch(
    field.filter ? `companies?${field.filter}` : "companies"
  );

  const [open, setOpen] = useState(false);

  const value = dataCollection[field.key];

  function handleSelect(element) {
    setOpen(false);
    updateValidationOnChange(field.key, element);
  }

  return (
    <>
      <LabeledBox
        label={field.label}
        className="w-full"
        onClick={() => setOpen(true)}
      >
        {value && (
          <Box className="flex items-center gap-2">
            <ExoAvatar
              picture={value.logo ? value.logo.thumbnailUrl : ""}
              name={value.name}
              size="30px"
              type={"company"}
            />
            {value.name}
          </Box>
        )}
      </LabeledBox>
      <ExoDialog open={open} onClose={() => setOpen(false)} limitWidth>
        <SelectCompany onSelect={handleSelect} preSelected={value} />
      </ExoDialog>
    </>
  );
};

export default TypeCompany;
