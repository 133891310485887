import { Box, Dialog, IconButton, Slide, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

const ExoDialog = ({
  children,
  open,
  z = "50",
  onClose,
  className,
  limitWidth,
  maxWidth = "800px",
  noBlur,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Dialog
      open={Boolean(open)}
      TransitionComponent={Transition}
      fullScreen
      sx={{
        "& .MuiPaper-root": {
          bgcolor: "transparent",
          backgroundImage: "none",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
        },
      }}
    >
      {onClose && (
        <Box
          sx={{ bgcolor: colors.card }}
          className="px-4 py-2 flex flex-row-reverse"
        >
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          height: onClose ? "calc(100% - 53px)" : "100%",
          maxWidth: limitWidth ? maxWidth : "auto",
          margin: "auto",
        }}
        className={"p-2 w-full relative " + className}
      >
        {children}
      </Box>
    </Dialog>
  );
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ExoDialog;
