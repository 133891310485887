import { Box, Dialog, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../global/theme/tokens";
import CardGrid from "../../components/cards/layout/CardGrid";
import SkeletonCard from "../../components/cards/SkeletonCard";
import { useEffect, useState } from "react";
import { API_DELETE, API_GET, DBRequest, useConfig } from "../../api/api";
import TextCard from "../../components/cards/TextCard";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import Feedback from "../../components/special/Feedback";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoneyCard from "../../components/cards/MoneyCard";
import PercentCard from "../../components/cards/PercentCard";
import MoneyLabel from "../../components/label/MoneyLabel";
import ImagesCard from "../../components/cards/ImagesCard";
import ExoAvatar from "../../components/exo/ExoAvatar";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import useFetch from "../../api/useFetch";
import NewService from "../../components/new/NewService";
import ExoDialog from "../../components/exo/ExoDialog";

const ExtendService = ({ row, onUpdate, onClickEdit, onDelete, skr03 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`services/${row.id}`);

  const [open, setOpen] = useState(false);
  const [startData, setStartData] = useState(null);

  // menu
  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: handleEdit,
    },
    {
      icon: <ContentCopyIcon />,
      name: t("Copy"),
      onClick: handleCopy,
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  function handleEdit() {
    setStartData(apiData.data);
    setOpen(true);
  }

  function handleCopy() {
    const dataCopied = {};
    dataCopied.name = apiData.data.name;
    dataCopied.price = apiData.data.price;
    dataCopied.taxRate = apiData.data.taxRate;
    dataCopied.unit = apiData.data.unit;
    dataCopied.image = apiData.data.image;
    dataCopied.description = apiData.data.description;
    var categorieIds = [];
    apiData.data.serviceCategories.forEach((category) => {
      categorieIds.push(category.id);
    });
    dataCopied.serviceCategoryIds = categorieIds;
    setStartData(dataCopied);
    setOpen(true);
  }

  function handleUpdate(data) {
    setOpen(false);
    setApiData({ ...apiData, data });
    onUpdate(data);
  }

  // delete
  function handleDelete() {
    DBRequest({
      config,
      path: `services/${row.id}`,
      method: API_DELETE,
      onFeedback: handleAlertOpen,
    });
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return !apiData ? (
    <ServiceSkeleton />
  ) : (
    <CardGrid>
      <TextCard
        text={
          <>
            <ExoAvatar
              picture={apiData.data.image && apiData.data.image.temporaryUrl}
              icon={<NoPhotographyIcon />}
            />

            {apiData.data.name}
          </>
        }
        span={99}
      />
      <MoneyCard
        amount={apiData.data.price}
        title={`${t("Hourly Rate")} ${t("Netto")}`}
      />
      <MoneyCard
        amount={apiData.data.price * (apiData.data.taxRate / 100 + 1)}
        title={`${t("Hourly Rate")} ${t("Brutto")}`}
      />
      <SpeedDialMenu actions={actions} />
      <Feedback setState={setAlertState} state={alertState} />
      <ExoDialog open={open} limitWidth>
        <NewService
          fullWidth
          onClose={() => setOpen(false)}
          onCreate={handleUpdate}
          startData={startData}
        />
      </ExoDialog>
    </CardGrid>
  );
};
const ServiceSkeleton = () => {
  return (
    <CardGrid>
      <SkeletonCard height={52} span={4} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={191} />
      <SkeletonCard height={156} span={4} />
    </CardGrid>
  );
};

export default ExtendService;
