import { useTranslation } from "react-i18next";
import DataModule from "../../DataModules";
import { Box, Button, Divider, Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";

export const ContractNode = ({ data }) => {
  const { t } = useTranslation();

  const dataModules = [
    {
      type: "text",
      label: t("Description"),
      value: data.description,
    },
    {
      type: "text",
      label: t("Full Time"),
      value: data.fullTime ? t("yes") : t("no"),
    },
    {
      type: "text",
      label: t("Main Job"),
      value: data.mainJob ? t("yes") : t("no"),
    },
    {
      type: "text",
      label: t("Pay Type"),
      value: data.payType ? t("per Hour") : t("flat"),
    },
    {
      type: "date",
      label: t("Start Date"),
      value: data.startDate,
    },
    {
      type: "date",
      label: t("Valid Until"),
      value: data.validUntil ? data.validUntil : "unlimited",
    },
  ];
  return (
    <Box className="w-full @container">
      {dataModules.map((display, index) => (
        <DataModule
          key={index}
          type={display.type}
          label={display.label}
          value={display.value}
        />
      ))}
      <Box className="flex flex-col gap-4 @lg:flex-row pt-2">
        <Button
          variant="contained"
          color="secondary"
          target="_blank"
          disabled={!Boolean(data.unsignedContract)}
          href={
            data.unsignedContract ? data.unsignedContract.temporaryUrl : null
          }
          className="flex items-center gap-2"
        >
          <FileDownloadIcon />
          {t("Contract")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          target="_blank"
          disabled={!Boolean(data.signedContract)}
          href={data.signedContract ? data.signedContract.temporaryUrl : null}
          className="flex items-center gap-2"
        >
          <FileDownloadIcon />
          {t("Signed")}
        </Button>
        <Button
          onClick={() => data.onSign(data)}
          variant="contained"
          color="secondary"
        >
          <FileUploadIcon />
          {t("Upload signed Contract")}
        </Button>
      </Box>

      <Divider className="pt-4" />
    </Box>
  );
};
