import {
  useTheme,
  Box,
  Typography,
  IconButton,
  Dialog,
  Button,
  TextField,
  Skeleton,
} from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import MailPreviewer from "../../components/preview/MailPreviewer";
import { useEffect, useState } from "react";
import {
  API_DELETE,
  API_GET,
  API_POST,
  DBRequest,
  useConfig,
} from "../../api/api";
import MailTemplateCodeEditor from "./MailTemplateCodeEditor";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Loader from "../../components/special/Loader";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const AllSerialMailsTemplates = ({ onFeedback }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [mailTemplates, setMailTemplates] = useState({});
  const [mailTemplateIsLoading, setMailTemplateIsLoading] = useState(true);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  const [editorOpen, setEditorOpen] = useState(false);
  const config = useConfig();
  useEffect(() => {
    DBRequest(
      config,
      "templates/mail",
      API_GET,
      setTemplates,
      setMailTemplateIsLoading,
      onFeedback,
      true
    );
  }, []);

  // new Template
  const [openNewTemplate, setOpenNewTemplate] = useState(false);

  function setNewTemplate(data) {
    var newTemplates = mailTemplates;
    newTemplates.push(data.data);
    setMailTemplates([...newTemplates]);
  }

  function setTemplates(data) {
    setMailTemplates(data.data);
  }

  function handleToggleEditor() {
    setEditorOpen(!editorOpen);
    setCurrentTemplate(null);
  }

  function handleEditTemplate(data) {
    setCurrentTemplate(data);
    setEditorOpen(true);
  }

  // edit
  function handleUpdateTemplates(code, id, style) {
    var newTemplates = mailTemplates;
    mailTemplates.forEach((element, index) => {
      if (element.id == id) {
        newTemplates[index]["content"] = code;
        newTemplates[index]["styleJson"] = style;
        setCurrentTemplate(newTemplates[index]);
      }
    });
  }

  return (
    <Box sx={{ height: "80vh", width: "90vw" }}>
      <Box className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8">
        {!mailTemplateIsLoading ? (
          <>
            {mailTemplates.map((element, index) => (
              <TemplateItem
                onClick={() => handleEditTemplate(element)}
                element={element}
                setMailTemplates={setMailTemplates}
                allTemplates={mailTemplates}
              >
                <MailPreviewer
                  key={element.name + index}
                  style={element.styleJson}
                  autoscale
                >
                  {element.content}
                </MailPreviewer>
              </TemplateItem>
            ))}
          </>
        ) : (
          <>
            {[...Array(2)].map((e, i) => (
              <Skeleton
                key={i}
                className="w-60 h-96"
                sx={{
                  aspectRatio: "0.8",
                  transform: "scale(1)",
                }}
              />
            ))}
          </>
        )}
      </Box>
      <NewTemplate
        open={openNewTemplate}
        setOpen={setOpenNewTemplate}
        setNewTemplate={setNewTemplate}
      />
      {currentTemplate && (
        <MailTemplateCodeEditor
          open={editorOpen}
          template={currentTemplate}
          onClose={handleToggleEditor}
          onSave={handleUpdateTemplates}
        />
      )}
    </Box>
  );
};

const TemplateItem = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  function handleDelItem() {
    var newMailTemplates = [];
    props.allTemplates.forEach((template) => {
      if (template.id !== props.element.id) {
        newMailTemplates.push(template);
      }
    });
    props.setMailTemplates(newMailTemplates);

    DBRequest(config, "templates/" + props.element.id, API_DELETE);
  }

  return (
    <Box
      className="p-3 rounded-lg flex flex-col gap-3 relative"
      sx={{
        backgroundColor: colors.primary[400],
      }}
    >
      <IconButton
        className="top-1 left-1 z-10"
        onClick={handleDelItem}
        color="primary"
        sx={{
          position: "absolute",
          backgroundColor: colors.primary[500],
          ":hover": {
            backgroundColor: colors.greenAccent[500],
          },
        }}
      >
        <DeleteOutlineIcon />
      </IconButton>
      <Box
        className="w-full overflow-auto relative cursor-pointer"
        sx={{
          aspectRatio: "0.8",
        }}
        onClick={props.onClick}
      >
        {props.children}
      </Box>
      <Typography variant="h4" textAlign="center">
        {props.element.name}
      </Typography>
    </Box>
  );
};

const NewTemplate = ({ open, setOpen, setNewTemplate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  function handleCreate() {
    var data = {};
    data.name = name;
    data.templateType = "mail";
    DBRequest(
      "templates",
      API_POST(data),
      setNewTemplate,
      setIsLoading,
      handleFeedback
    );
    setName("");
    setOpen(false);
  }

  function handleFeedback() {
    setIsLoading(false);
  }

  return (
    <Box>
      <Loader message="Creating Template" active={isLoading} />
      <IconButton
        onClick={() => setOpen(true)}
        className=" top-0 right-0 z-10"
        color="primary"
        sx={{
          position: "absolute",
          backgroundColor: colors.primary[500],
          ":hover": {
            backgroundColor: colors.greenAccent[500],
          },
        }}
      >
        <PostAddIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            backgroundImage: "unset!important",
            overflow: "auto",
            borderRadius: "15px",
            maxWidth: "95%",
            minWidth: "500px",
            padding: "16px",
          },
        }}
      >
        <Box className="relative flex flex-col justify-between">
          <Box className="flex flex-col gap-4">
            <Typography variant="h2" textAlign="center">
              New Template
            </Typography>
            <TextField
              className="w-full"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box className="flex flex-row justify-between mt-4">
            <Button onClick={() => setOpen(false)}>Cancle</Button>
            <Button
              variant="contained"
              onClick={handleCreate}
              disabled={name !== "" ? false : true}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AllSerialMailsTemplates;
