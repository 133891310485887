import { Typography } from "@mui/material";
import { IndirectCostCenterIcon } from "../icons/IndirectCostCenterIcon";
import { DirectCostCenterIcon } from "../icons/DirectCostCenterIcon";
import { TimeCostCenterIcon } from "../icons/TimeCostCenterIcon";
import MoneyLabel from "../label/MoneyLabel";
import CardContainer from "../cards/layout/CardContainer";
import { t } from "i18next";
export const costCenterIconMap = {
  INDIRECT_COSTS: <IndirectCostCenterIcon size={"50px"} />,
  DIRECT_COSTS: <DirectCostCenterIcon size={"40px"} />,
  TIME_COSTS: <TimeCostCenterIcon size={"50px"} />,
};

const ExoCostCenter = ({ costCenter, noValue, onClick, share = 0, hidden }) => {
  if (hidden) return;

  const shareAmount =
    costCenter.type === "INDIRECT_COSTS"
      ? costCenter.totalCost * share
      : costCenter.totalCost;

  return (
    <CardContainer
      onClick={() => onClick && onClick(costCenter)}
      sx={{
        cursor: onClick ? "pointer" : "default",
        transition: onClick ? "scale 0.3s ease" : "unset",
        ":hover": {
          scale: onClick ? "1.02" : "1",
        },
      }}
      className="flex flex-col gap-2 justify-center items-center"
    >
      {costCenterIconMap[costCenter.type]}
      <Typography textAlign="center">{costCenter.name}</Typography>
      {!noValue && (
        <>
          <Typography className="monospace" variant="h3" textAlign="center">
            <MoneyLabel money={shareAmount || 0} />
          </Typography>
          {costCenter.type === "INDIRECT_COSTS" && (
            <Typography className="monospace" variant="p" textAlign="center">
              {`${t("of")} `}
              <MoneyLabel money={costCenter.totalCost} />
            </Typography>
          )}
        </>
      )}
    </CardContainer>
  );
};

export default ExoCostCenter;
