import { useEffect, useState } from "react";
import { tokens } from "../../global/theme/tokens";
import { API_GET, DBRequest, DBUploadData, useConfig } from "../../api/api";
import { useTheme, Box, Dialog, Typography, Button } from "@mui/material";
import SingleFileUpload from "../../components/upload/SingleFileUpload";
import Feedback from "../../components/special/Feedback";
import Loader from "../../components/special/Loader";
import ExoAvatar from "../../components/exo/ExoAvatar";
import DBSearch from "../../components/special/DBSearch";
import { useTranslation } from "react-i18next";
import SkeletonCard from "../../components/cards/SkeletonCard";
import ExoDialog from "../../components/exo/ExoDialog";
import Pagination from "../../components/special/Pagination";

const UploadSigendOffer = ({ open, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
    if (type === "success") {
      onClose();
      setOpenFileDrop(false);
    }
  }

  // offers
  const config = useConfig();
  useEffect(() => {
    if (!open || !config) return;
    DBRequest({
      config,
      path: "offers?status[ne][]=1&status[ne][]=2&status[ne][]=3",
      method: API_GET,
      onResponse: handleDataInit,
    });
  }, [open, config]);

  // selected
  const [openFileDrop, setOpenFileDrop] = useState(false);
  const [file, setFile] = useState({});
  const [selectedOfferId, setSelectedOfferId] = useState();
  const [percent, setPercent] = useState(0);
  const [isFinished, setIsFinished] = useState(true);
  const [reloading, setReloading] = useState(true);
  const [apiData, setApiData] = useState([]);

  function handleSelect(offerId) {
    setSelectedOfferId(offerId);
    setOpenFileDrop(true);
  }

  function handleUpload() {
    setIsFinished(false);
    const formData = new FormData();
    if (Object.keys(file).length > 0) formData.append(`file`, file);

    DBUploadData({
      config,
      path: `offers/${selectedOfferId}/signedOffer`,
      formData,
      onProgressChange: setPercent,
      onFinished: setIsFinished,
      onFeedback: handleFeedbackSignedOffer,
    });
  }

  function handleFeedbackSignedOffer(response) {
    setIsFinished(true);
    if (response.status === 200) {
      window.location.reload(false);
      handleFeedback(response.response.data.message, "success");
    } else {
      handleFeedback(response.response.data.message, "error");
    }
  }

  function handleDataInit(data) {
    setApiData(data);
    setReloading(false);
  }

  return (
    <>
      <ExoDialog open={open} onClose={onClose} limitWidth>
        <Box
          className="p-4 w-full h-full flex flex-col overflow-hidden"
          sx={{
            width: "700px",
            maxWidth: "100%",
          }}
        >
          {reloading ? (
            <Box className="flex flex-col gap-2">
              <SkeletonCard height={46} />
              <SkeletonCard height={46} />
              <SkeletonCard height={46} />
              <SkeletonCard height={46} />
              <SkeletonCard height={46} />
            </Box>
          ) : (
            <>
              <Typography textAlign="center" variant="h2" className="pb-4">
                {t("Current Offers")}
              </Typography>
              <Box className="w-full flex flex-col h-full gap-4 overflow-hidden">
                <Box className="overflow-y-auto flex flex-col gap-2 h-full">
                  {apiData &&
                    apiData.data.map((offer, index) => (
                      <Offer
                        key={offer.id}
                        offer={offer}
                        onSelect={handleSelect}
                      />
                    ))}
                </Box>
                <Pagination
                  numberCount={3}
                  data={apiData}
                  setData={handleDataInit}
                  filter={{
                    filter: "status[ne][]=1&status[ne][]=2&status[ne][]=3",
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </ExoDialog>

      <Dialog open={openFileDrop}>
        <Box
          sx={{
            width: "calc(80vh / 1.412 )",
            maxWidth: "100%",
          }}
          className="flex flex-col overflow-hidden gap-2"
        >
          <Box className="p-4 flex  w-full h-full overflow-y-auto">
            <SingleFileUpload
              setFile={setFile}
              uploadedMedia={file}
              label={t("Upload Signed Offer")}
            />
          </Box>

          <Box className="flex flex-row justify-between pt-0 p-4">
            <Button
              onClick={() => setOpenFileDrop(false)}
              variant="outlined"
              color="error"
            >
              {t("Cancle")}
            </Button>
            <Button
              onClick={handleUpload}
              disabled={!Object.keys(file).length}
              variant="contained"
              color="success"
            >
              {t("Upload")}
            </Button>
          </Box>
          <Loader active={!isFinished} />
        </Box>
      </Dialog>

      <Feedback setState={setAlertState} state={alertState} />
    </>
  );
};

const Offer = ({ offer, onSelect }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  var name = "";
  if (offer.recipientType === "company") name = offer.recipient.name;
  if (offer.recipientType === "contact")
    name = offer.recipient.firstName + " " + offer.recipient.lastName;
  return (
    <Box
      className="flex flex-row justify-start items-center gap-4 w-full rounded-lg p-2 cursor-pointer"
      sx={{
        backgroundColor: colors.card,
        ":hover": {
          backgroundColor: colors.selected,
        },
      }}
      onClick={() => onSelect(offer.id)}
    >
      <Box className="monospace">{offer.nr}</Box>
      <ExoAvatar
        picture={offer.recipient.logo && offer.recipient.logo.temporaryUrl}
        type={offer.recipientType}
        name={name}
        size="30px"
      />
      <Typography variant="h4">{offer.title}</Typography>
    </Box>
  );
};

export default UploadSigendOffer;
