import { Box, Dialog } from "@mui/material";
import ExoDialog from "../../components/exo/ExoDialog";
import NewBankAccount from "../../components/new/NewBankAccount";
import { useState } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { API_DELETE, API_GET, DBRequest, useConfig } from "../../api/api";
import useFetch from "../../api/useFetch";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import AddCardIcon from "@mui/icons-material/AddCard";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { BankCard } from "../bank-accounts/BankCard";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ImportCSVTransactions from "../../components/special/ImportCSVTransactions";
import TableChartIcon from "@mui/icons-material/TableChart";

export const BankSettings = () => {
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, setApiData } = useFetch("bankaccounts");

  const [open, setOpen] = useState(false);
  const [openImport, setOpenImport] = useState(false);

  function handleCreatedAccount(newBankAccount) {
    setApiData({
      ...apiData,
      data: [...apiData.data, newBankAccount],
    });
  }

  function handleSyncExistingAccounts() {
    DBRequest({
      config,
      path: "bankaccounts/sync-connected",
      method: API_GET,
      onResponse: setApiData,
    });
  }

  function handleSyncTransactions() {
    DBRequest({
      config,
      path: "transactions/sync",
      method: API_GET,
    });
  }

  function handleRemoveAccount(account) {
    DBRequest({
      config,
      path: `bankaccounts/${account.id}`,
      method: API_DELETE,
      onResponse: () => handleDeletedAccount(account),
    });
  }

  function handleDeletedAccount(account) {
    const updatedAccounts = apiData.data.filter((obj) => obj.id !== account.id);
    setApiData({ ...apiData, data: updatedAccounts });
  }

  return (
    <Box className="w-full">
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("Add Bank Account")}
          icon={<AddCardIcon />}
          onClick={() => setOpen(true)}
        />
        <ExoTableToolbarItem
          text={t("Sync Existing Accounts")}
          icon={<CloudSyncIcon />}
          onClick={handleSyncExistingAccounts}
        />
        <ExoTableToolbarItem
          text={t("Sync Transactions")}
          icon={<CurrencyExchangeIcon />}
          onClick={handleSyncTransactions}
        />
        <ExoTableToolbarItem
          text={t("Import via CSV file")}
          icon={<TableChartIcon />}
          onClick={() => setOpenImport(true)}
        />
      </ExoTableToolbar>
      <Box className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-4">
        {apiData &&
          apiData.data.map((account) => (
            <BankCard
              key={account.id}
              bank={account}
              onRemove={handleRemoveAccount}
            />
          ))}
      </Box>
      <ExoDialog
        open={open}
        limitWidth
        className="flex flex-col justify-center items-center"
      >
        <NewBankAccount
          onClose={() => setOpen(false)}
          onCreate={handleCreatedAccount}
        />
      </ExoDialog>
      <Dialog open={openImport} onClose={() => setOpenImport(false)}>
        <ImportCSVTransactions onClose={() => setOpenImport(false)} />
      </Dialog>
    </Box>
  );
};
