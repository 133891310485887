import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";

const TypeDivider = ({ color, width }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box
      className="m-auto rounded-full"
      sx={{
        backgroundColor: color || colors.primary[500],
        height: width || "2px",
        width: "98%",
      }}
    ></Box>
  );
};
export default TypeDivider;
