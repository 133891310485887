import {
  Alert,
  Box,
  Button,
  Dialog,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../global/theme/tokens";

const ExoAlert = ({ onSubmit, open, onClose, alert, children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <Alert
        severity={alert.type || "warning"}
        className=" max-w-full w-96 flex flex-col items-center gap-2"
        sx={{
          bgcolor: "transparent",
          border: `1px solid ${colors.info}80`,
          ".MuiAlert-icon": {
            margin: 0,
            svg: {
              width: "50px",
              height: "50px",
            },
          },
        }}
      >
        {children ? (
          <Box className="w-full">{children}</Box>
        ) : (
          <Typography className="w-full overflow-x-auto">
            {alert.message}
          </Typography>
        )}

        <Box
          className="flex flex-row justify-between mt-4 w-full gap-6"
          sx={{
            justifyContent: onSubmit ? "space-between" : "center",
          }}
        >
          <Button onClick={onClose} color="error" variant="outlined">
            {alert.cancleLabel || t("Cancle")}
          </Button>
          {onSubmit && (
            <Button variant="contained" color="success" onClick={onSubmit}>
              {alert.submitLabel || t("Submit")}
            </Button>
          )}
        </Box>
      </Alert>
    </Dialog>
  );
};

export default ExoAlert;
