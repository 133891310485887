function updateViaId(arrayOfObjects, updatedObject) {
  if (Object.keys(updatedObject).length === 0) return arrayOfObjects;
  var newArray = [];
  arrayOfObjects.forEach((object, index) => {
    newArray[index] = updatedObject.id === object.id ? updatedObject : object;
  });
  return newArray;
}

export default updateViaId;
