import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";

const DisplayLabel = ({ text }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  if (!text) return;
  return (
    <Typography variant="p" sx={{ color: colors.grey[600] }}>
      {text}:
    </Typography>
  );
};

export default DisplayLabel;
