import { Box, Button, Dialog, Divider, Typography } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import clipboardCopy from "clipboard-copy";
import FeedbackButton from "./FeedbackButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Share = ({ url, title, text = "", linkText }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(null);

  const copyToClipboard = () => {
    clipboardCopy(url)
      .then(() => {
        setCopySuccess(true);
      })
      .catch((error) => {
        setCopySuccess(false);
      });
  };

  function handleDefaultShare() {
    const shareData = {
      title: title,
      text: text,
      url: url,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => {
          console.log("Successfully shared.");
        })
        .catch((error) => {
          console.error("Error sharing:", error);
        });
    } else {
      console.log("Web Share API not supported.");
    }
  }

  function handleWhatsappShare() {
    const encodedText = encodeURIComponent(
      linkText || t("I would like to share a file with you")
    );
    const encodedUrl = encodeURIComponent(url);
    const whatsappUrl = `whatsapp://send?text=${encodedText}%20${encodedUrl}`;

    window.location.href = whatsappUrl;
  }

  function handleLinkedInShare() {
    const encodedUrl = encodeURIComponent(url);
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;

    window.open(linkedInUrl, "_blank");
  }

  function handleFacebookShare() {
    const encodedUrl = encodeURIComponent(url);
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;

    window.open(facebookUrl, "_blank");
  }

  function handleTwitterShare() {
    const encodedText = encodeURIComponent(
      linkText || t("I would like to share a file with you")
    );
    const encodedUrl = encodeURIComponent(url);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;

    window.open(twitterUrl, "_blank");
  }

  function handlePinterestShare() {
    const encodedUrl = encodeURIComponent(url);
    const pinterestUrl = `https://pinterest.com/pin/create/button/?url=${encodedUrl}`;

    window.open(pinterestUrl, "_blank");
  }

  return (
    <Box className="flex flex-col gap-6 justify-center items-center p-2">
      <Typography variant="h4" textAlign="center">
        {title}
      </Typography>
      <AvTimerIcon sx={{ fontSize: "60px" }} />
      <Typography variant="body1" textAlign="center">
        {t("Each link is valid for 24 hours from the time it is created")}
      </Typography>
      <a
        href={url}
        target="_blank"
        className="flex justify-center items-center"
      >
        <Button variant="contained">{t("Preview")}</Button>
      </a>
      <FeedbackButton
        onClick={copyToClipboard}
        isLoading={isLoading}
        success={copySuccess}
      >
        {t("Copy Link")}
      </FeedbackButton>
      <Divider className="w-full" />
      <Box className=" flex flex-row gap-2">
        <ShareButton
          onClick={handleDefaultShare}
          icon={<ShareIcon />}
          title="Default"
        />
        <ShareButton
          onClick={handleWhatsappShare}
          icon={<WhatsAppIcon />}
          title="WhatsApp"
        />
        <ShareButton
          onClick={handleLinkedInShare}
          icon={<LinkedInIcon />}
          title="LinkedIn"
        />
        <ShareButton
          onClick={handleFacebookShare}
          icon={<FacebookIcon />}
          title="Facebook"
        />
        <ShareButton
          onClick={handleTwitterShare}
          icon={<TwitterIcon />}
          title="Twitter"
        />
        <ShareButton
          onClick={handlePinterestShare}
          icon={<PinterestIcon />}
          title="Pinterest"
        />
      </Box>
    </Box>
  );
};

const ShareButton = ({ onClick, icon, title }) => {
  return (
    <Button className="flex flex-col gap-2" onClick={onClick}>
      {icon}
      {title}
    </Button>
  );
};

export default Share;
