import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState } from "react";

const moment = require("moment");

const TypeTime = ({
  field,
  updateValidationOnChange,
  dataCollection,
  errors,
}) => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    if (dataCollection[field.key]) {
      setTime(moment(dataCollection[field.key]));
    }
  }, [dataCollection]);

  // handle input changes
  function handleDateChange(newTime) {
    updateValidationOnChange(field.key, newTime.toISOString());
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        className="w-full"
        onChange={handleDateChange}
        value={time}
        label={field.label}
        ampm={false}
      />
    </LocalizationProvider>
  );
};

export default TypeTime;
