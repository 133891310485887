import { useParams } from "react-router-dom";
import ExtendInvoice from "./extendInvoice";
import { Box } from "@mui/material";

export const ShowInvoice = () => {
  const { id } = useParams();

  return (
    <Box className="flex flex-col h-full w-full overflow-y-auto md:pt-[50px]">
      <ExtendInvoice row={{ id, onUpdate: () => {} }} />
    </Box>
  );
};
