import { useTheme, Box, Typography } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import TaxAccount from "./TaxAccount";

const TaxGroup = ({ taxGroup, currentId, onChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box className="w-full flex flex-col gap-2 ">
      <Typography variant="h2" textAlign="center">
        {taxGroup.groupName}
      </Typography>
      {taxGroup.taxAssignments.map((account) => (
        <TaxAccount
          key={account.id}
          account={account}
          color={colors.colorPool[taxGroup.colorPoolId]}
          currentId={currentId}
          onChange={onChange}
        />
      ))}
    </Box>
  );
};

export default TaxGroup;
