import { Button } from "@mui/material";

const DisplayButton = ({ label, value }) => {
  return (
    <Button variant="outlined" href={value}>
      {label}
    </Button>
  );
};

export default DisplayButton;
