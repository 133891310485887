import { Box, Typography, useTheme } from "@mui/material";
import SavingsIcon from "@mui/icons-material/Savings";
import { tokens } from "../../../global/theme/tokens";
import { ChartsReferenceLine, LineChart } from "@mui/x-charts";
import MoneyLabel from "../../label/MoneyLabel";
import { useEffect, useRef, useState } from "react";
import { numberToString } from "../../special/numberConverter";
import HourLabel from "../../label/HourLabel";
import { useConfig } from "../../../api/api";

const NumberIcon = ({
  label = "DEMO",
  number = 9999,
  Icon = SavingsIcon,
  color,
  isLoading,
  type = "money",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const typeMap = {
    money: {
      element: MoneyLabel,
      props: { money: number },
    },
    hour: {
      element: HourLabel,
      props: { minutes: number },
    },
  };

  const NumberDisplayElement = typeMap[type].element;

  return (
    <Box className="flex flex-row gap-2 p-4 pb-2">
      <Box
        className="rounded-lg w-14 h-14 flex justify-center items-center"
        sx={{
          backgroundColor: colors.card,
          minWidth: "56px",
          minHeight: "56px",
        }}
      >
        <Icon
          sx={{
            fontSize: "30px",
            color: color ? color : colors.primary[500],
          }}
        />
      </Box>
      <Box>
        <Typography textAlign="left" variant="h6">
          {label}
        </Typography>
        <Typography variant="h3" fontWeight={500} className="monospace">
          {isLoading ? (
            numberToString(0)
          ) : (
            <NumberDisplayElement {...typeMap[type].props} />
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default NumberIcon;
