import React, { useEffect, useRef, useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export const PositionText = ({
  data,
  onFocus,
  unit,
  onChange,
  label,
  defaultValue,
  decimalPlaces,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(data || defaultValue);

  useEffect(() => {
    if (data === undefined) onChange(defaultValue);
  }, [defaultValue]);

  const textFieldRef = useRef(null);

  function handleFocus() {
    onFocus(textFieldRef);
  }
  function handleBlur(e) {
    onChange(e.target.value);
  }

  function handleChange(e) {
    setValue(e.target.value);
    onChange(e.target.value);
  }

  return (
    <Box className="flex flex-col">
      <TextField
        label={t(label)}
        value={value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
        }}
      />
    </Box>
  );
};
