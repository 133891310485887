import { useTranslation } from "react-i18next";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../../../api/api";
import ExoDialog from "../../ExoDialog";
import ExoForm from "../../ExoForm";
import * as Yup from "yup";
import { useEffect, useState } from "react";

export const WorkPackageForm = ({
  phaseId,
  open,
  onClose,
  onChange,
  startDataCollection,
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    if (!config) return;
    resetDefaultValues();
  }, [config]);

  function resetDefaultValues() {
    const newDeflaultValues = {
      isTimeTrackable: false,
      alertLimit: config.project.defaultWorkPackageAlertThreshold,
    };

    setDefaultValues(newDeflaultValues);
  }

  const fields = [
    {
      label: t("Icon"),
      key: "icon",
      type: "icon",
    },
    {
      label: t("Name"),
      key: "title",
      type: "text",
    },
    {
      label: `${t("Estimated Time")} (${t("optional")})`,
      key: "estimatedTime",
      type: "hours",
    },
    {
      label: t("Allow Time Tracking"),
      key: "isTimeTrackable",
      type: "checkbox",
    },
    {
      label: `${t("Alert Limit")} (${t("optional")})`,
      key: "alertLimit",
      type: "taxRate",
    },
    {
      label: `${t("Description")} (${t("optional")})`,
      key: "description",
      type: "richText",
    },
  ];

  function handleSubmit(data) {
    const isUpdate = Boolean(data.id);

    const requestData = {
      phase: { id: phaseId },
      ...data,
    };

    DBRequest({
      config,
      path: isUpdate ? `work-packages/${data.id}` : `work-packages`,
      method: isUpdate ? API_PATCH(requestData) : API_POST(requestData),
      onResponse: handleData,
    });
  }

  function handleData(data) {
    onClose();
    onChange(data.data);
  }

  const validationSchema = Yup.object().shape({
    icon: Yup.object().shape({ id: Yup.string().required(t("Is required")) }),
    title: Yup.string().required(t("Is required")),
    isTimeTrackable: Yup.string().required(t("Is required")),
  });

  return (
    <ExoDialog open={open} limitWidth>
      <ExoForm
        header={t("New Work Package")}
        startDataCollection={startDataCollection || defaultValues}
        fields={fields}
        onCancle={onClose}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      />
    </ExoDialog>
  );
};
