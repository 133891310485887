import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { Box, IconButton } from "@mui/material";
import { useRef } from "react";
import BankCard from "../BankCard";
const MultiBankAccount = ({ onBankUpdate, bankAccounts }) => {
  const ref = useRef();

  return (
    <Box className="relative w-full py-5">
      <ResponsiveContainer
        className="py-4"
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 5;
          if (parentWidth <= 1440) currentVisibleSlide = 3;
          if (parentWidth <= 580) currentVisibleSlide = 1;
          let maxSlidesVisible = 5;
          if (bankAccounts.data.length < 3) {
            maxSlidesVisible = 3;
            currentVisibleSlide = 1;
          }

          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={(props) => (
                <BankCard {...props} onBankUpdate={onBankUpdate} />
              )}
              slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              carouselWidth={parentWidth}
              data={bankAccounts.data}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={maxSlidesVisible}
              useGrabCursor
            />
          );
        }}
      />
      <>
        <IconButton
          style={{ position: "absolute", top: "40%", left: 10, zIndex: 10 }}
          size="small"
          color="primary"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton
          style={{
            position: "absolute",
            top: "40%",
            right: 10,
            zIndex: 10,
          }}
          size="small"
          color="primary"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </>
    </Box>
  );
};

export default MultiBankAccount;
