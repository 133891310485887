import React from "react";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { WorkPackageWrapper } from "./WorkPackageWrapper";

export const PhaseWrapper = ({ phase, project, onDeselect }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className="rounded-lg px-2 py-1">
      <Typography variant="h4" className=" pb-4">
        {phase.name}
      </Typography>
      <Box className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
        {phase.workPackages.map((workPackage) => (
          <WorkPackageWrapper
            key={workPackage.id}
            workPackage={workPackage}
            project={project}
            onDeselect={onDeselect}
          />
        ))}
      </Box>
    </Box>
  );
};
