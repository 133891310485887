import { Skeleton } from "@mui/material";

export const ProjectGridSkeletons = ({ n, className }) => {
  return Array(n)
    .fill(null)
    .map((_, index) => (
      <Skeleton
        key={index}
        className={className}
        sx={{
          padding: 0,
          margin: 0,
          height: "53px",
          transform: "unset",
          borderRadius: "10px",
        }}
      />
    ));
};
