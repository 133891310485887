import React, { useState, useEffect, useRef } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import {
  EditorState,
  RichUtils,
  ContentState,
  convertFromHTML,
  Modifier,
  SelectionState,
  genKey,
  KeyBindingUtil,
  ContentBlock,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { List } from "immutable";
//styling
import StyledTextContainer from "../preview/StyledTextContainer";
import { Headings } from "./styling-controls/BlockStyle";
import { Inline } from "./styling-controls/InlineStyle";

import { ImageStyle } from "./styling-controls/ImageStyle";
import { Editor, getDefaultKeyBindingFn } from "contenido";
import ImageElement from "./styling-controls/ImageElement";

const RichTextEditor = ({
  setHTML,
  startHTML,
  editorRef,
  setText = () => {},
  className,
  onFocus = () => {},
  imageType,
  imageApiFilter,
  fontSizeFactor = 1,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { hasCommandModifier } = KeyBindingUtil;

  const [isFocused, setIsFocused] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(startHTML || "")
    );
    return EditorState.createWithContent(contentState);
  });

  useEffect(() => {
    if (!startHTML) return;
    const newContentState = ContentState.createFromBlockArray(
      convertFromHTML(startHTML)
    );
    setEditorState(EditorState.createWithContent(newContentState));
  }, [startHTML]);

  const handleImageResize = (entityKey, newDimensions) => {
    let newEditorState = editorState;

    // Get the current content state
    const currentContentState = newEditorState.getCurrentContent();
    // Get the entity corresponding to the provided entity key
    const entity = currentContentState.getEntity(entityKey);
    // Get the current entity data
    const { type, mutability, data } = entity;

    // Create new entity data with updated width and height
    const newData = {
      ...data,
      width: newDimensions.width + "px",
      height: newDimensions.height + "px",
    };

    // Merge the updated entity data into the current content state
    const updatedContentState = currentContentState.mergeEntityData(
      entityKey,
      newData
    );

    // Push the updated content state to the editor state
    newEditorState = EditorState.push(
      newEditorState,
      updatedContentState,
      "apply-entity"
    );

    // Set the new editor state
    setEditorState(newEditorState);
    prepairHTML(newEditorState);
  };

  useEffect(() => {
    prepairHTML(editorState);
  }, [editorState]);

  function prepairHTML(editState) {
    const rawHTML = stateToHTML(editState.getCurrentContent());
    const regex = /(<([^>]+)>)/gi;
    setText(rawHTML.replace(regex, ""));
    setHTML(rawHTML);
  }

  const toggleBlockType = (blockType) => {
    const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
    setEditorState(
      EditorState.forceSelection(newEditorState, editorState.getSelection())
    );
  };

  const toggleInline = (inlineType) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, inlineType);
    setEditorState(
      EditorState.forceSelection(newEditorState, editorState.getSelection())
    );
  };

  const handleFocus = () => {
    setIsFocused(true);
    onFocus(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    onFocus(false);
  };

  // custom types
  const customBlockRenderer = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "atomic") {
      return {
        component: (props) => (
          <ImageElement
            {...props}
            onResize={handleImageResize}
            isFocused={isFocused}
          />
        ),
        editable: false,
        props: {},
      };
    }
    return null;
  };

  const myKeyBindingFn = (e) => {
    if (e.keyCode === 83 /* `S` key */ && hasCommandModifier(e)) {
      return "save";
    }
    if (e.keyCode === 32) {
      // 32 is the keyCode for the space bar
      return "space";
    }
    return getDefaultKeyBindingFn(e);
  };

  const handleKeyCommand = (command, editorState) => {
    if (command === "save") return "not-handled"; //handleSave
    if (
      command === "space" ||
      command === "split-block" ||
      command === "delete"
    ) {
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();
      const currentBlock = contentState.getBlockForKey(selection.getStartKey());

      if (currentBlock.getType() === "atomic") {
        // Optionally, you could manipulate the editor state here
        const newEditorState = createNewBlockBelow(editorState);
        // Use the new editor state with the updated selection
        setEditorState(newEditorState);
        return "handled";
      }
    }

    // Manually insert space if not handled
    if (command === "space") {
      const newContentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        " "
      );
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-characters"
      );
      setEditorState(newEditorState);
      return "handled"; // We handle space manually
    }

    return "not-handled";
  };

  // Function to create a new block below the current block
  const createNewBlockBelow = (editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const currentBlockKey = selection.getStartKey();

    // Generate a new key for the new block
    const newBlockKey = genKey();

    // Create a new block with an empty string
    const newBlock = new ContentBlock({
      key: newBlockKey,
      type: "unstyled", // You can choose the type of block (e.g., 'unstyled', 'atomic', 'header-one', etc.)
      text: "", // Empty content for the new block
      characterList: List(), // No characters in the new block
    });

    // Split the block to insert the new one after the current block
    const blockMap = contentState.getBlockMap();
    const blocksBefore = blockMap
      .toSeq()
      .takeUntil((_, k) => k === currentBlockKey);
    const blocksAfter = blockMap
      .toSeq()
      .skipUntil((_, k) => k === currentBlockKey)
      .rest();

    // Rebuild the block map with the new block inserted
    const newBlockMap = blocksBefore
      .concat(
        [[currentBlockKey, contentState.getBlockForKey(currentBlockKey)]],
        [[newBlockKey, newBlock]],
        blocksAfter
      )
      .toOrderedMap();

    // Create new content state with the new block map
    const newContentState = contentState.merge({
      blockMap: newBlockMap,
      selectionAfter: selection
        .set("anchorKey", newBlockKey)
        .set("focusKey", newBlockKey)
        .set("anchorOffset", 0)
        .set("focusOffset", 0),
    });

    // Update the editor state
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-fragment"
    );

    // Move the selection to the new block
    return EditorState.forceSelection(
      newEditorState,
      newContentState.getSelectionAfter()
    );
  };

  const defaultStyle = {
    page: {
      size: 794,
    },
    papermargin: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    font: {
      url: "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: "400",
    },
    p: {
      fontSize: `${12 * fontSizeFactor}px`,
      margin: "0px",
      padding: "2px 0px 2px 0px",
      color: colors.grey[900],
      lineHeight: "normal",
    },
    h1: {
      fontSize: `${28 * fontSizeFactor}px`,
      margin: "0px",
      padding: "10px 0px 5px 0px",
      color: colors.grey[900],
      lineHeight: "normal",
    },
    h2: {
      fontSize: `${24 * fontSizeFactor}px`,
      margin: "0px",
      padding: "10px 0px 5px 0px",
      color: colors.grey[900],
      lineHeight: "normal",
    },
    h3: {
      fontSize: `${20 * fontSizeFactor}px`,
      margin: "0px",
      padding: "10px 0px 5px 0px",
      color: colors.grey[900],
      lineHeight: "normal",
    },
    h4: {
      fontSize: `${16 * fontSizeFactor}px`,
      margin: "0px",
      padding: "10px 0px 5px 0px",
      color: colors.grey[900],
      lineHeight: "normal",
    },
    h5: {
      fontSize: `${12 * fontSizeFactor}px`,
      margin: "0px",
      padding: "10px 0px 5px 0px",
      color: colors.grey[900],
      lineHeight: "normal",
    },
    h6: {
      fontSize: `${10 * fontSizeFactor}px`,
      margin: "0px",
      padding: "10px 0px 5px 0px",
      color: "#494a50",
      lineHeight: "normal",
    },
    ul: {
      fontSize: `${12 * fontSizeFactor}px`,
      margin: "0px 0px 0px 20px",
      padding: "0px",
      color: colors.grey[900],
      lineHeight: "normal",
      listStyle: "disc",
    },
    ol: {
      fontSize: `${12 * fontSizeFactor}px`,
      margin: "0px 0px 0px 20px",
      padding: "0px",
      color: colors.grey[900],
      lineHeight: "normal",
      listStyle: "decimal",
    },
    th: {
      fontSize: `${14 * fontSizeFactor}px`,
      fontWeight: "100",
      color: colors.grey[900],
      backgroundColor: "#e7e7e7",
      padding: "5px",
      lineHeight: "normal",
    },
    td: {
      fontSize: `${12 * fontSizeFactor}px`,
      fontWeight: "100",
      color: colors.grey[900],
      backgroundColor: "",
      padding: "5px",
      lineHeight: "normal",
    },
  };

  return (
    <Box className="flex flex-col gap-2 relative w-full h-full overflow-hidden">
      <Toolbar>
        <Inline editorState={editorState} onToggle={toggleInline} />
        <Headings editorState={editorState} onToggle={toggleBlockType} />
        <ImageStyle
          mediaType={imageType}
          filter={imageApiFilter}
          noTypeChange
          editorState={editorState}
          setEditorState={setEditorState}
        />
      </Toolbar>
      <StyledTextContainer
        fullWidth
        backgroundColor="transparent"
        className="flex flex-col h-full w-full"
        styleJson={JSON.stringify(defaultStyle)}
      >
        <Box
          className="h-full w-full "
          sx={{
            maxWidth: "800px",
            padding: "15px!important",
            borderRadius: "10px",
            backgroundColor: colors.bgInput,
            "& .public-DraftEditor-content, & .DraftEditor-editorContainer, & .DraftEditor-root":
              {
                height: "100%",
                minHeight: "300px",
              },
          }}
        >
          <Editor
            editorState={editorState}
            onChange={setEditorState}
            editorRef={editorRef}
            blockRendererFn={customBlockRenderer}
            onFocus={handleFocus}
            onBlur={handleBlur}
            keyBindingFn={myKeyBindingFn}
            handleKeyCommand={handleKeyCommand}
          />
        </Box>
      </StyledTextContainer>
    </Box>
  );
};

const Toolbar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box
      className="rounded-lg p-2 flex flex-row justify-start items-center sticky top-0 z-10 gap-2 overflow-x-auto w-full overflow-y-hidden"
      sx={{ backgroundColor: colors.bgInput, minHeight: "53px" }}
    >
      {props.children}
    </Box>
  );
};

export default RichTextEditor;
