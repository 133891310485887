import { useTheme, Box, Button, Typography } from "@mui/material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CreateIcon from "@mui/icons-material/Create";
import { tokens } from "../../global/theme/tokens";
import { useEffect, useRef, useState } from "react";
import NewOrderInvoice from "./NewOrderInvoice";
import NewCustomInvoice from "./NewCustomInvoice";
import ExoDialog from "../exo/ExoDialog";
import { ExoVariants } from "../exo/ExoVariants";
import { useTranslation } from "react-i18next";

const NewInvoice = ({
  open,
  onClose,
  invoices,
  setInvoices,
  startData,
  type,
  onUpdate = () => {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const variantsRef = useRef(null);

  async function handleClose() {
    await variantsRef.current.reset();
    onClose();
  }

  useEffect(() => {
    if (variantsRef.current) variantsRef.current.set(type);
  }, [type, open]);

  function checkInvoiceType() {
    if (variantsRef.current) {
      if (startData) {
        variantsRef.current.set("custom");
      } else {
        variantsRef.current.reset();
      }
    }
  }

  const variants = [
    {
      label: t("Custom"),
      Icon: CreateIcon,
      key: "custom",
      content: (
        <NewCustomInvoice
          onUpdate={onUpdate}
          invoices={invoices}
          setInvoices={setInvoices}
          onClose={handleClose}
          startData={startData}
        />
      ),
    },
    {
      label: t("Orderbased"),
      Icon: DocumentScannerIcon,
      key: "order",
      content: (
        <NewOrderInvoice
          onUpdate={onUpdate}
          invoices={invoices}
          setInvoices={setInvoices}
          onClose={handleClose}
        />
      ),
    },
  ];

  return (
    <ExoDialog open={open}>
      <ExoVariants
        ref={variantsRef}
        variants={variants}
        onLoaded={checkInvoiceType}
      />
    </ExoDialog>
  );
};

export default NewInvoice;
