export function timesOverlapping(times, time2) {
  var timesToCheck = [];

  var isOverlapping = null;

  times.forEach((time) => {
    if (time.method && time.method === "delete") return;
    if (time.id === time2.id) return;

    timesToCheck.push(time);
  });

  timesToCheck.forEach((time) => {
    if (isOverlapping) return;

    const overlappingElements = checkIsOverlapping(time, time2);

    if (overlappingElements) {
      isOverlapping = overlappingElements;
    }
  });

  return isOverlapping;
}

export function checkIsOverlapping(time1, time2) {
  let isOverlapping = false;

  // IsInElement does not work!!
  const startsInSelection =
    time1.timeStart > time2.timeStart && time1.timeStart < time2.timeEnd;

  const endesInSelection =
    time1.timeEnd > time2.timeStart && time1.timeEnd < time2.timeEnd;

  const selectionStartsInElement =
    time2.timeStart > time1.timeStart && time2.timeStart < time1.timeEnd;

  const selectionIsInElement =
    time2.timeStart >= time1.timeStart && time2.timeEnd <= time1.timeEnd;

  if (
    startsInSelection ||
    endesInSelection ||
    selectionStartsInElement ||
    selectionIsInElement
  )
    isOverlapping = true;

  return isOverlapping ? { time1, time2 } : false;
}
