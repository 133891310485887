import { useTheme, Box, Skeleton, Typography, Tooltip } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../global/theme/tokens";
import BlockIcon from "@mui/icons-material/Block";
import PaidIcon from "@mui/icons-material/Paid";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../label/MoneyLabel";
import { ExoTableToolbarItem } from "../exo/ExoTableToolbar";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import StyledTextContainer from "../preview/StyledTextContainer";
import RenderHTML from "../preview/RenderHTML";
import { numberToString } from "../special/numberConverter";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const SelectPositions = ({
  positions,
  onSelect,
  isLoading = false,
  allowAll,
  columns = ["name", "amount", "price"],
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  // actions
  const [checkedItems, setCheckedItems] = useState([]);

  const handleClick = (item) => {
    if (!allowAll && (item.status === 1 || item.status === 2)) return;
    var newCheckedItems = [];
    if (checkItemIsSelected(item)) {
      newCheckedItems = checkedItems.filter((obj) => obj.id !== item.id);
    } else {
      newCheckedItems = [...checkedItems, item];
    }

    setCheckedItems(newCheckedItems);
    onSelect(newCheckedItems);
  };

  function checkItemIsSelected(item) {
    const requestedItem = checkedItems.find((obj) => obj.id === item.id);

    return Boolean(requestedItem);
  }

  function selectAll(withOptional = false) {
    if (allowAll) {
      setCheckedItems(positions);
      return onSelect(positions);
    }
    const newSelectedPositions = positions.filter(
      (obj) => obj.status !== 1 && obj.status !== 2 && !obj.isOptional
    );
    setCheckedItems(newSelectedPositions);
    onSelect(newSelectedPositions);
  }

  return (
    <Box className="w-full flex flex-col gap-2">
      {isLoading ? (
        <>
          {[...Array(30)].map((e, i) => (
            <Skeleton
              key={"pos-skeleton-" + i}
              height={37}
              sx={{ transform: "scale(1)", borderRadius: "9999px" }}
            />
          ))}
        </>
      ) : (
        <>
          <ExoTableToolbarItem
            text={t("Select everything except optional")}
            icon={<DoneAllIcon />}
            onClick={selectAll}
          />
          {positions.map((element) => (
            <Position
              allowAll={allowAll}
              key={"pos-" + element.id}
              element={element}
              onClick={handleClick}
              selected={checkItemIsSelected(element)}
              columns={columns}
            />
          ))}
        </>
      )}
    </Box>
  );
};

const Position = ({ element, onClick, selected, allowAll, columns }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  return (
    <Box
      onClick={() => onClick(element)}
      className="py-2 px-4 flex justify-between items-center rounded-lg"
      sx={{
        backgroundColor: selected ? colors.selected : colors.bgInput,
        cursor: !allowAll && element.status ? "not-allowed" : "pointer",
      }}
    >
      {columns.includes("name") && <PositionName element={element} />}
      <Box className="flex items-center gap-0">
        {columns.includes("amount") && !element.isGroup && (
          <Box
            className="monospace text-right min-w-fit flex items-start h-full w-28"
            sx={{
              textDecoration:
                !allowAll && (element.status == 1 || element.status == 2)
                  ? "line-through"
                  : "none",
            }}
          >
            {`${numberToString(element.amount)} ${element.unit || ""}`}
          </Box>
        )}
        {columns.includes("price") && !element.isGroup && (
          <PositionPrice element={element} allowAll={allowAll} />
        )}
      </Box>
    </Box>
  );
};

export const PositionName = ({ element }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  return (
    <Box className="block">
      <Box className="flex flex-row justify-between">
        <Typography className="flex gap-2">
          {element.isOptional && "("}
          {element.name}
          {element.isOptional && ")"}
        </Typography>
        <Box className="flex items-center gap-2">
          {element.deliveredAt && (
            <Tooltip title={t("delivered")}>
              <LocalShippingIcon sx={{ color: colors.greenAccent[500] }} />
            </Tooltip>
          )}
          {element.status == 1 && (
            <Tooltip title={t("settled")}>
              <PaidIcon sx={{ color: colors.greenAccent[500] }} />
            </Tooltip>
          )}
          {element.status == 2 && (
            <Tooltip title={t("cancled")}>
              <BlockIcon sx={{ color: colors.redAccent[500] }} />
            </Tooltip>
          )}
        </Box>
      </Box>

      <Box className="flex">
        {element.image && (
          <img
            src={element.image.thumbnailUrl}
            className="w-20 object-contain object-top hidden md:block"
          />
        )}
        {element.description && (
          <StyledTextContainer
            fullWidth
            backgroundColor="transparent"
            className="pl-2"
          >
            <RenderHTML HTML={element.description} />
          </StyledTextContainer>
        )}
      </Box>
    </Box>
  );
};

const PositionPrice = ({ element, allowAll }) => {
  return (
    <Box
      className="monospace min-w-fit flex items-start justify-end h-full w-28"
      sx={{
        textDecoration:
          !allowAll && (element.status == 1 || element.status == 2)
            ? "line-through"
            : "none",
      }}
    >
      {element.isOptional && "("}
      <MoneyLabel
        money={parseFloat(element.amount * element.price).toFixed(2)}
      />
      {element.isOptional && ")"}
    </Box>
  );
};
export default SelectPositions;
