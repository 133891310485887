import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { motion } from "framer-motion";

export const WPEContainer = ({
  title,
  sx,
  className,
  children,
  framerVariants,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="w-full flex flex-col h-full gap-2 rounded-lg backdrop-blur-lg"
      sx={{
        bgcolor: colors.card,
      }}
    >
      {title && (
        <Box className="w-full px-4 py-2" sx={{ bgcolor: colors.bgInput }}>
          <Typography variant="h4">{title}</Typography>
        </Box>
      )}
      <motion.div
        style={sx}
        className={"p-2 " + className}
        variants={framerVariants}
      >
        {children}
      </motion.div>
    </Box>
  );
};
