import CardContainer from "./layout/CardContainer";
import { useConfig } from "../../api/api";
import NumberIcon from "../dashboard-modules/display/NumberIcon";

const MoneyCard = ({ Icon, amount = 0, title = "Title", span = 1 }) => {
  const config = useConfig();
  return (
    <CardContainer span={span}>
      <NumberIcon
        Icon={Icon}
        number={`${amount} ${config && config.currency.currencySymbol}`}
        label={title}
      />
    </CardContainer>
  );
};

export default MoneyCard;
