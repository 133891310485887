import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Button,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../api/useFetch";
import { tokens } from "../../../global/theme/tokens";
import ExoAlert from "../../exo/ExoAlert";
import ExoDialog from "../../exo/ExoDialog";
import MoneyLabel from "../../label/MoneyLabel";
import LabeledBox from "../../special/LabeledBox";

const TypeAccessFeatures = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [aboSum, setAboSum] = useState(null);
  const [aboDuration, setAboDuration] = useState(
    dataCollection.aboDuration || "annually"
  );

  const [open, setOpen] = useState(false);

  function handleChange(e) {
    updateValidationOnChange(field.key, { [field.key]: e, aboDuration }, true);
  }

  const billingPeriodMap = {
    biannually: t("Biennial billing"),
    annually: t("Annual billing"),
    monthly: t("Monthly billing"),
  };

  function handleSaveMoneyClick(e) {
    setAboDuration("biannually");
    e.stopPropagation();
  }

  return (
    <>
      <LabeledBox
        label={field.label}
        onClick={() => setOpen(true)}
        className="flex flex-col"
      >
        {aboSum && (
          <Box>
            <Box>
              <Box className="flex gap-2 items-end justify-between">
                <Box>
                  <Typography variant="h4" className="monospace">
                    <MoneyLabel money={aboSum[aboDuration]} />
                  </Typography>
                  <Typography variant="h6">{t("per month")}</Typography>
                </Box>
                {aboDuration !== "biannually" && (
                  <Button
                    onClick={handleSaveMoneyClick}
                    color="info"
                    variant="outlined"
                    className="flex gap-2"
                  >
                    <img src="/assets/icons/discount.webp" className="w-10" />
                    <Box className="flex flex-col">
                      <Typography
                        variant="h4"
                        className="monospace"
                        color="info"
                      >
                        {t("save now") + " "}
                        <MoneyLabel
                          money={aboSum[aboDuration] - aboSum.biannually}
                        />
                      </Typography>
                      <Typography variant="h6">
                        {t("with biennial payment")}
                      </Typography>
                    </Box>
                  </Button>
                )}
              </Box>
              <Box className="py-2">
                <Divider />
              </Box>

              <Typography variant="h6" className="pb-2">
                {billingPeriodMap[aboDuration]}
              </Typography>
            </Box>
            {dataCollection[field.key] &&
              dataCollection[field.key].map((feature) => (
                <AccessFeatureLabel key={feature.key} feature={feature} />
              ))}
          </Box>
        )}
      </LabeledBox>

      <ExoDialog open={open} limitWidth onClose={() => setOpen(false)}>
        <AccessFeatureList
          onChange={handleChange}
          selectedFeatures={dataCollection[field.key]}
          onClose={() => setOpen(false)}
          setAboSum={setAboSum}
          aboSum={aboSum}
          setAboDuration={setAboDuration}
          aboDuration={aboDuration}
        />
      </ExoDialog>
    </>
  );
};

const AccessFeatureList = ({
  onChange,
  selectedFeatures,
  onClose,
  setAboSum,
  aboSum,
  aboDuration,
  setAboDuration,
}) => {
  const { t } = useTranslation();
  const { apiData } = useFetch("access-features");

  const aboDurationMap = {
    biannually: 24,
    annually: 12,
    monthly: 1,
  };

  function checkIfSelected(feature) {
    if (!selectedFeatures) return false;
    const index = selectedFeatures.findIndex((obj) => obj.key === feature.key);

    return index !== -1;
  }

  function updateSelectedFeatures(feature) {
    // its the first feature
    if (!selectedFeatures)
      return checkRequirements([feature], aboDuration, feature);
    // its not the first feature
    const index = selectedFeatures.findIndex((obj) => obj.key === feature.key);
    if (index === -1) {
      // add feature
      checkRequirements([...selectedFeatures, feature], aboDuration, feature);
    } else {
      //remove feature
      const newSelectedFeatures = selectedFeatures.filter(
        (obj) => obj.key !== feature.key
      );

      // check if required features are missing after removing
      const featureWithMissingRequirements =
        findElementsWithMissingRequirements(newSelectedFeatures);

      if (!featureWithMissingRequirements.length)
        return handleUpdatedSelectedFeatures(
          newSelectedFeatures,
          aboDuration,
          feature
        );

      setFeaturesToSubmit({
        type: "info",
        submitLabel: t("Remove this function"),
        cancleLabel: t("Do not deselect function"),
        message: t(
          "The function could not be deselected because it is required by another function:"
        ),
        features: newSelectedFeatures,
        duration: aboDuration,
        missingFeatures: featureWithMissingRequirements,
        onSubmit: () =>
          updateSelectedFeatures(featureWithMissingRequirements[0]),
      });
    }
  }

  // Function to find elements with missing requirements
  const findElementsWithMissingRequirements = (newSelection) => {
    return newSelection.filter((element) => {
      // Check if all required elements are in previous selection
      return element.requirements.some(
        (requirement) =>
          !newSelection.some((prevElement) => prevElement.id === requirement.id)
      );
    });
  };

  const [featuresToSubmit, setFeaturesToSubmit] = useState(null);
  function checkRequirements(newSelectedFeatures, duration, newFeature) {
    const { requirements } = newFeature;

    // select if no requirements
    if (!requirements.length)
      return handleUpdatedSelectedFeatures(
        newSelectedFeatures,
        duration,
        newFeature
      );

    // check if there are some requirements that are not selected yet
    const missingElements = requirements.filter(
      (required) =>
        !newSelectedFeatures.some((selected) => selected.id === required.id)
    );

    const missingFeatures = findMatchingEntries(missingElements, apiData.data);

    if (missingElements.length) {
      setFeaturesToSubmit({
        type: "info",
        submitLabel: t("Select missing functions"),
        cancleLabel: t("Do not select function"),
        message: t(
          "To be able to use this function, the following functions are also required:"
        ),
        features: [...newSelectedFeatures, ...missingFeatures],
        duration,
        missingFeatures: missingFeatures,
        onSubmit: handleUpdatedSelectedFeatures,
      });
    } else {
      handleUpdatedSelectedFeatures(newSelectedFeatures, duration, newFeature);
    }
  }

  // Function to find entries in arrayB where the id exists in arrayA
  const findMatchingEntries = (arrayA, arrayB) => {
    const idsInA = arrayA.map((a) => a.id); // Get the ids from arrayA
    return arrayB.filter((b) => idsInA.includes(b.id)); // Filter arrayB based on arrayA's ids
  };

  function handleUpdatedSelectedFeatures(newSelectedFeatures, duration) {
    setFeaturesToSubmit(null);
    let biannually = 0;
    let annually = 0;
    let monthly = 0;
    newSelectedFeatures.forEach((element) => {
      if (!element.availableSeats) {
        biannually += parseFloat(element.price.biannually);
        annually += parseFloat(element.price.annually);
        monthly += parseFloat(element.price.monthly);
      }
    });
    setAboSum({ biannually, annually, monthly });
    onChange(newSelectedFeatures);
  }

  function handleAboDurationChange(e) {
    setAboDuration(e.target.value);
  }

  return (
    <Box className="flex flex-col gap-1 h-full overflow-y-auto">
      <Box className="flex justify-between backdrop-blur-lg sticky top-0 z-10 px-2 py-1">
        <Box>
          <Typography variant="h6">{t("Accounting period")}</Typography>
          <ToggleButtonGroup
            size="small"
            value={aboDuration}
            exclusive
            onChange={handleAboDurationChange}
            aria-label="Project Status"
            className="pb-2 backdrop-blur-lg"
          >
            <ToggleButton value="biannually">{t("Biannually")}</ToggleButton>
            <ToggleButton value="annually">{t("Annually")}</ToggleButton>
            <ToggleButton value="monthly">{t("Monthly")}</ToggleButton>
          </ToggleButtonGroup>
          <Box className="flex flex-col">
            <Typography variant="h5" className="monospace">
              <MoneyLabel
                money={
                  aboSum ? aboSum[aboDuration] * aboDurationMap[aboDuration] : 0
                }
              />
            </Typography>
            <Typography variant="h6">
              {t("Invoicing for the period")}
            </Typography>
          </Box>
        </Box>

        <Box className="flex flex-col gap-2">
          <Box className="flex flex-col">
            <Typography variant="h2" className="monospace" fontWeight={600}>
              <MoneyLabel money={aboSum ? aboSum[aboDuration] : 0} />
            </Typography>
            <Typography variant="h6">{t("per month")}</Typography>
          </Box>
          <Button variant="contained" color="success" onClick={onClose}>
            {t("Continue")}
          </Button>
        </Box>
      </Box>

      {apiData &&
        apiData.data.map((feature) => (
          <AccessFeature
            key={feature.key}
            onClick={updateSelectedFeatures}
            feature={feature}
            selected={checkIfSelected(feature)}
            price={feature.price[aboDuration]}
          />
        ))}
      <ExoAlert
        onSubmit={() =>
          featuresToSubmit.onSubmit(
            featuresToSubmit.features,
            featuresToSubmit.duration
          )
        }
        open={Boolean(featuresToSubmit)}
        alert={featuresToSubmit || {}}
        onClose={() => setFeaturesToSubmit(null)}
      >
        <Typography>{featuresToSubmit && featuresToSubmit.message}</Typography>
        {featuresToSubmit &&
          featuresToSubmit.missingFeatures.map((feature, index) => (
            <Typography key={feature.key} className="py-1">
              <NavigateNextIcon fontSize="12px" className=" mr-2" />
              {t(feature.label)}
            </Typography>
          ))}
      </ExoAlert>
    </Box>
  );
};

const AccessFeature = ({ feature, onClick, selected, price }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation();

  const { label } = feature;

  const availableSeats =
    parseInt(feature.bookedSeatsCount) - parseInt(feature.usedSeatsCount);

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => onClick(feature)}
      sx={{
        bgcolor: selected ? colors.success + "30" : "",
        borderColor: selected ? colors.success : colors.card,
        borderWidth: "1px",
        borderStyle: "solid",
      }}
    >
      <Box className="flex w-full justify-between">
        <Box className="flex justify-start items-center gap-2">
          {selected ? (
            <CheckCircleIcon color="success" />
          ) : (
            <AddCircleOutlineIcon />
          )}
          <Typography>{t(label)}</Typography>
        </Box>
        <Box className="flex flex-col items-end">
          {Boolean(availableSeats <= 0) && (
            <Typography className="monospace">
              <MoneyLabel money={Boolean(availableSeats > 0) ? 0 : price} />
            </Typography>
          )}
          {Boolean(availableSeats > 0) && (
            <Typography>{`${availableSeats} ${t(
              "Available licenses"
            )}`}</Typography>
          )}
        </Box>
      </Box>
    </Button>
  );
};

const AccessFeatureLabel = ({ feature }) => {
  const { t } = useTranslation();
  return (
    <Typography>
      <NavigateNextIcon fontSize="12px" className=" mr-2" />
      {t(feature.label)}
    </Typography>
  );
};

export default TypeAccessFeatures;
