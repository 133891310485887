import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  Alert,
  Dialog,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Loader from "../special/Loader";
import SuccessCheck from "../special/SuccessCheck";
import { useTranslation } from "react-i18next";
import FailureCross from "../special/FailureCross";
import ExoDocumentPreview from "./ExoDocumentPreview";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Cookies from "universal-cookie";
import { updateOrCreate } from "../special/updateOrCreate";
import ExoDraftManager from "./ExoDraftManager";

const cookies = new Cookies();
/*
  const steps = [
    {
      label: "testtitel",
      subLabel: "optional",
      discription: "discription - 1 sdsd", //optional
      noControlls: true, //optional
      valid: true, //optional
      onNext: ()=>{},
      content: ({
        step,
        activeStep,
        setActiveStep,
        dataCollection,
        updateValidation,
        updateDataCollection,
      }) => (
        <Box>
          <h1>{step.label}</h1>
        </Box>
      ),
    },
  ];

  const finalStep = {
    operation: ({dataCollection, handleResponse, setIsLoading}) => { ... }, // your operation with the data
    content: (response) => <h1>YAAAHHH</h1>, // component with final operations and the feedback
  };
*/

const ExoStepper = ({
  onClose = () => {},
  data,
  finalStep = {},
  orientation = "horizontal",
  startDataCollection,
  message,
  debug = false,
  label = "",
  previewDocument,
  className = "",
  startStep = 0,
  draftType,
}) => {
  const [steps, setSteps] = useState(null);
  const [activeStep, setActiveStep] = useState(startStep);
  const [dataCollection, setDataCollection] = useState({});

  useEffect(() => {
    if (data) setSteps(data);

    setDataCollection({ ...startDataCollection });
  }, [data, startDataCollection]);

  function handleStepClick(clickedStep) {
    var stepValideIndex = clickedStep - 1;
    if (stepValideIndex < 0) stepValideIndex = 0;
    if (!steps[stepValideIndex].valid) return;
    setActiveStep(clickedStep);
  }
  function handleReset() {
    setActiveStep(0);
    setDataCollection(startDataCollection || {});
  }

  // document preview
  const containerRef = useRef(null);
  const breakWidth = "1280px";
  const [documentContainerWidth, setDocumentContainerWidth] = useState(0);
  const [openPreview, setOpenPreview] = useState(null);

  useEffect(() => {
    const documentPreviewCookies = cookies.get("documentPreview") === "true";

    if (documentPreviewCookies === undefined) {
      updateOpenPreview(true);
    } else {
      setOpenPreview(documentPreviewCookies);
    }
  }, []);

  useEffect(() => {
    if (!containerRef) return;

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial setup
    handleResize();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);

  function handleResize() {
    if (containerRef.current) {
      const containerHeight = containerRef.current.offsetHeight;
      const containerWidth = containerHeight / 1.4142;
      setDocumentContainerWidth(containerWidth);
    }
  }

  function updateOpenPreview(state) {
    cookies.set("documentPreview", state, {
      path: "/",
    });
    setOpenPreview(state);
  }

  return (
    <Box
      className={
        "flex flex-row h-full w-full overflow-hidden relative gap-2 pb-2" +
        className
      }
    >
      <Box
        className="h-full relative pt-3 flex flex-col gap-2 overflow-hidden px-1"
        sx={{
          transition: "min-width 0.4s",
          minWidth: "100%",
          paddingRight: "0px",
          [`@media (min-width: ${breakWidth})`]: {
            minWidth: openPreview
              ? `calc(100% - ${documentContainerWidth}px)`
              : "100%",
            paddingRight: previewDocument ? "36px" : "",
          },
        }}
      >
        <ExoDraftManager
          className="absolute top-2 right-2 xl:right-10"
          dataCollection={dataCollection}
          draftType={draftType}
          onChange={(insert) =>
            setDataCollection({ ...dataCollection, ...insert })
          }
          setDataCollection={setDataCollection}
          startDataCollection={startDataCollection}
        />
        {label && (
          <Typography variant="h2" textAlign="center" className="pb-3">
            {label}
          </Typography>
        )}
        {debug && (
          <>
            <Button
              color="warning"
              variant="contained"
              onClick={() => console.log(dataCollection)}
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
              }}
            >
              LOG DATA
            </Button>
            <Button
              color="warning"
              variant="contained"
              onClick={() => console.log(steps)}
              sx={{
                position: "absolute",
                top: "40px",
                left: "0",
              }}
            >
              LOG STEPS
            </Button>
          </>
        )}
        <Stepper
          className="w-full overflow-auto"
          activeStep={activeStep}
          orientation={orientation}
          sx={{ minHeight: "40px" }}
        >
          {steps &&
            steps.map((step, index) => (
              <Step key={step.label + "-" + index}>
                <StepLabel
                  sx={{
                    "& .MuiStepLabel-iconContainer": {
                      height: "22px",
                    },
                  }}
                  className=" cursor-pointer"
                  onClick={() => handleStepClick(index)}
                  optional={
                    step.subLabel && (
                      <Typography variant="caption">{step.subLabel}</Typography>
                    )
                  }
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
        </Stepper>
        {steps && orientation != "vertical" && (
          <ActiveStep
            previewDocument={previewDocument}
            onClose={onClose}
            steps={steps}
            setSteps={setSteps}
            finalStep={finalStep}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            dataCollection={dataCollection}
            setDataCollection={setDataCollection}
            isVertical={orientation === "vertical"}
            message={message}
            onReset={handleReset}
          />
        )}
      </Box>
      {previewDocument && (
        <Box
          className="flex h-full relative"
          sx={{
            display: "none",
            [`@media (min-width: ${breakWidth})`]: {
              display: "flex",
            },
          }}
        >
          <Button
            onClick={() => updateOpenPreview(!openPreview)}
            className=" -left-9 z-50 p-0 flex"
            variant="contained"
            color="secondary"
            sx={{
              position: "absolute",
              minWidth: "unset",
              width: "20px",
              height: "100% ",
              top: "0",
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                transform: openPreview ? `rotateY(180deg)` : `rotateY(0deg)`,
                transition: "transform 0.5s",
              }}
            />
          </Button>
          <Box
            ref={containerRef}
            sx={{
              width: `${documentContainerWidth}px`,
              display: "none",
              [`@media (min-width: ${breakWidth})`]: {
                display: "flex",
              },
            }}
            className=" justify-center items-center h-full hidden md:flex"
          >
            <ExoDocumentPreview
              dataCollection={dataCollection}
              documentId={dataCollection.templateId}
              previewDocument={
                dataCollection.recipientType
                  ? `${previewDocument}_${dataCollection.recipientType}`
                  : `${previewDocument}_company`
              }
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const ActiveStep = ({
  onClose,
  steps,
  setSteps,
  activeStep,
  setActiveStep,
  finalStep,
  dataCollection,
  setDataCollection,
  previewDocument,
  isVertical,
  message,
  onReset,
}) => {
  const updateValidation = (valid) => {
    var newSteps = steps;
    newSteps[activeStep].valid = valid;
    setSteps([...newSteps]);
  };

  const updateDataCollection = (insertData) => {
    setDataCollection({ ...dataCollection, ...insertData });
  };

  return (
    <Box className="w-full h-full flex flex-col px-0 overflow-hidden">
      {steps && activeStep === steps.length ? (
        <FinalStep
          finalStep={finalStep}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          dataCollection={dataCollection}
          steps={steps}
          message={message}
          onReset={onReset}
          onClose={onClose}
        />
      ) : (
        <Box className="flex flex-col w-full h-full overflow-hidden">
          {steps[activeStep].discription && (
            <Typography>{steps[activeStep].discription}</Typography>
          )}
          <Box
            className=" w-full overflow-y-auto flex flex-col h-full"
            sx={{
              paddingBottom: "10px",
            }}
          >
            {steps[activeStep].content({
              steps: steps,
              step: steps[activeStep],
              activeStep: activeStep,
              setActiveStep: setActiveStep,
              dataCollection: dataCollection,
              updateValidation: updateValidation,
              updateDataCollection: updateDataCollection,
            })}
          </Box>
          {!steps[activeStep].noControlls && (
            <Controls
              dataCollection={dataCollection}
              previewDocument={previewDocument}
              onClose={onClose}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              lastStep={activeStep + 1 == steps.length}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

const FinalStep = ({
  finalStep,
  setActiveStep,
  dataCollection,
  steps,
  activeStep,
  message = "Loading...",
  onReset,
  onClose,
}) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState({});

  function handleResponse(data, state) {
    setSuccess(true);
    setResponse(data);
  }

  function handleError(error, status) {
    setSuccess(false);
    setResponse(error);
  }

  useEffect(() => {
    if (activeStep === steps.length && steps.length !== 0) {
      finalStep.operation({
        dataCollection,
        handleResponse,
        handleError,
        setIsLoading,
      });
    }
  }, [activeStep]);

  return (
    <>
      <Loader active={isLoading} message={message} />
      {success !== null && (
        <Box className=" h-full flex flex-col justify-between">
          <Box className="w-full h-full flex flex-col justify-center items-center gap-4 overflow-y-auto relative">
            {success ? (
              <>
                <SuccessCheck />
                {finalStep.content(response)}
              </>
            ) : (
              <>
                <FailureCross />
                <Alert severity="error">{response.message}</Alert>
              </>
            )}
          </Box>
          <Box className="p-2 flex  w-full justify-between ">
            {!finalStep.noReset && (
              <Button variant="contained" color="warning" onClick={onReset}>
                {t("Reset")}
              </Button>
            )}
            {!finalStep.noClose && (
              <Button variant="contained" color="success" onClick={onClose}>
                {t("Close")}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export const Controls = ({
  onClose = {},
  steps,
  lastStep = false,
  activeStep,
  setActiveStep,
  btnNext = true,
  btnBack = true,
  dataCollection = {},
  previewDocument,
}) => {
  const { t } = useTranslation();
  const valid = (steps && steps[activeStep].valid) || false;
  const handleNext = () => {
    if (steps[activeStep].onNext) steps[activeStep].onNext(dataCollection);
    if (!lastStep || !previewDocument) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setOpenDialogPreview(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function handleCancle() {
    setOpenDialogPreview(false);
  }

  function handleSubmit() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setOpenDialogPreview(false);
  }

  // preview
  const [openDialogPreview, setOpenDialogPreview] = useState(false);
  return (
    <Box className="p-2 flex flex-row w-full justify-between bottom-0 left-0">
      {btnBack && (
        <>
          {activeStep == 0 ? (
            <Button onClick={onClose} color="error" variant="outlined">
              {t("Close")}
            </Button>
          ) : (
            <Button onClick={handleBack} disabled={!activeStep}>
              {t("Back")}
            </Button>
          )}
        </>
      )}
      {btnNext && (
        <Button
          onClick={handleNext}
          color={"success"}
          variant={lastStep ? "contained" : "outlined"}
          disabled={!valid}
        >
          {lastStep ? t("Finish") : t("Next")}
        </Button>
      )}
      <PreviewBeforeSend
        onCancle={handleCancle}
        onSubmit={handleSubmit}
        setActiveStep={setActiveStep}
        open={openDialogPreview}
        dataCollection={dataCollection}
        previewDocument={previewDocument}
        valid={valid}
      />
    </Box>
  );
};

const PreviewBeforeSend = ({
  dataCollection = {},
  previewDocument,
  open,
  onCancle,
  onSubmit,
  valid,
}) => {
  const { t } = useTranslation();
  const dialogContainerRef = useRef(null);
  const [maxDialogContainerHeight, setMaxDialogContainerHeight] = useState(0);

  useEffect(() => {
    if (!dialogContainerRef) return;

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial setup
    handleResize();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dialogContainerRef, dialogContainerRef.current]);

  function handleResize() {
    if (dialogContainerRef.current) {
      const maxDialogHeight =
        dialogContainerRef.current.offsetWidth * Math.sqrt(2);
      setMaxDialogContainerHeight(maxDialogHeight);
    }
  }

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": {
          width: "auto!important",
          backgroundColor: "transparent",
        },
      }}
    >
      <Box
        ref={dialogContainerRef}
        className="flex overflow-hidden"
        sx={{
          width: "calc((90vh - 49px) / 1.414)",
          height: "(90vh - 49px)",
          maxWidth: "100%",
          maxHeight: maxDialogContainerHeight
            ? `${maxDialogContainerHeight}px`
            : "auto",
          aspectRatio: "0.707!important",
        }}
      >
        <ExoDocumentPreview
          dataCollection={dataCollection}
          previewDocument={
            dataCollection.recipientType
              ? `${previewDocument}_${dataCollection.recipientType}`
              : `${previewDocument}_company`
          }
        />
      </Box>
      <Box className="p-2 flex flex-row w-full justify-between bottom-0 left-0">
        <Button onClick={onCancle}>{t("Back")}</Button>

        <Button
          onClick={onSubmit}
          color={"success"}
          variant="contained"
          disabled={!valid}
        >
          {dataCollection.delivery &&
          dataCollection.delivery.deliveryType === "saveOnly"
            ? t("Save")
            : t("Send")}
        </Button>
      </Box>
    </Dialog>
  );
};

export const FinalPage = ({ response, setData, data }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (data && setData && response)
      setData(updateOrCreate(data, response.data));
  }, [response, data, setData]);

  return (
    <Box className=" w-full flex flex-col items-center justify-center">
      <Typography variant="h4">{response.message}</Typography>
      {response.data.file && (
        <Button href={response.data.file.temporaryUrl} target="_blank">
          {t("Show File")}
        </Button>
      )}
    </Box>
  );
};

export const FinalPageCreate = ({ response, onCreate }) => {
  const { t } = useTranslation();
  useEffect(() => {
    onCreate(response.data);
  }, []);

  return (
    <Box className=" w-full flex flex-col items-center justify-center">
      <Typography variant="h4">{response.message}</Typography>
      {response.data.file && (
        <Button href={response.data.file.temporaryUrl} target="_blank">
          {t("Show File")}
        </Button>
      )}
    </Box>
  );
};

export default ExoStepper;
