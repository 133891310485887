import moment from "moment";
import { useEffect, useState } from "react";
import { useConfig } from "../../api/api";
import { useTranslation } from "react-i18next";

const DateLabel = ({ date, format }) => {
  const config = useConfig();
  const [dateLabel, setDateLabel] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    if (!config) return;
    const formattedDate = moment(date).format(
      format || config.general.dateOutputFormat
    );
    setDateLabel(formattedDate);
  }, [config]);

  return <>{dateLabel === "Invalid date" ? t("Infinite") : t(dateLabel)}</>;
};

export default DateLabel;
