import ExoAvatar from "../../../components/exo/ExoAvatar";
import { tokens } from "../../../global/theme/tokens";
import { Box, useTheme, Typography } from "@mui/material";
import { renderHours } from "../../time-tracking/worktime";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const TeamMemberTimeCard = ({ time, person, onSelect = () => {} }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="flex w-full p-2 rounded-lg flex-row gap-5 transition-transform cursor-pointer backdrop-blur-lg items-center justify-between"
      sx={{
        backgroundColor: colors.card,
        ":hover": {
          backgroundColor: colors.selected,
        },
      }}
      onClick={() => onSelect(person)}
    >
      <Box className=" flex gap-3 ">
        <ExoAvatar
          name={person.firstName + " " + person.lastName}
          icon={person.icon}
        />
        <Box className="flex justify-start items-center relative">
          <Box>
            <Typography variant="h4">
              {person.firstName + " " + person.lastName}
            </Typography>
            <Typography variant="h5" sx={{ color: colors.grey[500] }}>
              {person.position}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Typography
        variant="h4"
        className="monospace flex items-center gap-2 whitespace-nowrap"
      >
        <AccessTimeIcon />
        <b>{renderHours(time)} h</b>
      </Typography>
    </Box>
  );
};

export default TeamMemberTimeCard;
