import Cookies from "universal-cookie";
const cookies = new Cookies();
const permissions = cookies.get("permissions");

const lvlNumberMap = {
  read: 1,
  "read/write": 2,
  write: 2,
};

export function hasPermissions(key, level = null) {
  if (!permissions) return false;
  if (permissions.admin === "*") return true;
  if (!level && permissions[key]) return true;
  if (lvlNumberMap[permissions[key]] >= lvlNumberMap[level]) return true;
  return false;
}
