import { useTheme, Box } from "@mui/material";
import { useEffect } from "react";
import { tokens } from "../../global/theme/tokens";
import SelectContractTemplate from "../select/SelectContractTemplate";

const StepContractTemplate = ({
  dataCollection,
  updateValidation,
  updateDataCollection,
  activeStep,
  setActiveStep,
  type = "contract",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handleContractTemplateSelect(preset) {
    updateDataCollection({ preset: preset });
    setActiveStep(activeStep + 1);
  }

  // validation
  useEffect(() => {
    updateValidation(dataCollection.preset !== undefined);
  }, [dataCollection]);
  return (
    <SelectContractTemplate
      type={type}
      onSelect={handleContractTemplateSelect}
    />
  );
};

export default StepContractTemplate;
