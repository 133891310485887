import { Box, useTheme } from "@mui/material";
import { BarChart, ChartsReferenceLine } from "@mui/x-charts";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../global/theme/tokens";

export const MuiBarChart = ({
  data,
  xKey,
  yKey,
  labelY,
  valueFormatter = (v) => v,
  labelX,
  limit,
  line,
  series,
  color,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  const labelShift = {
    top: 30,
    right: 50,
    bottom: labelX ? 0 : 20,
    left: labelY ? 0 : 40,
  };

  useEffect(() => {
    if (containerRef.current) {
    }
  }, [containerRef.current]);

  const chartSetting = {
    yAxis: [
      {
        label: labelY,
        max: limit,
      },
    ],
    series: series || [
      {
        dataKey: yKey,
        color: color || colors.blueAccent[500] + "AB",
        valueFormatter,
      },
    ],
    xAxis: [
      {
        scaleType: "band",
        dataKey: xKey,
        label: labelX,
        tickPlacement: "middel",
        tickLabelPlacement: "middel",
      },
    ],
  };

  const handleResize = () => {
    // Update containerWidth when the container is resized
    if (containerRef.current) {
      const height =
        containerRef.current.offsetHeight + labelShift.top + labelShift.bottom;
      const width = containerRef.current.offsetWidth + labelShift.right;
      setDimensions({ height, width });
    }
  };
  useEffect(() => {
    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial setup
    handleResize();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);

  return (
    <Box
      ref={containerRef}
      className="w-full h-full  overflow-hidden"
      sx={{
        ">div": {
          justifyContent: "start",
        },
      }}
    >
      <BarChart
        dataset={data}
        {...chartSetting}
        {...dimensions}
        sx={{
          "& rect": {
            rx: "5px",
          },
          marginTop: `-${labelShift.top + 20}px`,
          marginLeft: `-${labelShift.left}px`,
          "& .MuiChartsAxis-left": {
            display: "none",
          },
          "& .MuiChartsAxis-bottom": {
            "& text": {
              opacity: 0.5,
            },
            "& line": {
              display: "none",
            },
          },
        }}
      >
        {line && (
          <ChartsReferenceLine
            y={line}
            lineStyle={{ opacity: 0.3, strokeDasharray: 10 }}
          />
        )}
      </BarChart>
    </Box>
  );
};
