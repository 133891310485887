import { FormControlLabel, Radio } from "@mui/material";

const OrderItem = ({ item, onChange, active }) => {
  function handleChange(e) {
    const checked = e.target.checked;
    if (checked) onChange();
  }

  return (
    <FormControlLabel
      value={item.value}
      control={<Radio onChange={handleChange} />}
      label={item.label}
      checked={active}
    />
  );
};

export default OrderItem;
