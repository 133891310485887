import { Box, Skeleton } from "@mui/material";

const TypeSkeleton = ({ n = 1, className }) => {
  return (
    <Box className="flex flex-col gap-4">
      {Array(n)
        .fill(null)
        .map((_, index) => (
          <Skeleton
            key={index}
            className={className}
            sx={{
              padding: 0,
              margin: 0,
              height: "53px",
              transform: "unset",
              borderRadius: "10px",
            }}
          />
        ))}
    </Box>
  );
};

export default TypeSkeleton;
