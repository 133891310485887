import React from "react";
import ExoTable from "../exo/ExoTable";
import { Box, Button, Typography } from "@mui/material";
import MoneyLabel from "../label/MoneyLabel";
import { useTranslation } from "react-i18next";
import useFetch from "../../api/useFetch";
import DateLabel from "../label/DateLabel";
import { ExoTableUser } from "../exo/ExoTableUser";

export const SelectPayroll = ({
  filter,
  onSelect,
  className,
  apiUrl,
  hasOpenAmount,
}) => {
  const { t } = useTranslation();

  const { apiData } = useFetch(apiUrl || `payrolls?${filter}`);

  const Columns = [
    {
      header: t("Employee"), // header of column
      key: "user", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <ExoTableUser user={row.user} />, // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Monat"), // header of column
      key: "endDate", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "230px", // width of column
      cell: ({ row }) => (
        <Box className="monospace">
          <DateLabel format="MM/YYYY" date={row.endDate} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Salary"), // header of column
      key: "startDate", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "230px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => (
        <Box className="monospace">
          <MoneyLabel money={row.amount} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Open"), // header of column
      key: "openAmount", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "200px", // width of column
      hiddenMobile: !hasOpenAmount, // hides the column on mobile
      hiddenDesktop: !hasOpenAmount, // hides the column on desktop
      cell: ({ row }) => (
        <Typography className=" monospace">
          <MoneyLabel money={row.openAmount} />
        </Typography>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
  ];
  return (
    <ExoTable
      data={apiData}
      columns={Columns}
      isLoading={!apiData}
      className={className}
    />
  );
};
