import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import BankCard from "../BankCard";
import { useTranslation } from "react-i18next";

const NoBankAccount = ({ setOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const demoBank = {
    color: colors.greenAccent[500],
    bankName: t("First Bankcard"),
    iban: t("Click Here"),
    accountHolder: "",
  };

  return (
    <Box className=" h-full w-full flex justify-center items-center">
      <Box onClick={() => setOpen(true)} className="cursor-pointer w-full">
        <BankCard data={[demoBank]} single />
      </Box>
    </Box>
  );
};

export default NoBankAccount;
