import { useEffect, useState } from "react";
import ExoPositions from "../exo/ExoPositions";

const StepPositions = ({
  updateDataCollection,
  dataCollection,
  updateValidation,
  imageType,
  imageApiFilter,
  collapseModules,
}) => {
  function handlePositionChange(positions) {
    updateDataCollection({ positions: positions });
  }

  // validation
  useEffect(() => {
    var pos = dataCollection.positions;
    if (pos == undefined) pos = [];
    var valPositions = true;

    for (let index = 0; index < pos.length; index++) {
      if (
        !pos[index].isGroup &&
        (!pos[index].name ||
          isNaN(pos[index].price) ||
          isNaN(pos[index].amount))
      ) {
        valPositions = false;
      }
    }
    updateValidation(valPositions);
  }, [dataCollection]);

  return (
    <ExoPositions
      imageType={imageType}
      imageApiFilter={imageApiFilter}
      onPositionChange={handlePositionChange}
      startPositions={dataCollection.positions}
      collapseModules={collapseModules}
      defaultTaxRate={
        dataCollection.templateMetaData &&
        dataCollection.templateMetaData.taxRate
      }
    />
  );
};

export default StepPositions;
