import { Box, Button, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { renderHours } from "../../time-tracking/worktime";
import ExoAvatar from "../../../components/exo/ExoAvatar";
import { NavLink } from "react-router-dom";
import { tokens } from "../../../global/theme/tokens";

export const ActiveTimeTracking = ({ activeTime }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  if (!activeTime) return;
  const currentMinutes =
    moment().diff(moment(activeTime.timeStart)) / (1000 * 60);
  const project = activeTime.project;
  const name = !project.clientType
    ? t("internal")
    : project.clientType === "company"
    ? project.client.name
    : `${project.client.lastName} ${project.client.firstName}`;

  return (
    <Box className="w-full pt-3 px-1">
      <NavLink to={`/projects/management/${project.id}`}>
        <Button
          className="w-full"
          sx={{
            padding: "0px 8px",
            border: `1px solid ${colors.selected}30`,
            bgcolor: colors.selected + "80",
            ":hover": {
              borderColor: colors.info + "80",
              bgcolor: colors.info + "30",
            },
          }}
        >
          <Box className="flex items-center gap-1 w-full">
            {project.image ? (
              <img
                src={project.image.temporaryUrl}
                className="w-6 h-6 object-contain"
              />
            ) : (
              <ExoAvatar
                picture={
                  project.client.logo && project.client.logo.temporaryUrl
                }
                type={project.clientType}
                name={name}
                size="128px"
              />
            )}
            <Typography>{project.name}</Typography>
          </Box>
        </Button>
      </NavLink>
      <Typography variant="h6" className="monospace px-2">
        {`${t("since")} ${renderHours(currentMinutes)}h`}
      </Typography>
    </Box>
  );
};
