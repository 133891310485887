import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const RenderHTML = ({
  HTML,
  className = "",
  sx = {},
  onClick,
  id,
  innerRef,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      ref={innerRef}
      id={id}
      onClick={onClick}
      className={className}
      sx={sx}
      dangerouslySetInnerHTML={{
        __html: HTML
          ? HTML
          : `<h1 style='text-align: center;'>${t("EMPTY")}</h1>`,
      }}
    ></Box>
  );
};

export default RenderHTML;
